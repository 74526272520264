<template>
<nlp-input
	v-if="isAtNLP"
	:is-a="!useVueRouting"
	:datamart="datamart"
	:context="context"
	:nlp-url="nlpUrl"
	:nlp-examples="nlpExamples"
	:mock-query="mockQuery"
	:starting-nlp-search="startingNlpSearch"
	@resolveStartingNlpSearch="$emit('resolveStartingNlpSearch')"
	@goToSQL="goToSQL"
	@goToWizard="goToWizard"
	@goToQuery="newFromNLP" />

<div v-else class="pz-query-view d-flex flex-column overflow-hidden">
	<!-- query header -->
	<page-header
		id="query-view-header"
		:actions="actions"
		level="3"
		@action-clicked="onButtonClicked">
		<template #title>
			<span>{{ headerTitle }} {{ hasChanges? '*': '' }} &emsp;
				<span v-if="!isReadOnly && !isNew" @click="editDetails">
					<icon
						v-tooltip.hideOnClick
						name="edit"
						size="sm"
						custom-class="text-primary"
						title="Edit Name/Description" />
				</span>
			</span>
		</template>
		<template #subtext>
			<div class="custom-popover-container">
				<div class="text-muted">
					<!-- eslint-disable-next-line vue/no-v-html -->
					<p v-html="subtext">
						{{ subtext }}
					</p>
					<div v-if="description" class="pz-query-description">
						<!-- since we are preserving whitespace, must have all this on one line to prevent extra visible spaces -->
						<span v-if="isDescriptionOverflowing">{{ descriptionSubstring }}<span v-contained-popover:bottom :data-content="description">...</span></span>
						<span v-else>{{ description }}</span>
					</div>
				</div>
			</div>
		</template>
	</page-header>

	<!-- content container -->
	<div class="p-4 bg-light flex-grow-1 d-flex flex-column overflow-auto">
		<!-- editor section -->
		<wizard-wrapper
			v-if="queryType === 'datamart'"
			:structured-query="structuredQuery"
			:ace-loaded="aceLoaded"
			:sql="sql"
			:sql-error="sqlError"
			:datamart="datamart"
			:nlp-search="nlpSearch"
			:nlp-url="nlpUrl"
			:mock-query="mockQuery"
			:has-insights="!!insights && !!insights.length"
			:can-run="canRun"
			@updateQuery="onQueryUpdated"
			@goToSQL="goToSQL" />

		<!-- SQL Editor -->
		<sql-editor
			v-if="queryType ==='sql' && aceLoaded"
			:query="sql"
			:datamart="datamart"
			@change="updateSQL" />

		<!-- results section -->
		<results-section
			class="flex-grow-1 mt-3"
			:is-wizard-mode="isWizardMode"
			:has-run="hasRun"
			:collection="collection"
			:is-outdated="runner.hasUnrunChanges"
			:can-run="canRun"
			:is-running="isRunning"
			:run-error="runError"
			:total-columns="totalColumns"
			:runner="runner"
			:datamart="datamart"
			:insights="insights"
			:start-at-insights="startAtInsights"
			@updateInsights="updateInsights"
			@download="download"
			@openColumnDialog="openColumnDialog"
			@run="run"
			@dismissError="dismissError" />
	</div>
	<download-dialog
		:open="isDownloadDialogOpen"
		:runner="runner"
		:should-use-columns="isWizardMode"
		:initial-values="downloadDialogOptions"
		:datamart="datamart"
		:structured-query="structuredQuery"
		@close="onDownloadClose" />

	<switch-dialog
		ref="dialog"
		:query-name="queryData.name"
		@goToSQLConvert="goToSQLConvert"
		@goToSQLCopy="goToSQLCopy" />

	<column-selector-dialog
		v-if="isColumnSelectorOpen"
		ref="columndialog"
		:datamart="datamart"
		:structured-query="structuredQuery"
		@updateColumns="updateColumns"
		@close="onColumnSelectorClose"
		@run="run" />
</div>
</template>

<script>
import { ContainedPopover, Icon, PageHeader, Tooltip } from 'aunsight-lib-ui';
import awiPrompt from 'aunsight-webapp/src/js/AWIComponents/Prompt/Prompt';
import QueryRunManager from 'aunsight-webapp/src/js/modules/GuidedQueryBrowser/models/QueryRunManager';
import TableCollection from 'aunsight-webapp/src/js/modules/GuidedQueryBrowser/models/TableCollection';
import getXHRError from 'aunsight-webapp/src/js/util/getXHRError';
import $ from 'jquery';
import _ from 'lodash';
import moment from 'moment';

import app from '@/webapp';

import ColumnSelectorDialog from './ColumnSelectorDialog.vue';
import DownloadDialog from './DownloadDialog.vue';
import NLPInput from './NLPPage.vue';
import ResultsSection from './ResultsSection.vue';
import SQLEditor from './SQLEditor.vue';
import SwitchDialog from './SwitchDialog.vue';
import MockQueryJob from './testUtils/MockQueryJob';
import startingInsights from './util/startingInsights';
import WizardWrapper from './WizardWrapper.vue';

export default {
	components: {
		PageHeader,
		WizardWrapper,
		ResultsSection,
		SwitchDialog,
		NlpInput: NLPInput,
		SqlEditor: SQLEditor,
		Icon,
		ColumnSelectorDialog,
		DownloadDialog
	},
	directives: {
		'contained-popover': ContainedPopover('.custom-popover-container'), Tooltip
	},

	props: {
		datamart: {
			type: Object,
			required: true
		},
		context: {
			type: Object,
			required: true
		},
		mockQuery: {
			type: Boolean,
			required: true
		},
		queryData: {
			type: Object,
			required: true
		},

		// one of ['sql', 'wizard', or 'text']
		startTool: {
			type: String,
			default: undefined
		},

		newURL: {
			type: String,
			required: true
		},
		aceLoaded: {
			type: Boolean,
			default: true
		},
		useVueRouting: {
			type: Boolean
		},
		nlpUrl: {
			type: String,
			required: true
		},
		nlpExamples: {
			type: Array,
			required: true
		},
		startingNlpSearch: {
			type: String,
			default: null
		}
	},
	data () {
		return {
			isAtNLP: undefined,
			runner: undefined,
			collection: undefined,
			hasRun: undefined,
			// the sql query created by child (null if invalid)
			sql: undefined, // string
			// the sql base query created by child (null if invalid)
			baseSql: undefined, // string
			// the updated structuredQuery created by child
			structuredQuery: undefined, // object
			queryType: undefined, // 'datamart' or 'sql'
			// the error that occurred creating sql (as a string) (or null if valid)
			sqlError: null,
			downloadDialogOptions: {},
			isDownloadDialogOpen: false,
			hasChanges: undefined,
			initLastRunDate: undefined,
			isLastRunDateFetching: false,
			isRunning: false,
			// hold the error from the last run so it can be passed to the view
			runError: undefined,

			// what the user input at nlp to create current structured query
			nlpSearch: undefined,

			isColumnSelectorOpen: false,

			insights: []
		};
	},

	computed: {
		isWizardMode () {
			return this.queryType === 'datamart';
		},

		canRun () {
			return !this.sqlError && !!this.sql;
		},

		isNew () {
			return !this.queryData.id;
		},

		isReadOnly () {
			return !!this.queryData.readOnly;
		},

		subtext () {
			if (this.isNew || this.queryData.category === 'template') return '';
			const items = [`Last run: ${this.getLastRunSubtext()}`];

			if (this.queryData.createdByName) {
				items.push(`Created By: ${this.queryData.createdByName}`);
			}

			if (this.queryData.tags) {
				const tags = [...this.queryData.tags].sort();
				items.push(`Tags: ${tags.join(', ')}`);
			}

			return items.join('&emsp;');
		},

		description () {
			return this.queryData.description;
		},

		isDescriptionOverflowing () {
			return this.description.length > 70 || this.description.substring(0, 70).includes('\n');
		},

		descriptionSubstring () {
			if (!this.queryData.description) return;
			let description = this.queryData.description.substring(0, 70);
			if (description.includes('\n')) {
				const ret = description.indexOf('\n');
				description = description.substring(0, ret);
			}
			return description;
		},

		lastRunDate () {
			return this.runner.lastRunDate || this.initLastRunDate;
		},

		headerTitle () {
			return this.queryData.name || 'Untitled';
		},

		getDownloadName () {
			let name;
			if (this.sql && !this.queryData.name) {
				// get name of first table in query
				const regex = /[Aa][Uu]\([ \t]*['"`]?([a-zA-Z0-9-_\s]+)['"`]?[ \t]*\)/;
				const matched = this.sql.match(regex)[1];
				name = matched.trim();
			}
			else name = this.queryData.name || this.structuredQuery.table;

			return name;
		},

		startAtInsights () {
			if (!_.isEmpty(this.insights) && this.nlpSearch) return true;
			else return false;
		},

		actions () {
			const actions = [
				{
					id: 'overflowActions',
					iconName: 'ellipsisV',
					buttonClass: 'btn-secondary',
					kind: 'dropdown',
					showIf: () => (!this.isNew),
					items: [
						{
							id: 'share',
							label: 'Share',
							iconName: 'share',
							buttonClass: 'btn-secondary',
							showIf: () => !this.isReadOnly
							// showIf: ()=> {} // todo only show for own queries
						},
						{
							id: 'saveas',
							label: 'Save As',
							iconName: 'save',
							buttonClass: 'btn-secondary'
						},
						{
							id: 'deleteQuery',
							label: 'Delete',
							iconName: 'trash',
							extraClass: 'text-danger',
							showIf: () => !this.isReadOnly
						}
					]
				},
				{
					id: 'cancel',
					label: 'Back',
					buttonClass: 'btn-secondary',
					url: this.newURL,
					extraClass: 'pz-nav-link',
					showIf: () => !this.isNew
				},
				{
					id: 'cancelnew',
					label: 'Back',
					buttonClass: 'btn-secondary',
					showIf: () => this.isNew
				},
				{
					id: 'save',
					label: 'Save',
					buttonClass: 'btn-secondary',
					showIf: () => !this.isReadOnly
				},
				{
					id: 'run',
					label: this.isRunning ? 'Running Query' : 'Run',
					buttonClass: 'btn-primary',
					iconName: this.isRunning ? 'spinner' : 'play',
					iconOptions: {
						spin: this.isRunning
					},
					isDisabled: !this.canRun
				}

			];
			const items = { items: _.filter(actions, a => a.showIf ? a.showIf(this.queryData) : true) };
			items.items[0].items = _.filter(items.items[0].items, a => a.showIf ? a.showIf(this.queryData) : true);

			return items;
		},

		totalColumns () {
			if (this.queryType !== 'datamart') return null;

			const tableName = this.structuredQuery.table;
			if (!tableName) return null;

			try {
				const table = this.datamart.getTable(tableName);

				return table.spec.propertiesOrder.length;
			}
			catch (err) {
				return null;
			}
		},

		activeTool () {
			if (this.isAtNLP) {
				return 'nlp';
			}
			else if (this.queryType === 'datamart') {
				return 'wizard';
			}
			else if (this.queryType === 'sql') {
				return 'sql';
			}

			// will be this briefly at the beginning.
			// if we let it start with sql, then it won't update with watcher if sql is selected.
			return null;
		}

	},

	watch: {
		// this happens after a convert of a saved query
		'queryData.query_type': function (value) {
			if (value === 'sql') {
				this.switchToSqlMode();
			}

			// no else. can't convert to wizard.
		},

		// report to parent which tool is open so it can modify tool sidebar
		activeTool (value) {
			this.$emit('updateQueryTool', value);
		}
	},

	created () {
		this.hasRun = false;
		this.baseSql = null;
		this.queryType = this.queryData.query_type || 'datamart';
		if (this.queryType === 'sql') {
			this.setHasChanges(false);
		}
		this.sqlError = null;
		this.structuredQuery = this.queryData.structured_query || {};
		this.sql = this.queryData.query || '';

		if (this.queryData.insights) {
			this.insights = _.cloneDeep(this.queryData.insights);
		}

		if (this.queryData.category !== 'template' && !this.isNew) {
			this.fetchLastRunDate();
		}

		if (this.isNew && !!this.nlpUrl && this.startTool !== 'wizard' && this.queryType === 'datamart') {
			this.isAtNLP = true;
		}

		// is there a better way/place to do this?
		const me = this;
		class MyQueryRunManager extends QueryRunManager {
			_listenForQueryChange () {} // change to noop, let updateSQL handle it

			_getQueryName () {
				return me.queryData.name + ' query';
			}

			_getFilters () {
				return me.structuredQuery;
			}

			_getBaseQuery () {
				if (_.isNull(me.baseSql)) return '';
				return me.baseSql;
			}

			_getQuery (columns) {
				// if it is null it means there is an error
				if (_.isNull(me.sql)) return '';

				if (columns) {
					return me.getSQLFromStructured(columns);
				}

				else {
					return me.sql;
				}
			}

			_createQueryJob (options) {
				// if should not actually run query, use a mock job class
				if (me.mockQuery) {
					return new MockQueryJob(options);
				}

				else {
					return super._createQueryJob(options);
				}
			}
		}

		const opts = {
			context: this.context,
			isStructured: this.isWizardMode,
			tool: 'daybreak',
			useInsights: true,
			insights: this.insights || [],
			datamart: this.datamart,
			target: 'scope'
		};
		// for v2, add the datamart id so it can send it on run
		if (this.datamart.queryEngine === 'exasol') {
			opts.engine = 'exasol';
		}
		else {
			// table aliases only used for drill queries
			opts.tableAliases = this.getTableAliases();
		}

		this.runner = new MyQueryRunManager(opts);

		if (this.queryData.id) this.runner.queryId = this.queryData.id;

		const MyTableCollection = TableCollection.extend({
			setSchema (schema) {
				if (schema && this.runner.datamart) {
					schema = this.runner.datamart.augmentQueryResultSchema(schema);
				}
				this.schema = schema;
			}
		});

		this.collection = new MyTableCollection([], { runner: this.runner });
	},

	methods: {
		// wrapping it in a member method helps unit tests
		prompt () {
			return awiPrompt.apply(null, arguments);
		},

		getLastRunSubtext () {
			let date;

			// if still loading, show a spinner
			if (!this.lastRunDate && this.isLastRunDateFetching) {
				date = '<i class="fa fa-spinner fa-spin ml-2"></i>';
			}
			else if (_.isDate(this.lastRunDate)) {
				// moment().calendar nicely formats date in relative terms for past week
				// or a plain date for everything else.
				const formatted = moment(this.lastRunDate).calendar();
				const iso = this.lastRunDate.toISOString();
				const fullDateString = this.lastRunDate.toLocaleString();

				date = `<time datetime="${iso}" title="${fullDateString}">${formatted}</time>`;
			}
			else {
				date = '--';
			}

			return date;
		},

		onButtonClicked (id) {
			// obviously this will get redone :p
			if (id === 'save') this.save();
			else if (id === 'saveas') this.saveas();
			else if (id === 'run') {
				if (this.isWizardMode) this.openColumnDialog();
				else this.run();
			}
			else if (id === 'deleteQuery') this.deleteQuery();
			else if (id === 'cancelnew') this.cancelnew();
			else if (id === 'share') this.share();
		},

		run () {
			this.maybeAddDefaultInsights();

			this.runError = null;

			let promise;
			try {
				// errors may happen in lib-query, catch them and present to user
				promise = this.runner.run();
			}
			catch (error) {
				this.runError = {
					title: 'An error occurred while preparing your query',
					errors: [error]
				};
				return;
			}
			this.isRunning = true;

			const runner = this.runner;
			promise.then(() => {
				this.collection.setData(this.runner.results);
				this.hasRun = true;
				this.isRunning = false;
			})
			// if you don't catch here, will report to sentry
				.catch((response) => {
					this.isRunning = false;

					let failMessage = '';

					// if a job has failed,
					const failedJob = _.find(runner.currentJobs, function (job) {
						if (!job || !job.job) return;
						return job.job.get('state') === 'FAILED';
					});
					if (failedJob) {
						failMessage += `<p>Job id: <strong>${failedJob.job.id}</strong></p>`;
					}

					let err;
					if (_.isFunction(response.state)) {
						err = getXHRError(response);
					}
					else err = response;

					this.runError = {
						message: failMessage,
						errors: [err],
						title: 'An error occurred fetching data'
					};
				});
		},

		/**
		 * for new structured queries, add default insights the first time it's run
		 */
		maybeAddDefaultInsights () {
			if (!this.hasRun && this.isNew && _.isEmpty(this.insights) &&
					this.startTool !== 'nlp' && this.isWizardMode) {
				this.insights = this.runner.insights = _.cloneDeep(startingInsights);
			}
		},

		dismissError () {
			this.runError = undefined;
		},

		save () {
			const data = {
				query_type: this.queryType,
				structured_query: this.structuredQuery,
				query: this.sql,
				tool: 'daybreak',
				insights: this.insights
			};

			if (this.queryData.id) data.id = this.queryData.id;
			this.$emit('save', data);
			this.setHasChanges(false);
		},

		saveas () {
			const data = {
				query_type: this.queryType,
				structured_query: this.structuredQuery,
				query: this.sql,
				tool: 'daybreak',
				insights: this.insights
			};
			if (this.queryData.tags) data.tags = this.queryData.tags;
			this.$emit('saveas', data);
			this.setHasChanges(false);
		},

		editDetails () {
			this.$emit('editDetails');
		},

		deleteQuery () {
			this.$emit('deleteQuery');
			this.setHasChanges(false);
		},

		cancelnew () {
			this.$emit('clearNew');
		},

		share () {
			this.$emit('share');
		},

		download () {
			const dialogOptions = {
				includeHeader: true,
				query_filename: this.getDownloadName
			};

			this.downloadDialogOptions = dialogOptions;
			this.isDownloadDialogOpen = true;
		},

		// recieve updated query from wizard and integrate it
		onQueryUpdated (serialized, isInitial, isReset) {
			// if wizard change is a reset (meaning table changed), need to update the columns
			if (!isReset && this.structuredQuery.columns) {
				serialized.columns = this.structuredQuery.columns;
			}

			// clear insights
			if (isReset) {
				this.runner.insights = this.insights = [];
			}

			this.updateQuery(serialized, isInitial);
		},

		updateQuery (serialized, isInitial) {
			if (this.nlpSearch) {
				// clear nlp info once query is modified.
				// if initial, its just the programatic setting of the query and should not rest nlpSearch
				if (!isInitial) {
					this.nlpSearch = undefined;

					if (this.runner.runSettings.nla) {
						delete this.runner.runSettings.nla;
					}
				}
				// if valid, run the query
				else {
					// nextTick assures all processing of data is done so `canRun` is accurate
					this.$nextTick(() => {
						if (this.canRun) {
							this.run();
						}
					});
				}
			}
			this.structuredQuery = serialized;

			// clear errors from last time
			this.sqlError = null;

			if (!serialized.table) {
				this.sql = '';
				this.baseSql = '';
			}

			else {
				try {
					this.sql = this.getSQLFromStructured();
					this.baseSql = this.getBaseSQLFromStructured();
				}
				catch (err) {
					// If sql cannot be created, set them to null and setup an error
					this.sql = null;
					this.baseSql = null;
					this.sqlError = err;
				}
			}

			this.runner._onQueryChange();

			this.setHasChanges(!isInitial);
		},

		updateSQL (sql) {
			this.sql = sql;

			this.runner._onQueryChange();
			this.setHasChanges(true);
		},

		setHasChanges (val) {
			const oldVal = this.hasChanges;

			this.hasChanges = val;

			// if `hasChanged` value is now different, emit event
			if (oldVal !== val) {
				this.$emit('hasChanges', val);
			}
		},

		onDownloadClose () {
			this.isDownloadDialogOpen = false;
		},

		// columns should be an array of objects with keys column, key, rootkey and table
		getSQLFromStructured (columns) {
			let structuredQuery = this.structuredQuery;

			if (columns) {
				structuredQuery = { ...structuredQuery, columns };
			}

			return this.datamart.buildStructuredQuery(structuredQuery).toSql();
		},

		getBaseSQLFromStructured () {
			const base = {
				table: this.structuredQuery.table
			};

			return this.datamart.buildStructuredQuery(base).toSql();
		},

		newFromNLP (structuredQuery, nlpSearch, insights) {
			this.structuredQuery = structuredQuery;
			this.isAtNLP = false;
			this.nlpSearch = nlpSearch;
			this.runner.runSettings.nla = nlpSearch;

			if (insights) {
				this.runner.insights = this.insights = insights;
			}
		},

		goToWizard () {
			this.isAtNLP = false;
		},

		goToSQL () {
			if (this.isAtNLP) {
				this.queryType = 'sql';

				// reset these as they are not needed in sql mode
				this.baseSql = null;
				this.structuredQuery = {};

				this.runner.isStructured = false;
				this.isAtNLP = false;
			}
			else if (this.isNew || this.isReadOnly) {
				this.prompt({
					type: 'confirm',
					title: 'Want to use the SQL Editor?',
					text: '<p>You have an existing data query already started in the Query Wizard.</p><p>You may start editing this SQL code, but you will no longer be able to use the Query Wizard while you work on this data query.</p><p>Would you like to continue?</p>',
					submitButtonText: 'Use SQL Editor'
				})
					.then(() => {
						this.switchToSqlMode();
					});
			}

			else {
				$(this.$refs.dialog.$el).modal('show');
			}
		},

		goToSQLCopy () {
			$(this.$refs.dialog.$el).modal('hide');

			const data = {
				query_type: 'sql',
				query: this.sql
			};
			this.$emit('createnewfrom', data);
		},

		goToSQLConvert () {
			$(this.$refs.dialog.$el).modal('hide');

			const data = {
				query_type: 'sql',
				structured_query: {},
				insights: null
			};

			this.$emit('save', data, false);
		},

		// needed for drill engine
		// TODO: remove aliases, no longer needed
		getTableAliases () {
			return _.reduce(this.datamart.spec.tables, (p, table) => {
				p[table.name] = table.record;
				return p;
			}, {});
		},

		fetchLastRunDate () {
			if (this.mockQuery) {
				const date = new Date(this.queryData.lastRun);
				this.initLastRunDate = _.isNaN(date.valueOf()) ? null : date;
				return;
			}

			this.isLastRunDateFetching = true;

			const context = {
				context_organization: this.context.organization
			};

			if (this.context.project) {
				context.context_project = this.context.project;
			}

			$.ajax(app.apiPath + 'tracker/job', {
				contentType: 'application/json',
				data: {
					query_id__eq: this.queryData.id,
					limit: 1,
					...context
				}
			})
				.then(response => {
					const job = _.get(response, ['result', 'data', 0]);
					if (!job) this.initLastRunDate = null;
					else {
						this.initLastRunDate = new Date(job.created_at);
					}
				})
				.always(() => {
					this.isLastRunDateFetching = false;
				});
		},

		openColumnDialog () {
			this.isColumnSelectorOpen = true;
		},

		// receive updated columns from selector
		updateColumns (columns) {
			const query = _.clone(this.structuredQuery);

			// null means it returned to default
			if (columns) query.columns = columns;
			else delete query.columns;

			this.updateQuery(query);
		},

		onColumnSelectorClose () {
			this.isColumnSelectorOpen = false;
		},

		updateInsights (method, i, newInsight) {
			if (method === 'add') {
				newInsight = i;

				this.insights.push(newInsight);
				this.refreshInsight(newInsight.id);
			}

			else if (method === 'edit') {
				const hasQueryChanged = !_.isEqual(this.insights[i].query, newInsight.query);
				this.$set(this.insights, i, newInsight);
				this.insights[i] = newInsight;

				if (hasQueryChanged) this.refreshInsight(newInsight.id);
			}
			else if (method === 'delete') {
				this.deleteInsight(i);
				return;
			}

			this.setHasChanges(true);
			this.runner.insights = this.insights;
		},

		deleteInsight (i) {
			const insight = this.insights[i];
			this.prompt({
				title: 'Delete Insight',
				text: `Are you sure you want to delete Insight <span class="entity-name">${insight.title}</span>?`,
				level: 'danger',
				submitButtonText: 'Delete Insight'
			})
				.then(() => {
					this.insights.splice(i, 1);
					this.setHasChanges(true);
					this.runner.insights = this.insights;
				});
		},

		refreshInsight (id) {
			this.runner.runInsightQuery(id);
		},

		/**
		 * what to do when going from structured to sql
		 */
		switchToSqlMode () {
			this.queryType = 'sql';

			// reset these as they are not needed in sql mode
			this.baseSql = null;
			this.structuredQuery = {};
			this.insights = this.runner.insights = [];

			this.runner.isStructured = false;
		}
	}
};
</script>

<style lang="scss">
// get rid of caret after dropdown
.pz-query-view .awi-action-overflowActions.dropdown-toggle::after {
	display: none;
}

.pz-query-description {
	padding-bottom: 0.5em;
	white-space: pre-wrap;
}

.custom-popover-container .popover-body {
	white-space: pre-wrap;
}

#query-view-header h3 span {
	overflow-wrap: break-word;
	hyphens: auto;
}
</style>
