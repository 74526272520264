<template>
<div id="pz-landing-recent-queries" class="card">
	<div class="card-body">
		<h2 class="card-title h5">
			My Recent Queries
			<router-link
				id="view-queries-button"
				:to="{ name: 'Query Catalog' }"
				class="btn btn-primary float-right">
				View all queries
			</router-link>
		</h2>
		<h3 class="card-subtitle h6">
			Jump back in right where you left off
		</h3>

		<!-- widget contents -->
		<div class="mt-3">
			<error-message
				v-if="fetchErr"
				:errors="[fetchErr]"
				title="Loading Error"
				message="Unable to fetch your recent queries" />

			<!-- load spinner -->
			<div v-else-if="queries === null" class="text-center">
				<i class="fa fa-spinner fa-spin fa-2x" />
			</div>

			<!-- Empty view -->
			<div v-else-if="!queries.length" class="py-5">
				No recent queries at this time,
				please go to the <router-link :to="{name: 'Query Builder'}">
					Query Builder
				</router-link>
				to get started
			</div>

			<!-- card container -->
			<div v-else class="row row-cols-2 row-cols-lg-4">
				<!-- for each query, make a card -->
				<div
					v-for="query in queries"
					:key="query.id"
					class="col mb-4 px-2">
					<query-card
						class="h-100"
						:query="query"
						:use-icons="false"
						:show-date="true" />
				</div>
			</div>
		</div> <!-- end widget contents -->
	</div>
</div>
</template>

<script>
import { ErrorMessage } from 'aunsight-lib-ui';
import getXHRError from 'aunsight-webapp/src/js/util/getXHRError';
import $ from 'jquery';
import _ from 'lodash';

import QueryCard from '@/components/QueryCard';
import app from '@/webapp';

/**
 * landing
 */
export default {
	name: 'LandingRecentQueriesWidget',

	components: { ErrorMessage, QueryCard },

	props: {
		context: {
			type: Object,
			required: true
		}
	},

	data () {
		return {
			queries: null,

			fetchErr: null
		};
	},

	created () {
		this.fetchQueries();
	},

	methods: {
		fetchQueries () {
			const data = {
				config: this.context.config,
				scope: this.context.scope,
				context_organization: this.context.organization,
				context_project: this.context.project,
				limit: 4,
				tool: 'daybreak',
				user: app.auth.user.id
			};
			$.ajax(app.apiPath + 'query/recent-queries', { data })
				.then(
					response => {
						this.queries = response.result;
					},
					err => {
						this.fetchErr = _.isFunction(err.state) ? getXHRError(err) : err;
					}
				);
		}

	}
};
</script>
