<template>
<div class="pz-sql-editor-container">
	<h4 class="mb-3">
		SQL Editor
	</h4>
	<div ref="editor" class="pz-sql-editor" />

	<!-- if user has order by, give warning about table not following it -->
	<div v-if="shouldShowWarning" class="alert alert-info mt-2 px-2 py-1">
		<i class="fa fa-info-circle" />
		Any ordering here will be applied in downloaded data, but you must use the sort controls for the table below
	</div>
</div>
</template>

<script>
import _ from 'lodash';

import EventBus from './util/DaybreakEventBus';
import getFieldCompletions from './util/getFieldCompletions';

export default {
	name: 'SQLEditor',

	props: {
		query: {
			type: String,
			default: ''
		},
		datamart: {
			type: Object,
			required: true
		}
	},

	computed: {
		hasOrderBy () {
			// words "order by" surrounded by word boundaries, separated by one or more spaces, case insensitive
			const regex = /\border(?:\s+)by\b/i;
			return regex.test(this.query);
		},
		shouldShowWarning () {
			return this.hasOrderBy && this.datamart.queryEngine === 'exasol';
		}
	},

	mounted () {
		this.renderEditor();
		EventBus.$on('data-source-tree:column-clicked', this.insertColumn);
	},

	beforeDestroy () {
		if (this.editor) {
			this.editor.destroy();
		}
		EventBus.$off('data-source-tree:column-clicked');
	},

	methods: {
		renderEditor () {
			this.$refs.editor.innerHTML = this.query;
			this.editor = window.ace.edit(this.$refs.editor);
			this.enableAutocomplete();

			// announce change event, throttled at 100ms
			this.editor.getSession().on('change', _.throttle((change, editor) => {
				this.$emit('change', this.editor.getValue());
			}, 100, { leading: false }));
		},

		enableAutocomplete () {
			const me = this;
			const langTools = window.ace.require('ace/ext/language_tools');

			this.editor.session.setMode('ace/mode/sql', () => {
				// once sql mode is loaded, make all keywords uppercase so they are appear that way in the autcomplete popup
				this.editor.session.$mode.$highlightRules.$keywordList = me.editor.session.$mode.$highlightRules.$keywordList.map(v => v.toUpperCase());
			});

			this.editor.setOptions({
				enableBasicAutocompletion: true,
				enableLiveAutocompletion: true
			});

			const tables = {};
			_.forEach(this.datamart.getQueryables(), function (table) {
				tables[table.name] = _.values(table.properties);
			});
			const fieldCompleter = { getCompletions: getFieldCompletions(tables) };

			// manually set completers so that textCompleter (local words) and snippetCompleter are not enabled
			langTools.setCompleters([langTools.keyWordCompleter, fieldCompleter]);
		},

		insertColumn ({ column }) {
			let col = column.name;

			// exasol requires columns uppercase
			if (this.datamart.queryEngine === 'exasol') {
				col = _.toUpper(col);
			}
			this.editor.insert(col);
			this.editor.focus();
		}
	}
};

</script>

<style lang="scss">
// I suppose this should be controlled by a variable somewhere
.pz-sql-editor {
	height: 336px;
}

</style>
