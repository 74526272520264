<template>
<action-list
	:items="menuItems"
	class="insight-actions"
	@action-clicked="$emit('action-clicked', $event)" />
</template>

<script>
import { ActionList } from 'aunsight-lib-ui';
export default {
	name: 'InsightActionMenu',
	components: { ActionList },
	props: {
		isReadOnly: { type: Boolean, default: false }
	},
	computed: {
		menuItems () {
			const items = !this.isReadOnly
				? [
					{
						id: 'edit',
						label: 'Edit Insight',
						iconName: 'edit'
					},
					{
						id: 'refresh',
						label: 'Refresh Data',
						iconName: 'cogs'
					},
					{
						id: 'download',
						label: 'Download',
						iconName: 'download'
					},
					{
						id: 'delete',
						label: 'Delete',
						iconName: 'trash',
						extraClass: 'text-danger'
					}
				]
				: [{
					id: 'download',
					label: 'Download',
					iconName: 'download'
				}];

			return [
				{
					id: 'insightActions',
					iconName: 'ellipsisV',
					buttonClass: 'btn btn-secondary',
					kind: 'dropdown',
					rightAligned: true,
					items: items
				}
			];
		}
	}
};
</script>
