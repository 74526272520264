import AuthManager from 'aunsight-webapp/src/js/controllers/AuthManager';

import EventBus from '@/utils/EventBus';
import app from '@/webapp'; ;

class DbAuthManager extends AuthManager {
	onSessionEnd () {
		app.vue.$router.push({ name: 'Login' });
	}

	sendToLogin () {
		app.vue.$router.push({ name: 'Login' });
	}

	trigger () {
		// AuthManager events: 'user:loaded', 'logged:in', 'logged:out'
		EventBus.$emit(...arguments);
		app.radio.trigger(...arguments);
	}
}
export default DbAuthManager;
