<template>
<div class="form-row">
	<div class="col">
		<input
			ref="searchInput"
			v-model="searchString"
			type="search"
			class="form-control"
			name="nlpSearch"
			placeholder="e.g. &quot;customers with an auto loan&quot;"
			@focus="showDropdown"
			@awesomplete-selectcomplete.self="updateSearchString"
			@awesomplete-highlight="skipHeader">
	</div>
	<div class="col-auto">
		<button
			id="nlpSearchSubmit"
			:class="['btn', isDark? 'btn-info' : 'btn-primary']"
			type="submit"
			:disabled="!searchString.length"
			@click.prevent="submit">
			Answer
		</button>
	</div>
</div>
</template>

<script>
import Awesomplete from 'awesomplete';
import $ from 'jquery';
import _ from 'lodash';

import app from '@/webapp';

import getMockSuggestions from './testUtils/getMockSuggestions';

export default {

	props: {
		startSearchString: { type: String, default: undefined },

		// changes button color if needed
		isDark: {
			type: Boolean,
			default: false
		},
		context: {
			type: Object,
			required: true
		},
		nlpAutoUrl: {
			type: String,
			required: true
		},
		mockQuery: {
			type: Boolean,
			required: true
		},
		mockNLPResponseTime: {
			type: Number,
			default: 50
		},
		mockThrottleLevel: {
			type: Number,
			default: 50
		}
	},
	data () {
		return {
			searchString: '',
			optionSelected: false,
			recentSearches: [],
			autocomplete: {}
		};
	},

	watch: {
		searchString: function () {
			if (!this.optionSelected) this.throttledGetSuggestions();
			else this.optionSelected = false;
		}
	},
	mounted () {
		this.getRecentSearches();
		const input = this.$refs.searchInput;
		this.autocomplete = new Awesomplete(input, {
			list: [],
			filter: () => {
				return true;
			},
			sort: false,
			minChars: 0,
			maxItems: undefined,
			item: function (text, input, itemId) {
				const html = _.reduce(text.label, (x, i) => {
					if (i.type === 'label') {
						return x + i.text;
					}
					else if (i.type === 'suggestion') {
						return x + '<strong>' + i.text + '</strong>';
					}
					else return x + i.text;
				}, '');

				return Awesomplete.$.create('li', {
					innerHTML: html,
					role: 'option',
					'aria-selected': 'false',
					id: 'awesomplete_list_' + this.count + '_item_' + itemId,
					class: (text.label[0].type === 'label') ? 'disabled' : ''
				});
			},
			data: function (item, input) {
				return { label: item, value: _.join(_.map(item, 'text'), '') };
			}
		});
	},
	created () {
		if (this.startSearchString) this.searchString = this.startSearchString;
		if (this.mockQuery) {
			this.throttledGetSuggestions = _.throttle(this.displaySuggestions, this.mockThrottleLevel);
		}
		else this.throttledGetSuggestions = _.throttle(this.displaySuggestions, 500);
	},

	methods: {
		submit () {
			this.$emit('submit', this.searchString);
			this.addRecentSearch();
		},
		updateSearchString (input) {
			this.optionSelected = true;
			this.searchString = input.text.value;
		},
		showDropdown () {
			// .list sets the list, but will not allow you to access the contents of the list. For that we have to check ._list
			// If the ._list is empty, that means it has not yet been set (i.e. the user just clicked on the input bar but has not typed anything yet)
			if (this.autocomplete._list.length === 0 && this.searchString === '') {
				const cachedSearches = _.map(this.recentSearches, i => {
					return [{ text: i, type: 'suggestion' }];
				});
				if (cachedSearches.length > 0) {
					// adding the label here, otherwise if the list is empty, there will still be a dropdown with the label.
					this.autocomplete.list = _.concat([], [[{ text: 'Recent Searches', type: 'label' }]], cachedSearches);
				}
			} this.autocomplete.evaluate();
		},

		displaySuggestions () {
			// don't try to fetch if user backspaces to an empty string - it will return an error
			if (this.searchString.length > 0) {
				this.fetchSuggestions().then((res) => {
					if (res.result.suggest.length > 0) {
						this.autocomplete.list = _.concat([], [[{ text: 'Suggested Questions', type: 'label' }]], res.result.suggest);
					}
					else {
						this.autocomplete.list = res.result.suggest;
					}
					this.autocomplete.evaluate();
				});
				return this.autocomplete.list;
			}
			else {
				this.autocomplete.list = [];
				this.showDropdown();
			}
		},
		fetchSuggestions () {
			if (this.mockQuery) {
				const input = this.$refs.searchInput.value;
				return getMockSuggestions(input, this.mockNLPResponseTime);
			}
			else {
				return $.ajax(this.nlpAutoUrl, {
					method: 'post',
					contentType: 'application/json',
					data: JSON.stringify({
						input: this.searchString,
						context_organization: this.context.organization,
						context_project: this.context.project,
						scope_ID: this.context.scope,
						user_ID: app.auth.user.id
					}),
					headers: { Authorization: `Bearer ${app.auth.token.get()}` }
				});
			}
		},
		getRecentSearches () {
			let cachedItems;
			if (localStorage.getItem('recent-searches')) {
				try {
					cachedItems = JSON.parse(localStorage.getItem('recent-searches'));
				}
				catch (error) {
					localStorage.removeItem('recent-searches');
				}

				this.recentSearches.push(...cachedItems);
			}
		},

		addRecentSearch () {
			this.recentSearches.unshift(this.searchString);
			if (this.recentSearches.length > 10) this.recentSearches.pop();
			localStorage.setItem('recent-searches', JSON.stringify(_.uniq(this.recentSearches)));
		},
		skipHeader (item) {
			// to disable the autocomplete label when the user presses the arrow keys
			if (item.text.value === 'Recent Searches' || item.text.value === 'Suggested Questions') {
				this.autocomplete.next();
			}
		}
	}

};
</script>

<style lang="scss">
@import '~awesomplete/awesomplete.css';
@import 'app_variables';

.pz-nlp-container {
	display: grid;
	overflow: auto;
}

.pz-nlp-card {
	height: auto;
	width: 600px;
}

.awesomplete {
	display: block;
	// adding z-index because the <a> tag on the cards under the NLP widget were interfering with the dropdown selections
	z-index: 2;

	ul > li {
		text-align: left;

		&:hover {
			background-color: saturate(mix($info, $body-bg, 7%), 60%);
		}
	}

	ul > li[aria-selected='true'] {
		background-color: saturate(mix($info, $body-bg, 7%), 60%);
		color: $body-color;
	}
}

.disabled {
	pointer-events: none;

	:hover {
		background: none;
	}
}
</style>
