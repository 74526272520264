import $ from 'jquery';
import Vue from 'vue';
import VueGtag from 'vue-gtag';

import router from '@/router/index';
import EventBus from '@/utils/EventBus';
import app from '@/webapp';

$.ajax('/config').then(({ disableReporting }) => {
	Vue.use(VueGtag, {
		includes: [
			{ id: 'UA-99251113-3' }
		],
		config: { id: 'G-E3QNSY1J2S' },
		enabled: !disableReporting
	},
	router
	);
	EventBus.$on('trackEvent', eventInfo => {
		// add event properties
		app.vue.$gtag.event(eventInfo.action, {
			event_category: eventInfo.category,
			event_label: eventInfo.label,
			event_value: eventInfo.value
		});
		// add user properties
		if (eventInfo.userType) {
			app.vue.$gtag.set('user_properties', {
				user_type: eventInfo.userType
			});
		}
	});
});
