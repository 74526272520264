import QueryJob from 'aunsight-webapp/src/js/modules/GuidedQueryBrowser/models/QueryJob';
import _ from 'lodash';

/**
 * Given a datamart and context, fetches enumerations of datamart columns
 *
 * Only for v2 datamarts, v1 datamarts need their enums in the config.
 *
 * @note if a column has no values, it will not be included in results.
 * @param {object} datamart - datamart specs
 * @param {object} context
 * @param {string} context.config - config id
 * @param {string} context.scope - scope id
 * @return {Promise<object>} a promise that resolves to an object whose keys are table name and whose values are key value pairs of column and enum.
 */
function loadEnums (datamart, context) {
	const JOINER = '__';
	const tables = [].concat(datamart.tables, datamart.views || []);

	// for each table, get queries for their categorical columns
	const queryParts = _.reduce(tables, (qparts, table) => {
		// get all the columns that are categorical
		const cols = _.reduce(table.properties, (p, c, i) => {
			if (c.categorical) p.push(i);
			return p;
		}, []);

		// for every column, make a query for it
		const parts = cols.map(col => {
			return `SELECT * FROM (SELECT DISTINCT '${table.id}${JOINER}${col}' AS "field", "${col.toUpperCase()}" AS "value" FROM AU('${table.id}') LIMIT 10001)`;
		});

		// return big list of query parts
		return qparts.concat(parts);
	}, []);

	if (_.isEmpty(queryParts)) {
		return Promise.resolve({});
	}

	const bigQuery = queryParts.join('\nUNION ALL\n');

	const job = new QueryJob({
		target: 'scope',
		targetIds: {
			config: context.config,
			scope: context.scope
		},
		runOptions: {
			query: bigQuery,
			enum: true,
			name: 'daybreak enum',
			tool: 'daybreak'
		}
	});

	return job.run()
		.then(result => {
		// for each result, split the table name and column name
			const enums = _.reduce(result.data, (p, c) => {
				const [table, field] = c.field.split(JOINER);

				// if no entry for table, add one
				if (!p[table]) p[table] = {};
				// if no entry for column, add one
				if (!p[table][field]) p[table][field] = [];

				// add value to column enum
				p[table][field].push(c.value);

				return p;
			}, {});

			// throw out any enumerations larger than 10,000
			_.forEach(enums, table => {
				_.forEach(table, (val, key) => {
					if (val.length > 10000) table[key] = null;
				});
			});

			return enums;
		});
}
export default loadEnums;
