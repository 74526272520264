import { cleanTemplate } from 'aunsight-webapp/src/js/awi-util';
import _ from 'lodash';

export default function (tables) {
	return function (editor, session, pos, prefix, callback) {
		const sqlText = editor.getValue();
		const tablesRegex = /FROM AU\(['"](\w+)['"]\)/gi; // e.g. FROM('Account')
		const tablesInAutocomplete = {};

		for (let match; (match = tablesRegex.exec(sqlText)) !== null;) {
			const selectedTable = match[1];
			const isTableInDatamart = _.has(tables, selectedTable);
			// if the table the user has selected is in the datamart and is not already mentioned , add it to the collection of tables whose fields will be autocompleted
			if (isTableInDatamart && !tablesInAutocomplete[selectedTable]) {
				tablesInAutocomplete[selectedTable] = _.get(tables, selectedTable);
			}
		}

		const fieldsToComplete = [];
		_.forEach(tablesInAutocomplete, (columns, tableName) =>
			columns.reduce((fieldsToComplete, column) => {
				let caption = column.name;
				if (column.name.length > 29) {
					caption = _.truncate(column.name, { length: 30, omission: '\u2026' });
				}
				fieldsToComplete.push({
					caption: caption,
					value: column.name,
					score: -1, // since keywords have score of 0, make field score lower so they are sorted after
					meta: tableName + ' Table',
					docHTML: cleanTemplate(`<div style="font-size: small">
													<strong>Field</strong>: ${column.name}<br>
													<strong>Source</strong>: ${tableName} Table<br>
													<strong>Type</strong>: ${column.type}
												</div>`)
				});
				return fieldsToComplete;
			}, fieldsToComplete)
		);
		callback(null, fieldsToComplete);
	};
}
