<template>
<div
	class="modal fade pz-switch-dialog"
	tabindex="-1"
	role="dialog">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">
					<icon name="exclamationCircle" custom-class="text-danger mr-2" />
					What would you like to do?
				</h5>
				<button
					type="button"
					class="close"
					data-dismiss="modal"
					aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>

			<div class="modal-body">
				<p>You are about to edit the SQL for a saved query that was built using the Query Wizard.</p>
				<p>Before you start editing, please choose an option:</p>

				<div class="option-list text-center">
					<div
						class="card"
						name="convert"
						tabindex="0"
						@click="convert"
						@keyup.enter="convert">
						<div class="card-body">
							<div class="flow-icon-group text-primary">
								<i class="fas fa-file-alt" />
								<i class="fas fa-arrow-right" />
								<i class="fas fa-code" />
							</div>
							<h6>
								Convert Original Query to SQL
							</h6>
							<p>
								We'll convert <strong>{{ queryName }}</strong> into an SQL query. Once converted, you will not be able to use the Query Wizard again for this query.
							</p>
							<p>Any insights will be lost.</p>
						</div>
					</div>

					<div
						class="card"
						name="copy"
						tabindex="0"
						@click="copy"
						@keyup.enter="copy">
						<div class="card-body">
							<div class="flow-icon-group text-primary">
								<i class="fas fa-copy" />
								<i class="fas fa-arrow-right" />
								<i class="fas fa-code" />
							</div>
							<h6>
								Make a Copy
							</h6>
							<p>
								We'll create an Untitled copy of this query, and this new copy will be converted into an SQL query. The Query Wizard will be disabled in this new copy.
							</p>
						</div>
					</div>

					<button
						type="button"
						class="btn btn-link"
						data-dismiss="modal">
						Nevermind, don't do anything
					</button>
				</div> <!-- end list of options -->
			</div>  <!-- end modal body -->
		</div>  <!-- end modal content -->
	</div>  <!-- end modal dialog -->
</div>
</template>

<script>
import { Icon } from 'aunsight-lib-ui';

export default {
	components: { Icon },

	props: {
		queryName: {
			type: String,
			default: ''
		}
	},

	methods: {
		copy () {
			this.$emit('goToSQLCopy');
		},
		convert () {
			this.$emit('goToSQLConvert');
		}
	}
};

</script>

<style lang="scss">

// add spacing between options
.pz-switch-dialog .option-list {
	> :not(:last-child) {
		margin-bottom: 1em;
	}

	.card {
		cursor: pointer;
	}
}

// styles for the 3 flow icons
.pz-switch-dialog .flow-icon-group {
	font-size: large;
	margin-bottom: 0.5em;

	// add spacing between icons
	i:not(:last-child) {
		margin-right: 1rem;
	}
	// lighten color of middle icon. (there's no color style for that blue)
	i:nth-child(2) {
		opacity: 50%;
	}
}

</style>
