<template>
<!-- if config is loading (or failed loading) show progress dialog -->
<div v-if="isLoading" class="pz-config-load-progress-container">
	<mn-component-view
		:key="contextKey + '-loading'"
		:view="ProgressDialog"
		:options="progressDialogOptions" />
</div>

<router-view
	v-else-if="datamart"
	:key="contextKey"
	:datamart="datamart"
	:context="context"
	:config="config" />
</template>

<script>

import MnComponentView from 'aunsight-webapp/src/js/AWIComponents/MnComponentView.vue';
import PromiseProgressDialog from 'aunsight-webapp/src/js/modules/ProgressDialog/PromiseProgressDialog';
import _ from 'lodash';

import loadConfig from '@/DaybreakTool/util/loadConfig.js';
/**
 * Wraps the landing and data builder page.
 *
 * Loads and holds onto the context config so they can be shared between home and data builder.
 */
export default {
	name: 'DaybreakInstance',

	components: {
		MnComponentView
	},

	data () {
		return {
			datamart: undefined,
			context: undefined,
			config: undefined,
			isLoading: false,
			loadError: null,
			ProgressDialog: PromiseProgressDialog,
			progressDialogOptions: undefined,
			// change part of the key between contexts, prevents errors when going
			// from key 0 of one context to key 0 of another
			contextKey: _.uniqueId()
		};
	},

	watch: {
		'$route.params': {
			handler (to, from) {
				if (to.scopeId !== from.scopeId || to.configId !== from.configId) {
					// reset data
					this.datamart = this.context = this.config =
					this.progressDialogOptions = undefined;

					this.contextKey = _.uniqueId();
					this.isLoading = false;
					this.loadError = null;

					this.loadContext();
				}
			},
			deep: true
		}
	},

	created () {
		this.loadContext();
	},

	methods: {
		loadContext () {
			// establish context
			const { configId, scopeId } = this.$route.params;

			// keep a reference in a var to compare later
			const context = this.context = {
				config: configId,
				scope: scopeId
			};

			const promise = loadConfig(this.context);
			this.isLoading = true;
			this.progressDialogOptions = {
				promises: [promise],
				errorDisplay: {
					title: 'An error occurred loading configs'
				},
				showServerError: true,
				embedded: true
			};

			promise.then(({ config, datamart }) => {
				// check if context was changed before loading finished
				if (context !== this.context) {
					return;
				}

				this.config = config;
				this.datamart = datamart;

				// get these context params from the config
				this.context.organization = config.organization;
				this.context.project = config.project;

				this.isLoading = false;
			})
				.catch((error) => {
					let redirectInfo;
					if (error.status === 401) {
						redirectInfo = 'You do not have permissions to view the link you were trying to access.';
					}
					// Missing scope gets returned as a 400 instead of a 404
					else if (error.status === 404 || error.status === 400) {
						redirectInfo = 'Error:  We were unable to find a Daybreak site at this URL. Please check your link again or contact your system administrator.';
					}
					else return;
					// redirect user if they don't have the correct permissions to view a context, or if the context they're looking for doesn't exist
					this.$router.push({
						name: 'Context Selector',
						params: {
							redirectInfo: redirectInfo
						}
					});
				}
				);
		}

	}
};
</script>

<style lang="scss">
.pz-config-load-progress-container {
	padding: 2rem;

	.awi-progress-dialog {
		max-width: 45rem;
		margin-left: auto;
		margin-right: auto;
	}
}
</style>
