<template>
<main id="pz-query-catalog">
	<query-catalog-menu
		id="pz-query-catalog-side-menu"
		:categories="categories"
		:counts="categoryCounts"
		:active-category="activeCategory"
		@setCategory="setCategory" />

	<div class="right-side-container">
		<div id="pz-query-catalog-header">
			<h5>
				{{ activeCategoryTitle }}
			</h5>
			<!-- search queries -->
			<div class="pz-query-catalog-search-widget input-group">
				<input
					id="pz-query-catalog-search-input"
					v-model="inputSearchString"
					class="form-control"
					type="search"
					placeholder="Search"
					title="Search queries"
					aria-label="Search queries"
					aria-controls="pz-query-catalog-items">

				<div class="input-group-append">
					<button
						id="pz-query-catalog-search-clear"
						class="btn btn-outline-secondary"
						type="button"
						title="Clear Search"
						aria-controls="pz-query-catalog-search-input"
						@click="clearSearch">
						<icon name="times" />
					</button>
				</div>
			</div>
		</div>

		<div id="pz-query-catalog-list-container">
			<!-- load indicator -->
			<div v-if="isFetching" class="load-indicator-mask">
				<icon	name="spinner" spin />
			</div>

			<!-- display any fetching errors getting the data -->
			<error-message
				v-if="savedQueryBroker && savedQueryBroker.fetchErr"
				role="alert"
				:errors="[savedQueryBroker.fetchErr]"
				title="Error Fetching Data" />

			<!-- if no queries in list -->
			<div
				v-else-if="!isFetching && queries.length === 0"
				class="d-flex flex-column justify-content-center align-items-center h-100"
				role="status">
				<img
					src="@/assets/empty-results.svg"
					height="100"
					width="100">
				<h5>No queries found</h5>
				<p>Change your criteria or go to the Query Builder to get started</p>
				<router-link
					:to="{ name: 'Query Builder'}"
					class="btn btn-primary mt-2">
					Open Query Builder
				</router-link>
			</div>

			<div id="pz-query-catalog-items" role="list">
				<query-card
					v-for="q in queries"
					:key="q.id"
					:query="q"
					role="listitem"
					:to-preview="true"
					class="px-2" />
			</div>

			<!-- takes up any space to push paginator to bottom -->
			<div class="spacer" />

			<div class="text-center py-2">
				<paginator
					v-if="savedQueryBroker"
					class="d-block"
					aria-controls="pz-query-catalog-items"
					:page-size-options="null"
					:show-record-indicies="false"
					:has-next-page="savedQueryBroker.hasNext"
					:has-prev-page="savedQueryBroker.hasPrev"
					:total-pages="savedQueryBroker.pageCount"
					:current-page="savedQueryBroker.currentPageNumber"
					@last-page="savedQueryBroker.lastPage()"
					@next-page="savedQueryBroker.nextPage()"
					@first-page="savedQueryBroker.firstPage()"
					@prev-page="savedQueryBroker.previousPage()" />
			</div>
		</div>
	</div>
</main>
</template>

<script>
import { ErrorMessage, Icon, Paginator } from 'aunsight-lib-ui';
import _ from 'lodash';

import QueryCard from '@/components/QueryCard.vue';
import QueryCatalogMenu from '@/components/QueryCatalogMenu.vue';
import SavedQueryBroker from '@/DaybreakTool/lib/SavedQueryBroker';
import app from '@/webapp';

export default {
	name: 'QueryCatalog',

	components: {
		QueryCard,
		QueryCatalogMenu,
		Paginator,
		Icon,
		ErrorMessage
	},

	props: {
		context: {
			type: Object,
			required: true
		}
	},

	data () {
		return {
			savedQueryBroker: undefined,

			activeCategory: 'all',

			// what has been typed
			inputSearchString: '',
			// what is active search term, updated on a throttle
			// this distinction is helpful when saving state in url
			searchString: '',

			debouncedUpdateSearch: undefined,

			staticCategories: Object.freeze([
				{
					id: 'all',
					label: 'All Queries',
					helpText: 'Show all queries',
					icon: 'book',
					criteria: {}
				},
				{
					id: 'owned',
					label: 'My Queries',
					helpText: 'Show only queries I own',
					icon: 'user',
					criteria: { shared: false, template: false }
				},
				{
					id: 'shared',
					label: 'Shared With Me',
					helpText: 'Show only queries that have been shared with me',
					icon: 'users',
					criteria: { template: false, owned: false }
				},
				{
					id: 'template',
					label: 'Query Templates',
					helpText: 'Show only query templates',
					icon: 'table',
					criteria: { owned: false, shared: false }
				}
			])
		};
	},

	computed: {
		queries () {
			if (!this.savedQueryBroker) return [];
			return this.savedQueryBroker.queries;
		},

		isFetching () {
			if (!this.savedQueryBroker) return;
			return !!this.savedQueryBroker.fetchingPromise;
		},

		activeCategoryTitle () {
			return _.get(this.activeCategoryObj, 'label', '');
		},

		activeCategoryObj () {
			return _.find(this.categories, { id: this.activeCategory });
		},

		resumeState () {
			const state = {};
			if (this.activeCategory !== 'all') state.category = this.activeCategory;

			if (this.searchString) state.search = this.searchString;

			if (this.savedQueryBroker && this.savedQueryBroker.currentPageNumber !== 1) {
				state.startPage = this.savedQueryBroker.currentPageNumber;
			}

			return state;
		},

		tagCategories () {
			if (!this.savedQueryBroker) return;

			const sorted = _.sortBy(this.savedQueryBroker.tags, 'tag');
			return _.map(sorted, (tag) => {
				return {
					id: `tag-${tag.tag}`,
					label: tag.tag,
					helpText: 'Show only queries for this tag',
					icon: 'hashtag',
					criteria: {
						tags__contains: tag.tag
					}
				};
			});
		},

		categories () {
			return this.staticCategories.concat(this.tagCategories);
		},

		categoryCounts () {
			if (!this.savedQueryBroker) return;

			const counts = _.clone(this.savedQueryBroker.counts);
			_.forEach(this.savedQueryBroker.tags, (tag) => {
				counts[`tag-${tag.tag}`] = tag.count;
			});
			return counts;
		}
	},

	watch: {
		inputSearchString () {
			this.debouncedUpdateSearch();
		},

		resumeState: {
			deep: true,
			handler (newVal) {
				const oldValue = _.get(this.$route, 'query', {});

				// resumeState already removes default initial values
				const pageNeedsUpdate = newVal.startPage !== oldValue.startPage;
				const catNeedsUpdate = newVal.category !== oldValue.category;
				const searchNeedsUpdate = newVal.search !== oldValue.search;

				if (pageNeedsUpdate || catNeedsUpdate || searchNeedsUpdate) {
					this.stashPageState(newVal);
				}
			}
		}
	},

	created () {
		this.activeCategory = _.get(this.$route, ['query', 'category']) || 'all';
		this.inputSearchString = _.get(this.$route, ['query', 'search']) || '';
		this.setupQueryBroker();

		// add a debounced update function for search so it can update as you type,
		// but not every keypress
		this.debouncedUpdateSearch = _.throttle(this.updateSearch.bind(this), 500);
	},

	methods: {
		/**
		 *
		 * @param {Object} state
		 * @param {number} state.startPage
		 * @param {string} state.category
		 * @param {string} state.search
		 */
		stashPageState ({ startPage, category, search }) {
			this.$router.replace({
				name: 'Query Catalog',
				query: { startPage, category, search }
			});
		},

		setupQueryBroker () {
			const query = _.get(this.$route, 'query', {});
			// the base options
			const options = {
				userId: app.auth.user.id,
				context: this.context,
				startSort: [{ field: 'name', direction: 'ASC' }]
			};

			const page = Number(query.page);
			if (!_.isNaN(page)) {
				options.startPage = page;
			}

			// if a category exists, add it to the start filter
			const catId = query.category;
			const category = _.find(this.categories, { id: catId });
			if (category) {
				options.startFilters = { category: category.criteria };
			}
			// Tags aren't loaded until queries are loaded but queries can't be
			// loaded until we resolve this so circumvent the initial tag lookup
			else if (_.startsWith(catId, 'tag-')) {
				const tag = catId.replace('tag-', '');
				options.startFilters = { category: { tags__contains: tag } };
			}

			if (query.search) {
				options.startSearch = query.search;
			}
			this.savedQueryBroker = new SavedQueryBroker(options);
		},

		setCategory (category) {
			if (category.id === this.activeCategory) return;
			this.activeCategory = category.id;

			this.savedQueryBroker.addFilter('category', category.criteria);
		},

		clearSearch () {
			this.inputSearchString = '';
		},

		updateSearch () {
			if (this.searchString !== this.inputSearchString) {
				this.searchString = this.inputSearchString;
				this.savedQueryBroker.setSearch(this.inputSearchString);
			}
		}

	}

};
</script>

<style lang="scss">

@import 'app_variables';

#pz-query-catalog {
	height: 100%;
	overflow: hidden;
	display: flex;

	// put filters on top on narrow layouts
	flex-flow: column nowrap;

	// put filters on side on wide layouts
	@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
		flex-flow: row nowrap;
	}

	> .right-side-container {
		// take up rest of horizontal space
		flex-grow: 1;
		overflow: hidden;
		display: flex;
		flex-flow: column nowrap;

		#pz-query-catalog-header {
			flex: 0 0 auto;
			padding: 0.5rem 1rem 0;
			display: flex;
			flex-wrap: wrap;

			@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
				padding-top: 1.5rem;
			}

			h5 {
				padding: 7px 0;
				margin: 0;
				margin-right: auto;
				min-width: 10rem;
			}

			.pz-query-catalog-search-widget {
				flex-basis: 20rem;
			}
		}

		#pz-query-catalog-list-container {
			flex: 1 1 auto;
			overflow: hidden;
			display: flex;
			flex-flow: column nowrap;

			// for loading layover
			position: relative;

			> .spacer {
				flex-grow: 1;
			}
		}
	}

	// keep list in bounds and scroll
	#pz-query-catalog-items {
		overflow: auto;
		flex-shrink: 1;
		padding: 1rem 1.5rem;

		// normal layout, use grid
		@media screen and (min-width: map-get($grid-breakpoints, 'sm')) {
			display: grid;
			gap: 1.5rem;
			grid-template-rows: repeat(auto-fill, 9rem);
			grid-template-columns: repeat(auto-fill, minmax(22rem, 1fr));
		}

		.card {
			overflow: hidden;
			height: max-content;

			// let take up full width on small screens
			width: 100%;

			// in narrow layout, no grid, just add margin below
			margin-bottom: 1.5rem;

			@media screen and (min-width: map-get($grid-breakpoints, 'sm')) {
				height: 9rem;
				// remove margin below used in narrow layout
				margin-bottom: 0;
			}
		}
	}

	#pz-query-catalog-side-menu {
		border-bottom: 1px solid $border-color;
		margin-bottom: 0.5rem;

		@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
			flex: 0 0 15rem;
			border-bottom: 0;
			margin-bottom: 0;
			padding-top: 0.5rem;
			padding-left: 0.5rem;
		}
	}
}
</style>
