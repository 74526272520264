<template>
<div class="pz-filter-val-field">
	<select
		ref="select"
		class="form-control"
		autocomplete="off"
		:value="tempType"
		:disabled="disabled"
		:list="rangeEnum? id + '-datalist' : false"
		@input="update">
		<option
			value=""
			label="select..."
			disabled />
		<option
			v-for="opt in rangeEnum"
			:key="opt"
			:value="opt"
			:label="prettifyRange(opt)" />
		<option value="special" label="Special..." />
	</select>
</div>
</template>

<script>
import resolveWithinRelative from 'aunsight-lib-query-js/lib/util/resolve-within-relative.js';
import _ from 'lodash';

const RANGE_ENUM = resolveWithinRelative.ranges;

export default {
	name: 'FilterRelativeRangeField',

	props: {
		id: { type: String, required: true },
		// eslint-disable-next-line
		value: {},
		disabled: Boolean
	},

	data () {
		return {
			rangeEnum: RANGE_ENUM,
			tempType: ''
		};
	},

	created: function () {
		if (this.value) {
			this.tempType = this.value.type;
		}
	},
	methods: {
		update (e) {
			const type = e.target.value;

			this.tempType = type;

			const newValue = { type };

			// if the type is changed to special, it is incomplete and needs helper row to finish
			// isIncomplete signals to Filter controller not to send to lib-query
			if (type === 'special') {
				newValue.isIncomplete = true;
			}

			this.$emit('change', 'value', newValue);
		},

		// transform from snake_case to spaces and capitalize
		prettifyRange (range) {
			return _.capitalize(range.replace(/_/g, ' '));
		}
	}
};

</script>
