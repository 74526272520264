import _ from 'lodash';
import Vue from 'vue';
import VueRouter from 'vue-router';

import ContextSelector from '@/views/ContextSelector';
import DaybreakInstance from '@/views/DaybreakInstance';
import Landing from '@/views/Landing';
import NotFound from '@/views/NotFound';
import NotificationsList from '@/views/NotificationsList';
import QueryCatalog from '@/views/QueryCatalog';
import QueryPreview from '@/views/QueryPreview';
import QueryTool from '@/views/QueryTool.vue';
import Redirecting from '@/views/Redirecting.vue';
import UnknownContext from '@/views/UnknownContextPage';
import WelcomePage from '@/views/WelcomePage';
import Wrapper from '@/views/Wrapper.vue';
import app from '@/webapp';

import LoginRoutes from './LoginRoutes';

const BuildInfo = () => import('@/views/BuildInfo');

Vue.use(VueRouter);

const routes = [
	...LoginRoutes,
	{
		path: '/organization/:organizationId/project/:projectId/*',
		name: 'redirect',
		component: Redirecting
	},
	{
		path: '/organization/:organizationId/project/:projectId',
		component: Redirecting
	},
	{
		path: '/workspace/:configId/view/:scopeId',
		component: Wrapper,
		children: [
			// wrapping home and databuilder so parent can load context config which can be shared
			{
				path: '',
				component: DaybreakInstance,
				name: 'Instance',
				redirect: { name: 'Home' },
				children: [
					{
						path: 'home',
						name: 'Home',
						component: Landing,
						meta: { title: 'Home', icon: 'home' }
					},
					{
						path: 'catalog',
						name: 'Query Catalog',
						component: QueryCatalog,
						meta: { title: 'Query Catalog', icon: 'book' }
					},
					{
						path: 'catalog/:queryid',
						name: 'Query Catalog Preview',
						component: QueryPreview,
						meta: { title: 'Preview', icon: 'book' }
					},
					{
						path: 'querybuilder/:queryid?',
						name: 'Query Builder',
						component: QueryTool,
						meta: { title: 'Query Builder', icon: 'wrench' },
						beforeEnter (to, from, next) {
							if (!to.params.queryid) {
								to.params.queryid = 'new';
								next({ name: to.name, params: to.params, query: to.query });
							}
							else {
								next();
							}
						}
					},
					{
						path: 'databuilder/:queryid?',
						name: 'Data Builder',
						redirect: { name: 'Query Builder' }
					}
				]
			},

			{
				path: 'notifications',
				name: 'Notifications',
				component: NotificationsList,
				meta: { title: 'Notifications', icon: 'bell' }
			},
			{
				path: '404',
				name: '404',
				component: NotFound,
				meta: { title: '404 Not Found' }
			},
			{
				path: '*',
				redirect: { name: '404' }
			}
		]
	},
	{
		path: '/workspaces',
		name: 'Context Selector',
		component: ContextSelector,
		meta: {
			title: 'Choose a Workspace'
		}
	},

	{
		path: '/welcome',
		name: 'Welcome',
		component: WelcomePage,
		meta: { bypassLogin: true, title: 'Welcome to Daybreak!' }
	},
	{
		path: '/system/build-info',
		name: 'Build Info',
		component: BuildInfo,
		meta: { bypassLogin: true, title: 'Build Info' }
	},
	{
		path: '/',
		name: 'root',
		redirect: { name: 'Context Selector' }
	},
	{
		path: '*',
		name: 'Unknown context',
		component: UnknownContext,
		meta: { bypassLogin: true, title: 'Unknown context' }
	}

];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	linkActiveClass: 'active',
	linkExactActiveClass: 'active',
	routes
});
export const beforeEachGuard = function (to, from, next) {
	return app.auth.checkLoggedIn().then(() => {
		const hasContext = _.has(to, 'params.scopeId') || _.has(to, 'params.projectId');
		// if going to login but already logged in, go to starting point.
		if (to.name === 'Login') {
			if (hasContext) {
				next({ name: 'Home', params: to.params });
			}
			else {
				next({ name: 'Context Selector' });
			}
		}
		// if they were going to query builder, but no context set, go to selector
		else if (to.name === 'Query Builder' && !hasContext) {
			next({ name: 'Context Selector' });
		}
		else {
			next();
		}
	}).catch(() => {
		if (to.meta.bypassLogin) {
			next();
		}
		else {
			next({
				name: 'Login',
				params: to.params,
				query: { redirect: to.fullPath }
			});
		}
	});
};

router.beforeEach((to, from, next) => beforeEachGuard(to, from, next));

export default router;
