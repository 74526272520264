<template>
<div class="pz-filter-val-field pz-filter-val-daterange-field input-group">
	<input
		:id="pickerId"
		type="text"
		class="form-control"
		autocomplete="off"
		:value="toDisplayString(value)"
		:disabled="disabled"
		@change="update">

	<div class="input-group-append">
		<button
			class="btn btn-outline-secondary"
			type="button"
			@click="clear">
			<icon name="times" />
		</button>
	</div>
</div>
</template>

<script>
import { Icon } from 'aunsight-lib-ui';
import Litepicker from 'litepicker';
import _ from 'lodash';
import moment from 'moment';

const DISPLAY_FORMAT = 'MM/DD/YYYY';
// todo: make this determined by datamart specs
const DATE_FORMAT = 'YYYY-MM-DD';

export default {
	name: 'FilterValueDateField',

	components: { Icon },

	picker: undefined,

	props: {
		id: { type: String, required: true },
		value: { type: String, required: true },
		disabled: { type: Boolean, default: false }
	},

	computed: {
		pickerId () {
			return this.id + '-datepicker';
		}
	},

	mounted () {
		const me = this;
		this.picker = new Litepicker({
			element: document.getElementById(this.pickerId),

			format: DISPLAY_FORMAT,

			// date range instead of single date
			singleMode: false,
			// next forward operators are separate for each column
			splitView: true,

			// show two pickers in two columns
			numberOfMonths: 2,
			numberOfColumns: 2,

			// start on sunday
			firstDay: 0,

			mobileFriendly: false,

			selectForward: true,

			dropdowns: {
				minYear: 1900,
				maxYear: (new Date()).getFullYear() + 1,
				months: true,
				years: true
			},

			setup (picker) {
				picker.on('selected', () => {
					const date1 = picker.getStartDate();
					const date2 = picker.getEndDate();
					if (date1) {
						me.updatedirect([
							me.formatDate(date1.dateInstance),
							me.formatDate(date2.dateInstance)
						]);
					}
				});

				picker.on('show', () => {
					// if there is no date selected
					if (!me.value) {
						// show last month and this month (not this and next month)
						const now = new Date();
						const lastMonth = new Date();
						lastMonth.setMonth(lastMonth.getMonth() - 1);

						me.picker.gotoDate(lastMonth, 0);
						me.picker.gotoDate(now, 1);
					}
				});
			}

		});
	},

	beforeDestroy () {
		if (this.picker) {
			this.picker.destroy();
		}
	},

	methods: {
		update (val) {
			const me = this;
			// wait for next tick to allow the picker to update itself
			setTimeout(() => {
				const dates = [
					me.formatDate(me.picker.getDate().dateInstance),
					me.formatDate(me.picker.getEndDate().dateInstance)
				];

				this.$emit('change', 'value', dates);
			}, 10);
		},
		updatedirect (val) {
			this.$emit('change', 'value', val);
		},

		formatDate (date) {
			if (date instanceof Date) {
				return moment(date).format(DATE_FORMAT);
			}
			else return date;
		},

		formatDateDisplay (date) {
			if (_.isString(date)) {
				// parse date this way to help assure timezone doesn't shift it a day
				return moment(date, DATE_FORMAT).format(DISPLAY_FORMAT);
			}

			else {
				return moment(date).format(DISPLAY_FORMAT);
			}
		},

		toDisplayString (dates) {
			if (!(dates instanceof Array)) return;

			return `${this.formatDateDisplay(dates[0])} - ${this.formatDateDisplay(dates[1])}`;
		},

		clear () {
			this.picker.clearSelection();
			this.$emit('change', 'value', '');
		}
	}
};

</script>
