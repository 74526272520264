import loginEvents from 'aunsight-lib-ui/src/components/Login/EventsToTrack';
import $ from 'jquery';
import _ from 'lodash';

import daybreakToolEventsToTrack from '@/DaybreakTool/DaybreakToolEventsToTrack';
import EventBus from '@/utils/EventBus';
import app from '@/webapp';

const eventsToTrack = [
	...daybreakToolEventsToTrack,
	...loginEvents,
	{
		trigger: 'click',
		description: 'Sidebar Context Item Clicked',
		selector: '.pz-sidebar-context-item a',
		event (e) {
			return {
				action: 'Click',
				category: 'Sidebar Context',
				label: $(e.currentTarget).data('id')
			};
		}
	},
	{
		trigger: 'click',
		description: 'Sidebar User Dropdown Clicked',
		selector: '[aria-labelledby="sidebar-user-dropdown"]',
		event (e) {
			return {
				action: 'Click',
				category: 'User Dropdown',
				label: e.currentTarget.innerText
			};
		}
	},
	{
		trigger: 'click',
		description: 'Sidebar Context Dropdown Clicked',
		selector: '[db-layout="sidebar-left"] button',
		event (e) {
			return {
				action: 'Click',
				category: 'Sidebar Item',
				label: e.currentTarget.innerText
			};
		}
	},
	{
		trigger: 'click',
		description: 'Sidebar Option Clicked',
		selector: '[db-layout="sidebar-left"] a',
		event (e) {
			if (e.currentTarget.id !== 'sidebar-notifications') {
				return {
					action: 'Click',
					category: 'Sidebar Item',
					label: e.currentTarget.innerText
				};
			}
			else if (e.currentTarget.id === 'sidebar-notifications') {
				return {
					action: 'Click',
					category: 'Sidebar Item',
					label: 'Notifications'
				};
			}
		}
	},
	{
		trigger: 'click',
		description: 'View All Queries Button Clicked',
		selector: '#view-queries-button',
		event: {
			action: 'Click',
			category: 'LP-RecentQueries',
			label: 'View All Queries'
		}
	},
	{
		trigger: 'click',
		description: 'Reference Widget Clicked',
		selector: '[id="pz-reference-holder"] .card',
		event (e) {
			return {
				action: 'Click',
				category: 'LP-References',
				label: e.currentTarget.innerText
			};
		}
	},
	{
		trigger: 'click',
		description: 'Password change form',
		selector: '#change-password-modal button',
		event (e) {
			return {
				action: 'Click',
				category: 'Change Password Form',
				label: e.target.innerText
			};
		}
	},
	{
		trigger: 'click',
		description: 'Workspace description clicked',
		selector: '#pz-workspace-description-trigger',
		event: {
			action: 'Click',
			category: 'Sidebar',
			label: 'Workspace description open button'
		}
	},

	// --------- Query Catalog ---------
	{
		trigger: 'click',
		description: 'Query Catalog New Query Clicked',
		selector: '#pz-query-catalog-new-query',
		event: {
			action: 'Click',
			category: 'Query Catalog',
			label: 'New Query Button'
		}
	},
	{
		trigger: 'click',
		description: 'Query Catalog query card clicked',
		selector: '#pz-query-catalog .pz-query-card',
		event: {
			action: 'Click',
			category: 'Query Catalog',
			label: 'Query card'
		}
	},
	{
		trigger: 'click',
		description: 'Query Catalog search clicked',
		selector: '#pz-query-catalog-search-input',
		event: {
			action: 'Click',
			category: 'Query Catalog',
			label: 'Search input'
		}
	},
	{
		trigger: 'click',
		description: 'Query Catalog download clicked',
		selector: '#pz-query-preview-data-results .awi-action-download',
		event: {
			action: 'Click',
			category: 'Query Catalog',
			label: 'Download button'
		}
	},
	{
		trigger: 'click',
		description: 'Query Catalog data insights expanded/collapsed',
		selector: '#pz-query-preview-insights summary',
		event: {
			action: 'Click',
			category: 'Query Catalog',
			label: 'Insights toggle'
		}
	},
	{
		trigger: 'click',
		description: 'Query Catalog data results expanded/collapsed',
		selector: '#pz-query-preview-data-results summary',
		event: {
			action: 'Click',
			category: 'Query Catalog',
			label: 'Data Results toggle'
		}
	},
	{
		trigger: 'click',
		description: 'Open in Query Builder clicked',
		selector: '#pz-query-preview-open-editor-btn',
		event: {
			action: 'Click',
			category: 'Query Catalog',
			label: 'Open in Query Builder button'
		}
	},
	{
		trigger: 'click',
		description: 'Query Catalog share clicked',
		selector: '#pz-query-preview-share-btn',
		event: {
			action: 'Click',
			category: 'Query Catalog',
			label: 'Share button'
		}
	},
	{
		trigger: 'mouseup',
		description: 'Query Catalog tag filter clicked',
		selector: '#pz-query-catalog-category-menu [id^=pz-query-catalog-category-tag-]',
		event: {
			action: 'Click',
			category: 'Query Catalog',
			label: 'Filter queries by [tag]'
		}
	},
	{
		trigger: 'mouseup',
		description: 'Query Catalog filter clicked',
		selector: '#pz-query-catalog-category-menu button:not([id^=pz-query-catalog-category-tag-])',
		event (e) {
			const filterLabel = $(e.currentTarget).contents()[1].nodeValue.trim();
			return {
				action: 'Click',
				category: 'Query Catalog',
				label: 'Filter queries by ' + filterLabel
			};
		}
	},
	{
		trigger: 'click',
		description: 'Query Preview Add Tag clicked',
		selector: '.pz-taglist-addbutton',
		event: {
			action: 'Click',
			category: 'Query Preview',
			label: 'Add tag'
		}
	},
	{
		trigger: 'click',
		description: 'Query Preview Remove Tag clicked',
		selector: '.pz-taglist-removebutton',
		event: {
			action: 'Click',
			category: 'Query Preview',
			label: 'Removed tag'
		}
	},
	{
		trigger: 'click',
		description: 'Results table paging',
		selector: '.pz-results-table-wrapper .paginate_button',
		event: {
			action: 'Click',
			category: 'Results table action',
			label: 'Query results paging'
		}
	},
	{
		trigger: 'click',
		description: 'Results table sort',
		selector: '.pz-results-table-wrapper .sorting',
		event: {
			action: 'Click',
			category: 'Results table action',
			label: 'Query results sort'
		}
	}
];

function getUserType () {
	if (!_.isEmpty(app.auth.user.attributes)) {
		const domain = app.auth.user.attributes.email.substring(app.auth.user.attributes.email.lastIndexOf('@') + 1);
		if (domain === 'aunalytics.com') {
			return 'Aunalytics';
		}
		else {
			return 'External';
		}
	}
}

export default function (_vue) {
	eventsToTrack.forEach(event => {
		const trigger = event.trigger || 'click';
		$(document).on(trigger, event.selector, function (e) {
			let eventInfo = event.event;
			if (_.isFunction(eventInfo)) {
				eventInfo = eventInfo(e);
			}
			eventInfo.userType = getUserType();
			EventBus.$emit('trackEvent', eventInfo);
		});
	});
}
