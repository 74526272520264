<template>
<div class="pz-nlp-container bg-light align-items-center text-center position-relative">
	<div class="mx-auto my-4">
		<div class="card pz-nlp-card p-5 border-0">
			<div class="card-body text-center  align-items-center">
				<h2 class="mb-4">
					What do you need answered today?
				</h2>

				<form class="mb-5">
					<nlp-input
						:mock-query="mockQuery"
						:context="context"
						:start-search-string="startingNlpSearch"
						:nlp-auto-url="nlpAutoUrl"
						@submit="submit" />

					<error-message
						v-if="nlpErr"
						:errors="[nlpErr]"
						class="text-left mt-3"
						title="Query Error" />
				</form>

				<div v-if="nlpExamples" class="pz-nlp-suggestions text-center">
					<h6>Need some help? Try one of these:</h6>

					<template v-for="(q,i) in nlpExamples">
						"{{ q }}"
						<br :key="i">
					</template>
				</div>
			</div>
		</div>

		<div class="pz-nlp-alt-options mt-5">
			<h6>Prefer to search another way?</h6>
			<button
				id="use-query-wizard-button"
				type="button"
				class="btn btn-secondary"
				@click="goToWizard">
				Use Query Wizard
			</button>
			<button
				id="use-sql-editor-button"
				type="button"
				class="ml-2 btn btn-secondary"
				@click="goToSQL">
				Use SQL Editor
			</button>
		</div>
	</div>

	<!-- loading spinner for column sorting -->
	<div v-if="isLoading" class="pz-load-indicator-mask">
		<icon name="spinner" spin />
		<h3 class="mt-4">
			I'm thinking...
		</h3>
	</div>
</div>
</template>

<script>
import { ErrorMessage, Icon } from 'aunsight-lib-ui';
import getXHRError from 'aunsight-webapp/src/js/util/getXHRError';
import $ from 'jquery';
import _ from 'lodash';

import app from '@/webapp';

import NLPInput from './NLPInput.vue';
import mockNLP from './testUtils/mockNLP';
import validateStructuredQuery from './util/validateStructuredQuery';

export default {
	components: {
		Icon,
		ErrorMessage,
		nlpInput: NLPInput
	},

	props: {
		datamart: {
			type: Object,
			required: true
		},
		standardList: {
			type: Array,
			default: () => []
		},
		isA: {
			type: Boolean,
			default: false
		},
		context: {
			type: Object,
			required: true
		},
		nlpUrl: {
			type: String,
			required: true
		},
		nlpExamples: {
			type: Array,
			required: true
		},
		mockQuery: {
			type: Boolean,
			required: true
		},
		startingNlpSearch: {
			type: String,
			default: null
		}
	},

	data () {
		return {
			nlpErr: null,
			isLoading: false,
			nlpAutoUrl: undefined
		};
	},

	created () {
		this.nlpAutoUrl = app.nlpAutoUrl || 'https://nl2sql-autocomplete.aunsight.com/';
	},

	mounted () {
		if (this.startingNlpSearch) {
			this.submit(this.startingNlpSearch);
			this.$emit('resolveStartingNlpSearch');
		}
	},

	methods: {
		submit (searchString) {
			// clear any previous error
			this.nlpErr = null;
			this.isLoading = true;

			this.fetchQuery(searchString)
				.then(response => {
					const query = _.get(response, 'result.structured_query');
					const insights = _.get(response, 'result.insights');
					const validation = validateStructuredQuery(query, this.datamart);

					// if it passes validation, go to wizard
					if (!validation) {
						const nlpSearch = {
							request_id: _.get(response, 'result.request_id'),
							string: searchString
						};
						this.goToQuery(query, nlpSearch, insights);
					}
					// else show that there's an error
					else {
					// server will sometimes return an error with name 'ValidationError'
					// so set this one apart with a different name.
						validation.name = 'DatamartValidationError';
						this.nlpErr = {
						// add a human readable message and the request id to the validation info
							humanReadableMessage: 'The server returned a query that cannot be used with the current Datamart schema.<br><br>Please contact your Daybreak administrator',
							id: _.get(response, 'result.request_id'),

							...validation
						};
					}
				})
				.fail((response) => {
				// provide a fallback error, for worst case
					this.nlpErr = getXHRError(response) || { message: 'An unknown error has occurred.' };

					if (this.nlpErr.user_message) {
						this.nlpErr.humanReadableMessage = this.nlpErr.user_message;
						delete this.nlpErr.user_message;
					}
				})
				.always(() => {
					this.isLoading = false;
				});
		},

		fetchQuery (searchString) {
			if (this.mockQuery) {
				return mockNLP(searchString);
			}

			else {
				return $.ajax(this.nlpUrl, {
					method: 'post',
					contentType: 'application/json',
					data: JSON.stringify({
						question: searchString,
						user_ID: app.auth.user.id,
						context_organization: this.context.organization,
						context_project: this.context.project,
						scope_ID: this.context.scope
					}),
					headers: { Authorization: `Bearer ${app.auth.token.get()}` }
				})
					.fail(response => {
					// checking for invalid token which means to log out
					// currently choosing not to tap into auth to do this since nlp apiPath is messy
						const err = getXHRError(response);
						if (err.name === 'InvalidTokenError') {
							app.auth.logout();
						}
						// if some other error, pass it along
						else return $.Deferred().reject(err);
					});
			}
		},

		goToWizard () {
			this.$emit('goToWizard');
		},

		goToSQL () {
			this.$emit('goToSQL');
		},

		goToQuery (query, nlpSearch, insights) {
			this.$emit('goToQuery', query, nlpSearch, insights);
		}
	}
};
</script>

<style lang="scss">
.pz-nlp-container {
	display: grid;
	overflow: auto;
}

.pz-nlp-card {
	height: auto;
	width: 600px;
}
</style>
