import TrackerJobModel from 'aunsight-webapp/src/js/models/TrackerJob';
import Marionette from 'backbone.marionette';
import _ from 'lodash';

import insightMockData from './insightMockData';

let getMockFetchResults;

const getSummaryResults = (total) => {
	return {
		schema: {
			type: 'object',
			properties: {
				base: { type: 'number' },
				filtered: { type: 'number' }
			},
			propertiesOrder: ['base', 'filtered']
		},
		data: [
			{ base: total, filtered: total }
		]
	};
};

export default Marionette.Object.extend(/** @lends GuidedQueryBrowser.QueryJob# */{
	initialize: function (options) {
		this.mergeOptions(options, ['runOptions', 'vis']);

		this.mockPromise = this.importGetMockData();
	},

	// dynamically import because this is only used in development and is a waste of space otherwise
	importGetMockData: function () {
		return import(/* webpackChunkName: "mockdata" */ './getMockFetchResults')
			.then(module => {
				getMockFetchResults = module.default;
			}
			);
	},

	run: function () {
		// if loading not done yet, wait
		if (!getMockFetchResults) {
			return this.mockPromise.then(() => this.run());
		}

		return new Promise((resolve) => {
			// initial delay to similate init submit request
			setTimeout(() => {
				if (this.isAborted) return;

				this._setupJob();

				// second delay to simulate rendesvous
				setTimeout(() => {
					if (this.isAborted) return;

					this.job.set('state', 'SUCCEEDED');
					this.trigger('job:finished');

					// if a single query, return results, else return array of results
					if (this.runOptions.query) {
						resolve(this.getQueryResults(this.vis, this.runOptions.params));
					}
					else {
						const results = _.map(this.runOptions.queries_meta, qm => {
							return this.getQueryResults(qm.insight || { id: qm.type }, qm.params);
						});
						resolve(results);
					}
				}, 1000);
			}, 500);
		});
	},

	// given the vis and params, return the results
	getQueryResults (vis, params) {
		if (['table', 'summary'].includes(vis.id)) {
			const mockFetchResults = getMockFetchResults(params, this.runOptions.structured_query);
			if (vis.id === 'table') {
				return _.pick(mockFetchResults, ['data', 'schema']);
			}

			else if (vis.id === 'summary') {
				return getSummaryResults(mockFetchResults.total);
			}
		}

		else if (vis.type && ['column', 'donut'].includes(vis.type)) {
			return insightMockData.getFieldAggResults();
		}
		else if (vis.type && vis.type === 'line') {
			return insightMockData.getDateAggResults();
		}
		else if (vis.type === 'summary') {
			return insightMockData.getSummaryChartResults();
		}
	},

	download: function () {
		// resolve with a javascript url which alerts user of the futility of their request
		return new Promise((resolve) => {
			resolve('javascript:alert(\'Can\\\'t download with mock endpoint, sorry!\')');
		});
	},

	abort: function () {
		this.isAborted = true;
		if (this.job) this.job.set('state', 'killed');
	},

	_setupJob: function () {
		this.job = new TrackerJobModel(
			{ id: 12345 },
			{ context: _.pick(this.runOptions, ['organization', 'project']) });

		// simulate initial job fetch
		setTimeout(() => {
			this.job.set('state', 'RUNNING');
		}, 200);

		this.trigger('job:started');

		this.jobPromise = new Promise((resolve) => {
			this.on('job:finished', resolve);
		});
	}

});
