<template>
<div v-if="nlpSearch" id="pz-nlp-results">
	<div id="pz-nlp-src-string" class="mb-2 font-weight-bold">
		You entered: "{{ nlpSearch.string }}"
	</div>

	<div class="mb-2 d-flex align-items-baseline flex-wrap">
		<span class="text-muted">
			Here's how we interpreted your query:
		</span>
		<!-- thumbs up/down feedback -->
		<div v-if="!feedbackSent" class="pz-nlp-feedback ml-auto pl-4">
			<small class="mr-2">Is this what you were looking for?</small>
			<button
				type="button"
				name="nlpFeedbackGood"
				title="Yes"
				class="btn btn-link p-0"
				@click="sendFeedback(true)">
				<span class="fa-stack text-success">
					<i class="fas fa-square fa-stack-2x bottom-icon" />
					<i class="fas fa-thumbs-up fa-stack-1x fa-inverse" />
				</span>
			</button>
			<button
				type="button"
				name="nlpFeedbackBad"
				title="No"
				class="btn btn-link p-0 dropdown-toggle"
				data-toggle="dropdown">
				<span class="fa-stack text-secondary">
					<i class="fas fa-square fa-stack-2x bottom-icon" />
					<i class="fas fa-thumbs-down fa-stack-1x fa-inverse" />
				</span>
			</button>
			<div class="dropdown-menu">
				<button
					v-for="option in feedbackOptions"
					:key="option"
					class="dropdown-item"
					type="button"
					@click="selectFeedback(option)">
					{{ option }}
				</button>
			</div>
		</div>
	</div>

	<mn-component-view
		v-if="isFeedbackDialogOpen"
		:view="FeedbackDialogView"
		@done="setFeedback"
		@destroy="onFeedbackClose" />
</div>
</template>

<script>
import $ from 'jquery';

import MnComponentView from '@/components/MnWrapper.vue';

import FeedbackDialogView from './ThumbsDownFeedback';

export default {
	name: 'NlpResult',

	components: {
		MnComponentView
	},

	props: {
		// info about the nlp search used to populate query, if applicable
		nlpSearch: {
			type: Object,
			default: undefined
		},
		nlpUrl: {
			type: String,
			required: true
		},
		mockQuery: Boolean
	},

	data () {
		return {
			selection: 'wizard',
			feedbackSent: false,
			FeedbackDialogView,
			isFeedbackDialogOpen: false,
			textFeedback: undefined,
			feedbackOptions: [
				'My query wasn’t fully parsed',
				'My query was interpreted incorrectly',
				'The data results were wrong',
				'My query had a typo',
				'Other (please specify)'
			]
		};
	},

	methods: {

		sendFeedback (isGood) {
			if (isGood) this.doSendFeedback(isGood);

			else {
				this.doSendFeedback(false);
			}
		},

		setFeedback (text) {
			this.textFeedback = text;
		},

		selectFeedback (text) {
			if (text === 'Other (please specify)') {
				this.isFeedbackDialogOpen = true;
			}
			else {
				this.setFeedback(text);
				this.sendFeedback(false);
			}
		},

		onFeedbackClose () {
			this.doSendFeedback(false);
		},

		doSendFeedback (isGood) {
			const url = this.nlpUrl.endsWith('/') ? this.nlpUrl + 'feedback' : this.nlpUrl + '/feedback';

			const data = {
				is_correct: isGood,
				request_id: this.nlpSearch.request_id
			};

			if (!isGood && this.textFeedback) {
				data.text = this.textFeedback;
			}

			if (this.mockQuery) {
				this.feedbackSent = true;
				return;
			}

			$.ajax(url, {
				method: 'post',
				contentType: 'application/json',
				data: JSON.stringify(data)
			})
				.then(() => {
					this.feedbackSent = true;
				});
		}
	}
};

</script>

<style lang="scss">
@import 'app_variables';

.pz-nlp-feedback {
	button {
		>.fa-stack {
			width: 2em;
		}

		// darken bottom icon on hover
		&:hover,
		&:focus,
		&.focus {
			.text-success .bottom-icon {
				color: darken($success, 7.5%);
			}

			.text-secondary .bottom-icon {
				color: darken($secondary, 7.5%);
			}
		}
	}

	.dropdown-item {
		&:hover {
			background-color: saturate(mix($info, $body-bg, 7%), 60%);
		}
	}
}

</style>
