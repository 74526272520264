<template>
<select-search-field
	:disabled="disabled"
	:list="aggOperatorList"
	:is-multiple="false"
	:value="aggOperator"
	name="aggOperator"
	@change="update" />
</template>

<script>
import SelectSearchField from './SelectSearchField.vue';

export default {
	name: 'FilterAggOperatorField',

	components: { SelectSearchField },

	props: {
		aggOperator: {
			type: String,
			default: ''
		},
		aggOperators: {
			type: Array,
			required: true
		},

		disabled: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		aggOperatorList () {
			return this.aggOperators.map(op => {
				return {
					value: op.id,
					label: op.title
				};
			});
		}
	},

	methods: {
		update (key, value) {
			this.$emit('change', 'aggOperator', value);
		}
	}
};

</script>
