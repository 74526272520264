import validateQuery from 'aunsight-lib-query-js/lib/util/validate-structured-query.js';

export default function (query, datamart) {
	let err;

	try {
		validateQuery(query, datamart);
	}
	catch (caughterr) {
		err = {
			...caughterr,
			message: caughterr.message

		};
	}

	if (err) return err;

	// TODO: make sure no operations are used that webapp doesn't support

	// TODO: make sure enums are respected

	return false;
}
