<template>
<div />
</template>

<script>
import $ from 'jquery';
import _ from 'lodash';

export default {
	name: 'MnWrapper',
	props: {
		view: {
			type: Function,
			required: true
		},
		options: {
			type: Object,
			default: () => ({})
		},
		watchedProps: {
			type: Object,
			default: () => ({})
		},
		propHandlers: {
			type: Object,
			default: () => ({})
		}
	},
	mounted () {
		const View = this.view;
		this.viewInstance = new View(this.options);
		this.viewInstance.render();
		this.viewInstance.triggerMethod('before:attach');
		$(this.$el).append(this.viewInstance.$el);
		this.viewInstance.triggerMethod('attach');

		if (this.propHandlers && this.watchedProps) {
			_.forEach(this.propHandlers, (handler, key) => {
				this.$watch('watchedProps.' + key, (val) => {
					handler(this.viewInstance, val);
				});
			});
		}

		// propagate all events up
		this.viewInstance.on('all', function () {
			this.$emit(...arguments);
		}.bind(this));
	},
	destroyed () {
		if (this.viewInstance) {
			this.viewInstance.triggerMethod('before:detach');
			this.viewInstance.triggerMethod('dom:remove');
			this.viewInstance.triggerMethod('detach');
			this.viewInstance.destroy();
		}
	}
};
</script>
