import $ from 'jquery';

import app from '@/webapp';

export default function () {
	const res = {
		DAYBREAK_COMMITHASH: DAYBREAK_COMMITHASH,
		DAYBREAK_BRANCH: DAYBREAK_BRANCH,
		DAYBREAK_PACKAGE_VERSION: DAYBREAK_PACKAGE_VERSION,
		AU_VERSIONS: DAYBREAK_AUNSIGHT_VERSIONS,
		apiHeaders: app.apiHeaders,
		config: 'not set'
	};
	return $.get('/config').then(config => {
		return {
			...res,
			config
		};
	});
}
