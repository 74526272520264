import 'aunsight-webapp/src/js/AWIComponents/BaseForm/css/base-form.scss';

import BaseForm from 'aunsight-webapp/src/js/AWIComponents/BaseForm/BaseForm';
import AWIIcons from 'aunsight-webapp/src/js/util/AWIIcons';
import $ from 'jquery';
import _ from 'lodash';

/**
 * @class ShareQuery
 * @param {object} options
 * @param {QueryModel} options.model
 * @param {object[]} options.members - array of member objects
 */
export default BaseForm.extend({
	initialize: function () {
		this.debouncedApplySearch = _.debounce(this.applySearch, 500);
	},

	id: 'share-query-dialog',

	modal: true,

	title: 'Share Query',

	doScroll: true,

	formOptions: function () {
		const members = _.filter(this.getOption('members'), 'active');
		const start = this.getStartData();

		const formOptions = {
			view: {
				parent: 'bootstrap-edit-horizontal',
				templates: {
					// add the search bar at the top of the form
					control: `<div class="px-5"><div class="input-group mb-3">
							<input class="form-control" type="search" placeholder="Search users..." name="searchMembers">
							<div class="input-group-append">
								<button class="btn btn-outline-secondary" type="button" name="clearSearch" title="Clear Search">
									<i class="${AWIIcons.times}"></i>
								</button>
							</div>
						</div>
						<p class="empty-message" style="display:none;">No users matched your search</p>
						{{#control}}{{/control}}
					</div>`
				}
			},
			schema: {
				type: 'array',
				items: {
					type: 'string',
					enum: _.map(members, 'id')
				}
			},
			options: {
				hideInitValidationError: true,
				optionLabels: _.map(members, 'fullname'),
				type: 'checkbox',
				form: {
					buttons: {
						clearAll: {
							type: 'button',
							label: 'Deselect All',
							styles: 'btn float-left'
						},
						cancel: {
							type: 'button',
							label: 'Cancel'
						},
						submit: {
							styles: 'btn btn-primary'
						}
					}
				}
			},
			postRender: function (control) {
				// don't allow submission unless value is different from original.
				$.alpaca.nextTick(() => control.form.disableSubmitButton());
				control.form.adjustSubmitButtonState = function () {
					this.disableSubmitButton();

					// see if the current value is the same as old value
					const hasChanged = !_.isEqual(this.getValue().sort(), start.sort());

					if (this.isFormValid() && hasChanged) {
						this.enableSubmitButton();
					}
				};
			}
		};

		return formOptions;
	},

	events: {
		'input [name="searchMembers"]': function (e) {
			const val = e.currentTarget.value;
			this.debouncedApplySearch(val);
		},
		'click [name="clearSearch"]': function () {
			this.$('[name="searchMembers"]').val('');
			this.applySearch('');
		}
	},

	// to be set on initialization
	debouncedApplySearch: _.noop,

	applySearch: function (val) {
		const members = this.getOption('members');

		const regEx = new RegExp(val, 'i');
		let count = 0;

		// for each member, hide or show based on search value
		_.forEach(members, m => {
			// if there is a value, show if matches, else just show
			const shouldShow = val ? regEx.test(m.fullname) : true;
			// hold a count of shown, so empty messsage can be applied
			if (shouldShow) count++;

			this.$(`[data-checkbox-value="${m.id}"]`).parent().toggle(shouldShow);
		});

		// if there are no results, show empty message, else hide it
		this.$('.empty-message').toggle(count === 0);
	},

	getStartData: function () {
		return this.model.get('shared_with') || [];
	},

	getFormData: function () {
		return this.ui.form.alpaca().getValue();
	},

	doSave: function () {
		const newShareWith = this.getFormData();

		return this.model.updateSharedWith(newShareWith);
	},

	progressOptions: {
		errorDisplay: {
			message: 'An error occured trying to share query'
		},
		showServerError: true
	},

	onComplete: function () {
		this.on('destroy', this.triggerMethod.bind(this, 'done', this.model));
		this.triggerMethod('close');
	},

	onCancel: function () {
		this.triggerMethod('close');
	},

	buttonHandlers: {
		clearAll: function () {
			this.ui.form.alpaca().setValue([]);
		}
	}
});
