<template>
<daybreak-tool
	v-if="datamart"
	:key="key"
	class="overflow-hidden position-relative"
	:datamart="datamart"
	:config="config"
	:context="context"
	:query-selection="selectedId"
	:use-vue-routing="true"
	:get-u-r-l="getURL"
	:nav-start-mode="navStartMode"
	:nlp-examples="nlpExamples"
	:nlp-url="nlpUrl"
	:nlp-auto-url="nlpAutoUrl"
	:starting-nlp-search="startingNLPSearch"
	@resolveStartingNlpSearch="resolveStartingNlpSearch"
	@updateSelection="updateSelection"
	@hasChanges="hasChanges" />
</template>

<script>

import _ from 'lodash';

import QueryTool from '@/DaybreakTool/DaybreakTool.vue';
import app from '@/webapp.js';

export default {
	name: 'QueryTool',

	components: { DaybreakTool: QueryTool },

	beforeRouteUpdate (to, from, next) {
		this.checkRouterNavigation((canLeavePage) => {
			if (canLeavePage === false) {
				next(false);
			}
			else {
				// If navigating away, remove need for lock
				this.hasChanges(false);

				if (!to.params.queryid) {
					// Increment key so a new instance of the daybreak tool is used
					this.key = this.key + 1;
					to.params.queryid = 'new';
					next({ name: to.name, params: to.params, query: to.query });
				}
				else next();
			}
		});
	},

	beforeRouteLeave (to, from, next) {
		this.checkRouterNavigation(next);
	},

	props: {
		datamart: {
			type: Object,
			required: true
		},
		config: {
			type: Object,
			required: true
		},
		context: {
			type: Object,
			required: true
		}
	},

	data () {
		return {
			builderHasChanges: undefined,
			isLoading: false,
			loadError: null,
			nlpUrl: undefined,
			nlpAutoUrl: undefined,
			nlpExamples: undefined,
			key: 0,
			startingNLPSearch: undefined
		};
	},

	computed: {
		selectedId () {
			return this.$route.params.queryid;
		},

		navStartMode () {
			return this.$route.query.mode;
		}
	},

	watch: {
		'$route.params': {
			handler (to, from) {
				if (to.scopeId !== from.scopeId || to.configId !== from.configId) {
					// reset data
					this.builderHasChanges = this.nlpUrl = this.nlpExamples = undefined;
				}
			},
			deep: true
		}
	},

	created () {
		this.nlpUrl = _.get(this.config, 'nlp_config.url');
		this.nlpAutoUrl = app.nlpAutoUrl;
		this.nlpExamples = _.get(this.config, 'nlp_config.examples');
		window.addEventListener('beforeunload', this.checkBrowserNavigation);

		if (_.has(this.$route.query, 'searchString')) {
			this.startingNLPSearch = this.$route.query.searchString;
		}
	},

	destroyed () {
		window.removeEventListener('beforeunload', this.checkBrowserNavigation);
	},

	methods: {
		// child doesn't know about this app's routing system so pass this function to it
		getURL (id, opts) {
			const options = { name: 'Query Builder', params: { queryid: id } };
			if (opts && opts.mode) options.query = { mode: opts.mode };
			return this.$router.resolve(options).href;
		},

		updateSelection (id) {
			this.$router.push({ name: 'Query Builder', params: { queryid: id } });
		},

		hasChanges (val) {
			this.builderHasChanges = val;
		},

		checkBrowserNavigation (event) {
			if (!this.canLeavePage()) {
				// Cancel the event as stated by the standard.
				event.preventDefault();
				// Chrome requires returnValue to be set.
				event.returnValue = '';
			}
		},
		/**
		 * checkRouterNavigation
		 * Checks canLeavePage and translates boolean to what Vue Router nav guard needs
		 * next callback is called with either:
		 * 	false if navigation should be cancelled
		 *  undefined if navigation should continue
		 * @param {function} next
		 */
		checkRouterNavigation (next) {
			if (!this.canLeavePage()) {
				next(false);
			}
			else {
				next();
			}
		},

		canLeavePage () {
			if (!this.builderHasChanges) return true;
			return confirm('Do you want to leave this page? Your work has not been saved.');
		},

		resolveStartingNlpSearch () {
			this.startingNLPSearch = null;
			// clear query from url
			this.$router.replace({ query: {} });
		}

	}
};
</script>

<style lang="scss">
// Add in some styles that are used but not included elsewhere
// (need to develop a better way to get style dependencies brought in)

// Need the styling for the two column chooser (and the app wide var it uses)
$valid-drop-highlight-color: teal;

// a global aunsight webapp style needed by table view
.awi-fill-space {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
}
</style>
