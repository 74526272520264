<template>
<div class="pz-data-source-tree p-3">
	<!-- search queries -->
	<div class="input-group mb-3">
		<input
			v-model="searchString"
			class="form-control"
			type="search"
			name="columnSearch"
			aria-label="Search Columns"
			placeholder="Search">
		<div class="input-group-append">
			<button
				class="btn btn-outline-secondary"
				type="button"
				title="Clear search"
				@click="searchString = ''">
				<icon name="times" />
			</button>
		</div>
	</div>

	<!-- Datamart Label -->
	<!-- <h6>
		<icon name="database" customClass="text-info pr-2"/>
		{{datamart.spec.name || 'Untitled DataMart'}}
	</h6> -->

	<!-- List of tables -->
	<ul class="table-list list-unstyled mt-4">
		<li
			v-for="(table, tableIndex) in filteredColumnTables"
			:key="tableIndex"
			:data-table="table.name">
			<!-- Table label -->
			<a
				data-toggle="collapse"
				:href="'#'+ makeTableId(table.name)"
				role="button"
				class="table-collapse-label"
				:aria-expanded="startCollapsed? 'false' : 'true'"
				:aria-controls="makeTableId(table.name)">

				<i class="toggle-icon fa fa-fw pr-2" />
				<icon name="table" custom-class="table-icon pr-2" />
				{{ table.name }}
			</a>
			<ul
				:id="makeTableId(table.name)"
				:class="['list-unstyled','column-list','collapse', {show: !startCollapsed, clickable: clickableColumns }]">
				<!-- item for each column -->
				<li
					v-for="column in table.columns"
					:id="makeColumnId(table, column)"
					:key="makeColumnId(table, column)"
					class="column-row"
					:data-column="column.name">
					<!-- everything but the details -->
					<div class="column-main-content">
						<!-- button to show the details -->
						<button
							:disabled="!column.description"
							type="button"
							class="column-detail-btn btn btn-link p-0"
							title="show details"
							data-toggle="collapse"
							:data-target="`#${makeColumnId(table, column)}-detail`"
							:aria-controls="`${makeColumnId(table, column)}-detail`"
							aria-expanded="false">
							<i class="toggle-icon fa fa-fw pr-2" />
						</button>

						<!-- label/main text for column -->
						<div class="label-container position-relative" :aria-describedby="`${makeColumnId(table, column)}-detail`">
							<a
								v-if="clickableColumns"
								href="javascript:void(0);"
								class="stretched-link"
								role="button"
								@click="columnClicked(column, table)">
								<span class="text-info">◆</span>&ensp;
								{{ column.name }}
								<span class="col-type text-muted">{{ column.type }}</span>
							</a>
							<template v-else>
								<span class="text-info">◆</span>&ensp;
								{{ column.name }}
								<span class="col-type text-muted">{{ column.type }}</span>
							</template>
						</div>
					</div> <!-- end column-main-content -->

					<!-- collapsable description of column -->
					<div
						v-if="column.description"
						:id="`${makeColumnId(table, column)}-detail`"
						class="column-description collapse border-left border-info p-2 ml-4">
						<!-- if it is expandable/collapsable, wrap in a button so user can toggle it -->
						<!-- bootstrap's collapse doesn't work in this case, so expand it via vue -->
						<button
							v-if="column.description.length > 100"
							class="shortened-description btn btn-link p-0 text-left text-body text-decoration-none"
							type="button"
							title="Expand description"
							:aria-expanded="String(!!expandedDescs[makeColumnId(table,column)])"
							@click="toggleDesc(table,column)">
							<!-- always visible text -->
							{{ column.description.slice(0,100) }}

							<!-- rest of text that gets hidden -->
							<span :hidden="!expandedDescs[makeColumnId(table,column)]">
								{{ column.description.slice(100) }}
							</span>

							<!-- ellipsis when hidden -->
							<span v-if="!expandedDescs[makeColumnId(table,column)]">
								...
							</span>
						</button>

						<!-- if fits in limit, just show it -->
						<span v-else>
							{{ column.description }}
						</span>
					</div>	<!-- end description -->
				</li>	<!-- end column list item -->

				<!-- empty view -->
				<li v-if="!table.columns || !table.columns.length" class="empty-view text-left">
					<template v-if="table.origHadItems">
						No matching columns
					</template>
					<template v-else>
						This table has no columns
					</template>
				</li>
			</ul>
		</li>
	</ul>

	<p v-if="isEmpty" class="text-muted font-italic">
		No tables have been created
	</p>
</div>
</template>

<script>
import { Icon } from 'aunsight-lib-ui';
import _ from 'lodash';

import DaybreakEventBus from './util/DaybreakEventBus';

export default {
	components: { Icon },

	props: {
		datamart: {
			type: Object,
			required: true
		},
		startCollapsed: {
			type: Boolean,
			default: false
		},
		clickableColumns: {
			type: Boolean,
			default: false
		}
	},

	data () {
		return {
			searchString: '',

			// holds expanded state of all descriptions. (populated at interaction)
			expandedDescs: {}
		};
	},

	computed: {
		isEmpty () {
			return _.isEmpty(this.datamart.getQueryables());
		},
		// get list of columns to show based on search string
		filteredColumnTables () {
			const tables = this.datamart.getQueryables();

			const regex = RegExp(this.searchString.trim(), 'i');

			return tables.map(table => {
				const size = table.propertiesOrder.length;

				let cols = _.values(table.properties);

				// if a search exists, apply it
				if (this.searchString.trim()) {
					cols = _.filter(cols, col => regex.test(col.id));
				}

				return {
					name: table.name,
					columns: cols,
					// helps distinguish between filtered out and none to begin with
					origHadItems: !!size
				};
			});
		}
	},

	methods: {
		// Make sure table name is id safe.
		makeTableId (name) {
			return 'table-' + _.kebabCase(name);
		},
		makeColumnId (table, column) {
			return 'table-' + _.kebabCase(table.name) + '-column-' + _.kebabCase(column.name);
		},
		columnClicked (column, table) {
			DaybreakEventBus.$emit('data-source-tree:column-clicked', { column, table });
		},

		// reverse state of a description
		toggleDesc (table, column) {
			const id = this.makeColumnId(table, column);
			// use set because the prop may not have existed on the object
			this.$set(this.expandedDescs, id, !this.expandedDescs[id]);
		}
	}
};

</script>

<style lang="scss">
@import 'app_variables';

.pz-data-source-tree {
	display: flex;
	flex-direction: column;
	overflow: hidden;

	[data-toggle='collapse'][aria-expanded=true] > .toggle-icon::before {
		content: '\F0D7';
	}

	[data-toggle='collapse'][aria-expanded=false] > .toggle-icon::before {
		content: '\F0DA';
	}

	ul.table-list {
		overflow: auto;
		width: max-content;
	}

	ul.list-unstyled {
		padding-left: 0.5rem;

		a,
		a:focus,
		a:hover,
		a:visited {
			color: $body-color;
			text-decoration: none;
		}

		.column-main-content {
			display: flex;

			> * {
				display: block;
				flex: 0 0 auto;
			}
		}

		.label-container {
			flex: 1 1 0;
		}

		.column-detail-btn {
			color: $body-color;

			// don't remove it so spacing is consistent, but don't show it
			&:disabled {
				visibility: hidden;
			}
		}

		.column-description {
			max-width: 16rem;
		}
	}

	// put a little space between table name and columns from prev table
	.column-list {
		margin-bottom: 0.5rem;
		// Add consistent padding for both clickable and non-clickable column-list
		li {
			padding-left: 0;
			padding-right: 0.5rem;
			border-radius: 0.25rem;
		}
		// Apply hover effect only to clickable column-list
		&.clickable {
			li:hover {
				background-color: lighten($info, 60%);
			}
		}
	}

	.table-icon {
		color: $purple-5;
	}

	.col-type {
		float: right;
		font-variant: small-caps;

		// add minimum space between type and name
		margin-left: 1rem;
	}
}

</style>
