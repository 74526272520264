import BaseForm from 'aunsight-webapp/src/js/AWIComponents/BaseForm/BaseForm';
import _ from 'lodash';

import getRelationColumns from '../util/getRelationColumns';

const NUMERIC_TYPES = ['float', 'integer', 'number'];
const NUMERIC_AGG_TYPES = ['min', 'max', 'avg', 'sum'];
const NUMERIC_AGG_TYPE_LABELS = ['Minimum', 'Maximum', 'Average', 'Sum'];
const GROUP_BY_TYPES = ['day', 'week', 'month', 'quarter', 'year'];
const TABLE_SEPARATOR = '__';

export default BaseForm.extend({
	initialize: function (options) {
		this.mergeOptions(options, ['chartType', 'datamart', 'table', 'mode']);
		if (options.insightStartData) {
			this.chartType = options.insightStartData.type;
			this.insightStartData = options.insightStartData;
		}
	},

	formOptions: function () {
		const allColumns = this.getColumnsOfType(() => true);

		const enumFilter = col => !!col.enum;
		const enumColumns = this.getColumnsOfType(enumFilter);

		const numFilter = col => NUMERIC_TYPES.includes(col.type);
		const numericColumns = this.getColumnsOfType(numFilter);

		const dateFilter = col => col.format === 'date-time';
		const dateColumns = this.getColumnsOfType(dateFilter);

		const formOpts = {
			view: {
				parent: 'bootstrap-edit-horizontal',
				messages: {
					// enums are long here, don't list them out if user skips the field
					invalidValueOfEnum: 'Please select a value'
				}
			},
			schema: {
				type: 'object',
				properties: {
					title: {
						type: 'string',
						title: 'Insight Title',
						required: true
					}
				}
			},
			options: {
				hideInitValidationError: true,
				fields: {},
				form: {
					buttons: this.getButtons()
				}
			}
		};

		/** *** Establish main fields (insight group) ****/
		// all fields have mainField except summary which only has aggregation
		if (this.chartType !== 'summary') {
			formOpts.schema.properties.mainField = {
				type: 'string',
				required: true
			};

			_.set(formOpts.options.fields, ['mainField', 'sort'], false);
			// if few items, don't use radio, it looks bad
			_.set(formOpts.options.fields, ['mainField', 'type'], 'select');
		}

		// set details specific to each type
		if (this.chartType === 'column') {
			formOpts.schema.properties.mainField.title = 'Horizontal Axis';
			formOpts.schema.properties.mainField.enum = ['', ..._.map(enumColumns, 'value')];
			formOpts.options.fields.mainField.optionLabels = ['', ..._.map(enumColumns, 'label')];
		}

		else if (this.chartType === 'line') {
			formOpts.schema.properties.mainField.title = 'Horizontal Axis';
			formOpts.schema.properties.mainField.enum = ['', ..._.map(dateColumns, 'value')];
			formOpts.options.fields.mainField.optionLabels = ['', ..._.map(dateColumns, 'label')];

			formOpts.schema.properties.group_by = {
				type: 'string',
				title: 'Group By',
				enum: ['', ...GROUP_BY_TYPES],
				required: true
			};

			_.set(formOpts.options.fields, ['group_by', 'sort'], false);
			_.set(formOpts.options.fields, ['group_by', 'optionLabels'], ['', ...GROUP_BY_TYPES.map(_.capitalize)]);
		}

		else if (this.chartType === 'donut') {
			formOpts.schema.properties.mainField.title = 'Field';
			formOpts.schema.properties.mainField.enum = ['', ..._.map(enumColumns, 'value')];
			formOpts.options.fields.mainField.optionLabels = ['', ..._.map(enumColumns, 'label')];
		}

		/** *** Establish aggregate fields ****/
		// all charts have agg fields except for donut
		if (this.chartType !== 'donut') {
			_.merge(formOpts.schema, {
				properties: {
					aggMode: {
						type: 'string',
						enum: ['Count All Records', 'Select Field'],
						required: true
					},
					aggField: {
						type: 'string',
						title: 'Field',
						required: true
					},
					aggType: {
						type: 'string',
						title: 'Aggregation',
						required: true
					}
				},
				dependencies: {
					aggField: ['aggMode'],
					aggType: ['aggMode']
				}
			});

			_.assign(formOpts.options.fields, {
				aggField: {
					dependencies: {
						aggMode: 'Select Field'
					},
					sort: false,
					type: 'select'
				},
				aggType: {
					dependencies: {
						aggMode: 'Select Field'
					},
					sort: false
				}
			});

			// configure for summary charts
			if (this.chartType === 'summary') {
				formOpts.schema.properties.aggMode.title = 'Data Field';
				formOpts.schema.properties.aggField.enum = ['', ..._.map(allColumns, 'value')];
				formOpts.options.fields.aggField.optionLabels = ['', ..._.map(allColumns, 'label')];
				formOpts.schema.properties.aggType.enum = ['', 'count', ...NUMERIC_AGG_TYPES];
				formOpts.options.fields.aggType.optionLabels = ['', 'Count not empty', ...NUMERIC_AGG_TYPE_LABELS];
			}
			// configure for line and colum charts
			else {
				formOpts.schema.properties.aggMode.title = 'Vertical Axis';
				formOpts.schema.properties.aggField.enum = ['', ..._.map(numericColumns, 'value')];
				formOpts.options.fields.aggField.optionLabels = ['', ..._.map(numericColumns, 'label')];
				formOpts.schema.properties.aggType.enum = ['', ...NUMERIC_AGG_TYPES];
				formOpts.options.fields.aggType.optionLabels = ['', ...NUMERIC_AGG_TYPE_LABELS];
			}
		}

		/** Summary charts' aggregations can be done on non numeric types, but `type`
			selection depends on the `field` type chosen. When `field` changes, make sure
			enums are updated **/
		if (this.chartType === 'summary') {
			formOpts.postRender = function (control) {
				const aggTypeField = control.childrenByPropertyId.aggType;
				const aggFieldField = control.childrenByPropertyId.aggField;

				function updateEnums (val) {
					let enumm, enumLabels;
					if (_.map(numericColumns, 'value').includes(val)) {
						enumm = ['', 'count', ...NUMERIC_AGG_TYPES];
						enumLabels = ['', 'Count not empty', ...NUMERIC_AGG_TYPE_LABELS];
					}
					else {
						enumm = ['', 'count'];
						enumLabels = ['', 'Count not empty'];
					}
					this.schema.enum = enumm;
					this.options.optionLabels = enumLabels;

					// If current value is not in the enum, clear it
					if (!enumm.includes(this.getValue())) {
						this.setValue('');
					}
					this.refresh();
				}

				// subscribe for future changes
				aggTypeField.subscribe(aggFieldField, updateEnums);

				// updates enums for init agg type value if in select field mode
				if (control.getValue().aggMode === 'Select Field') {
					updateEnums.call(aggTypeField, aggFieldField.getValue());
				}
			};
		}

		return formOpts;
	},

	/**
	 * Given a filter, get all columns that match
	 * @param  {Function} filter - a function that accepts a column and returns true or false
	 * @return {Object[]}
	 */
	getColumnsOfType (filter) {
		// get list of columns of certain types
		const table = this.datamart.getTable(this.table).spec;
		const colSpecs = table.columns || _.values(table.properties);

		const columns = colSpecs
			.filter(filter)
			.map(c => ({ label: c.name || c.id, value: c.id }));

		const relationColumns = getRelationColumns(this.datamart, this.table);

		relationColumns.forEach(relTable => {
			const ncols = relTable.columns
				.filter(filter)
				.map(c => {
					const type = relTable.root_key ? 'root_key' : 'key';
					const val = {
						table: relTable.id,
						field: c.id
					};
					val[type] = relTable[type];
					return {
						label: (relTable.name || relTable.id) + ' - ' + (c.name || c.id),
						value: this.encodeRelationValue(val)
					};
				});
			columns.push(...ncols);
		});

		return columns;
	},

	/**
	 * Split out the compunded string value of a relation column
	 *
	 * see encodeRelationValue
	 * @param  {string} value - the compounded string value
	 * @return {Object} an object with keys `table`, `field`, and `key` or `root_key`
	 */
	decodeRelationValue (value) {
		const parts = value.split(TABLE_SEPARATOR);
		const result = {
			field: parts[3],
			table: parts[0]
		};
		result[parts[1]] = parts[2];

		return result;
	},

	/**
	 * To put all info in single string val, combine into one
	 * for relation columns, to fit it all in a single string value, it will be
	 * encoded as:
	 *
	 * [table]__['key' or 'root_key']__[linking column]__[column]
	 *
	 * e.g. `Customer__root_key__Account_CustomerKey__HasExternalCarLease`
	 * @param  {Object} val
	 * @param {string} val.table
	 * @param {string} val.root_key
	 * @param {string} val.key
	 * @param {string} val.field
	 * @return {string}
	 */
	encodeRelationValue (val) {
		if (!val.table) return val.field;
		const type = val.root_key ? 'root_key' : 'key';
		return val.table + TABLE_SEPARATOR + type + TABLE_SEPARATOR + val[type] + TABLE_SEPARATOR + val.field;
	},

	getButtons: function () {
		if (this.mode === 'edit') {
			return {
				submit: {
					styles: 'btn btn-primary',
					label: 'Submit'
				}
			};
		}
		else {
			return {
				back: {
					type: 'button',
					label: 'Back'
				},
				submit: {
					styles: 'btn btn-primary',
					label: 'Submit'
				}
			};
		}
	},

	buttonHandlers: {
		back: function () {
			this.trigger('back');
		}
	},

	getStartData () {
		if (this.insightStartData) {
			return this.convertFromInsight(this.insightStartData);
		}
		else return {};
	},

	// translate from insight to form vals
	convertFromInsight (insight) {
		const data = {
			title: insight.title
		};
		const group = insight.query.group;
		if (group) {
			data.mainField = this.encodeRelationValue(group);
			if (group.group_by) {
				data.group_by = group.group_by;
			}
		}

		const agg = insight.query.aggregate;
		if (agg) {
			if (agg.field) {
				data.aggType = agg.type;
				data.aggField = this.encodeRelationValue(agg);
				data.aggMode = 'Select Field';
			}
			else {
				data.aggMode = 'Count All Records';
			}
		}

		return data;
	},

	/** **** transform into proper insight format *******/
	convertToInsight (formData) {
		const data = {
			title: formData.title,
			type: this.chartType
		};
		const query = {};

		if (formData.mainField) {
			// line charts have group type of date-time and all others are categorical
			query.group = {
				field: formData.mainField,
				type: this.chartType === 'line' ? 'date-time' : 'categorical'
			};

			if (_.includes(formData.mainField, TABLE_SEPARATOR)) {
				_.assign(query.group, this.decodeRelationValue(formData.mainField));
			}

			if (formData.group_by) {
				query.group.group_by = formData.group_by;
			}
		}
		if (formData.aggMode) {
			// if a field was selection for aggregate
			if (formData.aggType) {
				query.aggregate = {
					type: formData.aggType
				};

				if (formData.aggField) {
					if (_.includes(formData.aggField, TABLE_SEPARATOR)) {
						_.assign(query.aggregate, this.decodeRelationValue(formData.aggField));
					}
					else {
						query.aggregate.field = formData.aggField;
					}
				}
			}
			// if count all records was used for aggregate
			else {
				query.aggregate = { type: 'count' };
			}
		}
		if (this.chartType === 'donut') {
			query.aggregate = { type: 'count' };
		}

		data.query = query;

		return data;
	},

	submit: function () {
		const formData = this.ui.form.alpaca().getValue();

		/** **** transform into proper insight format *******/

		const data = this.convertToInsight(formData);

		this.trigger('done', data);
	}

});
