<template>
<div class="pz-daybreak-tool d-flex">
	<!-- need a container so dialogs are not siblings -->
	<div class="content d-flex flex-grow-1">
		<!-- actual content -->
		<div ref="leftpanel" class="d-flex flex-grow-1">
			<query-view
				v-if="activeQuery && !activeQuery.error && !isActiveQueryLoading"
				:key="viewKey"
				class="flex-grow-1"
				:ace-loaded="aceLoaded"
				:datamart="datamart"
				:query-data="activeQuery"
				:start-tool="startTool"
				:context="context"
				:new-u-r-l="newURL"
				:use-vue-routing="useVueRouting"
				:mock-query="mockQuery"
				:nlp-url="nlpUrl"
				:nlp-examples="nlpExamples"
				:starting-nlp-search="startingNlpSearch"
				@resolveStartingNlpSearch="$emit('resolveStartingNlpSearch')"
				@clearNew="clearNew"
				@save="save"
				@saveas="saveas"
				@createnewfrom="createNewFrom"
				@editDetails="editDetails"
				@deleteQuery="deleteQuery"
				@share="shareQuery"
				@hasChanges="hasChanges"
				@updateQueryTool="updateQueryTool" />

			<!-- wrapper helps size overlay -->
			<div v-if="isActiveQueryLoading" class="flex-grow-1 position-relative">
				<div class="pz-load-indicator-mask">
					<icon name="spinner" spin />
				</div>
			</div>
		</div> <!-- end left panel -->

		<!-- Tool sidebar -->
		<div
			v-if="activeQueryTool !== 'nlp'"
			ref="rightpanel"
			class="pz-tool-sidebar border-left">
			<!-- selector tabs -->
			<tab-list
				id="pz-tool-sidebar-tablist"
				class="pl-2"
				:items="tabs"
				:selection="activeTab"
				@itemselected="updateActiveTab" />

			<!-- sidebar content -->
			<data-source-tree
				v-if=" activeTab === 'datasources' "
				class="flex-grow-1"
				:datamart="datamart"
				:clickable-columns="activeQueryTool === 'sql'" />

			<query-list
				v-if=" activeTab === 'querylist' "
				class="flex-grow-1"
				:queries-list="myQueryList"
				:shared-list="sharedQueryList"
				:standard-list="standardQueryList"
				:selection="querySelection"
				:is-loading="!isListLoaded"
				:is-a="!useVueRouting"
				:new-u-r-l="newURL"
				:members="members"
				:new-s-q-l-u-r-l="newSQLURL"
				:new-wizard-u-r-l="newWizardURL"
				:nlp-enabled="!!nlpUrl"
				@clearNew="clearNew" />
		</div>
	</div> <!-- end daybreak tool content -->

	<!-- dialogs -->
	<div class="dialogs-container">
		<mn-component-view
			v-if="isCreateDialogOpen"
			ref="create-query-dialog"
			:view="CreateQueryDialog"
			:options="createQueryDialogOptions"
			@destroy="onCreateClose"
			@done="onCreated" />

		<mn-component-view
			v-if="isEditDialogOpen"
			:view="EditQueryDialog"
			:options="editQueryDialogOptions"
			@destroy="onEditClose" />

		<mn-component-view
			v-if="isShareDialogOpen"
			:view="ShareQueryDialog"
			:options="shareQueryDialogOptions"
			@destroy="onShareClose" />

		<mn-component-view
			v-if="isProgressDialogOpen"
			:view="ProgressDialog"
			:options="progressDialogOptions"
			@destroy="onProgressClose" />
	</div>
</div>
</template>

<script>

import Datamart from 'aunsight-lib-query-js/lib/Datamart.js';
import { Icon } from 'aunsight-lib-ui';
import awiPrompt from 'aunsight-webapp/src/js/AWIComponents/Prompt/Prompt';
import PromiseProgressDialog from 'aunsight-webapp/src/js/modules/ProgressDialog/PromiseProgressDialog';
import $ from 'jquery';
import _ from 'lodash';
import scriptjs from 'scriptjs';

import MnComponentView from '@/components/MnWrapper.vue';
import app from '@/webapp';

import CreateQueryDialog from './CreateQueryDialog';
import DataSourceTree from './DataSourceTree.vue';
import EditQueryDialog from './EditQueryDetails';
import SavedQueryBroker from './lib/SavedQueryBroker';
import QueryList from './QueryList.vue';
import QueryView from './QueryView.vue';
import ShareQueryDialog from './ShareQuery';
import TabList from './TabList.vue';

let testData = {};
let mockDataPromise;
let isMockDataLoaded = false;

export default {
	name: 'DaybreakTool',
	components: {
		QueryView,
		DataSourceTree,
		QueryList,
		MnComponentView,
		Icon,
		TabList
	},

	props: {
		datamart: {
			type: Datamart,
			required: true
		},
		config: {
			type: Object,
			required: true
		},
		context: {
			type: Object,
			required: true
		},
		// whether to actually run query or not
		mockQuery: {
			type: Boolean,
			default: false
		},

		// a function that takes object params and returns a url of a query
		getURL: {
			type: Function,
			required: true
		},

		querySelection: {
			type: String,
			default: null
		},

		useVueRouting: {
			type: Boolean,
			default: true
		},

		navStartMode: {
			type: String,
			default: undefined
		},

		nlpUrl: {
			type: String, required: true
		},
		nlpExamples: {
			type: Array, required: true
		},

		startingNlpSearch: {
			type: String,
			default: null
		}
	},

	data () {
		return {
			/**
			 * @type {SavedQueryBroker}
			 */
			savedQueryBroker: undefined,

			CreateQueryDialog: CreateQueryDialog,
			createQueryDialogOptions: {},
			isCreateDialogOpen: false,

			EditQueryDialog: EditQueryDialog,
			editQueryDialogOptions: {},
			isEditDialogOpen: false,

			ShareQueryDialog: ShareQueryDialog,
			shareQueryDialogOptions: {},
			isShareDialogOpen: false,

			ProgressDialog: PromiseProgressDialog,
			progressDialogOptions: {},
			isProgressDialogOpen: false,

			activeTab: 'datasources',

			queries: [],
			sharedQueries: [],
			aceLoaded: false,

			// for shared queries, it must reload before presenting
			isActiveQueryLoading: false,

			// helps active query not jump to not found when it is deleted
			isActiveQueryDeleting: false,

			// view key generally = query selection but not always.
			// in order to clear new easily, we need to give 'new' an identifier so we can go to a new "new"
			// Therefore have a separate key that we can update as needed.
			viewKey: undefined,

			// sql, wizard or text
			startTool: undefined,

			// whether the currently opened query has changes
			currentHasChanges: false,

			members: [],
			// any err from loading member request. Should probably do something with this.
			loadMemberErr: null,
			isMemListLoaded: false,

			stashedData: undefined,

			userId: undefined,

			// which tool is in use. Affects tool sidebar
			activeQueryTool: undefined
		};
	},

	computed: {
		isNew () {
			return this.querySelection === 'new';
		},
		newURL () {
			return this.getURL('new');
		},
		newSQLURL () {
			return this.getURL('new', { mode: 'sql' });
		},
		newWizardURL () {
			return this.getURL('new', { mode: 'wizard' });
		},
		isListLoaded () {
			return _.get(this.savedQueryBroker, 'hasLoaded') && this.isMemListLoaded;
		},
		activeQuery () {
			if (!this.querySelection) return null;
			// if the list hasn't loaded and we need it to be, just return
			if (!this.isListLoaded && !this.isNew) return null;
			if (this.isActiveQueryLoading) return null;

			if (this.isNew) {
				if (this.stashedData) {
					const data = this.stashedData;

					// new id isn't actually needed, but it helps this be recalculated when it changes.
					data.newid = this.viewKey;
					delete this.stashedData;
					return data;
				}
				else {
					return {
						newid: this.viewKey,
						query_type: (this.startTool === 'sql') ? 'sql' : 'datamart'
					};
				}
			}

			const query = this.savedQueryBroker.getQuery(this.querySelection);

			if (query) {
				return this.prepSavedQuery(query);
			}

			else if (this.isActiveQueryDeleting) {
				// if it's deleting, this means it just got removed from list
				// and selection will soon be changed to new
				return null;
			}
			else {
				// error message when query not found
				return {
					error: {
						name: 'notfound',
						message: 'Query cannot be found. It may have been deleted or the id may be incorrect.'
					}
				};
			}
		},

		// standard queries but with added url info for list view
		standardQueryList () {
			if (!this.savedQueryBroker) return [];
			return _.filter(this.savedQueryBroker.queries, { category: 'template' });
		},
		myQueryList () {
			if (!this.savedQueryBroker) return [];
			return _.filter(this.savedQueryBroker.queries, { category: 'owned' });
		},
		sharedQueryList () {
			if (!this.savedQueryBroker) return [];
			return _.filter(this.savedQueryBroker.queries, { category: 'shared' });
		},

		tabs () {
			const tabs = [
				{
					id: 'datasources',
					label: 'Field Definitions'
				},
				{
					id: 'querylist',
					label: 'Saved Queries'
				}
			];

			return tabs;
		}
	},

	watch: {
		querySelection () {
			this.startTool = this.navStartMode || undefined;
			this.updateViewKey();

			const query = this.savedQueryBroker.getQuery(this.querySelection);
			const isShared = _.get(query, 'category') === 'shared';
			if (isShared) {
				// fetch query
				this.isActiveQueryLoading = true;

				if (this.mockQuery) {
					setTimeout(() => {
						this.isActiveQueryLoading = false;
					}, 300);
				}
				else {
					this.savedQueryBroker.refetchQuery(query.id)
						.always(() => {
							this.isActiveQueryLoading = false;
						});
				}
			}
		},

		activeQuery (aq, prevQuery) {
			// When the active query changes, check for an error
			// to act, the query must not be loading and it must be a new error
			if (aq && aq.error && !_.isEqual(aq, prevQuery) && !this.isActiveQueryLoading) {
				this.showQueryError();
			}
		},

		$route () {
			// clear any existing modals
			this.onProgressClose();
			this.onShareClose();
			this.onEditClose();
			this.onCreateClose();
		}
	},

	created () {
		this.userId = this.mockQuery ? 'asdf' : app.auth.user.id;
		this.startTool = this.navStartMode || undefined;
		this.updateViewKey();
	},

	mounted () {
		// Load ace libraries here for use when raw sql is displayed
		scriptjs('https://cdnjs.cloudflare.com/ajax/libs/ace/1.2.5/ace.js', () => {
			scriptjs(['https://cdnjs.cloudflare.com/ajax/libs/ace/1.2.5/ext-static_highlight.js', 'https://cdnjs.cloudflare.com/ajax/libs/ace/1.2.5/ext-language_tools.js'], () => {
				this.aceLoaded = true;
			});
		});

		// -------- Setup data ---------
		this._setupMyQueriesCollection();

		// If we are mocking, use the mock data
		if (this.mockQuery) {
			this.mockFetchData();
		}
		else {
			this.loadMembers();
		}
	},

	methods: {

		updateQueryTool (tool) {
			this.activeQueryTool = tool;
		},

		updateActiveTab (tab) {
			this.activeTab = tab;
		},

		// wrapping it in a member method helps unit tests
		prompt () {
			return awiPrompt.apply(null, arguments);
		},

		showQueryError () {
			// this would normally get reset by Query view, but since there was an
			// error, it doesn't open and thus doesn't get reset
			if (this.currentHasChanges) this.hasChanges(false);

			const query = this.activeQuery;

			this.prompt({
				type: 'alert',
				title: 'Cannot open this query',
				text: query.error.message
			})
				.then(() => {
				// tell parent to change selection to new

					// if error is that query wasn't found, next step not needed
					if (query.error.name !== 'notfound') {
					// after selection is updated, remove errored query

						// since it relies on parent to update which is async, and nextTick
						// not enough, just watch (and unwatch)
						// (if you don't wait until selection changed before removing old,
						// you'll get a not found error)
						const unwatch = this.$watch('querySelection', () => {
							this.savedQueryBroker.removeQuery(query.id);
							unwatch();
						});
					}

					this.$emit('updateSelection', 'new');
				});
		},

		// functions to prepare/augment query data before presentation

		prepSavedQuery (query) {
			const creator = _.find(this.members, { id: query.created_by });
			if (creator) {
				query.createdByName = creator.fullname;
			}

			return query;
		},

		_setupMyQueriesCollection () {
			const options = {
				userId: this.userId,
				context: this.context,
				getURL: this.getURL,
				isMock: this.mockQuery,
				fetchAll: true
			};
			this.savedQueryBroker = new SavedQueryBroker(options);
		},

		loadMembers () {
			return $.ajax(`${app.apiPath}daybreak/config/${this.context.config}/scope/${this.context.scope}/members`)
				.then(
					response => {
						this.members = response.result.map(m => {
							m.fullname = [m.firstname, m.lastname].join(' ');
							return _.pick(m, ['id', 'firstname', 'lastname', 'email', 'fullname', 'active']);
						});

						this.isMemListLoaded = true;
					},
					(err) => {
						this.loadMemberErr = err;
						this.isMemListLoaded = true;
					}
				);
		},

		mockFetchData () {
			// load data if not already loaded
			if (!isMockDataLoaded && !mockDataPromise) {
				mockDataPromise = import(/* webpackChunkName: "daybreak-test-configs" */ './testUtils/testData')
					.then(module => {
						testData = module.default;
						isMockDataLoaded = true;
					});
			}

			// simulate loading with a timeout
			const loadData = () => {
				const savedQueries = this.datamart.queryEngine === 'drill'
					? testData.savedQueriesV1
					: testData.savedQueries;

				this.isMemListLoaded = true;
				this.savedQueryBroker.setMockData(savedQueries);
				this.members = testData.members;
			};
			if (isMockDataLoaded) {
				setTimeout(loadData, 1000);
			}
			else {
				mockDataPromise.then(() => {
					setTimeout(loadData, 1000);
				});
			}
		},

		updateViewKey () {
			// for new items, add a unique id for clearing purposes.
			if (this.isNew) {
				this.viewKey = _.uniqueId('new');
			}
			else this.viewKey = this.querySelection;
		},

		save (data, doPrompt) {
			if (this.isNew) {
				this.createNewQuery(data);
			}
			else this.updateCurrentQuery(data, doPrompt);
		},

		saveas (data) {
			this.createNewQuery(data);
		},

		createNewQuery (data) {
			this.createQueryDialogOptions = {
				data: data,
				context: this.context,
				includeResource: false
			};

			this.isCreateDialogOpen = true;
		},

		editDetails () {
			const model = this.savedQueryBroker.getQueryModel(this.querySelection);
			if (!model) throw new Error('Invalid query to save!');

			this.editQueryDialogOptions = {
				model: model,
				context: this.context
			};

			this.isEditDialogOpen = true;
		},

		shareQuery () {
			const model = this.savedQueryBroker.getQueryModel(this.querySelection);
			if (!model) throw new Error('Invalid query to share!');

			this.shareQueryDialogOptions = {
				members: _.reject(this.members, { id: this.userId }),
				model: model,
				context: this.context
			};

			this.isShareDialogOpen = true;
		},

		createNewFrom (data) {
			this.stashedData = data;

			this.$emit('updateSelection', 'new');
		},

		updateCurrentQuery (data, doPrompt) {
			// get model
			const model = this.savedQueryBroker.getQueryModel(this.querySelection);
			if (!model) throw new Error('Invalid query to save!');

			const doSave = () => {
				const promise = model.save(data);

				this.progressDialogOptions = {
					promises: [promise],
					errorDisplay: { title: 'An error occurred saving query' },
					title: 'Saving Query',
					showServerError: true
				};

				this.isProgressDialogOpen = true;
			};

			if (data.query !== model.get('query') && doPrompt !== false) {
				// if query has changed, confirm with user
				this.prompt({
					type: 'confirm',
					title: 'Changing saved query',
					text: '<p>You are about to overwrite an existing query with new or updated filters which may alter the query results.</p><p>Do you wish to overwrite this query?</p>',
					submitButtonText: 'Save Changes',
					extraClasses: 'change-saved-query'
				}).then(doSave);
			}
			else {
				doSave();
			}
		},

		onCreateClose () {
			this.isCreateDialogOpen = false;
			this.createQueryDialogOptions = {};
		},

		onCreated (model) {
			this.savedQueryBroker.refetch();
			// updating the queries is debounced, so use a watch
			const unwatch = this.$watch('savedQueryBroker.queries', () => {
				this.$emit('updateSelection', model.id);
				unwatch();
			});
		},

		onEditClose () {
			this.isEditDialogOpen = false;
			this.editQueryDialogOptions = {};
		},

		onShareClose () {
			this.isShareDialogOpen = false;
			this.shareQueryDialogOptions = {};
		},

		onProgressClose () {
			this.isProgressDialogOpen = false;
			this.progressDialogOptions = {};
		},

		deleteQuery () {
			let message = '<p>You are about to delete this saved query. This action cannot be reversed.</p>';

			if (!_.isEmpty(this.activeQuery.shared_with)) {
				message += '<p>This query has been shared with others.</p>';
			}

			message += '<p>Do you wish to delete this query?</p>';

			this.prompt({
				type: 'confirm',
				title: 'Delete This Query',
				text: message,
				submitButtonText: 'Delete Query',
				level: 'danger'
			})
				.then(() => {
				// get model
					const model = this.savedQueryBroker.getQueryModel(this.querySelection);

					if (!model) throw new Error('Invalid query to delete!');

					const promise = model.destroy();

					this.progressDialogOptions = {
						promises: [promise],
						errorDisplay: { title: 'An error occurred deleting query' },
						title: 'Deleting Query',
						showServerError: true
					};

					this.isActiveQueryDeleting = true;
					this.isProgressDialogOpen = true;

					// Maybe this can be done in a more reactive manner
					promise.then(() => {
					// use short delay to make sure dialog is closed
						setTimeout(() => {
							this.$emit('updateSelection', 'new');
							// since updating selection relies on parent and is async, watch for change
							// (if you don't, it will think the query is not found)
							const unwatch = this.$watch('querySelection', () => {
								this.isActiveQueryDeleting = false;
								unwatch();
							});
						}, 100);
					});
				});
		},

		hasChanges (val) {
			this.currentHasChanges = val;
			// Pass through to parent
			this.$emit('hasChanges', val);
		},

		clearNew (startTool) {
			if (this.currentHasChanges) {
				this.prompt({
					type: 'confirm',
					title: 'Clear Unsaved Changes',
					text: '<p>You have made changes to an unsaved query.</p>' +
							'<p>Do you wish to clear out these changes and start new?</p>',
					submitButtonText: 'Clear and Start New',
					level: 'warning'
				})
					.then(() => {
						this.startTool = startTool;
						this.updateViewKey();
					});
			}

			else {
				this.startTool = startTool;
				this.$nextTick(() => this.updateViewKey());
			}
		}
	}
};

</script>

<style lang="scss">
// import tool wide styles
@import './scss/daybreak.scss';

/* should expand to parent (hack) */
.pz-daybreak-tool {
	height: 100%;

	// make sure gutter is right size.
	.gutter {
		min-width: 5px;
	}
}

.pz-tool-sidebar {
	display: flex;
	flex-flow: column nowrap;
	flex: 0 0 auto;

	// this will enforce limits on the resizer
	min-width: 272px;
	max-width: 424px;
}
</style>
