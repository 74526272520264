<template>
<!-- define all in one line because it's inline, and extra whitespace is rendered as spaces which do show up UI -->
<span
	v-if="type!=='static'"
	v-tooltip.hideOnClick
	:class="classes"
	class="fragment-text"
	:title="type">{{ text }}</span>
<span
	v-else
	:class="classes"
	class="fragment-text">{{ text }}</span>
</template>

<script>

import { Tooltip } from 'aunsight-lib-ui';
export default {
	directives: {
		Tooltip
	},
	props: {
		type: {
			type: String,
			required: true
		},
		text: {
			type: [String, Number],
			required: true
		}
	},
	computed: {
		classes () {
			const classes = ['pz-text-fragment', 'pz-text-fragment-' + this.type];

			if (this.type !== 'static') {
				classes.push('fragment-bubble');
			}

			return classes.join(' ');
		}
	}
};

</script>

<style lang="scss">
@import 'app_variables';

.pz-text-fragment.fragment-bubble {

	// define colors
	$pz-text-frag-color-1: $green-5;
	$pz-text-frag-color-2: $purple-6;
	$pz-text-frag-color-3: $blue-6;
	$pz-text-frag-color-4: $megenta-5;

	//bubble styling
	font-size: 95%;

	// don't break line in a bubble
	white-space: nowrap;

	// color scheme for each different parts
	&.pz-text-fragment-column {
		color: $pz-text-frag-color-1;
	}

	&.pz-text-fragment-table {
		color: $pz-text-frag-color-2;
	}

	&.pz-text-fragment-value {
		color: $pz-text-frag-color-3;
	}

	&.pz-text-fragment-operator {
		color: $pz-text-frag-color-4;
	}

	&:not(.pz-text-fragment-static) {
		font-weight: bold;
	}
}

</style>
