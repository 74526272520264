import OrigNotificationTracker from 'aunsight-webapp/src/js/controllers/NotificationTracker';

import app from '@/webapp';

class NotificationTracker extends OrigNotificationTracker {
	// not sure if the store interactions should be in original or not
	setCount (count) {
		this.count = count;

		app.vue.$store.commit('newNotifications', count);
	}
}

export default NotificationTracker;
