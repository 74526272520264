
import $ from 'jquery';
import _ from 'lodash';

export default function getMockSuggestions (input, time) {
	const promise = $.Deferred();
	// for demonstration purposes, and since we cannot call the actual api, the user input will always return 'Customers' or 'Accounts'
	let mockResult;
	if (_.startsWith(input, 'cu')) {
		mockResult = {
			input: 'customer',
			suggest: [
				[{ text: 'show me ', type: 'suggestion' }, { text: 'Customers', type: 'user-input' }, { text: ' who buy cars', type: 'suggestion' }],
				[{ text: 'show me ', type: 'suggestion' }, { text: 'Customers', type: 'user-input' }, { text: ' with home loans', type: 'suggestion' }],
				[{ text: 'Customers', type: 'user-input' }, { text: ' with credit score greater than 700', type: 'suggestion' }],
				[{ text: 'Accounts with ', type: 'suggestion' }, { text: 'Customers', type: 'user-input' }, { text: ' in South Bend', type: 'suggestion' }],
				[{ text: 'Customers', type: 'user-input' }, { text: ' named Bob', type: 'suggestion' }],
				[{ text: 'Cu', type: 'user-input' }, { text: 'stomers named Bob', type: 'suggestion' }],
				[{ text: 'Customers', type: 'user-input' }, { text: ' who like Star Wars', type: 'suggestion' }],
				[{ text: 'Customers', type: 'user-input' }, { text: ' with overdraft protection', type: 'suggestion' }],
				[{ text: 'Customers', type: 'user-input' }, { text: ' with a second ', type: 'suggestion' }, { text: 'Customers', type: 'user-input' }, { text: ' in the search results', type: 'suggestion' }]
			]
		};
	}
	else if (_.startsWith(input, 'cs')) {
		mockResult = {
			input: 'cstomer',
			suggest: [
				[{ text: 'show me ', type: 'suggestion' }, { text: 'Customers', type: 'user-input' }, { text: ' who buy cars', type: 'suggestion' }],
				[{ text: 'show me ', type: 'suggestion' }, { text: 'Customers', type: 'user-input' }, { text: ' with home loans', type: 'suggestion' }],
				[{ text: 'Customers', type: 'user-input' }, { text: ' with credit score greater than 700', type: 'suggestion' }],
				[{ text: 'Accounts with ', type: 'suggestion' }, { text: 'Customers', type: 'user-input' }, { text: ' in South Bend', type: 'suggestion' }],
				[{ text: 'Customers', type: 'user-input' }, { text: ' named Bob', type: 'suggestion' }],
				[{ text: 'Cu', type: 'user-input' }, { text: 'stomers named Bob', type: 'suggestion' }],
				[{ text: 'Customers', type: 'user-input' }, { text: ' who like Star Wars', type: 'suggestion' }],
				[{ text: 'Customers', type: 'user-input' }, { text: ' with overdraft protection', type: 'suggestion' }],
				[{ text: 'Customers', type: 'user-input' }, { text: ' with a second ', type: 'suggestion' }, { text: 'Customers', type: 'user-input' }, { text: ' in the search results', type: 'suggestion' }]
			]
		};
	}
	else if (_.startsWith(input, 'a')) {
		mockResult = {
			input: 'accounts',
			suggest: [
				[{ text: 'show me ', type: 'suggestion' }, { text: 'Accounts', type: 'user-input' }, { text: ' with overdraft protection', type: 'suggestion' }],
				[{ text: 'show me ', type: 'suggestion' }, { text: 'Accounts', type: 'user-input' }, { text: ' with home loans', type: 'suggestion' }],
				[{ text: 'Accounts', type: 'user-input' }, { text: ' in San Diego', type: 'suggestion' }],
				[{ text: 'Accounts', type: 'user-input' }, { text: ' with Customers', type: 'suggestion' }, { text: ' in South Bend', type: 'suggestion' }]
			]
		};
	}
	else if (_.startsWith(input, 'p')) {
		mockResult = {
			input: 'product c',
			suggest: [
				[{ text: 'product category', type: 'user-input' }, { text: '', type: 'suggestion' }],
				[{ text: 'product market', type: 'user-input' }, { text: '', type: 'suggestion' }],
				[{ text: 'product type', type: 'user-input' }, { text: '', type: 'suggestion' }]]
		};
	}
	else {
		mockResult = {
			input: '',
			suggest: []
		};
	}
	setTimeout(() => {
		promise.resolve({
			result: mockResult
		});
	}, time);

	return promise;
}
