<template>
<div class="pz-condition-group px-4 bg-light">
	<!-- remove group button -->
	<div class="float-right">
		<button
			name="remove"
			class="btn btn-link"
			title="Remove this condition group"
			@click="remove">
			<span class="fa-stack icon-stack-danger">
				<i class="bottom-icon fas fa-circle fa-stack-2x" />
				<i class="top-icon fas fa-minus fa-stack-1x" />
			</span>
		</button>
	</div>

	<!-- group operator -->
	<div class="form-inline">
		<select
			ref="opSelect"
			name="table_operator"
			class="form-control"
			@change="updateOp">
			<option value="all" :selected="operator==='all'">
				all
			</option>
			<option value="any" :selected="operator==='any'">
				any
			</option>
		</select>

		<span class="px-1"> of the following conditions: </span>
	</div>

	<filter-list
		:class="`pz-cond-group-${operator === 'any'? 'or': 'and'}`"
		:value="value"
		:columns="columns"
		:relations="relations"
		:parent-id="id"
		@remove="removeChild"
		@change="changeChild"
		@addRow="addChildRow" />
</div>
</template>

<script>
import FilterList from './FilterList.vue';

export default {
	name: 'ConditionGroup',
	components: {
		FilterList
	},

	props: {
		// list of available columns
		columns: {
			type: Array,
			required: true
		},
		// List of available relations
		relations: {
			type: Array,
			required: true
		},

		// list of filters
		value: {
			type: Array,
			default: () => []
		},

		// id of this filter group
		id: {
			type: String, required: true
		},

		// if this is a nested filter list, the id will be id of parent filter
		parentId: {
			type: String,
			default: ''
		},

		operator: {
			type: String, required: true
		}
	},

	methods: {
		changeChild (id, prop, val) {
			this.$emit('change', id, prop, val);
		},

		removeChild (id) {
			this.$emit('remove', id);
		},

		addChildRow (id) {
			this.$emit('addRow', id);
		},

		remove () {
			this.$emit('removeGroup', this.id);
		},

		updateOp (e) {
			this.$emit('changeOp', this.id, e.target.value);
		}
	}
};
</script>
