<template>
<error-message
	v-if="fetchErr"
	class="m-3"
	:errors="[fetchErr]"
	title="Could not get query information" />
<main v-else id="pz-query-preview">
	<!-- load indicator -->
	<div v-if="fetchQueryPromise" class="load-indicator-mask">
		<icon	name="spinner" spin />
	</div>
	<!-- metadata -->
	<div id="pz-query-preview-info">
		<!-- left -->
		<div v-if="query" id="pz-query-preview-info-text">
			<!-- header -->
			<div class="header-container">
				<h1>
					{{ query.name }}
				</h1>

				<!-- subheader -->
				<div v-if="query.creatorName" class="subheader">
					<span class="text-muted">Created by:</span> {{ query.creatorName }}
				</div>
				<div v-else-if="query.category === 'template'" class="subheader">
					Query Template
				</div>
			</div>

			<!-- description -->
			<!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
			<p id="pz-query-preview-info-description">{{ query.description }}</p>

			<tag-list
				:query="query"
				:context="context"
				@tagsupdated="onTagsUpdated" />
		</div>

		<!-- right -->
		<div id="pz-query-preview-info-actions">
			<!-- actions -->
			<action-list :items="actions" @action-clicked="actionClicked" />
		</div>
	</div>

	<!-- error view for when the query fails or can't run -->
	<div
		v-if="!!sqlError || !!runError"
		id="pz-query-preview-run-error"
		class="text-center mt-5">
		<icon
			name="exclamationTri"
			size="x5"
			custom-class="text-warning mb-3" />
		<details>
			<summary class="h5">
				Query Error
			</summary>
			<error-message
				v-if="runError"
				:errors="runError.errors"
				:message="runError.message"
				:title="runError.title" />
			<error-message
				v-else
				:errors="[sqlError]" />
		</details>
		<p>Open in Query Builder to review or edit</p>
		<router-link
			:to="{ name: 'Query Builder', params: { queryid: query.id } }"
			class="btn btn-primary mt-2">
			Open in Query Builder
		</router-link>
	</div>

	<div
		v-else
		id="pz-query-preview-results"
		class="position-relative">
		<!-- loading spinner when running -->
		<div v-if="isRunning" class="pz-load-indicator-mask">
			<icon name="spinner" spin />
			<h3 class="mt-3">
				Running query and fetching results...
			</h3>
		</div>

		<!-- insights -->
		<details
			v-if="isStructured"
			id="pz-query-preview-insights"
			open>
			<summary
				class="h3"
				role="header"
				aria-level="2">
				Data Insights
			</summary>

			<insights-panel
				v-if="hasRun && hasInsights"
				id="pz-query-preview-insights-panel"
				:datamart="datamart"
				:insights="query.insights"
				:is-read-only="true"
				:runner="runner" />

			<div v-else-if="hasRun && !hasInsights" class="empty-view">
				No insights have been created for this query
			</div>
		</details>

		<!-- data results -->
		<details id="pz-query-preview-data-results" open>
			<summary
				class="h3"
				role="header"
				aria-level="2">
				Data Results
			</summary>
			<results-table
				v-if="hasRun && !isEmptyResults"
				id="pz-query-preview-data-results-table"
				:collection="collection"
				:is-outdated="false"
				:is-wizard-mode="isStructured"
				:total-columns="totalColumns"
				@openColumnDialog="openColumnDialog"
				@download="download" />

			<!-- the empty view when no results -->
			<div
				v-else-if="hasRun && isEmptyResults"
				class="text-center text-muted pt-4">
				<img
					src="@/assets/empty-results.svg"
					height="100"
					width="100">
				<p>No results found</p>
				<router-link
					:to="{ name: 'Query Builder', params: { queryid: query.id } }"
					class="btn btn-primary mt-2">
					Open in Query Builder
				</router-link>
			</div>
		</details>

		<download-dialog
			:open="isDownloadDialogOpen"
			:runner="runner"
			:should-use-columns="isStructured"
			:initial-values="downloadDialogOptions"
			:datamart="datamart"
			:structured-query="isStructured? query.structured_query: {}"
			container-selector="#pz-query-preview"
			@close="onDownloadClose" />

		<column-selector-dialog
			v-if="isColumnSelectorOpen"
			:datamart="datamart"
			:structured-query="isStructured? query.structured_query: {}"
			@updateColumns="updateColumns"
			@close="onColumnSelectorClose"
			@run="run" />
	</div>

	<!-- Share Dialog -->
	<mn-component-view
		v-if="isShareDialogOpen"
		:view="ShareQueryDialog"
		:options="shareQueryDialogOptions"
		@done="reloadSharedWith"
		@destroy="onShareClose" />
</main>
</template>

<script>
import { ActionList, ErrorMessage, Icon } from 'aunsight-lib-ui';
import MnComponentView from 'aunsight-webapp/src/js/AWIComponents/MnComponentView.vue';
import QueryRunManager from 'aunsight-webapp/src/js/modules/GuidedQueryBrowser/models/QueryRunManager';
import TableCollection from 'aunsight-webapp/src/js/modules/GuidedQueryBrowser/models/TableCollection';
import QueryModel from 'aunsight-webapp/src/js/modules/QueryTool/models/SavedQuery';
import getXHRError from 'aunsight-webapp/src/js/util/getXHRError';
import $ from 'jquery';
import _ from 'lodash';

import TagList from '@/components/TagList';
import ColumnSelectorDialog from '@/DaybreakTool/ColumnSelectorDialog.vue';
import DownloadDialog from '@/DaybreakTool/DownloadDialog.vue';
import InsightsPanel from '@/DaybreakTool/Insights/InsightsPanel.vue';
import ResultsTable from '@/DaybreakTool/ResultsTableWrapper.vue';
import ShareQueryDialog from '@/DaybreakTool/ShareQuery';
import MockQueryJob from '@/DaybreakTool/testUtils/MockQueryJob';
import app from '@/webapp';

export default {
	name: 'QueryPreview',
	components: {
		ActionList,
		Icon,
		ErrorMessage,
		ResultsTable,
		DownloadDialog,
		ColumnSelectorDialog,
		MnComponentView,
		InsightsPanel,
		TagList
	},

	props: {
		context: {
			type: Object,
			required: true
		},
		datamart: {
			type: Object,
			required: true
		},
		config: {
			type: Object,
			required: true
		},
		isMock: {
			type: Boolean,
			default: false
		}
	},

	data () {
		return {
			query: undefined,
			fetchQueryPromise: null,
			fetchErr: null,

			members: undefined,
			memberPromise: false,

			runner: undefined,
			collection: undefined,
			hasRun: undefined,
			isRunning: false,
			isEmptyResults: undefined,
			// hold the error from the last run so it can be passed to the view
			runError: undefined,

			// the sql query created by child (null if invalid)
			sql: undefined, // string
			// the sql base query created by child (null if invalid)
			baseSql: undefined, // string
			// the error that occurred creating sql (as a string) (or null if valid)
			sqlError: null,

			downloadDialogOptions: {},
			isDownloadDialogOpen: false,

			ShareQueryDialog: ShareQueryDialog,
			shareQueryDialogOptions: {},
			isShareDialogOpen: false,

			isColumnSelectorOpen: false
		};
	},

	computed: {
		actions () {
			if (!this.query) return [];
			const list = [
				{
					id: 'openInBuilder',
					label: 'Open In Query Builder',
					domId: 'pz-query-preview-open-editor-btn',
					buttonClass: 'btn-secondary btn-block',
					url: this.$router.resolve({ name: 'Data Builder', params: { queryid: this.query.id } }).href
				}
			];
			if (this.query.category === 'owned') {
				list.push({
					id: 'shareQuery',
					label: 'Share Query',
					buttonClass: 'btn-secondary btn-block',
					domId: 'pz-query-preview-share-btn'
				});
			}

			return list;
		},

		isStructured () {
			return _.get(this.query, 'query_type') === 'datamart';
		},

		totalColumns () {
			if (!this.isStructured) return null;

			const tableName = this.query.structured_query.table;
			if (!tableName) return null;

			try {
				const table = this.datamart.getTable(tableName);

				return table.spec.propertiesOrder.length;
			}
			catch (err) {
				return null;
			}
		},

		downloadName () {
			let name;
			if (this.sql && !this.query.name) {
				// get name of first table in query
				const regex = /[Aa][Uu]\([ \t]*['"`]?([a-zA-Z0-9-_\s]+)['"`]?[ \t]*\)/;
				const matched = this.sql.match(regex)[1];
				name = matched.trim();
			}
			else name = this.query.name || _.get(this.query, 'structured_query.table');

			return name;
		},

		hasInsights () {
			const insights = _.get(this, 'query.insights');
			return !_.isEmpty(insights);
		}
	},

	created () {
		this.loadMembers();
		this.fetchQuery();

		this.fetchQueryPromise.then(() => {
			this.initRunner();
			if (!this.sqlError) {
				this.run();
			}
		});
	},

	methods: {
		fetchQuery () {
			const queryId = this.$route.params.queryid;

			const queryQuery =
`{ daybreakQuery(query: "${queryId}", scope: "${this.context.scope}", config: "${this.context.config}") {
	id name description query query_type category tags
	structured_query insights shared_with { id }
	created_by { firstname lastname id }
} }`;

			const url = `${app.apiPath}query`;
			const options = {
				method: 'post',
				data: {
					context_organization: this.context.organization,
					context_project: this.context.project,
					query: queryQuery
				}
			};

			this.fetchQueryPromise = $.ajax(url, options).then(
				response => {
					const q = _.get(response, 'result.data.daybreakQuery');

					if (!q && response.result.errors) {
						this.fetchErr = _.get(response, ['result', 'errors', '0']);
						return;
					}
					try {
						q.insights = JSON.parse(q.insights);
						q.structured_query = JSON.parse(q.structured_query);
					}
					catch (err) {
						// do nothing
					}
					if (q.created_by) q.creatorName = `${q.created_by.firstname} ${q.created_by.lastname}`;

					// just want an array of users, not user objects;
					if (q.shared_with) q.shared_with = q.shared_with.map(user => user.id);

					this.query = q;
				},
				err => {
					if (_.isFunction(err.state)) err = getXHRError(err);
					this.fetchErr = err;
				}
			)
				.always(() => {
					this.fetchQueryPromise = null;
				});
		},

		actionClicked (id) {
			if (id === 'shareQuery') this.shareQuery();
		},

		shareQuery () {
			// don't show until members have finished loading
			if (this.memberPromise) {
				this.memberPromise.then(() => this.shareQuery());
				return;
			}

			const model = new QueryModel(this.query, { context: this.context });

			this.shareQueryDialogOptions = {
				members: _.reject(this.members, { id: app.auth.user.id }),
				model: model,
				context: this.context
			};

			this.isShareDialogOpen = true;
		},

		onShareClose () {
			this.isShareDialogOpen = false;
			this.shareQueryDialogOptions = {};
		},

		// After a user shares a query, shared with info will need to be updated.
		reloadSharedWith () {
			const url = `${app.apiPath}daybreak/config/${this.context.config}/scope/${this.context.scope}/query/${this.query.id}`;

			$.ajax(url).then(response => {
				const sharedWith = response.result.shared_with;
				if (_.isArray(sharedWith)) {
					this.query.shared_with = sharedWith;
				}
			});
		},

		loadMembers () {
			const url = `${app.apiPath}daybreak/config/${this.context.config}/scope/${this.context.scope}/members`;

			this.memberPromise = $.ajax(url).then(
				response => {
					this.members = response.result.map(m => {
						m.fullname = [m.firstname, m.lastname].join(' ');
						return _.pick(m, ['id', 'firstname', 'lastname', 'email', 'fullname', 'active']);
					});
					this.memberPromise = null;
				},
				() => {
					this.memberPromise = null;
				}
			);
		},

		download () {
			const dialogOptions = {
				includeHeader: true,
				query_filename: this.downloadName
			};

			this.downloadDialogOptions = dialogOptions;
			this.isDownloadDialogOpen = true;
		},

		onDownloadClose () {
			this.isDownloadDialogOpen = false;
		},

		openColumnDialog () {
			this.isColumnSelectorOpen = true;
		},

		// receive updated columns from selector
		updateColumns (columns) {
			const query = _.clone(this.query.structured_query);

			// null means it returned to default
			if (columns) query.columns = columns;
			else delete query.columns;

			this.updateQuery(query);
		},

		onColumnSelectorClose () {
			this.isColumnSelectorOpen = false;
		},

		// columns should be an array of objects with keys column, key, rootkey and table
		getSQLFromStructured (columns) {
			let structuredQuery = this.query.structured_query;

			if (columns) {
				structuredQuery = { ...structuredQuery, columns };
			}

			return this.datamart.buildStructuredQuery(structuredQuery).toSql();
		},

		initRunner () {
			const me = this;
			class MyQueryRunManager extends QueryRunManager {
				_listenForQueryChange () {} // change to noop, let updateSQL handle it

				_getQueryName () {
					return me.query.name + ' query';
				}

				_getFilters () {
					return me.query.structured_query;
				}

				_getBaseQuery () {
					if (_.isNull(me.baseSql)) return '';
					return me.baseSql;
				}

				_getQuery (columns) {
					// if it is null it means there is an error
					if (_.isNull(me.sql)) return '';

					if (columns) {
						return me.getSQLFromStructured(columns);
					}

					else {
						return me.sql;
					}
				}

				_createQueryJob (options) {
					// if should not actually run query, use a mock job class
					if (me.isMock) {
						return new MockQueryJob(options);
					}

					else {
						return super._createQueryJob(options);
					}
				}
			}

			const opts = {
				context: this.context,
				isStructured: this.isStructured,
				tool: 'daybreak',
				useInsights: true,
				insights: this.query.insights || [],
				datamart: this.datamart,
				target: 'scope'
			};
			// for v2, add the datamart id so it can send it on run
			if (this.datamart.queryEngine === 'exasol') {
				opts.engine = 'exasol';
			}

			this.runner = new MyQueryRunManager(opts);

			if (this.query.id) this.runner.queryId = this.query.id;

			if (this.isStructured) {
				try {
					this.sql = this.getSQLFromStructured();
				}
				catch (e) {
					this.sqlError = e;
				}
			}
			else {
				this.sql = this.query.query;
			}

			const MyTableCollection = TableCollection.extend({
				// schema is what powers the table columns
				setSchema (schema) {
					if (schema && this.runner.datamart) {
						schema = this.runner.datamart.augmentQueryResultSchema(schema);
					}
					this.schema = schema;
				}
			});

			this.collection = new MyTableCollection([], { runner: this.runner });
		},

		run () {
			this.runError = null;

			let promise;
			try {
				// errors may happen in lib-query, catch them and present to user
				promise = this.runner.run();
			}
			catch (error) {
				this.runError = {
					title: 'An error occurred while preparing your query',
					errors: [error]
				};
				return;
			}
			this.isRunning = true;

			const runner = this.runner;
			promise.then(() => {
				this.collection.setData(this.runner.results);
				this.isEmptyResults = !this.runner.results.table.data.length;
				this.hasRun = true;
				this.isRunning = false;
			})
			// if you don't catch here, will report to sentry
				.catch((response) => {
					this.isRunning = false;

					let failMessage = '';

					// if a job has failed,
					const failedJob = _.find(runner.currentJobs, function (job) {
						if (!job || !job.job) return;
						return job.job.get('state') === 'FAILED';
					});
					if (failedJob) {
						failMessage += `<p>Job id: <strong>${failedJob.job.id}</strong></p>`;
					}

					let err;
					if (_.isFunction(response.state)) {
						err = getXHRError(response);
					}
					else err = response;

					this.runError = {
						message: failMessage,
						errors: [err],
						title: 'An error occurred fetching data'
					};
				});
		},

		updateQuery (serialized) {
			this.query.structured_query = serialized;

			// clear errors from last time
			this.sqlError = null;

			if (!serialized.table) {
				this.sql = '';
			}

			else {
				try {
					this.sql = this.getSQLFromStructured();
				}
				catch (err) {
					// If sql cannot be created, set them to null and setup an error
					this.sql = null;
					this.sqlError = err;
				}
			}

			this.runner._onQueryChange();
		},

		onTagsUpdated (tags) {
			this.query.tags = tags;
		}
	}
};
</script>

<style lang="scss">
@import 'app_variables';

#pz-query-preview {
	padding: 1.5rem;
	display: flex;
	height: 100%;
	flex-flow: column nowrap;
	overflow: auto;

	#pz-query-preview-info {
		display: flex;
		flex: 0 0 auto;
		margin-bottom: 3rem;
	}

	#pz-query-preview-info-actions {
		flex: 0 0 13rem;

		.awi-action-list-item + .awi-action-list-item {
			margin-top: 0.5rem;
			margin-left: 0;
		}
	}

	#pz-query-preview-info-text {
		flex: 1 1 auto;

		> .header-container {
			margin-bottom: 1rem;

			h1 {
				margin-bottom: 1.5rem;
			}

			h1 + .subheader {
				margin-top: -1rem;
				font-size: $font-size-sm;
			}
		}
	}

	#pz-query-preview-info-description {
		max-width: 35rem;
		white-space: pre-wrap;
	}

	#pz-query-preview-insights {
		margin-bottom: 2rem;
	}

	#pz-query-preview-results,
	#pz-query-preview-run-error {
		flex: 1 1 auto;
	}

	// adding space between collapsable headers and content
	#pz-query-preview-data-results,
	#pz-query-preview-insights {
		&[open] summary {
			margin-bottom: 1.25rem;
		}
	}

	#pz-query-preview-run-error .alert {
		// since parent message is compact, keep this at a reasonable size
		max-width: 50rem;
		margin-left: auto;
		margin-right: auto;
		// make sure parent doesn't override direction
		text-align: left;
	}

	#pz-query-preview-data-results-table {
		height: 500px;
		min-height: 500px;
	}
}
</style>
