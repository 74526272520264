<template>
<a
	v-if="isA"
	:href="url"
	class="pz-nav-link">
	<slot />
</a>

<router-link
	v-else
	:to="url"
	class="pz-nav-link">
	<slot />
</router-link>
</template>

<script>

// A link that can either use vue's router-link or a vanilla <a> tag
// Needed for when links need to work in a variety of router setups
export default {
	name: 'FlexiLink',
	props: {
		url: {
			type: [String, Object],
			required: true
		},
		// if true, means use <a> tag, false means <router-link>
		isA: {
			type: Boolean,
			default: false
		}
	}
};
</script>
