<template>
<div class="pz-query-list p-3 position-relative">
	<!-- show spinner while list is loading -->
	<div v-if="isLoading" class="pz-load-indicator-mask">
		<icon name="spinner" spin />
	</div>

	<!-- New query button/dropdown -->
	<div id="create-query-buttons" class="text-right pb-2">
		Create:&emsp;
		<!-- if at a new, use a button, else use a link -->
		<component
			:is="isAtNew? 'button': 'flexi-link'"
			v-if="nlpEnabled"
			id="create-query-text"
			v-tooltip.html.hideOnClick
			class="btn btn-link px-1"
			:url="!isAtNew? newURL : null"
			role="button"
			title="Text Search"
			:is-a="isAtNew? null : isA"
			@click="isAtNew? clearNew() : null">
			<span class="fa-stack icon-stack-secondary">
				<i class="bottom-icon fas fa-circle fa-stack-2x" />
				<i class="top-icon far fa-comment fa-stack-1x" />
			</span>
		</component>

		<component
			:is="isAtNew? 'button': 'flexi-link'"
			id="create-query-wizard"
			v-tooltip.html.hideOnClick
			class="btn btn-link px-1"
			:url="!isAtNew? newWizardURL : null"
			role="button"
			title="Query Wizard"
			:is-a="isAtNew? null : isA"
			@click="isAtNew? clearNew('wizard') : null">
			<span class="fa-stack icon-stack-secondary">
				<i class="bottom-icon fas fa-circle fa-stack-2x" />
				<i class="top-icon fas fa-magic fa-stack-1x" />
			</span>
		</component>

		<component
			:is="isAtNew? 'button': 'flexi-link'"
			id="create-query-sql"
			v-tooltip.html.hideOnClick
			class="btn btn-link px-1"
			:url="!isAtNew? newSQLURL : null"
			role="button"
			title="SQL Editor"
			:is-a="isAtNew? null : isA"
			@click="isAtNew? clearNew('sql') : null">
			<span class="fa-stack icon-stack-secondary">
				<i class="bottom-icon fas fa-circle fa-stack-2x" />
				<i class="top-icon fas fa-code fa-stack-1x" />
			</span>
		</component>
	</div>

	<!-- search queries -->
	<div class="input-group mb-3">
		<input
			v-model="searchString"
			class="form-control"
			type="search"
			aria-label="Search saved queries"
			placeholder="Search saved queries">
		<div class="input-group-append">
			<button
				class="btn btn-outline-secondary"
				type="button"
				title="Clear Search"
				@click="clear">
				<icon name="times" />
			</button>
		</div>
	</div>

	<div class="pz-query-container">
		<!-- ****** Queries list ******** -->
		<!-- my queries -->
		<div>
			<h6
				data-toggle="collapse"
				data-target="#pz-my-query-list"
				aria-expanded="true"
				aria-controls="pz-my-query-list">
				<i class="close-icon fa fa-caret-down fa-fw pr-3" />
				<i class="open-icon fa fa-caret-right fa-fw pr-3" />
				My Queries
			</h6>
			<ul id="pz-my-query-list" class="list-unstyled collapse show">
				<li
					v-for="q in queryListItems"
					:id="'pz-query-list-item-' + q.id"
					:key="q.id"
					:class="q.id === selection? ' active' : ''">
					<flexi-link :url="q.url" :is-a="isA">
						{{ q.name }}
						<span v-if="q.query_type === 'sql'" class="badge badge-secondary">
							SQL
						</span>
						<icon
							v-if="isShared(q)"
							v-tooltip.html
							name="users"
							custom-class="text-muted ml-2"
							:title="listUsers(q)" />
					</flexi-link>
				</li>

				<li v-if="!queriesList.length && !isLoading" class="empty-view">
					You haven't created any queries
				</li>
			</ul>
		</div>

		<!-- shared with me queries list -->
		<div>
			<h6
				data-toggle="collapse"
				data-target="#pz-shared-query-list"
				aria-expanded="true"
				aria-controls="pz-shared-query-list">
				<i class="close-icon fa fa-caret-down fa-fw pr-3" />
				<i class="open-icon fa fa-caret-right fa-fw pr-3" />
				Shared With Me
			</h6>
			<ul id="pz-shared-query-list" class="list-unstyled collapse show">
				<li
					v-for="q in sharedListItems"
					:id="'pz-query-list-item-' + q.id"
					:key="q.id"
					:class="q.id === selection? ' active' : ''">
					<flexi-link :url="q.url" :is-a="isA">
						{{ q.name }}
						<span v-if="q.query_type === 'sql'" class="badge badge-secondary">
							SQL
						</span>
					</flexi-link>
				</li>

				<li v-if="!sharedList.length && !isLoading" class="empty-view">
					No queries have been shared with you
				</li>
			</ul>
		</div>

		<hr>

		<!-- standard queries -->
		<div>
			<h6
				data-toggle="collapse"
				data-target="#pz-standard-query-list"
				aria-expanded="true"
				aria-controls="pz-standard-query-list">
				<i class="close-icon fa fa-caret-down fa-fw pr-3" />
				<i class="open-icon fa fa-caret-right fa-fw pr-3" />
				Query Templates
			</h6>

			<ul id="pz-standard-query-list" class="list-unstyled collapse show">
				<li
					v-for="q in standardListItems"
					:id="'pz-query-list-item-' + q.id"
					:key="q.id"
					:class="q.id === selection? ' active' : ''">
					<flexi-link :url="q.url" :is-a="isA">
						{{ q.name }}
					</flexi-link>
				</li>

				<li v-if="!standardList.length && !isLoading" class="empty-view">
					No standard queries
				</li>
			</ul>
		</div>
	</div>
</div>
</template>

<script>
import { Icon, Tooltip } from 'aunsight-lib-ui';
import _ from 'lodash';

import FlexiLink from './FlexiLink.vue';

export default {
	components: { Icon, FlexiLink },
	directives: {
		Tooltip
	},
	props: {
		queriesList: {
			type: Array,
			required: true
		},
		standardList: {
			type: Array,
			required: true
		},
		sharedList: {
			type: Array,
			required: true
		},
		selection: {
			type: String,
			required: true
		},
		isLoading: {
			type: Boolean,
			default: false
		},
		newURL: {
			type: String,
			required: true
		},
		newSQLURL: {
			type: String,
			required: true
		},
		newWizardURL: {
			type: String,
			required: true
		},
		// if false, new text search btn should not be shown
		nlpEnabled: {
			type: Boolean
		},

		isA: {
			type: Boolean,
			default: false
		},
		members: {
			type: Array,
			required: true
		}
	},

	data () {
		return {
			searchString: ''
		};
	},

	computed: {
		queryListItems () {
			if (this.searchString) {
				const regex = new RegExp(this.searchString, 'i');
				return _.filter(this.queriesList, q => regex.test(q.name));
			}

			else return this.queriesList;
		},
		sharedListItems () {
			if (this.searchString) {
				const regex = new RegExp(this.searchString, 'i');
				return _.filter(this.sharedList, q => regex.test(q.name));
			}

			else return this.sharedList;
		},
		standardListItems () {
			if (this.searchString) {
				const regex = new RegExp(this.searchString, 'i');
				return _.filter(this.standardList, q => regex.test(q.name));
			}

			else return this.standardList;
		},
		isAtNew () {
			return this.selection === 'new';
		}
	},
	methods: {
		clear () {
			this.searchString = '';
		},
		clearNew (tool) {
			this.$emit('clearNew', tool);
		},

		// whether a query has been shared with other users
		isShared (query) {
			return !_.isEmpty(query.shared_with);
		},
		listUsers (query) {
			let name;
			let users = '<div class="pz-list-users">Shared with: ';
			_.forEach(query.shared_with, u => {
				const user = _.find(this.members, function (o) {
					return o.id === u && o.active === true;
				});
				if (!user) {
					name = 'User not found';
				}
				else {
					name = user.fullname;
					users += `<br>${name}`;
				}
			});
			users += '</div>';
			return users;
		}
	}

};
</script>

<style lang="scss">
@import 'app_variables';

.pz-query-list {
	display: flex;
	flex-direction: column;
	overflow: hidden;

	.pz-query-container {
		overflow: auto;
	}

	h6 {
		font-weight: bold;
	}

	// when its open hide the open icon, and when its closed hide the close icon
	h6[aria-expanded='true'] .open-icon,
	h6[aria-expanded='false'] .close-icon {
		display: none;
	}

	ul.list-unstyled {
		padding-left: 0.75rem;

		li {
			padding-left: 0.5rem;
			padding-right: 0.5rem;
			border-radius: 0.25rem;
			overflow-wrap: break-word;
			hyphens: auto;
		}
		// Define hover before active so that active takes precedence
		li:hover {
			background-color: saturate(mix($info, $body-bg, 7%), 60%);
		}

		li.active {
			background-color: saturate(mix($info, $body-bg, 20%), 60%);
		}

		a,
		a:focus,
		a:hover,
		a:visited {
			color: $body-color;
			text-decoration: none;
		}
	}
}

/* tooltip style can not be changed in scoped */
.tooltip-inner .pz-list-users {
	text-align: left;
}

</style>
