<template>
<div :class="['pz-sidebar navbar navbar-dark sidebar align-items-stretch flex-column', {condensed}]">
	<div class="logo-section">
		<img
			v-show="condensed"
			src="@/assets/logo-square.svg"
			width="44">
		<img
			v-show="!condensed"
			src="@/assets/logo-fw-dark.svg"
			width="188">
	</div>
	<ul :class="['sidebar-items nav navbar-nav flex-grow-1', {condensed: condensed}]">
		<!-- if contexts are still loading, show a spinner -->
		<li v-if="!contexts">
			<icon
				name="spinner"
				spin
				custom-class="navbar-text" />
		</li>
		<!-- ------------- Context Selector & description -------------- -->
		<li
			v-else-if="contexts && contexts.length > 1"
			id="pz-context-item"
			class="nav-item">
			<div id="pz-context-selector-dropdown" class="dropright">
				<button
					id="pz-context-dropdown-button"
					type="button"
					class="btn btn-light dropdown-toggle"
					title="Workspace Selector"
					data-toggle="dropdown"
					aria-haspopup="true"
					aria-expanded="false">
					<!-- if expanded show full name and caret -->
					<template v-if="!condensed">
						<span class="context-name">
							{{ getContextName(activeContext) }}
						</span>
						<span class="float-right">
							<icon name="caretRight" />
						</span>
					</template>
					<!-- if condensed just show org icon -->
					<span v-else>
						<icon name="institution" />
					</span>
				</button>

				<!-- dropdown with context links -->
				<div class="dropdown-menu" aria-labelledby="pz-context-dropdown-button">
					<router-link
						v-for="(c, i) in contexts"
						:key="c.id + i"
						class="dropdown-item"
						:to="{name: 'Instance', params: { configId: c.config.id, scopeId: c.id }}">
						{{ getContextName(c) }}
					</router-link>
				</div>
			</div>

			<!-- workspace description button and modal -->
			<workspace-description
				v-if="!condensed && activeContext"
				:active-context="activeContext" />
		</li>
		<!-- if user has only one context, show it without dropdown -->
		<li
			v-else
			id="pz-single-context-label"
			title="Workspace Name">
			<span class="badge badge-light">
				<template v-if="!condensed">
					{{ getContextName(contexts[0]) }}
				</template>
				<icon
					v-else
					name="institution"
					:title="getContextName(contexts[0])" />
			</span>
			<workspace-description
				v-if="!condensed && activeContext"
				:active-context="activeContext" />
		</li>

		<side-bar-item
			v-for="item in items"
			:key="item.id"
			class="pz-sidebar-context-item"
			:item="item"
			:condensed="condensed" />
	</ul>

	<ul :class="['sidebar-items nav navbar-nav mb-4', {condensed: condensed}]">
		<!-- notifications -->
		<li class="nav-item">
			<router-link :to="{name: 'Notifications'}" class="nav-link">
				<span v-if="condensed" class="fa-stack fa-fw">
					<icon name="bell" :fixed-width="true" />
					<sup v-if="!!newNotifications">
						<icon name="circleFilled" custom-class="text-danger fa-stack-corner-ur" />
					</sup>
				</span>
				<icon
					v-if="!condensed"
					:fixed-width="true"
					name="bell" />
				<span
					v-if="!condensed"
					id="sidebar-notifications"
					class="button-label">Notifications
					<span v-if="!!newNotifications" class="ml-2 badge badge-danger">
						{{ newNotifications > 9? '9+' : newNotifications }} new
					</span>
				</span>
			</router-link>
		</li> <!-- end notifications -->

		<!-- user menu -->
		<li class="nav-item dropright">
			<a
				id="sidebar-user-dropdown"
				class="nav-link dropdown-toggle"
				role="button"
				href="javascript:void(0)"
				data-toggle="dropdown"
				title="User Menu"
				aria-haspopup="true"
				aria-expanded="false">
				<icon name="user" :fixed-width="true" />
				<span v-if="!condensed" class="button-label">{{ username }}</span>
			</a>

			<div class="dropdown-menu" aria-labelledby="sidebar-user-dropdown">
				<button
					v-if="canChangePassword"
					class="dropdown-item"
					type="button"
					@click="changePassword">
					Change Password
				</button>
				<button
					class="dropdown-item"
					type="button"
					@click="logout">
					Log out
				</button>
			</div>
		</li> <!-- end user menu -->

		<!-- toggle condensed menu -->
		<li class="nav-item">
			<a
				class="nav-link"
				href="javascript:void(0)"
				role="button"
				:title="condensed? 'Expand Menu' : 'Collapse Menu'"
				@click="toggleCondensed">

				<icon :name="condensed? 'caretRight' : 'caretLeft'" :fixed-width="true" />
				<span v-if="!condensed" class="button-label">Collapse Menu</span>
			</a>
		</li> <!-- end toggle condensed menu -->
	</ul>
	<portal v-if="canChangePassword">
		<change-password-view :open="passwordOpen" @close="passwordOpen = false" />
	</portal>
</div>
</template>

<script>
import { Portal } from '@linusborg/vue-simple-portal';
import { Icon } from 'aunsight-lib-ui';

import getContextNameArr from '@/utils/getContextNameArr';
import app from '@/webapp';

import ChangePasswordView from './ChangePassword.vue';
import SideBarItem from './SideBarItem.vue';
import WorkspaceDescription from './WorkspaceDescription.vue';

export default {
	name: 'SideBar',
	components: { SideBarItem, Icon, ChangePasswordView, Portal, WorkspaceDescription },
	data () {
		return {
			condensed: false,
			items: [
				// { id: 'Table', path: { name: 'Table' }, title: 'Table', icon: 'table' },
				{ id: 'Home', path: { name: 'Home' }, title: 'Home', icon: 'home' },
				{ id: 'Query Catalog', path: { name: 'Query Catalog' }, title: 'Query Catalog', icon: 'book' },
				{ id: 'Query Builder', path: { name: 'Query Builder' }, title: 'Query Builder', icon: 'wrench' }
			],
			username: 'User Menu',
			passwordOpen: false,
			canChangePassword: false
		};
	},

	computed: {
		contexts () {
			return this.$store.state.contexts;
		},
		activeContext () {
			const context = {
				config: this.$route.params.configId,
				scope: this.$route.params.scopeId
			};
			return this.$store.getters.getContext(context);
		},

		newNotifications () {
			return this.$store.state.newNotifications;
		}
	},

	created () {
		this.setUserInfo();
		if (window.localStorage.getItem('sidebarCondensed')) {
			this.condensed = window.localStorage.getItem('sidebarCondensed') === 'true';
		}
	},
	methods: {
		toggleCondensed () {
			this.condensed = !this.condensed;
			window.localStorage.setItem('sidebarCondensed', this.condensed);
			this.$emit('toggleCondensed', this.condensed);
		},
		logout () {
			app.auth.logout();
		},
		changePassword () {
			this.passwordOpen = true;
		},
		setUserInfo () {
			this.username = app.auth.user.getName();
			this.canChangePassword = app.auth.user.get('authentication').method === 'local';
		},

		getContextName (context) {
			return getContextNameArr(context).join(' - ');
		}
	}
};
</script>

<style lang="scss">
@import 'app_variables';

.pz-sidebar {
	// todo: make primary a different color. (wouldn't that be nice??)
	background-color: $primary;

	// need to make sidebar and all children 100% width so we can constrain width and avoid using
	// `overflow: hidden` which would hide dropdown menu.
	width: 100%;

	.logo-section {
		height: 72px;

		img {
			max-height: 100%;
			padding: 1em 0;
		}
	}

	.sidebar-items {
		width: 100%;

		&.condensed li {
			text-align: center;
		}

		// Special styles for when sidebar is expanded
		&:not(.condensed) {
			li > .nav-link {
				// navbar-nav wants to remove padding because it isn't meant for vertical
				padding: 0.5rem 1rem;
			}

			#pz-context-selector-dropdown,
			#pz-single-context-label .badge {
				// should take up space but give room for info icon
				width: calc(100% - 1.5rem);
			}
		}
	}

	.sidebar-items li {
		max-width: 100%;
		text-align: left;

		> .nav-link,
		.context-name,
		> .badge {
			// make sure text doesn't exceed parent width
			overflow: hidden;
			text-overflow: ellipsis;
			width: 100%;
			white-space: nowrap;
		}

		.fa-stack sup {
			position: absolute;
			top: 0.3em;
			font-size: 0.5em;
		}

		// makes dropdown not show up below
		.dropdown-menu {
			position: absolute;
		}

		// put vertical space between buttons
		& + li {
			margin-top: 1em;
		}

		// put space between icon and text
		.button-label {
			margin-left: 1em;
		}
	}

	// context selector dropdown
	#pz-context-dropdown-button {
		// should take up space but give room for info icon
		width: 100%;

		// label in the dropdown button for context name
		.context-name {
			// give room for dropright arrow
			width: calc(100% - 1rem);
			display: inline-block;
			vertical-align: middle;
		}
	}

	// make content side by side
	#pz-context-item,
	#pz-single-context-label {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
	}

	// label for context when there is only one
	#pz-single-context-label {
		width: 100%;
		// we want it to look like button version, but without button-ness
		.badge {
			vertical-align: middle;
			font-size: $btn-font-size;
			font-weight: $btn-font-weight;
			line-height: $btn-line-height;
			padding: $btn-padding-y $btn-padding-x $btn-padding-y $btn-padding-x;
		}
	}
}
</style>
