<template>
<modal
	class="pz-download-dialog"
	:open="open"
	@close="close">
	<template #header>
		<h5 class="modal-title">
			<icon name="download" custom-class="mr-2" />
			Download Results
		</h5>
		<button
			type="button"
			class="close"
			data-dismiss="modal"
			aria-label="Close">
			<span aria-hidden="true">&times;</span>
		</button>
	</template>

	<!-- main content -->
	<!-- hiding children when dialog is closed helps prevent some oddness -->
	<template v-if="open">
		<error-message
			v-if="err"
			message="An error occured while getting link"
			:errors="[err]" />

		<download-file-form
			v-if="step===1"
			:initial-values="{query_filename, format, includeHeader}"
			:supported-formats="supportedFormats"
			@update="updateFileInfo" />

		<column-selector
			v-else
			:datamart="datamart"
			:structured-query="structuredQuery"
			:starting-selection="stashedSelection"
			@update="updateColumns" />
	</template>
	<!-- end main content -->
	<template #footer>
		<button
			v-if="step === 1"
			class="btn btn-secondary"
			type="button"
			data-dismiss="modal">
			Cancel
		</button>
		<button
			v-if="hasNext"
			class="btn btn-primary"
			type="button"
			name="next"
			@click="goToStep2">
			Next
		</button>

		<button
			v-if="step === 2"
			class="btn btn-secondary"
			type="button"
			@click="backToStep1">
			Back
		</button>
		<button
			v-if="!hasNext"
			class="btn btn-primary"
			type="button"
			name="apply"
			:disabled="!isReady"
			@click="download">
			Download
		</button>
	</template>
</modal>
</template>

<script>
import { ErrorMessage, Icon, Modal } from 'aunsight-lib-ui';
import $ from 'jquery';
import _ from 'lodash';

import ColumnSelector from './ColumnSelector.vue';
import DownloadFileForm from './DownloadFileForm.vue';

export default {
	components: {
		Icon,
		ColumnSelector,
		DownloadFileForm,
		ErrorMessage,
		Modal
	},

	props: {
		runner: {
			default: undefined,
			type: Object
		},
		supportedFormats: {
			type: Array,
			default: undefined
		},
		initialValues: {
			type: Object,
			default: () => ({})
		},
		open: {
			type: Boolean,
			default: false
		},
		datamart: {
			required: true,
			type: Object
		},
		structuredQuery: {
			type: Object,
			required: true
		},
		shouldUseColumns: {
			type: Boolean,
			default: true
		},
		containerSelector: {
			type: String,
			default: '.pz-daybreak-tool'
		}
	},

	data () {
		return {
			selection: [],
			step: 1,
			err: null,
			query_filename: '',
			format: 'csv',
			includeHeader: undefined,
			// if user makes column selection then clicks `back`, we must stash their selection.
			stashedSelection: null
		};
	},

	computed: {
		hasNext () {
			return this.shouldUseColumns && this.step === 1;
		},
		isReady () {
			if (this.shouldUseColumns) return _.isNull(this.selection) || this.selection.length > 0;
			else return true;
		}
	},

	watch: {
		open () {
			// since this dialog persists, apply initital values on opening
			_.assign(this, this.initialValues);
		}
	},

	methods: {
		download () {
			const options = {
				format: this.format,
				includeHeader: this.includeHeader
			};
			if (this.query_filename) options.query_filename = this.query_filename;
			if (this.shouldUseColumns) options.columns = this.selection;

			this.runner.download(options)
				.then(this.initiateDownload.bind(this), this.onRequestFailed.bind(this))
				.then(() => {
					this.$emit('close');
				});
		},

		onRequestFailed (err) {
			this.err = err;
		},

		initiateDownload (url) {
			// should this be changed to use vue?
			// remove any previous download iframes
			$(this.containerSelector + ' iframe[name="downlodarea"]').remove();
			// download on Daybreak
			$(this.containerSelector).append(`<iframe name="downloadarea" src="${url}" hidden></iframe>`);
		},

		updateFileInfo (info) {
			_.assign(this, _.pick(info, ['format', 'includeHeader', 'query_filename']));
		},

		updateColumns (selection) {
			this.selection = selection;
		},

		backToStep1 () {
			// stash the user's selection for when they come back to step 2
			this.stashedSelection = this.selection;

			// if the timeout isn't here, somehow the dismiss modal button which
			// replaces the back button also gets triggered
			setTimeout(() => {
				this.step = 1;
			}, 300);
		},

		goToStep2 () {
			this.step = 2;
		},

		close  () {
			this.$emit('close');

			// reset the state to the default for next time.
			_.assign(this, {
				selection: [],
				hasChanges: false,
				step: 1,
				err: null,
				query_filename: '',
				format: 'csv',
				includeHeader: undefined,
				stashedSelection: null
			});
		}
	}
};

</script>
