<template>
<div v-if="hasRun" class="pz-results-section d-flex flex-column">
	<div v-if="isOutdated && !isRunning" class="pz-results-outdated alert alert-info">
		<icon name="exclamationTri" custom-class="mr-2" />
		You've updated this query, so these data results may no longer be accurate. Click "Refresh" to update them.
		<button
			type="button"
			class="btn btn-primary btn-sm"
			:disabled="!canRun"
			@click="run">
			Refresh
		</button>
	</div>

	<!-- header section -->
	<div class="d-flex mb-3">
		<!-- tabs -->
		<tab-list
			class="flex-grow-1"
			:items="tabs"
			:selection="activeView"
			@itemselected="updateActivePanel" />
	</div>

	<!-- content -->

	<div class="results-content-container position-relative d-flex flex-grow-1">
		<!-- loading spinner when running -->
		<div v-if="isLoading || isRunning" class="pz-load-indicator-mask">
			<icon name="spinner" spin />
			<h3 class="mt-3">
				Running query and fetching results...
			</h3>
		</div>

		<!-- show error when present -->
		<div v-if="runError" class="pz-result-err-container awi-fill-space">
			<div class="pz-load-indicator-mask" />
			<div class="m-4 card bg-white">
				<div class="card-body">
					<error-message
						:title="runError.title"
						:message="runError.message"
						:errors="runError.errors" />
					<div class="text-right">
						<button class="btn btn-secondary" @click="dismissError">
							Close
						</button>
					</div>
				</div>
			</div>
		</div>

		<results-table-wrapper
			v-if="activeView === 'table'"
			class="flex-grow-1"
			:is-wizard-mode="isWizardMode"
			:collection="collection"
			:total-columns="totalColumns"
			:is-outdated="isOutdated"
			@openColumnDialog="openColumnDialog"
			@download="download" />

		<insights-panel
			v-else
			class="flex-grow-1"
			:runner="runner"
			:datamart="datamart"
			:insights="insights"
			:is-outdated="isOutdated"
			@updateInsights="updateInsights" />
	</div>
</div>

<!-- Unrun view -->
<div v-else class="d-flex align-items-center pz-not-run-section position-relative">
	<div v-if="runError" class="pz-result-err-container w-100">
		<div class="m-4 card bg-white">
			<div class="card-body">
				<error-message
					:title="runError.title"
					:message="runError.message"
					:errors="runError.errors" />
				<div class="text-right">
					<button class="btn btn-secondary" @click="dismissError">
						Close
					</button>
				</div>
			</div>
		</div>
	</div>
	<p v-else-if="!isRunning" class="text-center text-muted flex-grow-1">
		Build a query, then click "Run" to start searching your data!
		<br>
		<button
			id="run-button-empty-view"
			type="button"
			class="btn btn-primary mt-4"
			:disabled="!canRun"
			@click="isWizardMode ? openColumnDialog() : run()">
			Run
		</button>
	</p>
	<div v-else class="pz-load-indicator-mask">
		<icon name="spinner" spin />
		<h3 class="mt-3">
			Running query and fetching results...
		</h3>
	</div>
</div>
</template>

<script>
import { ErrorMessage, Icon } from 'aunsight-lib-ui';

import InsightsPanel from './Insights/InsightsPanel.vue';
import ResultsTableWrapper from './ResultsTableWrapper.vue';
import TabList from './TabList.vue';

export default {
	name: 'ResultsSection',
	components: {
		TabList,
		Icon,
		ErrorMessage,
		InsightsPanel,
		ResultsTableWrapper
	},

	props: {
		hasRun: Boolean,
		collection: { type: Object, required: true },
		isOutdated: Boolean,
		canRun: {
			type: Boolean,
			default: true
		},
		isRunning: Boolean,
		runError: {
			type: Object,
			default: undefined
		},
		totalColumns: { type: Number, default: undefined },
		runner: { type: Object, required: true },
		isWizardMode: Boolean,
		datamart: { type: Object, required: true },
		insights: { type: Array, required: true },
		startAtInsights: {
			type: Boolean,
			default: false
		}
	},

	data () {
		return {
			isLoading: false,
			activeView: this.startAtInsights ? 'vis' : 'table'
		};
	},

	computed: {
		tabs () {
			if (this.runner.isStructured) {
				return [
					{
						id: 'table',
						label: 'Data Results'
					},
					{
						id: 'vis',
						label: 'Insights'
					}
				];
			}
			else {
				return [
					{
						id: 'table',
						label: 'Data Results'
					}
				];
			}
		}

	},

	watch: {
		// when switching from wizard to sql, make sure to exit insights tab
		'runner.isStructured' (val) {
			if (!val && this.activeView === 'vis') {
				this.activeView = 'table';
			}
		}
	},

	mounted () {
		const vm = this;
		// this happens whenever table control triggers a run
		this.collection.on('request', function (model, promise) {
			vm.isLoading = true;
			promise.finally(function () {
				vm.isLoading = false;
			});
		});
	},

	methods: {
		run () {
			this.$emit('run');
		},

		download () {
			this.$emit('download');
		},

		openColumnDialog () {
			this.$emit('openColumnDialog');
		},

		dismissError () {
			this.$emit('dismissError');
		},

		updateActivePanel (id) {
			this.activeView = id;
		},

		updateInsights (action, i, val) {
			this.$emit('updateInsights', action, i, val);
		}
	}
};

</script>

<style lang="scss">

.pz-results-section {
	// Should be turned into a variable at some point
	height: 500px;
	// Safari needs min-height as well
	min-height: 500px;

	.pz-load-indicator-mask i {
		margin-top: 3rem;
	}
}

.pz-not-run-section {
	> p {
		margin-top: 4rem;
		margin-bottom: 4rem;
	}

	.pz-load-indicator-mask {
		min-height: 200px;
		margin-top: 1rem;
		margin-bottom: 1rem;

		> i {
			// needs smaller margin when in empty
			margin-top: 2rem;
		}
	}
}

// todo: move this to more general stylesheet
.pz-load-indicator-mask {
	height: 100%;
	width: 100%;
	background-color: transparentize(gray, 0.5);
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1900;
	text-align: center;

	> i {
		margin-top: 8rem;
		font-size: 5rem;
	}
}

.pz-result-err-container {
	min-height: 400px;
	padding-top: 2em;

	// buttons in paginator have zindex of 3 for some reason, make sure it sits above.
	z-index: 5;

	> .card,
	> .pz-load-indicator-mask {
		z-index: 5;
	}
}
</style>
