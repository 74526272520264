<template>
<div class="pz-wizard-wrapper">
	<div class="pz-editor-info">
		<nlp-result
			v-if="nlpSearch"
			:nlp-search="nlpSearch"
			:nlp-url="nlpUrl"
			:mock-query="mockQuery" />

		<text-view
			:datamart="datamart"
			:structured-query="structuredQuery" />
	</div>

	<error-message
		v-if="queryErr"
		:title="queryErr.title"
		:message="queryErr.message" />

	<wizard
		v-else
		:structured-query="structuredQuery"
		:datamart="datamart"
		:has-insights="hasInsights"
		:can-run="canRun"
		@openSQLPreview="openPreview"
		@updateQuery="updateQuery" />

	<!-- SQL preview or error view if invalid -->
	<modal
		:open="isPreviewOpen"
		class="sql-preview"
		@close="closePreview">
		<template #header>
			<h4 class="modal-title">
				SQL Preview
			</h4>
		</template>
		<!-- preview -->
		<sql-preview v-if="!!sql && aceLoaded && isPreviewOpen" :query="sql" />

		<!-- error (if present) -->
		<div v-if="!!sqlError" class="alert alert-danger m-3">
			<p>SQL could not be generated from wizard value</p>
			<samp>{{ sqlError }}</samp>
		</div>
		<template #footer>
			<button
				class="btn btn-secondary"
				type="button"
				@click="closePreview">
				Close
			</button>
			<button
				class="btn btn-primary"
				type="button"
				name="convertToSQL"
				@click="goToSQL">
				Convert to SQL
			</button>
		</template>
	</modal>
</div>
</template>

<script>
import { ErrorMessage, Modal } from 'aunsight-lib-ui';

import NLPResultView from './NLPResultView.vue';
import SQLPreview from './SQLPreview.vue';
import TextView from './TextView.vue';
import Wizard from './Wizard/Wizard.vue';

export default {
	name: 'WizardWrapper',

	components: {
		Wizard,
		SqlPreview: SQLPreview,
		Modal,
		ErrorMessage,
		TextView,
		NlpResult: NLPResultView
	},

	props: {
		datamart: { type: Object, required: true },
		sql: { type: String, default: undefined },
		sqlError: Error,
		structuredQuery: { type: Object, required: true },
		aceLoaded: {
			type: Boolean,
			default: true
		},
		// info about the nlp search used to populate query, if applicable
		nlpSearch: {
			type: Object,
			default: undefined
		},
		nlpUrl: {
			type: String,
			default: undefined
		},
		mockQuery: Boolean,

		// for wizard to know if it needs to prompt to change table
		hasInsights: {
			type: Boolean,
			default: false
		},
		canRun: {
			type: Boolean,
			default: true
		}
	},

	data () {
		return {
			isPreviewOpen: false,
			feedbackSent: false,

			// If query can't be opened, set an error here
			queryErr: null
		};
	},

	created () {
		// The wizard can't handle the query referencing a non-existant table
		// (presumably, other errors will not be an issue for it)
		if (this.structuredQuery.table) {
			try {
				this.datamart.getTable(this.structuredQuery.table);
			}
			catch (err) {
				this.queryErr = {
					title: 'Cannot open this query',
					message: `The table referenced in the query, "${this.structuredQuery.table}", was not found. It may have been deleted or renamed.`
				};
			}
		}
	},

	methods: {
		// just bubble this up to parent
		updateQuery () {
			this.$emit('updateQuery', ...arguments);
		},

		goToSQL () {
			this.isPreviewOpen = false;
			this.$emit('goToSQL');
		},

		openPreview () {
			this.isPreviewOpen = true;
		},

		closePreview () {
			this.isPreviewOpen = false;
		}
	}
};

</script>
<style lang="scss">
@import 'app_variables';

.pz-nlp-feedback {
	button {
		>.fa-stack {
			width: 2em;
		}

		// darken bottom icon on hover
		&:hover,
		&:focus,
		&.focus {
			.text-success .bottom-icon {
				color: darken($success, 7.5%);
			}

			.text-secondary .bottom-icon {
				color: darken($secondary, 7.5%);
			}
		}
	}
}

</style>
