<template>
<component
	:is="filtertype"
	:id="id"
	:is-multiple="isMultiple"
	:value="value"
	:disabled="disabled"
	:enumeration="enumeration"
	@change="change" />
</template>

<script>

import _ from 'lodash';

import FilterValueDateField from './FilterValueDateField.vue';
import FilterValueDateRangeField from './FilterValueDateRangeField.vue';
import FilterValueEnumField from './FilterValueEnumField.vue';
import FilterValueListField from './FilterValueListField.vue';
import FilterValueRelativeRangeField from './FilterValueRelativeRangeField.vue';
import FilterValueStringField from './FilterValueStringField.vue';

export default {
	name: 'FilterValueField',
	components: {
		FilterValueDateField,
		FilterValueDateRangeField,
		FilterValueEnumField,
		FilterValueStringField,
		FilterValueRelativeRangeField,
		FilterValueListField
	},

	props: {
		id: { type: String, required: true },
		// eslint-disable-next-line
		value: {},
		disabled: Boolean,
		enumeration: { type: Array, default: undefined },
		opType: { type: String, default: undefined }
	},

	computed: {
		isMultiple () {
			return this.opType === '[string]';
		},

		filtertype () {
			// choose component based on type
			if (this.opType === 'date') {
				return FilterValueDateField;
			}

			if (this.opType === 'dateRange') {
				return FilterValueDateRangeField;
			}

			if (this.opType === 'relativeRange') {
				return FilterValueRelativeRangeField;
			}

			// null is for is (not) null operators which need last one.
			if (!_.isNull(this.opType) && this.enumeration) {
				return FilterValueEnumField;
			}

			if (this.opType === '[string]') {
				return FilterValueListField;
			}

			else return FilterValueStringField;
		}
	},

	methods: {
		change (ename, attr, val) {
			this.$emit('change', ename, attr, val);
		}
	}
};
</script>
