<template>
<login-wrapper>
	<div class="text-center">
		<h1 class="py-3">
			Welcome to Daybreak!
		</h1>
		<p>To access Daybreak, please contact your system administrator.</p>
	</div>
</login-wrapper>
</template>

<script>
import LoginWrapper from '@/views/Login';
export default {
	name: 'WelcomePage',
	components: { LoginWrapper }
};
</script>
