<template>
<div :class="`pz-condition-group-list pz-cond-group-${operator === 'any'? 'or': 'and'}`">
	<!-- if there are 2+ conditions, show list -->
	<template v-if="!hasSingleGroup">
		<condition-group
			v-for="(group, i) in groups"
			:id="group.id"
			:key="i"
			class="pz-conjunction-tagged"
			:value="group.value"
			:operator="group.operator"
			:columns="columns"
			:relations="relationColumns"
			@change="update"
			@changeOp="changeOp"
			@removeGroup="remove"
			@remove="remove"
			@addRow="addRow" />
	</template>

	<!-- if only one condition, collapse it -->
	<filter-list
		v-else
		:class="`pz-cond-group-${['any','nany'].includes(groups[0].operator)? 'or': 'and'}`"
		:value="groups[0].value"
		:parent-id="groups[0].id"
		:columns="columns"
		:relations="relationColumns"
		@remove="remove"
		@change="update"
		@addRow="addRow" />

	<!-- add condition group button -->
	<button
		class="btn btn-link"
		name="addConditionGroup"
		title="Add new condition group"
		@click="addGroup">
		<span class="fa-stack icon-stack-secondary">
			<i class="bottom-icon fas fa-circle fa-stack-2x" />
			<i class="top-icon fas fa-plus fa-stack-1x" />
		</span>
		<span>Add condition group...</span>
	</button>
</div>
</template>

<script>
import ConditionGroup from './ConditionGroup.vue';
import FilterList from './FilterList.vue';

// Wrapper group around condition list to leave Wizard view cleaner
export default {
	components: {
		ConditionGroup,
		FilterList
	},

	props: {
		hasSingleGroup: {
			type: Boolean,
			required: true
		},
		groups: {
			type: Array,
			required: true
		},
		operator: {
			type: String,
			required: true
		},
		relationColumns: {
			type: Array,
			default: undefined
		},
		columns: {
			type: Array,
			required: true
		}
	},

	methods: {
		update () {
			this.$emit('update', ...arguments);
		},
		addRow () {
			this.$emit('addRow', ...arguments);
		},
		remove () {
			this.$emit('remove', ...arguments);
		},
		changeOp () {
			this.$emit('changeOp', ...arguments);
		},
		addGroup () {
			this.$emit('addGroup', ...arguments);
		}
	}
};

</script>
