<template>
<div class="pz-insights-panel">
	<!-- no insights made view -->
	<div v-if="insights.length === 0" class="text-center my-4">
		<h4>Nothing here yet</h4>
		Click the button below to add your first Insight
		<br>
		<button
			type="button"
			class="btn btn-secondary my-3"
			title="Create Insight"
			:disabled="isOutdated"
			@click="openCreateDialog">
			<icon name="plus" /> Create Insight
		</button>
	</div>

	<!-- show list of all insights -->
	<div v-else>
		<!-- add insights button moved here -->
		<div v-if="!isReadOnly" class="text-right mb-2">
			<button
				type="button"
				title="Create Insight"
				:disabled="isOutdated"
				class="btn btn-secondary"
				@click="openCreateDialog">
				<icon name="plus" /> Create Insight
			</button>
		</div>

		<insights-view
			:insights="insights"
			:query-results="queryResults"
			:datamart="datamart"
			:selected-table="selectedTable"
			:is-outdated="isOutdated"
			:insight-states="insightStates"
			:is-read-only="isReadOnly"
			@edit="openEditDialog"
			@delete="removeInsight"
			@refresh="refreshInsight"
			@download="downloadInsight" />
	</div>

	<create-insight-dialog
		v-if="!isReadOnly"
		:datamart="datamart"
		:selected-table="selectedTable"
		:open="isCreateOpen"
		:insight-start-data="createOptions.insightStartData"
		:mode="createOptions.mode"
		@done="addInsight"
		@doneEdit="editInsight"
		@close="closeCreateDialog" />

	<download-insight-dialog
		:open="isDownloadOpen"
		:element="downloadOptions.element"
		:insight="downloadOptions.insight"
		:insight-data="downloadOptions.data"
		@close="closeDownloadDialog" />
</div>
</template>

<script>
import { Icon } from 'aunsight-lib-ui';
import _ from 'lodash';

import CreateInsightDialog from './CreateInsightDialog.vue';
import DownloadInsightDialog from './DownloadInsightDialog.vue';
import InsightsView from './InsightsView.vue';

export default {
	components: {
		Icon,
		CreateInsightDialog,
		DownloadInsightDialog,
		InsightsView
	},

	props: {
		runner: { required: true, type: Object },
		datamart: { required: true, type: Object },
		insights: { required: true, type: Array },
		isOutdated: {
			type: Boolean
		},
		isReadOnly: { type: Boolean, default: false }
	},

	data () {
		return {
			isCreateOpen: false,
			isDownloadOpen: false,
			createOptions: {},
			queryResults: undefined,
			insightStates: {},
			downloadOptions: {}
		};
	},

	computed: {
		selectedTable () {
			return _.get(this.runner, ['currentQuery', 'value', 'table']);
		}
	},

	mounted () {
		this.updateResults();
		this.updateStates();

		// can't use vue's watch for results because new attrs get added
		const handler = () => this.updateResults();
		this.runner.on('results:updated', handler);
		const handler2 = () => this.updateStates();
		this.runner.on('update:insight:state', handler2);

		this.unbind = () => {
			this.runner.off('results:updated', handler);
			this.runner.off('update:insight:state', handler2);
		};
	},

	beforeDestroy () {
		this.unbind();
	},

	methods: {
		openCreateDialog () {
			this.createOptions = {
				mode: 'create'
			};
			this.isCreateOpen = true;
		},

		closeCreateDialog () {
			this.isCreateOpen = false;
			this.createOptions = {};
		},

		openEditDialog (insight) {
			this.createOptions = {
				insightStartData: insight,
				mode: 'edit'
			};
			this.isCreateOpen = true;
		},

		addInsight (insight) {
			insight.id = this.makeUniqueId();
			this.$emit('updateInsights', 'add', insight);
		},

		removeInsight (insight) {
			const index = this.insights.indexOf(insight);
			this.$emit('updateInsights', 'delete', index);
		},

		editInsight (id, data) {
			const index = _.findIndex(this.insights, { id: id });
			this.$emit('updateInsights', 'edit', index, data);
		},

		refreshInsight (insight) {
			this.runner.runInsightQuery(insight.id);
		},

		downloadInsight (insight) {
			const index = this.insights.findIndex(ins => insight.id === ins.id);

			// Probably want a better way of finding the element
			const element = this.$el.getElementsByClassName('view')[index].getElementsByClassName('card')[0];
			this.downloadOptions = {
				element,
				insight,
				data: this.queryResults[insight.id]
			};
			this.isDownloadOpen = true;
		},

		closeDownloadDialog () {
			this.isDownloadOpen = false;
			this.downloadOptions = {};
		},

		makeUniqueId () {
			const id = _.uniqueId('i');
			// make sure that id doesn't exist else recurse (probably won't ever happen)
			if (!_.find(this.insights, { id })) {
				return id;
			}
			else {
				return this.makeUniqueId();
			}
		},

		updateResults () {
			this.queryResults = this.parseResults(this.runner.results.insights);
		},

		parseResults (results) {
			if (!results) return;

			// need to know if results are really empty, because empty and a field
			// with only empties looks the same at insight level.
			const isEmpty = _.get(this.runner, 'results.summary.data[0].filtered') === 0;

			const res = {};
			this.insights.forEach(insight => {
				const result = _.get(results, [insight.id, 'data']);
				if (!result) return;

				if (insight.type === 'summary') {
					res[insight.id] = {
						value: _.get(result, [0, 'value'])
					};
					if (isEmpty && !_.isNumber(res[insight.id].value)) {
						res[insight.id].empty = true;
					}
					else if (_.isNull(res[insight.id].value)) {
						res[insight.id].noVal = true;
					}
				}
				else {
					res[insight.id] = {
						value: _.map(result, 'value'),
						labels: _.map(result, item => {
							let label = item.label;
							// show empty values as `[empty]`. (`0` is falsy but valid as a label)
							if (!label && label !== 0) {
								label = '[empty]';
							}
							return label;
						})
					};
					if (isEmpty) {
						res[insight.id].empty = true;
					}
					else if (_.every(res[insight.id].value, _.isNull)) {
						res[insight.id].noVal = true;
					}
				}
			});
			return res;
		},

		updateStates () {
			const states = {};
			this.insights.forEach((i) => {
				states[i.id] = this.runner.insightRunStates[i.id] || { state: 'done' };
			});

			this.insightStates = states;
		}
	}

};

</script>
