import _ from 'lodash';

/**
 * return an array of srings of context name parts.
 * @param {contextitem} context
 * @returns {string[]}
 */
function getContextNameArr (context) {
	if (!context) return [];
	return [
		_.get(context, 'organization.name'),
		_.get(context, 'config.name'),
		context.name
	];
}

export default getContextNameArr;
