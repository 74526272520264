<template>
<nav class="navbar navbar-light py-3 border-bottom">
	<span class="navbar-brand mr-auto font-weight-bold d-flex">
		<icon
			v-if="icon"
			custom-class="text-info mr-2 align-self-center"
			:name="icon" />
		<span v-if="isPreviewPage" class="d-flex">
			<router-link
				:to="{name: 'Query Catalog'}"
				class="nav-link p-0">
				Query Catalog
			</router-link>
			<span class="px-1">/</span>
		</span>
		{{ title }}
	</span>

	<span class="navbar-text mr-1">Version {{ version }}</span>
</nav>
</template>

<script>
import { Icon } from 'aunsight-lib-ui';

export default {
	components: { Icon },
	props: {
		title: {
			default: 'Daybreak',
			type: String
		},

		icon: {
			type: String,
			default: undefined
		},

		version: {
			default: '0.0.0',
			type: String
		}
	},
	computed: {
		isPreviewPage () {
			return this.title === 'Preview';
		}
	}
};

</script>

<style lang="scss" scoped>

.navbar-brand {
	font-size: 20px;
}

</style>
