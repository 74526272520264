import QueryModel from 'aunsight-webapp/src/js/modules/QueryTool/models/SavedQuery';

import EditQueryDetails from './EditQueryDetails';

/**
 * @class CreateQuery
 * @param {object} options
 * @param {object} options.context
 * @param {object} [options.data]
 */

export default EditQueryDetails.extend({
	options () {
		return {
			model: new QueryModel()
		};
	},
	initialize: function (options) {
		this.mergeOptions(options, ['context']);
		if (options.data) this.model.set(options.data);
		this.model.context = this.context;
	},
	title: 'Save New Query',

	progressOptions: {
		errorDisplay: {
			message: 'Unable to create query'
		}
	}
});
