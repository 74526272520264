<template>
<div db-layout="sidebar-wrapper">
	<side-bar db-layout="sidebar-left" />
	<div db-layout="sidebar-right" class="d-flex flex-column">
		<main-header
			:title="title"
			:version="version"
			:icon="icon" />
		<router-view />
	</div>
</div>
</template>

<script>
/* eslint-disable vue/require-prop-types */
/* eslint-disable vue/require-default-prop */

import MainHeader from '@/components/MainHeader';
import SideBar from '@/components/SideBar';

export default {
	name: 'Home',
	components: {
		SideBar,
		MainHeader
	},
	data () {
		return {
			version: DAYBREAK_PACKAGE_VERSION
		};
	},
	computed: {
		title () {
			return this.$route.meta.title;
		},
		icon () {
			return this.$route.meta.icon || 'questionCircle';
		}
	}
};
</script>
