// @ts-check
import _ from 'lodash';

const generators = {
	summary ({ id, title, currencyFormat }) {
		const config = {
			id: id,
			title: {
				type: 'static',
				value: title
			},
			type: 'numberAbsolute',
			value: {
				type: 'field',
				field: `${id}__value`
			}
		};
		if (currencyFormat) {
			config.type = 'numberCurrency';
			config.currency = {
				type: 'static',
				value: currencyFormat
			};
		}
		else {
			// Currency not compatible with separate thousands
			config.numberConfig = {
				separateThousands: {
					type: 'static',
					value: true
				}
			};
		}
		return config;
	},
	column ({ id, title, currencyFormat }) {
		const config = {
			id: id,
			title: {
				type: 'static',
				value: title
			},
			type: 'chartColumn',
			values: {
				type: 'field',
				field: `${id}__value`
			},
			labels: {
				type: 'field',
				field: `${id}__labels`
			}
		};
		if (currencyFormat) {
			config.valueConfig = {
				type: 'currency',
				currency: {
					type: 'static',
					value: currencyFormat
				}
			};
		}
		return config;
	},
	donut ({ id, title }) {
		const config = {
			id,
			title: {
				type: 'static',
				value: title
			},
			type: 'chartDonut',
			values: {
				type: 'field',
				field: `${id}__value`
			},
			labels: {
				type: 'field',
				field: `${id}__labels`
			}
		};
		return config;
	},
	line ({ id, title, currencyFormat }) {
		const config = {
			id,
			title: {
				type: 'static',
				value: title
			},
			type: 'chartLine',
			values: {
				type: 'field',
				field: `${id}__value`
			},
			labels: {
				type: 'field',
				field: `${id}__labels`
			}
		};
		if (currencyFormat) {
			config.valueConfig = {
				type: 'currency',
				currency: {
					type: 'static',
					value: currencyFormat
				}
			};
		}
		return config;
	}
};
/**
 * currencyFormatIfApplicable
 * Determines if the resulting visualization should have its value formatted as currency
 * @param {*} aggregate insight aggregation object
 * @param {*} spec datamart column schema
 * @returns {(string|undefined)} currency code, defaulting to USD, or undefined if not applicable
 */
export function currencyFormatIfApplicable (aggregate, spec) {
	const AGG_TYPES_RESULTING_IN_CURRENCY = ['min', 'max', 'avg', 'sum'];

	if (spec.format !== 'currency') return undefined;
	if (!AGG_TYPES_RESULTING_IN_CURRENCY.includes(aggregate.type)) return undefined;
	return spec.currencyFormat || 'USD';
}

/**
 * @typedef {object} RenderedInsight
 * @property {object} visualization
 */

/**
  * @typedef {object} InsightModel
  * @property {string} id
  * @property {string} title
  * @property {('summary'|'line'|'column'|'donut')} type
  * @property {string} currencyFormat
  * @property {object} query
  */

/**
 * insightRenderer
 * Given Daybreak Insights, returns auviz vizualizations and queries
 * @param {object} config
 * @param {InsightModel} config.insight
 * @param {object} config.datamart
 * @param {object} config.selectedTable
 * @returns {object} Auviz visualization
 */
export default function ({ insight: _insight, datamart, selectedTable }) {
	const insight = _.cloneDeep(_insight);
	if (insight.query.aggregate.field) {
		const table = insight.query.aggregate.table || selectedTable;
		const { spec } = datamart.getColumn(table, insight.query.aggregate.field);
		insight.currencyFormat = currencyFormatIfApplicable(insight.query.aggregate, spec);
	}

	const visualization = generators[insight.type](insight);

	return visualization;
}
