<template>
<!-- documentation -->
<div id="pz-landing-reference-widget" class="card">
	<div class="card-body">
		<h2 class="card-title h5">
			References
		</h2>
		<h3 class="card-subtitle h6">
			See your documents and resources
		</h3>

		<div id="pz-reference-holder" class="mt-3 d-flex text-white">
			<!-- User Guide item -->
			<div id="pz-reference-user-guides" class="card">
				<div class="card-body">
					<h5 class="card-title">
						User Guides
						<icon name="arrowCircleRight" custom-class="float-right" />
					</h5>
					<i class="far fa-copy mt-3 big-icon" />
					<a
						class="stretched-link"
						rel="external"
						target="_blank"
						href="https://docs.daybreak.aunsight.com/" />
				</div>
			</div>
			<!-- FAQ item -->
			<div id="pz-reference-faq" class="card">
				<div class="card-body">
					<h5 class="card-title">
						FAQ
						<icon name="arrowCircleRight" custom-class="float-right" />
					</h5>
					<i class="fas fa-chalkboard-teacher big-icon" />
					<a
						class="stretched-link"
						rel="external"
						target="_blank"
						href="https://docs.daybreak.aunsight.com/faq/" />
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import { Icon } from 'aunsight-lib-ui';

export default {
	name: 'LandingReferencesWidget',
	components: { Icon }
};
</script>

<style lang="scss">
@import 'app_variables';

#pz-landing-reference-widget {
	.card {
		border: none;
		width: 13rem;
		height: 17rem;
		margin-left: 0.75rem;
		margin-right: 0.75rem;
		overflow: hidden;

		.big-icon {
			font-size: 15rem;
			position: absolute;
			left: 2rem;
			opacity: 0.5;
		}
	}
}

#pz-reference-user-guides {
	background-color: $teal-4;
}

#pz-reference-faq {
	background-color: $purple-5;
}
</style>
