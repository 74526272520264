import BaseForm from 'aunsight-webapp/src/js/AWIComponents/BaseForm/BaseForm';

/**
 * @class ThumbsDownFeedback
 */
export default BaseForm.extend({
	modal: true,

	id: 'thumbs-down-feedback',

	title: 'Tell us more',

	formOptions: function () {
		const formOptions = {
			schema: {
				type: 'text'
			},
			options: {
				type: 'textarea',
				form: {
					buttons: {
						submit: {
							label: 'Done',
							styles: 'btn btn-primary'
						}
					}
				}
			}
		};

		return formOptions;
	},

	submit: function () {
		const data = this.ui.form.alpaca().getValue();

		this.triggerMethod('done', data);
		this.triggerMethod('close');
	}
});
