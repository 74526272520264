<template>
<div class="pz-wizard bg-white">
	<h4>
		Query Wizard
		<span class="float-right">
			<button
				class="btn btn-secondary"
				name="togglePreview"
				:disabled="!selectedTable || !canRun"
				@click="$emit('openSQLPreview')">
				Preview in SQL
			</button>
		</span>
	</h4>

	<error-message
		v-if="viewErr"
		:errors="[viewErr]"
		title="Datamart Error" />

	<h6>Show me...</h6>

	<table-field
		:tables="tables"
		:selected-table="selectedTable? selectedTable.id : ''"
		:selected-operator="!hasSingleGroup? filter.operator : filter.value[0].operator"
		@change="changeTable"
		@changeOp="changeTableOp" />

	<condition-group-list
		v-if="shouldShowFilterList"
		:has-single-group="hasSingleGroup"
		:groups="filters"
		:operator="!hasSingleGroup? filter.operator : filter.value[0].operator"
		:columns="columns"
		:relation-columns="relationColumns"
		@remove="filterRemoved"
		@update="update"
		@addRow="addRow"
		@changeOp="changeGroupOp"
		@addGroup="addGroup" />
</div>
</template>

<script>
import { ErrorMessage } from 'aunsight-lib-ui';
import awiPrompt from 'aunsight-webapp/src/js/AWIComponents/Prompt/Prompt';
import _ from 'lodash';

import Filter from '../lib/Filter';
import ConditionGroupList from './ConditionGroupList.vue';
import TableField from './TableField.vue';

export default {
	name: 'Wizard',

	components: {
		TableField,
		ConditionGroupList,
		ErrorMessage
		// DebugOutput
	},

	props: {
		structuredQuery: {
			type: Object,
			required: true
		},
		datamart: {
			type: Object,
			required: true
		},
		hasInsights: {
			type: Boolean,
			default: false
		},
		canRun: {
			type: Boolean,
			default: true
		}
	},

	data () {
		return {
			filter: undefined,
			selectedTable: undefined,
			// first filter update needs to be ignored. this helps track it
			isInitializing: true,
			viewErr: null,
			relationColumns: []
		};
	},

	computed: {
		columns () {
			if (!this.selectedTable) return;

			const columns = this.selectedTable.getSimpleColumns();
			return _.filter(columns, col => col.spec.filterable);
		},

		tables () {
			return this.datamart.getQueryables();
		},

		filters () {
			return _.get(this, ['filter', 'value'], []);
		},

		shouldShowFilterList () {
			return !!this.selectedTable;
		},

		hasSingleGroup () {
			return this.filter.value.length < 2;
		}
	},

	watch: {
		'filter.resolvedValue' () {
			// first time this is run, it is from initialization.
			if (this.isInitializing) {
				this.isInitializing = false;
			}
			else this.updateSQL();
		},

		selectedTable () {
			this.relationColumns = this.getRelationColumns();
		}

	},

	created () {
		this.filter = Filter.fromQuery(this.structuredQuery, this.datamart);
		this.selectedTable = this.filter.table;

		// isInitial / true indicates that this was not the result of a user chage
		this.updateSQL(true);
	},

	methods: {

		addGroup () {
			this.filter.addChildRow();

			this.updateSQL();
		},

		changeGroupOp (id, operator) {
			this.filter.getChildFilter(id).operator = operator;
		},

		update (id, prop, val) {
			const filter = this.filter.getChildFilter(id);

			/** * maybe move this logic to the filter class? ***/
			if (prop === 'column') {
				// find column spec
				let column;

				let table;
				// if it is a relation column, it will be prepended with id
				if (_.includes(val, '.')) {
					[table, val] = val.split('.');
				}
				// if nested
				column = _.find(this.relationColumns, { id: val });
				if (!column) {
					column = filter.getParent().table.getColumn(val);
				}

				if (table && table !== this.selectedTable.id) {
					column.columnTable = this.datamart.getTable(table);
				}

				filter.column = column;
			}

			else {
				filter[prop] = val;
			}
		},

		filterRemoved (id) {
			this.filter.removeChildFilter(id);
		},

		addRow (e) {
			const id = e.currentTarget.value;
			this.filter.addChildRow(id);
		},

		changeTable (newTable, cancel) {
			const changeTable = () => {
				const table = this.datamart.getTable(newTable);

				this.selectedTable = table;
				this.filter.table = table;

				this.updateSQL(false, true);
			};

			// true if value not empty and if there's only one group, that it is not incomplete;
			const hasConditions = !_.isEmpty(this.filter.value) && !(this.hasSingleGroup && !this.filter.value[0].isComplete());
			// If the user has conditions on current table, warn that they will be lost
			if (this.hasInsights || hasConditions) {
				awiPrompt({
					type: 'confirm',
					title: 'Changing Selected Table',
					text: '<p>You have conditions or insights on the current table. If you switch tables you will lose them.</p>' +
							'<p>Do you wish to continue?</p>',
					submitButtonText: 'Change Table'
				})
					.then(changeTable, cancel);
			}
			// if no conditions already, just change it
			else {
				changeTable();
			}
		},

		changeTableOp (operator) {
			if (this.hasSingleGroup) {
				this.filter.value[0].operator = operator;
			}
			else {
				this.filter.operator = operator;
			}
		},

		updateSQL (isInitial, isReset) {
			this.$emit('updateQuery', this.filter.toQuery(), isInitial, isReset);
		},

		getRelationColumns () {
			// Maybe this code should be moved to filter class?
			const table = this.selectedTable;
			if (!table) return;

			// if datamart has bad values, errors can be thrown here
			try {
				const foreign = table.getForeignRelationColumns().map(f => {
					const cf = _.cloneDeep(f);
					cf.foreign = true;
					return cf;
				});
				const local = table.getRelationColumns().map(n => {
					n.columnTable = table;
					n.table = this.datamart.getTable(n.spec.link.table || n.spec.link.view);
					return n;
				});
				return [...foreign, ...local];
			}
			catch (err) {
				this.viewErr = err;
				return [];
			}
		}
	}
};

</script>

<style lang="scss">
@import 'app_variables';

.pz-wizard {
	padding: 1em;
	max-width: 900px;

	h4 {
		margin-bottom: 1rem;
	}

	button[name='remove'] {
		padding: 0;
		padding-top: 0.2rem;
	}

	button[name='addFilter'],
	button[name='addAggCond'] {
		padding: 0;
		margin-top: 0.5rem;
	}

	// space between rows
	.pz-filter-item,
	.pz-filter-sub-item {
		margin-top: 0.5em;
		margin-bottom: 0.5em;
	}

	// adding "and" between rows.
	.pz-conjunction-tagged,
	.pz-filter-aggregation {
		position: relative;
	}

	.pz-cond-group-and > .pz-conjunction-tagged:not(:first-of-type)::before {
		content: 'and';
	}

	.pz-cond-group-or > .pz-conjunction-tagged:not(:first-of-type)::before {
		content: 'or';
	}

	.pz-conjunction-tagged.pz-filter-item::before,
	.pz-filter-aggregation::before {
		position: absolute;
		left: -1.5rem;
		top: -1rem;
		font-variant: small-caps;
	}

	.pz-filter-aggregation {
		margin-top: 1rem;

		&::before {
			top: -1.2rem;
			content: 'having';
		}
	}

	.pz-conjunction-tagged.pz-condition-group::before {
		position: absolute;
		left: 1rem;
		top: -1.7rem;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		font-variant: small-caps;
		border-radius: $border-radius;
		background-color: $secondary;
		color: $white;
		font-weight: bold;
	}
	// give space for conjunction badge
	.pz-condition-group:not(:first-of-type) {
		margin-top: 2rem;
	}

	.pz-table-selector {
		margin-bottom: 1.7rem;

		select[name='selected_entity'] {
			width: 14rem;
		}

		select[name='table_operator'] {
			width: 5rem;
		}
	}

	.pz-condition-group {
		padding: 1rem;
		margin-bottom: 1rem;
	}
}
</style>
