import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/vue';
import $ from 'jquery';
import _ from 'lodash';
import Vue from 'vue';

import router from '@/router';
import getBuildInfo from '@/utils/getBuildInfo';

$.ajax('/config').then(({ disableReporting, environment }) => {
	if (!disableReporting) {
		Sentry.init({
			Vue,
			dsn: 'https://0a981b6930c1414abc6fa7746a4892da@o65388.ingest.sentry.io/6222192',
			integrations: [new BrowserTracing({
				routingInstrumentation: Sentry.vueRouterInstrumentation(router)
			})],
			tracesSampleRate: 1.0,
			release: DAYBREAK_COMMITHASH,
			environment: environment
		});
		getBuildInfo().then(res => {
			_.forEach(res.AU_VERSIONS.auDependencies, (val, key) => {
				Sentry.setContext(`AuDependency ${key}`, val);
			});
			delete res.AU_VERSIONS;
			Sentry.setContext('buildInfo', res);
		});
	}
});
