<template>
<div
	class="modal pz-column-selector-dialog fade"
	tabindex="-1"
	role="dialog">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">
					<icon name="cog" custom-class=" mr-2" />
					Select Fields
				</h5>
				<button
					type="button"
					class="close"
					data-dismiss="modal"
					aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>

			<column-selector
				class="modal-body px-4"
				:datamart="datamart"
				:structured-query="structuredQuery"
				@update="update" />

			<div class="modal-footer">
				<button
					class="btn btn-secondary"
					type="button"
					data-dismiss="modal">
					Cancel
				</button>
				<button
					class="btn btn-primary"
					type="button"
					name="apply"
					:disabled="selection && selection.length === 0"
					@click="apply">
					Run
				</button>
			</div>
		</div>  <!-- end modal content -->
	</div>  <!-- end modal dialog -->
</div>
</template>

<script>
import { Icon } from 'aunsight-lib-ui';
import $ from 'jquery';

import ColumnSelector from './ColumnSelector.vue';

export default {
	components: {
		Icon,
		ColumnSelector
	},

	props: {
		datamart: { type: Object, required: true },
		structuredQuery: { type: Object, required: true }
	},

	data () {
		return {
			selection: [],
			hasChanges: false
		};
	},

	beforeDestroy () {
		$(this.$el).off();
		this.close();
	},

	mounted () {
		$(this.$el).modal('show');
		$(this.$el).on('hidden.bs.modal', () => {
			this.close();
		});
	},

	methods: {
		update (selection, hasChanged) {
			this.selection = selection;
			this.hasChanged = hasChanged;
		},

		apply () {
			// if no change from previous value, just run
			if (this.hasChanged) {
				this.$emit('updateColumns', this.selection);
			}

			this.$emit('run');

			$(this.$el).modal('hide');
		},

		close  () {
			if (!this.isClosed) {
				$(this.$el).modal('hide');
				this.$emit('close');
			}
		}
	}
};

</script>
