<template>
<div class="pz-query-catalog-filterer" role="toolbar">
	<nav class="navbar navbar-expand-md navbar-light px-2">
		<!-- the button that will toggle the menu when collapsed on small viewports -->
		<button
			class="navbar-toggler"
			type="button"
			data-toggle="collapse"
			data-target="#pz-query-catalog-sidebar-collapse"
			aria-controls="pz-query-catalog-sidebar-collapse"
			aria-expanded="false"
			aria-label="Toggle navigation">
			<span class="navbar-toggler-icon" />
		</button>

		<div id="pz-query-catalog-sidebar-collapse" class="collapse navbar-collapse">
			<div class="top-container">
				<span class="navbar-brand">Queries</span>

				<!-- new query button -->
				<router-link
					id="pz-query-catalog-new-query"
					:to="{ name: 'Data Builder' }"
					class="btn btn-secondary ml-auto">
					<icon name="plus" custom-class="mr-1" /> New Query
				</router-link>
			</div>

			<!-- query categories -->
			<!-- using modified bs list-group since it's closest to design -->
			<div id="pz-query-catalog-category-menu" class="list-group w-100 mt-3">
				<!-- *** Category Button ***  -->
				<button
					v-for="c in categories"
					:id="getCategoryId(c.id)"
					:key="c.id"
					type="button"
					aria-controls="pz-query-catalog-items"
					:class="getClass(c.id)"
					:aria-current="c.id === activeCategory"
					:disabled="c.id === activeCategory"
					:title="c.helpText"
					@click="$emit('setCategory', c)">
					<!-- type icon -->
					<icon
						:name="c.icon"
						custom-class="mr-1 ml-n1"
						:fixed-width="true" />
					<!-- type label -->
					{{ c.label }}
					<!-- unfiltered type count -->
					<span class="cat-count">{{ getCount(c.id) }}</span>
				</button>
			</div>
		</div>
	</nav>
</div>
</template>

<script>
import { Icon } from 'aunsight-lib-ui';
import _ from 'lodash';

export default {
	name: 'QueryCatalogMenu',

	components: { Icon },

	props: {
		categories: {
			type: Array,
			required: true
		},
		activeCategory: {
			type: String,
			required: true
		},
		counts: {
			type: Object,
			default: () => ({})
		}
	},

	methods: {
		getCount (catId) {
			return _.get(this.counts, catId);
		},

		getClass (catId) {
			return [
				'category-item',
				'list-group-item',
				'list-group-item-action',
				catId === this.activeCategory ? 'active' : null
			];
		},

		getCategoryId (catId) {
			return 'pz-query-catalog-category-' + _.kebabCase(catId);
		}
	}
};
</script>

<style lang="scss">

@import 'app_variables';

.pz-query-catalog-filterer {
	@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
		> nav {
			height: 100%;
		}

		#pz-query-catalog-sidebar-collapse {
			height: 100%;
			flex-flow: column;
		}

		#pz-query-catalog-category-menu {
			flex-grow: 1;
			overflow: auto;
		}
	}

	#pz-query-catalog-category-menu {
		padding-top: 1rem;
	}

	.top-container {
		display: flex;
		width: 100%;
	}

	.category-item {
		padding-top: 0.2rem;
		padding-bottom: 0.2rem;
		border: 0;

		// for the active and hover backgrounds
		border-radius: $border-radius;

		// add space between items
		&:not(:last-of-type) {
			margin-bottom: 0.25rem;
		}

		.cat-count {
			float: right;
		}

		&:hover {
			background-color: saturate(mix($info, $body-bg, 7%), 60%);
		}

		&.active {
			background-color: saturate(mix($info, $body-bg, 20%), 60%);
			color: inherit;
			font-weight: bold;
		}
	}
}
</style>
