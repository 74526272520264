<template>
<section style="padding: 30px;">
	<header class="page-header">
		<h1>{{ title }}</h1>
	</header>
	<p>{{ message }}</p>
	<p>If you feel you have reached this page in error, please contact your system administrator.</p>
	<router-link-context :to="{name: 'Query Builder'}" class="btn btn-primary">
		Return to Query Builder
	</router-link-context>
</section>
</template>

<script>
export default {
	name: 'View404Error',
	props: {
		title: {
			type: String,
			default: '404 Not Found'
		},
		message: {
			type: String,
			default: 'The page you are looking for cannot be found.'
		}
	}
};
</script>
