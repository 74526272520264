import isArrayOf from 'aunsight-webapp/src/js/modules/GuidedQueryBrowser/util/isArrayOf';
import isTypeMatch from 'aunsight-webapp/src/js/modules/GuidedQueryBrowser/util/isTypeMatch';
import _ from 'lodash';

import aggOperators from '../util/aggOperators';
import operators from '../util/operators';

/**
 * create an error for when field is not found in table
 * @param {string} column
 * @param {Table} table
 * @returns {Error}
 */
function fieldNotFound (column, table) {
	return new Error(`Field "${column}" not found in table "${table.spec.name}"`);
}

/**
 * @classdesc
 * shared base functionality for all filter parts
 * @abstract
 */
class FilterNode {
	/**
	 * @param {Object} props
	 * @param {(string|Table)} props.table -
	 * @param {Datamart} props.mart - datamart to query
	 * @param {(Filter|Condition|Group)} [props.parent]
	 * @param {Table} [props.column] - the datamart column class. only if it is a condition
	 * @param {Error} [props.columnError]
	 * @param {string} [props.operator] - operator to start with, if avaiable
	 * @param {*} [props.value] - the value to start with.
	 */
	constructor (props) {
		this.resolvedValue = undefined;

		/**
		 * the parent filter if it exists
		 * @type {(Filter|Group|Condition)}
		 */
		this.parent = props.parent || undefined;

		this._column = props.column || undefined;

		/**
		 * if there was a problem with the column from the saved query, this should be that
		 * @type {?Error}
		 */
		this.columnError = props.columnError || null;

		if (props.table) {
			this._table = props.table;
		}

		/**
		 * The datamart
		 * @type {Datamart}
		 */
		this.mart = props.mart || this.getParent().mart;

		if (this.table && _.isString(this.table)) {
			this._table = this.mart.getTable(this.table);
		}

		this._operator = props.operator || undefined;

		/**
		 * criteria for selecting rows (used along side the operator)
		 * @type {*}
		 */
		this.value = (this.isValueEmpty(props.value)) ? null : props.value;

		/**
		 * the id of parent filter if it exists
		 *
		 * (probably don't need both parent and parentId? should fix)
		 * @type {string}
		 */

		// set the id based on params.
		if (!this.id) {
			if (this.isRoot()) {
			/**
				 * id of this filter
				 *
				 * if at root, is # else is autogenerated id.
				 * @type {String}
				 */
				this.id = '#';
			}

			else {
				let id = _.uniqueId('awi-filter-');

				const parentId = this.parent.id;
				if (parentId && parentId !== '#') {
					id = parentId + '.' + id;
				}
				this.id = id;
			}
		}

		// convert all child rows into filters
		if ((this.hasChildren()) && !_.isEmpty(this.value)) {
			const childData = this.value;
			this.value = [];
			_.forEach(childData, f => {
				this.addChildRow(null, f);
			});
		}
	}

	/**
	* the table whose data this filter is querying
	* @type {Table}
	*/
	get table () {
		return this._table;
	}

	set table (table) {
		this._table = table;

		if (this.isRoot()) {
			this.value = [
				new Group({ parent: this, mart: this.mart })
			];
		}
		else this.value = null;

		this._updateResolvedValue();
	}

	/**
	 * the id of the operator in use
	 * @type {string}
	 */
	set operator (opId) {
		this._operator = opId;
		this._updateResolvedValue();
	}

	get operator () {
		return this._operator;
	}

	get value () {
		return this._value;
	}

	set value (val) {
		this._value = val;

		this._updateResolvedValue();
	}

	/**
	 * the column this node works on
	 * @type {?Table}
	 */
	get column () {
		return this._column;
	}

	set column (column) {
		this.columnError = null;

		if (_.isString(column) && this.table) {
			try {
				column = this.table.getColumn(column);
			}
			catch (err) {
				this.columnError = fieldNotFound(column, this.table);
			}
		}

		this._column = column;
		this._updateResolvedValue();
	}

	/**
	 * whether this filter object is a relation filter
	 * @return {boolean}
	 */
	isRelation () {
		// if columnError exists, there will be no column specs
		if (this.columnError) return !!this.columnError.isRelation;

		return !!_.get(this.column, ['spec', 'link', 'table']) || _.get(this.column, ['spec', 'link', 'view']);
	}

	/**
	 * Whether this is capable of having child items
	 * @return {Boolean}
	 */
	hasChildren () {
		return this.isRoot() || this.isRelation() || this.isGroup();
	}

	/**
	 * Returns an object containing the filter parts, `column`, `operator` and `value`
	 * @return {Object} a plain object version of filer
	 */
	toFilter () {
		let val = this.value;
		if (this.hasChildren() && _.isArray(this.value)) {
			val = this.value.map(v => v.toFilter());
		}
		const filter = {
			operator: this.operator,
			value: val
		};

		if (this.column) {
			filter.column = _.get(this.column, 'spec.name');
		}

		if (this.table && (this.isRoot() || this.isRelation())) {
			filter.table = _.get(this.table, 'id');
		}

		if (_.get(this, 'column.foreign')) {
			filter.foreign = true;
		}

		return filter;
	}

	toJSON () {
		return this.toFilter();
		// return {
		// 	column: this.column,
		// 	operator: this.operator,
		// 	value: this.value
		// };
	}

	/**
	 * Function to call anytime the value is modified
	 */
	_updateResolvedValue () {
		// don't allow it to update while initializing
		if (!this.id) return;

		// if this is not the root, tell bubble up that there is a change
		if (!this.isRoot()) this.parent.onChildChanged();
		else {
			// the root should update the resolved value so it can be observed
			const val = this.isComplete() ? this.toFilter() : null;

			if (!_.isEqual(this.resolvedValue, val)) {
				this.resolvedValue = val;
			}
		}
	}

	/**
	 * handler for when child changes for this to update
	 */
	onChildChanged () {
		this._updateResolvedValue();
	}

	/**
	 * Whether all fields have been filled out and are thus valid to be included in query
	 * @return {boolean}
	 */
	isComplete () {
		return true;
	}

	/**
	 * @abstract
	 * @param  {*}  val
	 * @return {boolean}
	 */
	isValueEmpty (val) {
		return !(val || val === 0);
	}

	/**
	 * get a child filter
	 *
	 * if the id is compound (id1.id2), means its a child of a child. will find the child and tell child to remove it.
	 * (this only works one level deep right now)
	 * @param  {string} id
	 * @return {Filter}
	 */
	getChildFilter (id) {
		if (!this._isDirectDescendant(id)) {
			return this._getChildParent(id).getChildFilter(id);
		}

		else return _.find(this.value, { id: id });
	}

	/**
	 * remove a child filter from value
	 *
	 * if the id is compound (id1.id2), means its a child of a child.
	 *
	 * will find the child and tell child to remove it.
	 *
	 * (this only works one level deep right now)
	 * @param  {string} id
	 */
	removeChildFilter (id) {
		if (!this._isDirectDescendant(id)) {
			this._getChildParent(id).removeChildFilter(id);
		}

		else {
			const i = _.findIndex(this.value, { id: id });
			this.value.splice(i, 1);
			this._updateResolvedValue();
		}
	}

	/**
	 * Given a node id, see if it is a child in the current node (verses a grandchild etc)
	 * @param  {string}  id - node id
	 * @return {Boolean} true if node is child of current node
	 * @private
	 */
	_isDirectDescendant (id) {
		return !/\./.test(id.replace(this.id + '.', ''));
	}

	/**
	 * for the given child id, return this node's direct descendant that is
	 * ancestor to the node with given id
	 * @param  {string} id child Id
	 * @return {(Group|Condition)}
	 * @private
	 */
	_getChildParent (id) {
		const withoutthis = id.replace(this.id + '.', '').split('.');
		const childIdPart = withoutthis[0];
		const childId = this.isRoot() ? childIdPart : this.id + '.' + childIdPart;
		return _.find(this.value, { id: childId });
	}

	/**
	 * Add a child row
	 *
	 * @TODO check to make sure type is appropriate before adding
	 * @param {string} [parentId] - if present, it is the id of a child to add the row to
	 * @param {object} [props] - if present, these props will be assigned to new filter. Note this only works if there is no parentId
	 */
	addChildRow (parentId, props) {
		if (parentId) {
			const parent = this.getChildFilter(parentId);

			parent.addChildRow(null);
		}
		else {
			this._addImmediateChild(props);
		}
	}

	_addImmediateChild (props) {
		const opts = {
			parent: this,
			mart: this.mart
		};

		if (props) {
			_.assign(opts, props);

			if (!props.column && _.isArray(props.value)) {
				opts.table = this.table;
			}
			// if this is a foreign relation
			else if (opts.table && opts.foreign) {
				try {
					const columnTable = this.mart.getTable(opts.table);
					opts.column = _.cloneDeep(columnTable.getColumn(opts.column));
					opts.column.foreign = true;
				}
				catch (err) {
					opts.columnError = fieldNotFound(props.column, this.mart.getTable(opts.table));
					// since no column info, add that this is a relation on the error
					opts.columnError.isRelation = true;
				}
			}
			else {
				try {
					opts.column = _.cloneDeep(this.table.getColumn(props.column));
				}
				catch (err) {
					opts.columnError = fieldNotFound(props.column, this.table);
				}
			}
		}

		const ClassType = this.isRoot() ? Group : Condition;

		const filter = new ClassType(opts);
		if (this.isValueEmpty(this.value)) this.value = [];
		this.value.push(filter);

		this._updateResolvedValue();
	}

	/**
	 * Get the parent filter
	 * @return {(Filter|Condition|Group)}
	 */
	getParent () {
		return this.parent;
	}

	/**
	 * Whether this node is the root node (i.e. has children and no parent)
	 * @return {boolean} true if is root
	 */
	isRoot () {
		return false;
	}

	/**
	 * whether this node is a group
	 * @return {boolean}
	 */
	isGroup () {
		return false;
	}
}

/**
 * A node which only exists to group other conditions and relations
 * @extends FilterNode
 */
class Group extends FilterNode {
	constructor (props) {
		if (!props.operator) props.operator = 'all';
		super(props);
	}

	get table () {
		return this.getParent().table;
	}

	isGroup () {
		return true;
	}

	isComplete () {
		return !!_.size(this.value) && _.some(this.value, child => child.isComplete());
	}
}

/**
 * An aggregate condition on a relation
 *
 * its parent will always be a Condition
 * @extends FilterNode
 */
class AggCondition extends FilterNode {
	constructor (props) {
		super(props);

		this.aggOperator = props.aggOperator || null;
	}

	/**
	 * The type (id) of aggregation operator (e.g. sum/min/max/count)
	 * @type  {?string} aggOperator
	 */
	get aggOperator () {
		return this._aggOperator;
	}

	set aggOperator (aggOperator) {
		this._aggOperator = aggOperator;

		// when it changes, clear column, op and value if new operator doesn't need them
		if (!this.needsColumn()) {
			this.column = null;
		}

		// if current column doesn't match new operator type, clear it
		if (!_.find(this.getAvailableColumns(), this.column)) {
			this.column = null;
		}

		this._updateResolvedValue();
	}

	/**
	 * whether aggregator requires column specified
	 * @return {boolean}
	 */
	needsColumn () {
		return this.getAggOpColType() !== null;
	}

	/**
	 * Find out what types is required for the column for currently selected agg operator
	 * @return {?string}
	 */
	getAggOpColType () {
		return _.get(_.find(aggOperators, { id: this.aggOperator }), 'columnType');
	}

	isComplete () {
		// agg operator, operator and value always required.
		if (!this.aggOperator ||
				!this.operator ||
				(this.isValueEmpty(this.value))) {
			return false;
		}

		// check if it has column or if doesn't need column
		return !!this.column || !this.needsColumn();
	}

	/**
	 * return the operators available on aggregations
	 * @return {aggOperatorDef[]}
	 */
	getAvailableOperators () {
		return _.filter(operators, function (op) {
			return isTypeMatch(op.columnType, 'number') && !op.requiresEnum && !op.excludeAggregations;
		});
	}

	/**
	 * Return the operators for aggregations
	 * @return {import('../util/aggOperators').aggOperatorDef[]}
	 */
	getAggOperators () {
		return aggOperators;
	}

	/**
	 * for chosen agg operator, retrieve the columns that can be selected
	 * @return {Table[]} columns that can be used with current agg operator
	 */
	getAvailableColumns () {
		const table = this.getParent().table;

		return _.filter(table.getSimpleColumns(), col => {
			return isTypeMatch(this.getAggOpColType(), col.spec.type);
		});
	}

	toFilter () {
		const filter = {
			aggOperator: this.aggOperator,
			operator: this.operator,
			value: this.value
		};
		if (this.column) filter.column = this.column.id;

		return filter;
	}
}

/**
 * a condtion, either on local table or from a related table
 * @extends FilterNode
 */
class Condition extends FilterNode {
	constructor (props) {
		// table doesn't need to be passed as long as there is a column
		if (!props.table && props.column && props.column.table) {
			props.table = props.column.table;
		}
		super(props);

		if (this.isRelation() && props.aggregation) {
			/**
			 * the group condition / aggregation on a relation
			 * @type {AggCondition}
			 */
			this.aggregation = props.aggregation;
		}
		else {
			this.aggregation = null;
		}

		/**
		 * If present, means current value is invalid.
		 *
		 * has property type and message
		 * @type {?validationError}
		 */
		if (!this.validationError) this.validationError = null;
	}

	/**
	 * Get all operators that are valid for the current column
	 * @return {Object[]}
	 */
	getAvailableOperators () {
		if (!this.column) return [];

		// find all operators that work with column type
		const matching = this.getOperatorsForColumn(this.column);

		// return operators
		return matching;
	}

	/**
	 * For a given type, return all operators that are valid
	 * @param  {Object} column
	 * @return {operator[]}
	 */
	getOperatorsForColumn (column) {
		const type = this._getColumnType(column);

		// HACK: fix this later
		if (type === 'relation') {
			return _.filter(operators, { columnType: 'relation' });
		}
		return _.filter(operators, function (op) {
			return isTypeMatch(op.columnType, type);
		});
	}

	/**
	 * Get the relevant enumeration, either from the operator or the spec
	 * @returns {Array}
	 */
	getEnumeration () {
		if (this.operator) {
			const opSpec = _.find(operators, { id: this.operator });
			if (opSpec.enum) return opSpec.enum;
		}
		return _.get(this, ['column', 'spec', 'enum']);
	}

	get column () {
		return this._column;
	}

	set column (column) {
		this.columnError = null;
		if (_.isString(column) && this.table) {
			try {
				column = this.table.getColumn(column);
			}
			catch (err) {
				this.columnError = err;
			}
		}
		this._column = column;

		// update the table when a different relation is selected
		// only happens when this filter is not root (since root has no column)
		if (column.table) {
			this._table = column.table;
		}
		// if there is an old table, remove it.
		else if (this._table) {
			this._table = null;
		}

		// if field types are incompatable, clear the operator and value
		if (!_.includes(this.getOperatorsForColumn(column), this.operator)) {
			this._operator = undefined;
			this.value = null;
		}

		if (this.aggregation) this.aggregation = null;

		this._updateResolvedValue();
	}

	/**
	 * Set the operator and update value if necessary
	 * @type {string}
	 */
	set operator (opId) {
		const oldOp = this._operator;
		this._operator = opId;

		this._updateValueForOp(opId, oldOp);

		this._updateResolvedValue();
	}

	get operator () {
		return this._operator;
	}

	get value () {
		return this._value;
	}

	// we need to add validation in here
	set value (val) {
		this._value = val;

		this.validationError = this._validateValue(val);

		this._updateResolvedValue();
	}

	/**
	 * if this is a relation, the aggregation condition
	 * @type {Condition}
	 */
	set aggregation (aggregation) {
		this.hasAggregation = !!aggregation;
		if (!(aggregation instanceof AggCondition) && !_.isNull(aggregation)) {
			if (aggregation.column) {
				aggregation.column = this.table.getColumn(aggregation.column);
			}

			aggregation = new AggCondition({
				isAgg: true,
				table: this.table,
				parent: this,
				mart: this.mart,
				...aggregation
			});
		}
		this._aggregation = aggregation;

		this._updateResolvedValue();
	}

	get aggregation () {
		return this._aggregation;
	}

	/**
	 * After operator change, value may need to be changed too if type is incompatable
	 * @param  {string} opId - current operator
	 * @param  {string} oldOp - previous operator that the current `value` was for
	 * @private
	 */
	_updateValueForOp (opId, oldOp) {
		if (!opId) return;
		// TODO: Clear value if it is no longer valid.
		if (!_.some(operators, { id: opId })) {
			throw new Error('Invalid operator'); // sanity check
		}

		// if operator types are incompatable, clear the value
		const newType = this.getTypeForOperator(opId);
		const oldType = this.getTypeForOperator(oldOp);
		if (!isTypeMatch(newType, oldType)) {
			// if the new type is an array of old type, wrap old value in array
			if (isArrayOf(newType, oldType)) {
				if (this.isValueEmpty(this.value)) this.value = [];
				else {
					this.value = [this.value];
				}
			}
			else {
				if (newType === 'filterlist') this.value = [];
				else {
					this.value = null;
				}
			}
		}
	}

	/**
	 *
	 * @typedef validationError
	 * @property {string} name
	 * @property {string} message
	 * @property {string[]} [invalidValues]
	 * @property {string} [invalidValue]
	 */

	/**
	 * returns a validationError if value is bad. Currently only enums have validation
	 * @param {*} val
	 * @returns {?validationError}
	 */
	_validateValue (val) {
		const enm = _.get(this.column, 'spec.enum');

		if (val && enm) {
			if (_.isArray(val) && _.without(val, ...enm).length) {
				const vals = _.without(val, ...enm).map(v => `"${v}"`).join(', ');
				return {
					name: 'InvalidEnumValue',
					message: `Invalid value ${vals}`,
					invalidValues: _.without(val, ...enm)
				};
			}
			else if (!_.isArray(val) && !_.includes(enm, val)) {
				return {
					name: 'InvalidEnumValue',
					message: `Invalid value "${val}"`,
					invalidValue: val
				};
			}
			else return null;
		}

		else return null;
	}

	toFilter () {
		const filter = super.toFilter();

		// check the aggregation and add it if needed
		if (this.isRelation() && this.aggregation) {
			filter.aggregation = this.aggregation.toFilter();
		}

		return filter;
	}

	/**
	 * given a certain operator return the data type for it
	 * @param  {string} operator - id of operator
	 * @return {string}
	 */
	getTypeForOperator (operator) {
		return _.get(_.find(operators, { id: operator }), 'valueType');
	}

	/**
	 * get the data type for the operator selected
	 * @return {string}
	 */
	getOperatorType () {
		return this.getTypeForOperator(this.operator);
	}

	/**
	 * Gets the type that a column should be filtered as
	 *
	 * The `type` field on a column only indicates the way the data is stored
	 * internally, but may be different from conceptual type that it needs to be
	 * filtered as.
	 *
	 * For example, dates are stored as strings and foreign keys can be any type
	 * @return {string}
	 */
	getColumnType () {
		return this._getColumnType(this.column);
	}

	_getColumnType (column) {
		if (this.isRelation()) {
			return 'relation';
		}

		if (_.includes(['date-time', 'date'], _.get(column, 'spec.format'))) {
			return 'date';
		}

		else return _.get(column, 'spec.type');
	}

	/**
	 * whether the operator for this condition requires a value
	 * @return {boolean} true if value is required
	 */
	needsValue () {
		return this.getOperatorType() !== null;
	}

	/**
	 * Whether all fields have been filled out and are thus valid to be included in query
	 * @return {boolean}
	 */
	isComplete () {
		if (!this.column) return false;

		if (!this.operator) return false;

		if (this.needsValue() && this.isValueEmpty(this.value) && !this.aggregation) return false;

		// a way for object type values to signal they are incomplete,
		// (since falsy won't help here)
		if (this.value && this.value.isIncomplete) return false;

		// this is less helpful than i thought it would be. Perhaps there might be some use though later?
		// if (this.isRoot() || this.isRelation() && _.some(this.value, child => !child.isComplete())) {
		// 	return false
		// }

		return true;
	}

	/**
	 * Returns true if the selected column is numeric
	 *
	 * is this not used anywhere?
	 * @return {boolean}
	 */
	isColumnNumeric () {
		return _.includes(['number', 'integer', 'float'], _.get(this.column, 'spec.type'));
	}

	/**
	 * returns true if value should be number
	 *
	 * is different from isColumnNumeric when an operator needs a different value type
	 * @return {boolean}
	 */
	isValueNumeric () {
		const opType = this.getOperatorType();

		// if the operator specifes numeric, it is true
		if (opType === 'number') return true;

		// if the operator says any and column is numeric, then true
		if (opType === '*' && this.isColumnNumeric()) return true;

		return false;
	}

	/**
	 * Determine if a value is considered empty
	 *
	 * all falsey values are empty except 0
	 *
	 * all truthy values are not empty except empty array
	 * @param  {*}  val
	 * @return {boolean}
	 */
	isValueEmpty (val) {
		if (_.isArray(val) && !val.length) return true;

		return !(val || val === 0);
	}
}

/**
 * The root filter
 * @extends FilterNode
 */
class Filter extends FilterNode {
	isRoot () {
		return true;
	}

	/**
	 * Whether all fields have been filled out and are thus valid to be included in query
	 * @return {boolean}
	 */
	isComplete () {
		return !!this.table && !!this.operator;
	}

	/**
	 * Get the value query format (as specified in lib-query)
	 * @return {Object}
	 */
	toQuery () {
		function processChildren (filter) {
			const quer = {};
			const conditions = []; const relations = [];
			// process children
			_.forEach(filter.value, child => {
				// if all needed parts are not present, ignore that row
				if (!child.isComplete()) return;

				// if it is not a relation, process as a condition
				if (!child.isRelation()) {
					const cond = {
						column: _.get(child.column, 'name') || _.get(child.column, 'id'),
						operator: child.operator
					};
					if (child.needsValue()) {
						cond.value = child.value;

						// if numeric, cast to number
						if (child.isValueNumeric()) {
							cond.value = Number(cond.value);
						}

						else if (child.getOperatorType() === 'dateRange') {
							cond.start = _.get(cond, ['value', 0]);
							cond.end = _.get(cond, ['value', 1]);
							delete cond.value;
						}
						else if (child.getOperatorType() === '[string]') {
							cond.values = cond.value;
							delete cond.value;
						}

						// 'like' operator needs 'pattern', not 'value'
						else if (child.operator === 'like') {
							cond.pattern = cond.value;
							delete cond.value;
						}
					}

					conditions.push(cond);
				}
				// if it is a relation, recurse.
				else {
					relations.push({ ...makeQuery(child), ...processChildren(child) });
				}
			});

			// assign relations/conditions to query if populated
			if (conditions.length) quer.conditions = conditions;
			if (relations.length) quer.relations = relations;

			return quer;
		}

		/**
		 * @private
		 * @param  {(Filter|Condition)} filter
		 * @return {Object} structured query
		 */
		function makeQuery (filter) {
			const query = {};

			// options different if root or relation
			if (filter.getParent()) {
				query.include = ['all', 'any'].includes(filter.operator);
				query.mode = ['all', 'none'].includes(filter.operator) ? 'all' : 'any';

				if (_.get(filter, 'column.foreign')) {
					query.table = _.get(filter, ['table', 'id']);
					query.key = _.get(filter, ['column', 'name'], _.get(filter, ['column', 'id']));
				}
				else {
					// for local relations (where relation is specified on queried table)
					query.table = _.get(filter.column, ['spec', 'link', 'table']) || _.get(filter.column, ['spec', 'link', 'view']);
					query.root_key = _.get(filter, ['column', 'name'], _.get(filter, ['column', 'id']));
				}
			}

			else {
				query.table = _.get(filter.table, 'id');

				if (filter.operator) query.mode = filter.operator;
			}

			const agg = filter.aggregation;
			if (agg && agg.isComplete()) {
				const groupcondition = {
					aggregation: agg.aggOperator,
					operator: agg.operator,
					value: agg.value
				};
				if (agg.needsColumn()) {
					groupcondition.column = agg.column.id;
				}

				query.group_conditions = [groupcondition];
				query.aggregation = true;
			}

			return query;
		}

		const groups = _(this.value)
			.filter(g => g.isComplete()) // only use groups that have complete conditions
			.map(group => {
				return {
					mode: group.operator,
					...processChildren(group)
				};
			})
			.value();

		const query = {
			...makeQuery(this),
			condition_groups: groups
		};
		// if there is only one condition group, hoist its contents
		if (query.condition_groups.length === 1) {
			const onlyGroup = query.condition_groups[0];
			_.assign(query, onlyGroup);
			delete query.condition_groups;
		}

		if (_.isEmpty(query.condition_groups)) {
			if (_.has(query, 'condition_groups')) delete query.condition_groups;
		}

		return query;
	}

	/**
	 * Create a filter from lib-query format
	 * @param  {Object} query
	 * @param  {Datamart} datamart - the associated datamart
	 * @static
	 * @return {Filter}
	 */
	static fromQuery (query, datamart) {
		function convertCondition (cond) {
			const c = {
				column: cond.column,
				operator: cond.operator
			};

			if (!Condition.prototype.isValueEmpty(cond.value)) {
				c.value = cond.value;
			}

			const valType = _.get(_.find(operators, { id: c.operator }), 'valueType');

			if (valType === 'dateRange') {
				c.value = [cond.start, cond.end];
			}
			else if (valType === '[string]') {
				c.value = cond.values;
			}
			else if (c.operator === 'like') {
				c.value = cond.pattern;
			}

			return c;
		}

		function processConditonsAndRelations (subQuery) {
			const value = [];
			// convert conditions into local format
			_.forEach(subQuery.conditions, cond => {
				const c = convertCondition(cond);
				value.push(c);
			});

			// convert relations into local format
			_.forEach(subQuery.relations, rel => {
				// mode may be missing, defaults to 'all'
				const mode = rel.mode || 'all';
				const op = rel.include
					? (mode === 'all' ? 'all' : 'any')
					: mode === 'all' ? 'none' : 'nany';
				const r = {
					table: rel.table,
					operator: op,
					value: []
				};
				if (rel.key) {
					r.column = rel.key;
					r.foreign = true;
				}
				else {
					r.column = rel.root_key;
				}

				_.forEach(rel.conditions, cond => {
					const c = convertCondition(cond);
					r.value.push(c);
				});

				if (!_.isEmpty(rel.group_conditions)) {
					const gc = rel.group_conditions[0];

					r.aggregation = {
						aggOperator: gc.aggregation,
						..._.pick(gc, ['operator', 'column', 'value'])
					};
				}

				value.push(r);
			});

			return {
				operator: subQuery.mode,
				value
			};
		}

		const opts = {
			mart: datamart,
			table: query.table,
			operator: query.mode || 'all'
		};

		if (query.condition_groups) {
			opts.value = query.condition_groups.map(processConditonsAndRelations);
		}

		else {
			opts.value = [processConditonsAndRelations(query)];
		}

		return new Filter(opts);
	}
}

// for testing
Filter.Group = Group;
Filter.Condition = Condition;
Filter.AggCondition = AggCondition;

export default Filter;
