<template>
<div class="pz-filter-val-field pz-filter-val-date-field input-group">
	<input
		:id="pickerId"
		type="text"
		class="form-control"
		autocomplete="off"
		:value="formatDateDisplay(value)"
		:disabled="disabled"
		@change="update">

	<div class="input-group-append">
		<button
			class="btn btn-outline-secondary"
			type="button"
			@click="clear">
			<icon name="times" />
		</button>
	</div>
</div>
</template>

<script>
import { Icon } from 'aunsight-lib-ui';
import Litepicker from 'litepicker';
import _ from 'lodash';
import moment from 'moment';

const DISPLAY_FORMAT = 'MM/DD/YYYY';
// todo: make this determined by datamart specs
const DATE_FORMAT = 'YYYY-MM-DD';

export default {
	name: 'FilterValueDateField',

	components: { Icon },

	picker: undefined,

	props: {
		id: { type: String, required: true },
		value: { type: String, required: true },
		disabled: { type: Boolean, default: false }
	},

	computed: {
		pickerId () {
			return this.id + '-datepicker';
		}
	},

	mounted () {
		const me = this;
		this.picker = new Litepicker({
			element: document.getElementById(this.pickerId),

			format: DISPLAY_FORMAT,

			// start on sunday
			firstDay: 0,

			mobileFriendly: false,

			dropdowns: {
				minYear: 1900,
				maxYear: (new Date()).getFullYear() + 1,
				months: true,
				years: true
			},

			setup (picker) {
				picker.on('selected', () => {
					const date = picker.getDate();
					if (date) {
						me.updatedirect(me.formatDate(date.dateInstance));
					}
				});
			}
		});
	},

	beforeDestroy () {
		if (this.picker) {
			this.picker.destroy();
		}
	},

	methods: {
		update (val) {
			const me = this;
			setTimeout(() => {
				const date = this.formatDate(me.picker.getDate().dateInstance);
				me.$emit('change', 'value', date);
			}, 10);
		},
		updatedirect (val) {
			this.$emit('change', 'value', val);
		},

		formatDate (date) {
			if (date instanceof Date) {
				return moment(date).format(DATE_FORMAT);
			}
			else return date;
		},

		formatDateDisplay (date) {
			if (!date) return '';

			if (_.isString(date)) {
				return moment(date, DATE_FORMAT).format(DISPLAY_FORMAT);
			}

			else return moment(date).format(DISPLAY_FORMAT);
		},

		clear () {
			this.picker.clearSelection();
			this.$emit('change', 'value', '');
		}
	}
};

</script>
