<template>
<modal
	id="pz-query-catalog-add-tag-dialog"
	:open="open"
	title="Add Tag"
	:disable-save-button="!!validationMessage || !tag"
	save-button-text="Submit"
	footer-close-button-text="Cancel"
	show-close-button
	@close="onClose"
	@hidden="clearData"
	@submit="save">
	<error-message
		v-if="saveErr"
		:errors="[saveErr]"
		title="Unable to save tag" />

	<div>
		<label for="pz-taglist-add-tag-input">Enter name for tag</label>
		<small class="form-text text-small text-muted float-right">{{ remainingMessage }}</small>
	</div>

	<input
		id="pz-taglist-add-tag-input"
		v-model="tag"
		aria-describedby="pz-taglist-add-tag-input-validation"
		type="input"
		:class="['form-control', validationMessage? 'is-invalid': null ]"
		:maxlength="maxLength">

	<span id="pz-taglist-add-tag-input-validation" class="invalid-feedback">
		{{ validationMessage }}
	</span>
</modal>
</template>

<script>
import { ErrorMessage, Modal } from 'aunsight-lib-ui';
import getXHRError from 'aunsight-webapp/src/js/util/getXHRError';
import _ from 'lodash';

export default {
	name: 'AddTagDialog',
	components: { Modal, ErrorMessage },

	props: {
		open: {
			type: Boolean,
			required: true
		},
		tags: {
			type: Array,
			required: true
		},
		addTag: {
			type: Function,
			required: true
		}
	},

	data () {
		return {
			tag: '',
			maxLength: 16,

			saveErr: null,

			// prevent validation from saying duplicate tag after tag is added
			shouldValidate: true
		};
	},

	computed: {
		remainingChars () {
			return this.maxLength - this.tag.length;
		},

		remainingMessage () {
			if (this.remainingChars >= 0) {
				return `${this.remainingChars} character${(this.remainingChars !== 1) ? 's' : ''} remaining`;
			}

			else return 'Max characters exeeded';
		},

		/** ****** Validation Checks ***********/
		/*
		 * these have been broken out, rather than using a complicated regex
		 * for readability and maintainablity
		 */

		hasDuplicateTag () {
			return this.tags.includes(this.tag.trim());
		},

		startOrEndsSpace () {
			return _.startsWith(this.tag, ' ') || _.endsWith(this.tag, ' ');
		},

		// this will probably never be triggered because we have set the max length with the input
		// most browsers will prevent user from exceeding this limit automatically
		exceedsMaxLength () {
			return this.tag.length > this.maxLength;
		},

		hasConsecutiveSpaces () {
			return /\s\s/.test(this.tag);
		},

		hasInvalidCharacters () {
			return !/^[a-z\d ]*$/.test(this.tag);
		},

		validationMessage () {
			if (!this.shouldValidate) return false;
			if (this.startOrEndsSpace) {
				return 'Tag may not start or end with a space';
			}
			else if (this.hasDuplicateTag) {
				return 'This tag already exists on this query';
			}
			else if (this.exceedsMaxLength) {
				return 'This tag may only contain 16 characters';
			}
			else if (this.hasConsecutiveSpaces) {
				return 'Tag may not contain more than one consecutive space';
			}
			// the uppercase letters should not trigger here due to the watch which
			// sets them to lowercase, but this is here as a backup
			else if (this.hasInvalidCharacters) {
				return 'Please use lowercase letters, numbers and spaces';
			}
			else return false;
		}
	},

	watch: {
		// Prevent uppercase letters from being entered
		tag (value) {
			if (/[A-Z]/.test(value)) {
				this.tag = this.tag.toLowerCase();
			}
		}
	},

	methods: {
		onClose () {
			this.$emit('done');
		},

		clearData () {
			this.tag = '';
			this.saveErr = null;
			this.shouldValidate = true;
		},

		save () {
			this.shouldValidate = false;
			this.addTag(this.tag).then(
				() => {
					this.onClose();
				},
				(err) => {
					if (err && _.isFunction(err.state)) {
						err = getXHRError(err);
					}
					this.saveErr = err;
				}
			);
		}
	}
};
</script>
