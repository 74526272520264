<template>
<modal
	id="pz-download-insight-dialog"
	data-backdrop="static"
	:title="title"
	:show-close-button="true"
	:show-save-button="false"
	:show-footer-close-button="false"
	:open="open"
	@close="close">
	<div class="form-group row">
		<label for="download-insight-type-selector" class="col-sm-2 col-form-label">Type</label>
		<div class="col-sm-10">
			<select
				id="download-insight-type-selector"
				v-model="type"
				class="custom-select">
				<option value="jpeg">
					JPEG
				</option>
				<option value="png">
					PNG
				</option>
				<option value="data">
					Data
				</option>
			</select>
		</div>
	</div>

	<div v-if="loaded" class="container">
		<div class="row">
			<div v-if="type === 'jpeg'" class="download-image-container">
				<img
					:src="canvasJPEG"
					class="my-4"
					alt="Insight as jpeg">
				<a
					class="btn btn-primary float-right"
					:href="canvasJPEG"
					download="insight.jpg">
					Download JPEG
				</a>
			</div>
			<div v-else-if="type === 'png'" class="download-image-container">
				<img
					:src="canvasPNG"
					class="my-4"
					alt="Insight as png">
				<a
					class="btn btn-primary float-right"
					:href="canvasPNG"
					download="insight.png">Download PNG
				</a>
			</div>
			<div v-else-if="type === 'data'" class="w-100">
				<div class="row my-3 no-gutters">
					<div class="col">
						<textarea
							v-model="data"
							class="form-control"
							readonly
							rows="6" />
					</div>
					<div class="col-auto">
						<copy-button :text="data" container="pz-download-insight-dialog" />
					</div>
				</div>
				<a
					class="btn btn-primary float-right"
					:href="dataCSV"
					download="insight.csv">Download CSV
				</a>
			</div>
		</div>
	</div>
</modal>
</template>

<script>
import { CopyButton, Modal } from 'aunsight-lib-ui';
import html2canvas from 'html2canvas';
import _ from 'lodash';
import papaparse from 'papaparse';

export default {
	name: 'DownloadInsightDialog',

	components: {
		Modal,
		CopyButton
	},

	props: {
		open: {
			type: Boolean,
			default: false
		},
		element: {
			type: HTMLElement,
			default: undefined
		},

		insight: {
			type: Object,
			default: () => ({})
		},
		insightData: {
			type: Object,
			default: () => ({})
		}
	},

	data () {
		return {
			downloadButton: {},
			canvas: undefined,
			canvasJPEG: undefined,
			canvasPNG: undefined,
			type: 'jpeg',
			loaded: false
		};
	},

	computed: {
		title () {
			return `Download "${this.insight.title}"`;
		},

		headers () {
			const { query } = this.insight;
			const categories = query.group ? query.group.field : '';
			return [categories, this.insight.title];
		},

		data () {
			if (_.has(this.insightData, 'labels')) {
				const data = _.zip(
					this.insightData.labels,
					this.insightData.value
				);
				return papaparse.unparse({
					fields: this.headers,
					data
				});
			}
			else {
				const data = [[this.insight.title, this.insightData.value]];
				return papaparse.unparse({ data }, { headers: false });
			}
		},

		dataCSV () {
			return `data:application/csv;charset=utf-8,${encodeURIComponent(this.data)}`;
		}
	},

	watch: {
		element () {
			this.loadCanvas();
		}
	},

	methods: {
		close () {
			this.$emit('close');
			this.loaded = false;
			this.type = 'jpeg';
		},

		loadCanvas () {
			if (!this.element) {
				this.canvasJPEG = undefined;
				this.canvasPNG = undefined;
			}
			else {
				const ignoreElements = (el) => {
					return el.classList.contains('action');
				};
				const config = {
					scale: 4,
					ignoreElements: ignoreElements,
					scrollX: 0,
					scrollY: 0
				};

				// Clone element to remove scroll uncertainty and offset
				const clonedElement = this.element.cloneNode(true);
				clonedElement.style.position = 'fixed';
				clonedElement.style.top = `${window.innerHeight}px`;
				clonedElement.style.left = 0;
				clonedElement.style.width = `${this.element.clientWidth}px`;
				document.body.appendChild(clonedElement);

				html2canvas(clonedElement, config).then((res) => {
					this.canvasJPEG = res.toDataURL('image/jpeg');
					this.canvasPNG = res.toDataURL('image/png');
					this.loaded = true;
					document.body.removeChild(clonedElement);
				});
			}
		}
	}
};
</script>

<style lang="scss">
#pz-download-insight-dialog {
	// hide modal footer since lib-ui won't let you not have it
	.modal-footer {
		display: none;
	}

	.download-image-container img {
		max-width: 100%;
	}
}
</style>
