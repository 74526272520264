import contextMixin from 'aunsight-webapp/src/js/models/contextMixin';
import queryMixin from 'aunsight-webapp/src/js/models/queryMixin';
import QueryModel from 'aunsight-webapp/src/js/modules/QueryTool/models/SavedQuery';
import Backbone from 'backbone';
import _ from 'lodash';

import app from '@/webapp';

/**
 * @class QueriesCollection
 * @memberof module:QueryTool
 * @param {array} models
 * @param {Object} options
 * @param {Object} options.context
 * @param {string} options.context.organization
 * @param {string} options.context.project
 * @param {string} options.context.config
 * @param {string} options.context.scope
 * @param {string} options.userId
 */
export default Backbone.Collection.extend({
	initialize: function (models, options) {
		_.assign(this, options, ['context', 'userId']);

		queryMixin.mix(this, options);
		contextMixin.mix(this);
	},

	url: function () {
		return `${app.apiPath}daybreak/config/${this.context.config}/scope/${this.context.scope}/query`;
	},

	model: QueryModel,

	fetch: function (options) {
		if (!options) options = {};

		if (!options.data) options.data = {};

		const queryParams = {
			limit: this.query.limit,
			skip: this.query.skip,
			user: this.userId
		};

		if (this.getSortQuery(true)) queryParams.sort = this.getSortQuery(true);
		if (this.getFilterQuery(true)) _.assign(queryParams, this.getFilterQuery(true));

		_.assign(options.data, queryParams, this._getContextArguments());

		return Backbone.Collection.prototype.fetch.call(this, options);
	},

	parse: function (response) {
		this.setMetadata(_.get(response, 'result.meta'));
		return _.get(response, 'result.data', response);
	}
});
