export default [
	{
		trigger: 'click',
		description: 'Click query list search',
		selector: '.pz-query-list input[type="search"]',
		event: {
			action: 'Click',
			category: 'Query List',
			label: 'Search'
		}
	},
	{
		trigger: 'click',
		description: 'Query list accordions',
		selector: '.pz-query-list [data-toggle="collapse"]',
		event (e) {
			return {
				action: e.target.classList.contains('collapsed') ? 'Collapse' : 'Expand',
				category: 'Query List',
				label: e.target.textContent.trim()
			};
		}
	},
	{
		trigger: 'click',
		description: 'Download items',
		selector: '#pz-download-insight-dialog a',
		event (e) {
			return {
				action: 'Download',
				category: 'Insights',
				label: e.target.textContent.trim()
			};
		}
	},
	{
		trigger: 'click',
		description: 'Download items',
		selector: '.pz-insights .insight-actions button',
		event (e) {
			return {
				action: 'Action',
				category: 'Insights',
				label: e.target.textContent.trim() || 'Open'
			};
		}
	},
	{
		trigger: 'click',
		description: 'Create Query',
		selector: '#create-query-buttons .btn',
		event (e) {
			return {
				action: 'Create',
				category: 'Query',
				label: e.currentTarget.dataset.originalTitle
			};
		}
	},
	{
		trigger: 'mouseup',
		description: 'Run Query Empty view',
		selector: 'button#run-button-empty-view',
		event: {
			action: 'Run',
			category: 'Query',
			label: 'Empty view'
		}
	},
	{
		trigger: 'mouseup',
		description: 'Page header actions',
		selector: '#query-view-header .awi-action-list-item',
		event (e) {
			return {
				action: 'Click',
				category: 'Header action',
				label: e.currentTarget.dataset.action
			};
		}
	},
	{
		trigger: 'click',
		description: 'Results table actions',
		selector: '.pz-results-table-wrapper .awi-action-list-item',
		event (e) {
			return {
				action: 'Click',
				category: 'Results table action',
				label: e.currentTarget.dataset.action
			};
		}
	},
	{
		trigger: 'click',
		description: 'Share query buttons',
		selector: '#share-query-dialog .alpaca-form-buttons-container button',
		event (e) {
			return {
				action: 'Click',
				category: 'Share Query dialog',
				label: e.currentTarget.dataset.key
			};
		}
	},
	{
		trigger: 'click',
		description: 'Download query buttons',
		selector: '#download-dialog .modal-footer button',
		event (e) {
			return {
				action: 'Click',
				category: 'Download Query dialog',
				label: e.currentTarget.textContent
			};
		}
	},
	{
		trigger: 'click',
		description: 'Results tabs',
		selector: '.pz-results-section .nav-item',
		event (e) {
			return {
				action: 'Click',
				category: 'Results tab',
				label: e.currentTarget.textContent.trim()
			};
		}
	},

	// Insights

	{
		trigger: 'click',
		description: 'Create Insight',
		selector: '.pz-insights-panel button[title="Create Insight"]',
		event: {
			action: 'Click',
			category: 'Create Insight'
		}
	},
	{
		// click doesn't work, mouseup works
		trigger: 'mouseup',
		description: 'Start create insight',
		selector: '.pz-create-insight-dialog .type-selector-container button',
		event (e) {
			return {
				action: 'Create',
				category: 'Insights',
				label: e.currentTarget.textContent.trim()
			};
		}
	},

	// SQL
	{
		trigger: 'click',
		description: 'Preview in SQL',
		selector: '.pz-wizard-wrapper button[name="togglePreview"]',
		event (e) {
			return {
				action: 'Click',
				category: 'SQL',
				label: 'Preview'
			};
		}
	},
	{
		trigger: 'click',
		description: 'Start Convert to SQL',
		selector: '.sql-preview button[name="convertToSQL"]',
		event (e) {
			return {
				action: 'Click',
				category: 'SQL',
				label: 'Start Convert'
			};
		}
	},
	// Copy not working?
	{
		trigger: 'click',
		description: 'Convert/Copy to SQL',
		selector: '.pz-switch-dialog .option-list div.card',
		event (e) {
			const label = e.currentTarget.getAttribute('name');
			return {
				action: 'Convert',
				category: 'SQL',
				label
			};
		}
	},
	{
		trigger: 'click',
		description: 'NLP vote',
		selector: '.pz-nlp-feedback button',
		event (e) {
			return {
				action: 'Vote',
				category: 'NLP Results',
				label: e.currentTarget.name
			};
		}
	},
	{
		trigger: 'click',
		description: 'NLP feedback',
		selector: '#thumbs-down-feedback button',
		event (e) {
			const label = e.currentTarget.dataset.key === 'submit' ? 'Submit' : 'Close';
			return {
				action: 'Give Feedback',
				category: 'NLP Results',
				label
			};
		}
	},
	{
		description: 'Answer button clicked',
		selector: '#nlpSearchSubmit',
		event: {
			action: 'Click',
			category: 'Answer button'
		}
	},

	// Column Selector
	{
		trigger: 'click',
		description: 'Column Selector expand/collapse',
		selector: '.pz-column-selector-list a[data-toggle="collapse"]',
		event (e) {
			const action = e.currentTarget.getAttribute('aria-expanded') === 'true' ? 'Expand' : 'Collapse';
			const label = e.currentTarget.getAttribute('aria-controls');
			return {
				category: 'Column Selector',
				action,
				label
			};
		}
	},
	{
		trigger: 'click',
		description: 'Column Selector show column details',
		selector: '.pz-data-source-tree .column-detail-btn:not([aria-expanded="true"])',
		event (e) {
			return {
				category: 'Data Dictionary',
				action: 'Show',
				label: 'Column Description'
			};
		}
	},

	{
		trigger: 'click',
		description: 'Change Tool Sidebar Active Tab',
		selector: '#pz-tool-sidebar-tablist a',
		event (e) {
			const label = e.currentTarget.getAttribute('data-item');
			return {
				category: 'Tool Sidebar',
				action: 'Show',
				label
			};
		}
	},

	// Query Builder
	{
		trigger: 'click',
		description: 'Add condition',
		selector: '.pz-filter-list button[name="addFilter"]',
		event (e) {
			return {
				category: 'Query Builder',
				action: 'Add',
				label: 'Condition'
			};
		}
	},
	{
		trigger: 'click',
		description: 'Add condition group',
		selector: '.pz-condition-group-list button[name="addConditionGroup"]',
		event (e) {
			return {
				category: 'Query Builder',
				action: 'Add',
				label: 'Condition Group'
			};
		}
	},
	{
		trigger: 'change',
		description: 'Select any/all on root table',
		selector: '.pz-table-selector select[name="table_operator"]',
		event (e) {
			const label = e.currentTarget.value;
			return {
				category: 'Query Builder',
				action: 'Change table operator',
				label
			};
		}
	},
	{
		trigger: 'change',
		description: 'Select any/all on condition group',
		selector: '.pz-condition-group select[name="table_operator"]',
		event (e) {
			const label = e.currentTarget.value;
			return {
				category: 'Query Builder',
				action: 'Change condition group operator',
				label
			};
		}
	},
	{
		trigger: 'click',
		description: 'Use the Query Wizard',
		selector: '#use-query-wizard-button',
		event (e) {
			return {
				category: 'Query Builder',
				action: 'Click',
				label: 'Use the Query Wizard'
			};
		}
	},
	{
		trigger: 'click',
		description: 'Use the SQL Editor',
		selector: '#use-sql-editor-button',
		event (e) {
			return {
				category: 'Query Builder',
				action: 'Click',
				label: 'Use the SQL Editor'
			};
		}
	}

];
