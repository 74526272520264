<template>
<div :class="['pz-query-card card', active? 'active-link' : null]">
	<div class="card-body">
		<!-- Query Title -->
		<h5 class="card-title">
			<icon
				v-if="useIcons"
				:name="iconName"
				role="presentation"
				custom-class="mr-2" />
			<router-link
				class="stretched-link"
				:to="{name: toPreview? 'Query Catalog Preview' : 'Data Builder', params: {queryid: query.id}}"
				:title="query.name"
				@focus.native="active=true"
				@blur.native="active=false">
				{{ title }}
			</router-link>
		</h5>

		<p class="card-text" style="white-space: pre-line;">
			{{ description }}
		</p>
		<p v-if="showDate" class="text-muted">
			{{ getRecentDate(query) }}
		</p>
	</div>
</div>
</template>

<script>
import { Icon } from 'aunsight-lib-ui';
import _ from 'lodash';

export default {
	name: 'QueryCard',

	components: { Icon },

	props: {
		query: {
			type: Object,
			required: true
		},
		showDate: {
			type: Boolean,
			default: false
		},
		useIcons: {
			type: Boolean,
			default: true
		},
		toPreview: {
			type: Boolean,
			default: false
		}
	},

	data () {
		return {
			// when the link is active, we want to bubble that up for display
			active: false
		};
	},

	computed: {
		iconName () {
			const map = {
				owned: 'user',
				shared: 'users',
				template: 'table'
			};
			return map[this.query.category];
		},

		title () {
			const title = this.query.name;
			return title.length > 40 ? title.substr(0, 40) + '...' : title;
		},

		description () {
			return this.truncatedDescription(this.query.description);
		}
	},

	methods: {
		/**
		 * @param {object} query
		 * @return {string}
		 */
		getRecentDate (query) {
			// start with updated date
			let date = new Date(query.updated_at);

			// switch to last submitted date if more recent
			if (query.last_submitted_by_owner_at) {
				const lsDate = new Date(query.last_submitted_by_owner_at);
				if (lsDate > date) {
					date = lsDate;
				}
			}

			return this.formatDate(date);
		},

		/**
		 * @param {string} dateString
		 * @return {string}
		 */
		formatDate (dateString) {
			const formatter = new Intl.DateTimeFormat('en-US', {
				year: 'numeric',
				month: 'short',
				day: 'numeric'
			});

			const date = new Date(dateString);
			return formatter.format(date);
		},

		/**
		 * @param {string} desc
		 * @return {string}
		 */
		truncatedDescription (desc) {
			// whether to add ellipsis
			let wasTruncated = false;

			if (!desc) return '';

			if (desc.length > 100) {
				desc = desc.slice(0, 100);
				wasTruncated = true;
			}
			// if there are more than 5 lines, find 5th line break and slice there
			const newlineReg = /(\n|\r\n)/g;
			if (_.get(desc.match(newlineReg), 'length', 0) > 4) {
				wasTruncated = true;
				const matches = desc.matchAll(newlineReg);
				// skip first 4 linebreaks
				_.times(4, () => matches.next());
				// get index of 5th linebreak
				const indexOf5th = matches.next().value.index;

				desc = desc.slice(0, indexOf5th);
			}

			if (wasTruncated) desc += '…';
			return desc;
		}
	}
};
</script>

<style lang="scss">
@import 'app_variables';

.pz-query-card {
	&.active-link,
	&:hover,
	&:focus {
		// use outline, not border so it doesn't shift contents
		outline: 3px solid $secondary;
		// shift normal border color too, so it blends
		border-color: $secondary;
	}

	h5 {
		font-size: inherit;
		font-weight: bold;

		// No line breaks, hide any extra with ellipsis
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		.stretched-link {
			text-decoration: none;
			color: inherit;

			// get rid of browser outline since we're making our own
			&:focus-visible {
				outline: 0;
			}
		}
	}
}
</style>
