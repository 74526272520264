import _ from 'lodash';

// currently these are the only entities that are linkable or need formatting.
const entities = {
	User: {
		formatValue: function (data) {
			const name = _.compact([data.firstname, data.lastname]).join(' ');
			return name;
		}
	},
	StoredQuery: {
		route: function (data, context) {
			// fallback for old notifications without scope or config
			if (!context.config) {
				return {
					name: 'redirect',
					params: {
						organizationId: context.organization,
						projectId: context.project,
						pathMatch: 'querybuilder/' + data.id
					}
				};
			}
			return {
				name: 'Query Builder',
				params: {
					configId: context.config,
					scopeId: context.scope,
					queryid: data.id
				}
			};
		}
	}
};

/**
 * Get name and url of entity from data
 * @param  {object} entity
 * @param  {string} entity.id
 * @param  {string} entity.type
 * @param  {Object} context
 * @return {Object} object with name and route
 */
function resolveEntity (entity, context) {
	const resolved = {};
	if (_.has(entities, [entity.type, 'formatValue'])) {
		resolved.name = entities[entity.type].formatValue(entity);
	}
	else resolved.name = entity.name;

	if (_.has(entities, [entity.type, 'route'])) {
		resolved.route = entities[entity.type].route(entity, context);
	}

	return resolved;
}

export default resolveEntity;
