<template>
<div class="card pz-results-table-wrapper">
	<div class="card-header bg-primary text-white navbar py-2 px-3">
		<span v-if="collectionCount" class="pz-result-row-count navbar-text mr-auto">
			Row Count: {{ collectionCount }}
		</span>

		<span v-if="!!totalColumns" class="pz-result-column-selection navbar-text">
			{{ selectedColumnCount }} / {{ totalColumns }} fields shown
		</span>

		<!-- actions -->
		<action-list
			class="d-inline ml-3"
			:items="actions"
			:navbar-align-right="false"
			layout="navbar"
			@action-clicked="onActionClick" />
	</div>
	<div class="card-body position-relative overflow-hidden p-0">
		<!-- if there is no results, show a message instead (datatables messages doesn't work well here) -->
		<div v-if="isResultsEmpty" class="pz-empty-results text-muted">
			<p>This query did not return any results.</p>
		</div>

		<mn-component-view
			v-show="!isResultsEmpty"
			class="pz-results-table"
			:view="TableView"
			:options="tableOptions"
			:watched-props="watchedProps"
			:prop-handlers="propHandlers" />
	</div>
</div>
</template>

<script>

import { ActionList } from 'aunsight-lib-ui';
import ResultsTable from 'aunsight-webapp/src/js/modules/GuidedQueryBrowser/ResultsTable';
import _ from 'lodash';

import MnComponentView from '@/components/MnWrapper.vue';

import formatURL from './util/formatURL';

const MyTable = ResultsTable.extend({
	initialize: function () {
		this.on('table:rendered', this.onTableRendered);
	},
	tableOptions: function () {
		return {
			// Note: currently not showing full size of list (e.g. `showing 1-25 of 10,323`)
			// reconfigure template so that the length menu is after the paginator
			dom: "<'row table-container'<'col-sm-12'tr>>" +
						"<'text-center paging-container'<'d-inline-block mx-2'p><'d-inline-block mx-2'l>>",

			...this._fetchTableOptions(),
			scrollX: true,
			scrollY: this._getTableHeight()
		};
	},

	getCellFormatter (column, schema) {
		if (schema && schema.format === 'uri') {
			return _.flow(_.property(column), formatURL);
		}

		else return ResultsTable.prototype.getCellFormatter.call(this, column);
	},

	getRecordCountStats () {
		return {
			recordsTotal: this.collection.getTotal(),
			recordsFiltered: this.collection.getTotal()
		};
	},

	_HEIGHT_OFFSET: 100,

	// opt out of this disable message
	showDisabledMessage: function () {},

	onTableRendered: function () {
		setTimeout(() => {
			this.dtable.columns.adjust().draw();
			this.adjustTableSize();
		}, 1000);

		if (this.options.startDisabled) this.disableInteraction();
	}
});

export default {
	name: 'ResultsTableWrapper',
	components: {
		ActionList,
		MnComponentView
	},

	props: {
		collection: { type: Object, required: true },
		isOutdated: Boolean,
		totalColumns: { type: Number, default: undefined },
		isWizardMode: Boolean
	},
	data () {
		return {
			TableView: MyTable,
			propHandlers: {
				isOutdated: function (view, val) {
					if (val) view.disableInteraction();
					else view.enableInteraction();
				}
			},
			// count of items in collection. vue has a hard time watching this
			collectionCount: undefined,
			selectedColumnCount: undefined
		};
	},

	computed: {
		actions () {
			const actions = [
				{
					id: 'openColumnDialog',
					description: 'Select Fields',
					buttonClass: 'btn-outline-light',
					iconName: 'columns',
					showIf: () => this.isWizardMode,
					isDisabled: this.isOutdated
				},
				{
					id: 'download',
					description: 'Download',
					buttonClass: 'btn-outline-light',
					iconName: 'download',
					isDisabled: this.isOutdated
				}
			];
			const items = _.filter(actions, a => a.showIf ? a.showIf(this.isWizardMode) : true);
			return items;
		},
		tableOptions () {
			return {
				collection: this.collection,
				// so it can disable it when it opens up the first time
				startDisabled: this.isOutdated
			};
		},
		watchedProps () {
			return {
				isOutdated: this.isOutdated
			};
		},
		isResultsEmpty () {
			return !(this.collectionCount > 0);
		}
	},

	mounted: function () {
		const vm = this;
		this.collectionCount =	_.get(this, 'collection.metadata.filtered');
		this.selectedColumnCount = _.get(this.collection, 'schema.propertiesOrder.length', 0);

		// when data is updated, either from table controls or running, update count
		this.collection.on('reset', () => {
			vm.collectionCount = vm.collection.metadata.filtered;
			vm.selectedColumnCount = _.get(vm.collection, 'schema.propertiesOrder.length', 0);
		});
	},

	methods: {
		onActionClick (id) {
			// bug in lib-ui where disabled button doesn't _actually_ get disabled
			if (this.isOutdated) return;

			if (id === 'openColumnDialog') this.openColumnDialog();
			else if (id === 'download') this.download();
		},

		download () {
			this.$emit('download');
		},
		openColumnDialog () {
			this.$emit('openColumnDialog');
		}
	}
};
</script>

<style lang="scss">
.pz-results-table-wrapper {
	.pz-empty-results {
		text-align: center;
		margin-top: 2.5rem;
	}

	table.dataTable {
		// get rid of pesky top margin.
		// (original uses !important so has to be here too.)
		/* stylelint-disable-next-line declaration-no-important */
		margin-top: 0 !important;
	}

	// Since not using scrollX / scroll Y, set size control here:
	.dataTables_wrapper {
		display: flex;
		flex-flow: column nowrap;
		overflow: hidden;
		height: 100%;
		width: 100%;
		position: absolute;

		.table-container {
			flex: 1 1 auto;
		}

		.paging-container {
			flex: 0 0 auto;
		}
	}
}
</style>
