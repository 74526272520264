<template>
<viz-grid
	:definitions="definitions"
	:attributes="attributes"
	:grid-layout="gridLayout"
	class="pz-insights">
	<template #actions="{ definition }">
		<action-menu
			v-if="!isOutdated"
			:is-read-only="isReadOnly"
			@action-clicked="actionClicked($event, definition)" />
	</template>
	<template #body="{definition}">
		<!-- add loading or error message -->
		<div v-if="isLoading(definition.id)" class="text-center">
			<i class="fa fa-spin fa-spinner fa-5x" />
		</div>
		<div v-else-if="isError(definition.id)" class="text-center">
			<error-message :errors="[getError(definition.id)]" title="Insight Error" />
		</div>
		<div v-else-if="isEmpty(definition.id)" class="text-center">
			The query returned no results
		</div>
		<div v-else-if="isNoVal(definition.id)" class="text-center">
			There are no results for your selection
		</div>
	</template>
</viz-grid>
</template>

<script>
import { ErrorMessage } from 'aunsight-lib-ui';
import getXHRError from 'aunsight-webapp/src/js/util/getXHRError';
import _ from 'lodash';

import ActionMenu from './InsightActionMenu.vue';
import insightRenderer from './insightRenderer';

export default {
	components: {
		VizGrid: () => {
			return import(/* webpackChunkName: "auviz" */ 'aunsight-lib-auviz-js')
				.then(auviz => auviz.VizGrid);
		},
		ActionMenu,
		ErrorMessage
	},
	props: {
		insights: { type: Array, required: true },
		queryResults: { type: Object, default: () => ({}) },
		datamart: { type: Object, required: true },
		selectedTable: { type: String, required: true },
		isOutdated: { type: Boolean, default: false },
		insightStates: { type: Object, default: () => ({}) },
		isReadOnly: { type: Boolean, default: false }
	},
	data () {
		return {
			definitions: [],
			gridLayout: { options: { fillWidth: false } }
		};
	},
	computed: {
		attributes () {
			return this.parseQueries(this.queryResults);
		}
	},
	watch: {
		// We may be able to make this computed instead
		insights: {
			deep: true,
			handler: 'createInsights'
		}
	},
	mounted () {
		this.createInsights();
	},
	methods: {
		isLoading (id) {
			return this.insightStates[id] && this.insightStates[id].state === 'loading';
		},
		isError (id) {
			return this.insightStates[id] && this.insightStates[id].state === 'error';
		},
		isEmpty (id) {
			return !!_.get(this.queryResults[id], 'empty');
		},
		isNoVal (id) {
			return !!_.get(this.queryResults[id], 'noVal');
		},

		getError (id) {
			const err = this.insightStates[id].error;
			return _.isFunction(err.state) ? getXHRError(err) : err;
		},
		parseQueries () {
			const parsed = {};
			_.forEach(this.queryResults, (qr, id) => {
				Object.keys(qr).forEach(key => {
					parsed[`${id}__${key}`] = qr[key];
				});
			});
			return parsed;
		},
		createInsights () {
			const res = this.insights.map(insight => {
				return insightRenderer({ insight: insight, datamart: this.datamart, selectedTable: this.selectedTable });
			});
			this.definitions = res;
		},
		actionClicked (action, definition) {
			const insight = this.definitionToInsight(definition);
			this.$emit(action, insight);
		},
		definitionToInsight (definition) {
			return this.insights.find(insight => insight.id === definition.id);
		}
	}
};
</script>
<style lang="scss">
.pz-insights {
	// get rid of background on card header
	.card-header {
		background-color: transparent;
	}

	.awi-action-insightActions.dropdown-toggle::after {
		display: none;
	}
}
</style>
