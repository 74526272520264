<template>
<login-component
	tool="daybreak-webapp"
	:api-path="apiPath"
	:redirect-uri="redirectUri"
	:initial-step="initialStep"
	:params="params"
	reset-base-url="https://daybreak.aunsight.com"
	reset-template="daybreak"
	@update="updateStep">
	<template #logo>
		<img
			src="../assets/logo-fw.svg"
			alt="Aunalytics Daybreak Logo"
			height="60">
	</template>
	<template #links>
		<button
			v-popover.html="requirementsGetter"
			class="btn btn-link"
			title="System Requirements">
			System Requirements
		</button>
		<div ref="requirements" class="d-none">
			<p>
				<strong>OS</strong><br>
				Windows 7 or higher<br>
				macOS 10.12 Sierra or higher
			</p>
			<p>
				<strong>Browsers</strong><br>
				Browser must support JavaScript and Style sheets.
			</p>
			<p>
				<strong>Recommended Requirements</strong><br>
				Latest browser version<br>
				8GB RAM
			</p>
		</div>
	</template>
	<template v-if="hasSlotContent" #default>
		<slot />
	</template>
	<template #card>
		<welcome-card>
			<template #message>
				“Daybreak has broken down our data silos and given stakeholders across the organization access to insights they have never had before.”
			</template>
			<template #footer>
				<div class="author d-flex flex-row align-items-center">
					<img
						srcset="../assets/testimonial-photo-50w.jpeg,
									../assets/testimonial-photo-75w.jpeg 1.5x,
									../assets/testimonial-photo-100w.jpeg 2x"
						src="../assets/testimonial-photo-50w.jpeg"
						alt="Executive Vice President of Mid-Sized Bank"
						class="ml-3 mr-4 rounded-circle">
					<div>
						<strong>Executive Vice President</strong>
						<br>
						<strong>Chief Information Officer</strong>
						<br>
						Mid-Sized Bank
					</div>
				</div>
			</template>
		</welcome-card>
	</template>
</login-component>
</template>

<script>
import { Login as LoginComponent, WelcomeCard } from 'aunsight-lib-login';
import { Popover as popover } from 'aunsight-lib-ui';

import app from '@/webapp';

export default {
	name: 'Login',
	components: { LoginComponent, WelcomeCard },
	directives: { popover },
	data () {
		return {
			initialStep: undefined,
			params: {},
			apiPath: app.apiPath,
			redirectUri: undefined
		};
	},
	computed: {
		hasSlotContent () {
			return !!this.$slots.default;
		}
	},
	created () {
		this.initialStep = 'lookup';
		if (this.$route.name === 'LoginReset') {
			this.initialStep = 'reset';
			this.params = {
				key: this.$route.query.key || '',
				email: this.$route.query.user || ''
			};
		}

		if (this.$route.name === 'LoginForgot') {
			this.initialStep = 'forgot';
		}

		if (this.$route.name === 'LoginOIDC') {
			this.initialStep = 'login-oidc';
		}
		if (this.$route.name === 'AcceptInvitation') {
			this.params = { inviteKey: this.$route.query.key, invitation: this.$route.query.invitation };
			this.initialStep = 'accept-invitation';
		}
		this.redirectUri = location.origin;
	},
	methods: {
		requirementsGetter () {
			return this.$refs.requirements.innerHTML;
		},
		updateStep ({ step, data }) {
			const lookup = {
				forgot: 'LoginForgot',
				reset: 'LoginReset',
				'login-local': 'LoginLocal',
				'login-success': 'root',
				lookup: 'Lookup',
				'login-oidc': 'LoginOIDC',
				'accept-invitation': 'AcceptInvitation'
			};
			if (step === 'login-success') {
				app.auth.initSessionWithToken(data.token).then(() => {
					if (this.$route.query && this.$route.query.redirect) {
						this.$router.push(this.$route.query.redirect);
					}
					else {
						this.$router.push({ name: 'root' });
					}
					data.complete();
				});
			}
			else {
				this.$router.push({ name: lookup[step], query: this.$route.query });
			}
		}
	}
};
</script>
