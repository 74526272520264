<template>
<div class="pz-filter-val-field pz-filter-val-list-field dropdown">
	<button
		:id="'list-field-label-' + id"
		type="button"
		data-toggle="dropdown"
		class="form-control dropdown-toggle text-left"
		:title="!isEmpty? value.join(', ') : 'Enter values...'"
		aria-haspopup="dialog"
		aria-expanded="false">
		<div :class="'list-button-label' + isEmpty? 'text-muted' : '' ">
			{{ buttonLabel }}
		</div>
	</button>

	<div class="dropdown-menu" :aria-labelledby="'list-field-label-' + id">
		<form class="px-2">
			<div class="form-group mb-0">
				<input
					:id="'list-field-' + id"
					ref="tokenfield"
					type="text"
					class="form-control"
					:aria-describedby="'list-field-helptext' + id">
				<p :id="'list-field-helptext' + id" class="form-text text-muted mb-0">
					<small>Enter comma separated items</small>
				</p>
			</div>
		</form>
	</div>
</div>
</template>

<script>
import 'bootstrap-tokenfield';

import $ from 'jquery';
import _ from 'lodash';

// max items to show in the label
const LABEL_MAX_ITEMS = 2;
// max characters for items in the label before ellipsis
const LABEL_MAX_CHARS = 12;

export default {

	props: {
		id: {
			type: String,
			required: true
		},
		disabled: {
			type: Boolean,
			default: false
		},
		value: {
			type: Array,
			default: () => ([])
		},
		// not actually needed here but to prevent it from going to dom
		isMultiple: {
			type: Boolean,
			default: true
		}
	},
	data () {
		return {
			// whether it has an active tokenfield instance
			hasField: false
		};
	},

	computed: {
		// what to show as summary
		buttonLabel () {
			if (this.isEmpty) return 'Enter values...';

			const len = _.get(this.value, 'length');

			if (len > LABEL_MAX_ITEMS) return `${len} items selected`;

			// if showing values, make sure they're not too long
			else {
				return this.value.map(val => {
					if (val.length > LABEL_MAX_CHARS) return val.slice(0, LABEL_MAX_CHARS) + '...';
					else return val;
				}).join(', ');
			}
		},

		isEmpty () {
			return !!_.isEmpty(this.value);
		}
	},

	watch: {
		value (val) {
			if (this.hasField && !_.isEqual(val, this.getFieldValue())) {
				$(this.$refs.tokenfield).tokenfield('setTokens', val);
			}
		},

		disabled (val) {
			if (!this.hasField) return;

			const action = val ? 'disable' : 'enable';
			$(this.$refs.tokenfield).tokenfield(action);
		}
	},

	mounted () {
		this.createField();
	},

	beforeDestroy () {
		if (this.hasField) {
			this.destroyField();
		}
	},

	methods: {
		update () {
			this.$emit('change', 'value', this.getFieldValue());
		},

		destroyField () {
			this.hasField = false;
			$(this.$refs.tokenfield).tokenfield('destroy');
			$(this.$refs.tokenfield).off();
		},

		createField () {
			this.hasField = true;
			$(this.$refs.tokenfield).tokenfield({
				createTokensOnBlur: true,
				tokens: this.value || []
			});
			$(this.$refs.tokenfield).on('tokenfield:createdtoken', this.update.bind(this));
			$(this.$refs.tokenfield).on('tokenfield:removedtoken', this.update.bind(this));

			if (this.disabled) $(this.$refs.tokenfield).tokenfield('disable');
		},

		// get the value from token widget
		getFieldValue () {
			return _.map($(this.$refs.tokenfield).tokenfield('getTokens'), 'value');
		}
	}
};
</script>

<style lang="scss">
.pz-filter-val-list-field {
	.list-button-label {
		display: inline-block;
		// give some room for the caret
		width: calc(100% - 0.7rem);
		max-width: calc(100% - 0.7rem);
		// if it has to cut off, use ellipsis
		text-overflow: ellipsis;
		overflow: hidden;
		// make sure caret is aligned
		vertical-align: middle;
	}

	.dropdown-menu {
		// constrain the height of the dropdown.
		max-height: 35rem;
		overflow: auto;
		min-width: 21rem;
	}

	.form-group > .tokenfield {
		// make sure it can grow to accommodate lots of chips
		height: auto;
	}
}
</style>
