<template>
<div id="app" class="vh-100 daybreak-webapp">
	<router-view />
	<MobileWarningModal class="mobile-warning" />
</div>
</template>
<script>
import { MobileWarningModal } from 'aunsight-lib-ui';
import $ from 'jquery';

export default {
	name: 'Daybreak',
	components: { MobileWarningModal },
	data () {
		return {
			version: DAYBREAK_PACKAGE_VERSION
		};
	},
	watch: {
		$route: {
			immediate: true,
			handler (to, from) {
				this.clearModals();
				document.title = to.meta.title || 'Daybreak';
			}
		}
	},
	methods: {
		clearModals () {
			if ($('.modal.show').length) {
				$('.modal').modal('hide');
			}
			if ($('.modal-backdrop').length) {
				$('.modal-backdrop').remove();
				$('body').removeClass('modal-open');
			}
		}
	}
};
</script>
<style lang="scss">
@import 'sass/app.scss';

@media screen and (min-width: 992px) {
	.mobile-warning {
		/* stylelint-disable-next-line declaration-no-important */
		display: none !important;
	}
}
</style>
