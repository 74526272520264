<template>
<div class="pz-filter-sub-item">
	<!-- this should be invisible when range is not special -->
	<div v-if="value && value.type === 'special'" class="form-row date-relative-special">
		<div class="col-md-5">
			<select
				class="form-control"
				autocomplete="off"
				name="scope"
				:value="tempVal.scope"
				required
				@input="update">
				<option
					value=""
					label="e.g., The day"
					disabled="true" />
				<option
					v-for="opt in unitEnum"
					:key="opt"
					:value="opt"
					:label="`The ${opt}`" />
			</select>
		</div>

		<div class="col-md-2">
			<input
				type="number"
				class="form-control"
				name="offset"
				min="0"
				step="1"
				placeholder="e.g., 3"
				required
				:value="tempVal.offset"
				@input="update">
		</div>

		<div class="col-md-5">
			<select
				class="form-control"
				autocomplete="off"
				name="offset_scope"
				required
				:value="tempVal.offset_scope"
				@input="update">
				<option
					value=""
					label="e.g., days ago"
					disabled="true" />
				<option
					v-for="(opt, i) in unitEnum"
					:key="opt + 'ago'"
					:hidden="i < unitEnum.indexOf(tempVal.scope)"
					:value="opt"
					:label="`${opt}(s) ago`" />
				<option
					v-for="(opt, i) in unitEnum"
					:key="opt + 'from now'"
					:value="'future_' + opt"
					:hidden="i < unitEnum.indexOf(tempVal.scope)"
					:label="`${opt}(s) from now`" />
			</select>
		</div>
	</div>

	<!-- Show the resolved date range -->
	<div v-if="resolvedRange" class="text-right date-relative-resolved">
		<template v-if="resolvedRange.length > 1">
			{{ resolvedRange[0] }} - {{ resolvedRange[1] }}
		</template>
		<!-- for single day, show only one date -->
		<template v-else>
			{{ resolvedRange[0] }}
		</template>
	</div>
</div>
</template>

<script>
/******************************************************************************
	This view provides the extra real estate needed for relative ranges.

	For both predefined and custom ranges, it will show the resolved dates so that
	users can validate that the range is doing what they need.

	For custom (special) ranges, it has the extra form fields needed that won't
	fit in normal slot.

	Maybe this should be split into two separate views?
 *****************************************************************************/

import resolveOnRelative from 'aunsight-lib-query-js/lib/util/resolve-on-relative.js';
import resolveWithinRelative from 'aunsight-lib-query-js/lib/util/resolve-within-relative.js';
import _ from 'lodash';

const UNIT_ENUM = ['day', 'week', 'month', 'quarter', 'year'];

export default {
	name: 'FilterValueRelativeRangeSubField',

	props: {
		value: {
			type: Object,
			default: undefined
		}
	},

	data () {
		return {
			unitEnum: UNIT_ENUM,
			tempVal: {}
		};
	},

	computed: {
		isComplete () {
			const type = _.get(this.value, 'type');

			// if not special and a value is present, it is complete
			if (type && type !== 'special') return true;

			else if (this.tempVal.scope &&
					_.isNumber(this.tempVal.offset) &&
					this.tempVal.offset_scope) {
				return true;
			}
			else return false;
		},

		resolvedRange () {
			if (!this.isComplete) return;

			return this.getResolvedDates(this.value);
		}
	},

	watch: {
		value (value) {
			// clear value when type is no longer 'special'
			if (value.type !== 'special') {
				this.tempVal = {};
			}
			else if (value && value.type === 'special') {
				this.tempVal = this.convertToTemp(value);
			}
		}
	},

	created: function () {
		if (this.value && this.value.type === 'special') {
			this.tempVal = this.convertToTemp(this.value);
		}
	},

	methods: {
		update (e) {
			const name = e.target.name;
			let val = e.target.value;

			if (name === 'offset' && val) val = Number(val);

			this.$set(this.tempVal, name, val);

			// when changing scope, if offset_scope is smaller than scope, clear it
			if (name === 'scope' && this.tempVal.offset_scope) {
				// offset_scope index
				const osi = UNIT_ENUM.indexOf(_.replace(this.tempVal.offset_scope, 'future_', ''));
				if (osi !== -1 && osi < UNIT_ENUM.indexOf(val)) {
					this.$set(this.tempVal, 'offset_scope', null);
				}
			}

			if (this.isComplete) {
				this.$emit('change', 'value', { type: 'special', ...this.convertToValue(this.tempVal) });
			}
		},

		// convert from format needed on form to format needed elsewhere
		convertToTemp (value) {
			const temp = _.pick(value, ['scope', 'offset_scope', 'offset']);

			if (!temp || _.isEmpty(temp)) return {};
			if (temp.offset < 0) {
				temp.offset = -temp.offset;
			}
			else {
				temp.offset_scope = 'future_' + temp.offset_scope;
			}

			return temp;
		},

		// convert from format needed elsewhere to format needed in form
		convertToValue (temp) {
			if (!this.isComplete) return;

			const val = _.clone(temp);

			// if its future offset scope, chop off the "future_" prefix,
			// else make offset negative since its in the past.
			if (val.offset_scope.startsWith('future_')) {
				val.offset_scope = val.offset_scope.replace('future_', '');
			}
			else {
				val.offset = -val.offset;
			}

			return val;
		},

		getResolvedDates (value) {
			let dates;

			if (value.type === 'special') {
				dates = resolveOnRelative(value.scope, value.offset, value.offset_scope);
			}
			else {
				dates = resolveWithinRelative(value.type);
			}
			const formatter = new Intl.DateTimeFormat('en-US', {
				weekday: 'short',
				year: 'numeric',
				month: '2-digit',
				day: '2-digit'
			});

			dates = dates.map(formatter.format);

			if (dates[0] === dates[1]) dates.pop();

			return dates;
		}
	}
};

</script>

<style lang="scss">
@import 'app_variables';

.pz-filter-sub-item {
	// make the placeholders be gray
	select:invalid,
	::placeholder {
		color: $disabled-color;
	}
}
</style>
