import $ from 'jquery';

function mockNLP (query) {
	const structuredQuery = {
		table: 'Customer',
		conditions: [
			{
				column: 'Customer_Age',
				operator: 'eq',
				value: 44
			}
		]
	};
	// add invalid val for testing.
	if (/gimme_invalids/.test(query)) {
		structuredQuery.conditions.push({
			operator: 'in',
			values: ['NON EXISTANT', 'BlAH'],
			column: 'NextBestProduct'
		});
	}
	else if (/gimme_invalid/.test(query)) {
		structuredQuery.conditions.push({
			operator: 'eq',
			value: 'NON EXISTANT',
			column: 'NextBestProduct'
		});
	}

	const insights = [
		{
			id: 'region_count_account',
			query: { aggregate: { type: 'count' }, group: { type: 'categorical', field: 'Account_OriginalBranchRegionName' } },
			title: 'Number of Accounts by Region',
			type: 'column'
		},
		{
			id: 'account_count',
			query: { aggregate: { type: 'count' } },
			title: 'Number of Accounts',
			type: 'summary'
		},
		{
			id: 'account_status',
			query: { group: { typ: 'categorical', field: 'Account_Status' }, aggregate: { type: 'count' } },
			title: 'Accounts by Current Status',
			type: 'donut'
		},
		{
			id: 'product_type',
			query: { aggregate: { type: 'count' }, group: { type: 'categorical', field: 'Account_ProductType' } },
			title: 'Accounts by Product Type',
			type: 'column'
		},
		{
			id: 'open_date',
			query: { aggregate: { type: 'count' }, group: { type: 'date-time', field: 'Account_OpenDate', group_by: 'month' } },
			title: 'Number of Accounts by Open Date',
			type: 'line'
		}
	];

	const promise = $.Deferred();

	setTimeout(() => {
		promise.resolve({
			result: {
				request_id: 'asdf',
				structured_query: structuredQuery,
				insights: insights
			}
		});
	}, 500);

	return promise;
}

export default mockNLP;
