import _ from 'lodash';

const getFieldAggResults = () => {
	return {
		schema: {
			type: 'object',
			properties: {
				value: { type: 'number' },
				label: { type: 'string' }
			},
			propertiesOrder: ['value', 'label']
		},
		data: [
			{ label: 'foo', value: _.random(1, 10) },
			{ label: 'bar', value: _.random(1, 10) },
			{ label: '', value: _.random(1, 10) }
		]
	};
};

const getDateAggResults = () => {
	return {
		schema: {
			type: 'object',
			properties: {
				value: { type: 'number' },
				label: { type: 'string' }
			},
			propertiesOrder: ['value', 'label']
		},
		data: [
			{ label: 'January', value: _.random(1, 10) },
			{ label: 'February', value: _.random(5, 15) },
			{ label: 'March', value: _.random(10, 20) }
		]
	};
};

const getSummaryChartResults = () => {
	return {
		schema: {
			type: 'object',
			properties: {
				value: { type: 'number' }
			},
			propertiesOrder: ['value']
		},
		data: [
			{ value: _.random(1, 1234) }
		]

	};
};

export default {
	getSummaryChartResults,
	getFieldAggResults,
	getDateAggResults
};
