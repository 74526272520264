import getXHRError from 'aunsight-webapp/src/js/util/getXHRError';
import _ from 'lodash';

import SavedQueries from '../models/SavedQueries';
// import { set } from 'vue';

/**
 * @classdesc
 * purpose is to cleanly blackbox the backbone collection since it requires a
 * bit of glue code with Vue
 *
 * listents to event updates and maintains state via attributes
 */
class SavedQueryBroker {
	/**
	 *
	 * @param {Object} options
	 * @param {string} options.userId
	 * @param {Object} options.context
	 * @param {string} options.context.config
	 * @param {string} options.context.scope
	 * @param {function} [options.getURL] - if specified, will be set on each item in list
	 * @param {?number} [options.limit=15]
	 * @param {boolean} [options.fetchAll=false]
	 * @param {number} [options.startPage=1]
	 * @param {Object} [options.startFilters] - key/value pairs where key is filter id and value is filter criteria
	 * @param {string} [options.startSearch]
	 * @param {boolean} [options.isMock=false] - if false, you must use setMockData to add your own data
	 * @param {Object[]} [options.startSort]
	 */
	constructor (options) {
		/**
		 * @type {string}
		 */
		this.userId = options.userId;
		/**
		 * @type {Object}
		 */
		this.context = options.context;
		/**
		 * @type {function}
		 */
		if (options.getURL) this.getURL = options.getURL;

		/**
		 * @type {Object[]}
		 */
		this.queries = [];

		/**
		 * whether to to fetch all locally (false) or to refetch as needed (true)
		 * @type {boolean}
		 */
		this.serverSideProcess = !options.fetchAll;

		if (this.serverSideProcess) {
			/**
			 * @type {number}
			 */
			this.limit = options.limit || 15;
			/**
			 * @type {number}
			 * how many pages in current paging setup
			 */
			this.pageCount = undefined;
			/**
			 * @type {number}
			 * what page are you currently on
			 */
			this.currentPageNumber = options.startPage || 1;
			/**
			 * @type {number}
			 * whether there is a next page
			 */
			this.hasNext = undefined;
			/**
			 * @type {boolean}
			 * whether there is a previous page
			 */
			this.hasPrev = undefined;

			this.tags = [];
		}

		/**
		 * if currently fetching, the promise
		 * @type {?JQuery.jqXHR}
		 */
		this.fetchingPromise = null;
		/**
		 * if fetching failed, the error
		 * @type {?Object}
		 */
		this.fetchErr = null;

		/**
		 * @type {boolean}
		 */
		this.isMock = !!options.isMock;

		/**
		 * @type {Function}
		 */
		this.debouncedSync = undefined;

		/**
		 * whether it has ever loaded
		 * @type {boolean}
		 */
		this.hasLoaded = false;

		const startOptions = _.pick(options, ['startSort', 'startFilters', 'startSearch']);

		this._setupMyQueriesCollection(startOptions);
	}

	/**
	 *
	 * @param {Object} options
	 * @param {Object[]} [options.startSort]
	 * @param {Object[]} [options.startFilters]
	 * @param {string} [options.startSearch]
	 */
	_setupMyQueriesCollection ({ startSort, startFilters, startSearch }) {
		// Make a collection for the my saved queries
		const options = { context: this.context, userId: this.userId };

		// if using server side paging establish skip and limit
		if (this.serverSideProcess) {
			if (this.limit) options.limit = this.limit;
			if (this.currentPageNumber) options.skip = (this.currentPageNumber - 1) * this.limit;
		}
		// use max high limit to fetch all queries
		else options.limit = 1000;

		const collection = new SavedQueries([], options);

		// this will setup this._collection.
		// we don't want to set it on this._collection directly or vue will make the collection reactive
		this.getCollection = () => collection;

		const opts = { fetch: false, resetSkip: false };
		if (startSort) {
			this._collection.setSorting(startSort, opts);
		}
		if (startFilters) {
			this._collection.resetFilter(undefined, startFilters, opts);
		}

		if (startSearch) {
			this._collection.addFilter(undefined, 'search', {
				search: {
					fields: ['name', 'description', 'id'],
					term: startSearch
				}
			}, opts);
		}

		// a debounced updater
		// make leading true so its ready even on initial load
		this.debouncedSync = _.debounce(() => this._syncCollection(), 20, { leading: true });

		// when an update event happens, call debounced updater
		this._collection.on('update', this.debouncedSync);
		this._collection.on('add', this.debouncedSync);
		this._collection.on('remove', this.debouncedSync);
		this._collection.on('change', this.debouncedSync);
		this._collection.on('reset', this.debouncedSync);

		this._collection.on('request', (collection, xhr) => {
			// only requests at collection level, no model level fetches
			if (this._collection !== collection) return;

			this.fetchErr = null;
			this.fetchingPromise = xhr;
			xhr.always(() => {
				this.fetchingPromise = null;
			});
			xhr.fail(err => {
				this.fetchErr = getXHRError(err);
			});
			xhr.then(() => {
				this.hasLoaded = true;
			});
		});

		if (this.isMock) return;

		this._collection.fetch()
			// do this in case it's empty in which case no events would fire
			.then(this.debouncedSync);
	}

	get _collection () {
		return this.getCollection();
	}

	_syncCollection () {
		const queries = this._collection.toJSON().map(q => {
			if (this.getURL)	q.url = this.getURL(q.id);
			if (q.category !== 'owned') q.readOnly = true;
			return q;
		});

		this.queries = queries;

		if (this.serverSideProcess) {
			// metadata
			this.pageCount = this._collection.getNumPages();
			this.currentPageNumber = this._collection.getCurrentPageNumber();
			this.hasNext = this._collection.hasNext();
			this.hasPrev = this._collection.hasPrevious();
			this.counts = this._collection.metadata.counts;
			this.tags = this._collection.metadata.tags;
		}
	}

	/**
	 * returns the query object
	 * @param {string} id
	 * @returns {Object}
	 */
	getQuery (id) {
		return _.find(this.queries, { id });
	}

	/**
	 * returns the backone model
	 *
	 * I want to get away from this but there are times when model is still needed such as forms
	 *
	 * only allow to return owned queries since only they can be modified
	 * @param {string} id
	 */
	getQueryModel (id) {
		const model = this._collection.get(id);
		if (!model || model.get('category') !== 'owned') return;
		return model;
	}

	refetch () {
		this._collection.fetch()
			.then(this.debouncedSync);
	}

	/**
	 * refetch a specific query
	 *
	 * use case for shared to make sure they still exist
	 * @param {string} id
	 * @return {Promise}
	 */
	refetchQuery (id) {
		const i = this.queries.indexOf(this.getQuery(id));
		const model = this._collection.get(id);
		return model.fetch().then(
			() => {
				// check to see if still shared with user
				if (_.includes(model.get('shared_with'), this.userId)) {
					// if so, update query in set and set as active query
					const newShared = model.toJSON();
					newShared.url = this.getURL(newShared.id);
					if (i >= 0) { // should always be but just for sanity check
						this.queries[i] = newShared;
					}
				}
				else {
					// if not, add an error to that query
					this.queries[i].error = {
						name: 'unshared', message: 'The query owner removed your access to this query.'
					};
				}
			},
			err => {
				// it doesn't exist, show error
				if (_.get(err, 'responseJSON.name') === 'QueryNotFoundError') {
					this.queries[i].error = {
						name: 'ownerdeleted', message: 'This query has been deleted by the owner.'
					};
				}
			}
		);
	}

	/**
	 * for testing
	 *
	 * @param {Object[]} data - mock data
	 * @param {Object} [meta] - mock metadata, as it would be returned from server
	 * @param {number} [meta.total]
	 * @param {number} [meta.skip]
	 * @param {number} [meta.limit]
	 * @param {Object} [meta.counts]
	 * @param {number} [meta.counts.all]
	 * @param {number} [meta.counts.owned]
	 * @param {number} [meta.counts.shared]
	 * @param {number} [meta.counts.template]
	 */
	setMockData (data, meta) {
		this._collection.setMetadata(meta);
		this._collection.add(data);
		this.hasLoaded = true;
	}

	/**
	 * remove query from the list without deleting it from server
	 * @param {string} id
	 */
	removeQuery (id) {
		const i = _.findIndex(this.queries, { id: id });
		if (i >= 0) this.queries.splice(i, 1);

		this._collection.remove(id);
	}

	/**
	 *
	 * @param {?string} term
	 */
	setSearch (term) {
		if (!term) {
			this.removeFilter('search');
		}
		else {
			const criteria = {
				search: {
					fields: ['name', 'description', 'id'],
					term: term
				}
			};
			this.addFilter('search', criteria);
		}
	}

	setSort (sort, options) {
		this._collection.setSorting(sort, options);
	}

	/**
	 *
	 * @param {string} id - name to be used locally to refer to it
	 * @param {Object} filter - contains the criteria to be sent to server
	 */
	addFilter (id, filter) {
		this._collection.addFilter(undefined, id, filter);
	}

	/**
	 *
	 * @param {string} id
	 */
	removeFilter (id) {
		this._collection.removeFilter(undefined, id);
	}

	nextPage () {
		this._collection.nextPage();
	}

	previousPage () {
		this._collection.previousPage();
	}

	firstPage () {
		this._collection.firstPage();
	}

	lastPage () {
		this._collection.lastPage();
	}
}

export default SavedQueryBroker;
