<template>
<div id="pz-landing-nlp-search" class="card">
	<div class="card-body py-5 text-center d-flex flex-column align-items-center">
		<h2 class="h1 mb-4 text-white ">
			What do you need answered today?
		</h2>
		<form>
			<nlp-input
				id="pz-landing-nlp-input"
				:is-dark="true"
				:context="context"
				:mock-query="false"
				:nlp-auto-url="nlpAutoUrl"
				@submit="submit" />
		</form>
	</div>
</div>
</template>

<script>

import NLPInput from '@/DaybreakTool/NLPInput.vue';
import app from '@/webapp';

export default {
	name: 'LandingNLPWidget',
	components: { nlpInput: NLPInput },
	props: {
		context: {
			type: Object,
			default: undefined
		}
	},
	data () {
		return {
			nlpAutoUrl: app.nlpAutoUrl
		};
	},
	methods: {
		submit (searchString) {
			this.$router.push({ name: 'Query Builder', params: { queryid: 'new' }, query: { searchString } });
		}
	}
};
</script>

<style lang="scss">
#pz-landing-nlp-search {
	background-color: #004cb2;
	background-image: url('../assets/login-illustration-tileable.png');
	background-repeat: repeat;

	#pz-landing-nlp-input {
		width: 30rem;
	}

	> .card-body {
		padding-top: 5rem;
	}
}
</style>
