<template>
<div class="pz-filter-list px-4">
	<filter-item
		v-for="f in value"
		:key="f.id"
		class="pz-conjunction-tagged"
		:filter="f"
		:columns="columns"
		:relations="relations"
		:disabled="disabled"
		@remove="remove"
		@change="change"
		@addRow="addRow" />

	<div>
		<!-- Add condition button -->
		<button
			class="btn btn-link"
			name="addFilter"
			title="Add new filter row"
			:disabled="disabled"
			:value="parentId"
			@click="addRow">
			<span class="fa-stack icon-stack-secondary">
				<i class="bottom-icon fas fa-circle fa-stack-2x" />
				<i class="top-icon fas fa-plus fa-stack-1x" />
			</span>
			<span>Add condition...</span>
		</button>
		<!-- add aggregation button -->
		<button
			v-if="enableAgg && !aggregation"
			class="btn btn-link"
			name="addAggCond"
			title="Add new filter row"
			:disabled="disabled"
			:value="parentId"
			@click="addAggregation">
			<span class="fa-stack icon-stack-secondary">
				<i class="bottom-icon fas fa-circle fa-stack-2x" />
				<i class="top-icon fas fa-plus fa-stack-1x" />
			</span>
			<span>Add aggregation...</span>
		</button>
	</div>

	<!-- the aggregation filter, if present -->
	<filter-aggregation
		v-if="aggregation"
		:filter="aggregation"
		:disabled="disabled"
		@remove="removeAggregation" />
</div>
</template>

<script>

import FilterAggregation from './FilterAggregation.vue';
import FilterItem from './FilterItem.vue';

export default {
	name: 'FilterList',
	components: {
		FilterItem,
		FilterAggregation
	},

	props: {
		// list of available columns
		columns: {
			type: Array,
			required: true
		},
		// List of available relations
		relations: { type: Array, default: undefined },

		// list of filters
		value: {
			type: Array,
			default: () => []
		},

		// if this is a nested filter list, the id will be id of parent filter
		parentId: { type: String, required: true },

		// if true, should allow agg filter UI
		enableAgg: {
			type: Boolean,
			default: false
		},

		aggregation: {
			type: Object,
			default: undefined
		},

		disabled: {
			type: Boolean,
			default: false
		}
	},

	methods: {
		change (id, prop, val) {
			this.$emit('change', id, prop, val);
		},

		remove (id) {
			this.$emit('remove', id);
		},

		addRow (id) {
			this.$emit('addRow', id);
		},

		addAggregation () {
			this.$emit('addAggregation');
		},

		removeAggregation () {
			this.$emit('removeAggregation');
		}
	}
};
</script>
