import 'aunsight-webapp/src/js/AWIComponents/BaseForm/customFields/ImprovedTokenField';

import BaseForm from 'aunsight-webapp/src/js/AWIComponents/BaseForm/BaseForm';
import QueryModel from 'aunsight-webapp/src/js/modules/QueryTool/models/SavedQuery';
import _ from 'lodash';

/**
 * @class EditQueryDetails
 * @memberof module:GuidedQueryBrowser
 * @inner
 * @param {object} options
 * @param {object} options.context
 * @param {object} [options.data]
 */
export default BaseForm.extend({
	modal: true,
	title: 'Edit Details',
	initialize: function (options) {
		this.mergeOptions(options, ['context']);
		if (options.data) this.model.set(options.data);
	},
	formOptions: function () {
		const schema = _(QueryModel.prototype.schema.properties)
			.pick(['name', 'description']).cloneDeep();

		schema.tags = {
			type: 'array',
			title: 'Tags',
			uniqueItems: true,
			maxItems: 5,
			items: {
				type: 'string',
				maxLength: 16,
				minLength: 1,
				// allowed chars are lower case letters numbers and spaces
				// spaces may not be at front or at end and may not repeat.
				pattern: /^[a-z\d]([a-z\d]| (?=[a-z\d]))*$/
			}
		};

		const formOptions = {
			view: {
				parent: 'bootstrap-edit-horizontal',
				messages: {
					stringTooLong: 'Each tag should contain at most 16 numbers, letters or spaces',
					invalidPattern: 'Please use lowercase letters or numbers, optionally separated by non-consecutive spaces'
				}
			},
			schema: {
				type: 'object',
				properties: schema
			},
			options: {
				hideInitValidationError: true,
				fields: {
					description: {
						type: 'textarea'
					},
					tags: {
						tokenfield: {
							createTokensOnBlur: true
						},
						type: 'improvedtoken',
						helpers: [
							'Enter up to 5 comma separated tags.',
							'Each tag may be up to 16 characters long and have lowercase letters and numbers, optionally separated by non-consecutive spaces.'
						],
						events: {
							// if user types upper case, make it lower case
							keyup (event) {
								const value = event.target.value;
								if (/[A-Z]/.test(value)) event.target.value = value.toLowerCase();
							},
							change () {
								const value = this.getValue().split(',');
								// adding helper when 5 tag limit reached
								if (value.length === 5 && this.options.helpers.length === 2) {
									this.options.helpers.push('You\'ve reached the 5 tags limit. Please remove a tag before adding a new one.');
									this.refresh();
								}
								// remove when no longer needed
								else if (value.length < 5 && this.options.helpers.length === 3) {
									this.options.helpers.pop();
									this.refresh();
								}
							}
						}
					}
				},
				form: {
					buttons: {
						cancel: {
							type: 'button',
							label: 'Cancel'
						},
						submit: {
							styles: 'btn btn-primary',
							label: 'Save'
						}
					}
				}
			}
		};
		return formOptions;
	},

	getStartData: function () {
		return this.model.toJSON();
	},

	getFormData: function () {
		const data = this.ui.form.alpaca().getValue();

		// this means user removed all the tags.
		if (!data.tags && !_.isEmpty(this.model.get('tags'))) {
			data.tags = [];
		}

		else if (data.tags) {
			data.tags = _(data.tags.split(/,/))
				.map(_.trim)
				.compact()
				.uniq()
				.value();
		}

		_.defaults(data, { description: '' });
		return data;
	},
	doSave: function () {
		return this.model.save(this.getFormData(), { wait: true });
	},
	progressOptions: {
		errorDisplay: {
			message: 'Unable to edit query details'
		}
	},

	onComplete: function () {
		this.on('destroy', this.triggerMethod.bind(this, 'done', this.model));
		this.triggerMethod('close');
	},

	onCancel: function () {
		this.triggerMethod('close');
	}
});
