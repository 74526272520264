import 'backbone.radio';

import Backbone from 'backbone';
import _ from 'lodash';

/**
		* @classdesc The central app object
		* @class webapp
		* @extends Marionette.Application
		* @param {Object} options - whatever was passed in the url query
		* @author Anne Bischof (anne.bischof@aunalytics.com)
		*/
const app = window.app = {

	/**
	 * stuff pertaining to authorization
	 *
	 * see controllers/AuthManager
	 * @type {AuthManager}
	 */
	auth: {},

	/**
	 * A reference to the api address
	 * @type {string}
	 */
	apiPath: '',

	/**
	 * A reference to the socketio address
	 * @type {string}
	 */
	socketIOPath: '',

	/**
	 * A reference to the nlp autocomplete address
	 * @type {string}
	 */
	nlpAutoUrl: '',

	/**
	 * Headers that should be sent in each call to api
	 * @type {Object}
	 */
	apiHeaders: {
		'x-au-client': 'daybreak-webapp',
		'x-au-client-version': DAYBREAK_PACKAGE_VERSION
	},

	/**
	 * the global app radio.
	 * @type {Backbone.Radio.Channel}
	 * @see https://github.com/marionettejs/backbone.radio
	 */
	radio: Backbone.Radio.channel('app'),

	/**
	 * socket listener
	 *
	 * Is set at runtime because it is an instance
	 * @see http://socket.io/docs/client-api/
	 * @type {SocketIOClient}
	 */
	socket: undefined,

	/**
	 * manager for activity subscriptions
	 * @type {ActivityManager}
	 */
	activity: undefined,

	/**
	 * Global state storage mechanism
	 *
	 * Is set at runtime because it is an instance
	 * @type {Storage}
	 */
	storage: undefined,

	definitions: {},

	/**
	 * Gets the GrapQL common end point URL
	 */
	getGraphQlUrl: function () {
		return app.apiPath + 'query';
	},
	getAunsightUrl: function () {
		if (!_.isEmpty(app.vue.$route.params)) {
			const context = {
				config: app.vue.$route.params.configId,
				scope: app.vue.$route.params.scopeId
			};
			const activeContext = app.vue.$store.getters.getContext(context);
			const domain = app.apiPath.replace('api', 'console');
			return `${domain}app/organization/${activeContext.organization.id}/project/${activeContext.project.id}/daybreak`;
		}
		// if user is in the context selector the link will be undefined
		else return 'Select a workspace to generate Aunsight link';
	}
};
export default app;
