import Login from '@/views/Login';

export default [
	{
		path: '/login',
		component: Login,
		meta: { bypassLogin: true },
		children: [
			{
				path: '',
				name: 'Login',
				meta: { bypassLogin: true, title: 'Log In' }
			},
			{
				path: 'local',
				name: 'LoginLocal',
				meta: { bypassLogin: true, title: 'Log In' }
			},
			{
				path: 'forgot',
				name: 'LoginForgot',
				meta: { bypassLogin: true, title: 'Request Password Reset' }
			},
			{
				path: 'reset',
				name: 'LoginReset',
				meta: { bypassLogin: true, title: 'Reset Password' }
			},
			{
				path: 'login-oidc',
				name: 'LoginOIDC',
				meta: { bypassLogin: true, title: 'Log In' }
			}
		]
	},
	{
		path: '/invitation',
		component: Login,
		name: 'AcceptInvitation',
		meta: { bypassLogin: true, title: 'Accept Invitation' }
	}
];
