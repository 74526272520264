
/** what insights a structured query should start out with  */
export default [
	{
		title: 'Total Rows',
		type: 'summary',
		query: {
			aggregate: {
				type: 'count'
			}
		},
		id: 'i0'
	}
];
