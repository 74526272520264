import _ from 'lodash';

/**
 * For displaying urls in the table.
 *
 * only http and https links are allowed to be shown as links.
 *
 * Since it is user generated, it is untrusted. Sanitize well.
 * @param  {string} urlText - the url
 * @return {string} an html representation
 */
function formatURL (urlText) {
	// escape for showing the user the value
	const escaped = _.escape(urlText);

	// if can't be shown as link, put text in table directly
	const getRaw = () => {
		const length = urlText.length;
		// if longer than 20 chars, only show first and last 10 chars
		if (length > 20) {
			const start = (urlText.substring(0, 10));
			const end = urlText.substring(length - 10, length);
			const shortened = _.escape(`${start}...${end}`);

			return `<span data-toggle="tooltip" title="${escaped}">${shortened}</span>`;
		}
		else return escaped;
	};

	try {
		// Remove any bad characters
		// referring to RFC 3986 section 2 for allowed characters.
		const strippedURL = urlText.replace(/[^A-Za-z0-9-._~%:/?#@!$&'()*+,;=]/g, '');
		// use the URL api to parse out url. (it also rejects bad urls and encodes when needed, handy!)
		const url = new URL(strippedURL);
		// only http and https are allowed
		if (['https:', 'http:'].includes(url.protocol)) {
			return `<a href="${url.toString()}" data-toggle="tooltip" title="${escaped}" target="_blank" rel="external noreferrer">[link]</a>`;
		}
		else return getRaw();
	}
	catch (err) { // `new URL('...')` may throw errors
		return getRaw();
	}
}

export default formatURL;
