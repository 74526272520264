<template>
<select-search-field
	:disabled="disabled"
	:list="cleanedList"
	:is-multiple="isMultiple"
	:value="value"
	name="value"
	@change="change" />
</template>

<script>
import SelectSearchField from './SelectSearchField.vue';

export default {
	name: 'FilterValueEnumField',
	components: { SelectSearchField },
	props: {
		enumeration: {
			type: Array,
			required: true
		},
		id: { type: String, required: true },
		// eslint-disable-next-line
		value: {},
		disabled: Boolean,
		isMultiple: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		// null should not be shown as an option
		cleanedList () {
			if (this.enumeration.includes(null)) {
				const list = [...this.enumeration];
				list.splice(this.enumeration.indexOf(null), 1);

				return list;
			}
			else return this.enumeration;
		}
	},

	methods: {
		change (key, value) {
			this.$emit('change', key, value);
		}
	}
};

</script>
