<template>
<div id="pz-workspace-description">
	<!-- workspace description toggle button -->
	<button
		id="pz-workspace-description-trigger"
		type="button"
		class="btn btn-link text-white ml-2 p-0"
		title="View workspace description"
		data-toggle="modal"
		data-target="#pz-workspace-description-dialog">
		<icon name="info" />
	</button>

	<!-- Workspace description modal -->
	<!-- controlled by bootstrap's js via button since there's no special funcitonality -->
	<modal
		id="pz-workspace-description-dialog"
		:open="false"
		aria-labelledby="pz-workspace-description-trigger">
		<template #title>
			<icon name="info" /> {{ activeContextName }}
		</template>
		<div style="white-space: pre-line;">
			{{ activeContext.description || 'This is a standard view that represents your full data set. This view contains fields that may be restricted in the original source, including employee data.' }}
		</div>
		<template #footer>
			<button
				type="button"
				class="btn btn-primary"
				data-dismiss="modal"
				name="close">
				Close
			</button>
		</template>
	</modal>
</div>
</template>

<script>
import { Icon, Modal } from 'aunsight-lib-ui';

import getContextNameArr from '@/utils/getContextNameArr';

export default {
	components: { Modal, Icon },
	props: {
		activeContext: {
			type: Object,
			required: true
		}
	},

	computed: {
		activeContextName () {
			if (!this.activeContext) return '';
			return getContextNameArr(this.activeContext).join(' - ');
		}
	}
};
</script>
