<template>
<login-wrapper>
	<div class="text-center">
		<h1 class="py-3">
			Error
		</h1>
		<p>We were unable to find a Daybreak site at this URL.</p>
		<p>Please check your link again or contact your system administrator.</p>
	</div>
</login-wrapper>
</template>

<script>
import LoginWrapper from '@/views/Login';
export default {
	name: 'UnknownContextPage',
	components: { LoginWrapper }
};
</script>
