<template>
<modal
	class="pz-create-insight-dialog"
	data-backdrop="static"
	:title="title"
	:show-close-button="true"
	:show-save-button="false"
	:show-footer-close-button="false"
	:open="open"
	@close="close">
	<!-- chart type selector, if no selection -->
	<div v-if="!selectedType && open" class="type-selector-container">
		<!-- for each of the chart types, make a button -->
		<div
			v-for="type in chartTypes"
			:key="type.id"
			v-tooltip.hideOnClick
			:title="getTooltipText(type)">
			<button
				type="button"
				:disabled="isDisabled(type)"
				class="btn btn-secondary pz-chart-type-button text-center py-4"
				@click="showChartForm(type.id)">
				<i :class="type.icon + ' fa-3x mb-2'" />
				<br>
				{{ type.label }}
			</button>
		</div>
	</div>

	<!-- insight form if selection present -->
	<mn-component-view
		v-else-if="open"
		:view="CreateInsightForm"
		:options="createFormOptions"
		@done="onFormDone"
		@back="selectedType = null" />
</modal>
</template>

<script>
import { Modal, Tooltip } from 'aunsight-lib-ui';
import _ from 'lodash';

import MnComponentView from '@/components/MnWrapper.vue';

import getRelationColumns from '../util/getRelationColumns';
import CreateInsightForm from './CreateInsightForm';

const chartTypes = [
	{
		id: 'summary',
		label: 'Summary',
		icon: 'fas fa-hashtag'
	},
	{
		id: 'column',
		label: 'Column',
		icon: 'fas fa-chart-bar'
	},
	{
		id: 'line',
		label: 'Line',
		icon: 'fas fa-chart-line'
	},
	{
		id: 'donut',
		label: 'Donut',
		icon: 'fas fa-chart-pie'
	}

];

export default {

	components: {
		Modal,
		MnComponentView
	},

	directives: { Tooltip },

	props: {
		open: {
			type: Boolean,
			default: false
		},
		datamart: { type: Object, required: true },
		selectedTable: { type: String, required: true },
		mode: {
			type: String,
			default: 'create'
		},
		insightStartData: {
			type: Object,
			default: null
		}
	},

	data () {
		return {
			chartTypes: chartTypes,
			selectedType: undefined,
			CreateInsightForm
		};
	},

	computed: {
		createFormOptions () {
			return {
				table: this.selectedTable,
				datamart: this.datamart,
				chartType: this.selectedType,
				mode: this.mode,
				insightStartData: this.insightStartData
			};
		},

		title () {
			if (!this.selectedType) {
				return 'Choose an Insight type';
			}
			else {
				return 'New Insight: ' + _.capitalize(this.selectedType);
			}
		},

		lineDisabled () {
			const colSpecs = this.getAllColumns();

			const dateColumns = colSpecs
				.filter(col => col.format === 'date-time')
				.map(c => c.id);

			return dateColumns.length === 0;
		},

		columnAndDonutDisabled () {
			const colSpecs = this.getAllColumns();

			const enumColumns = colSpecs
				.filter(col => !!col.enum)
				.map(c => c.id);

			return enumColumns.length === 0;
		}
	},

	watch: {
		insightStartData (val) {
			if (val && val.type) {
				this.selectedType = val.type;
			}
			else this.selectedType = null;
		}
	},

	methods: {
		// get all columns in this and related tables
		getAllColumns () {
			// get columns from main table
			const table = this.datamart.getTable(this.selectedTable).spec;
			const colSpecs = _.values(table.properties);

			const relationColumns = getRelationColumns(this.datamart, this.selectedTable);
			// for each related table, add its columns to the list.
			relationColumns.forEach(table => {
				colSpecs.push(...table.columns);
			});

			return colSpecs;
		},

		getTooltipText (type) {
			const isDisabled = this.isDisabled(type);
			if (isDisabled) return 'There are no results that support this type of chart';
			else return '';
		},

		showChartForm (type) {
			this.selectedType = type;
		},

		close () {
			this.$emit('close');

			// clear selected type for next time
			this.selectedType = null;
		},

		onFormDone (data) {
			if (this.mode === 'edit') {
				data.id = this.insightStartData.id;
				this.$emit('doneEdit', this.insightStartData.id, data);
			}
			else {
				this.$emit('done', data);
			}
			this.$emit('close');
		},
		isDisabled (type) {
			if (type.id === 'line') {
				return this.lineDisabled;
			}

			if (type.id === 'column' || type.id === 'donut') {
				return this.columnAndDonutDisabled;
			}

			if (type.id === 'summary') {
				return false;
			}
		}
	}
};

</script>

<style lang="scss">
.pz-create-insight-dialog {
	.pz-chart-type-button {
		width: 10em;
		margin: 0.5em;
	}

	.type-selector-container {
		display: flex;
		flex-flow: row wrap;
		// keep width a little more than twice the buttons so two per row
		width: 25em;
		// center it
		margin-left: auto;
		margin-right: auto;
	}

	// hide modal footer since lib-ui won't let you not have it
	.modal-footer {
		display: none;
	}
}
</style>
