import Datamart from 'aunsight-lib-query-js/lib/Datamart';
import $ from 'jquery';
import _ from 'lodash';

import app from '@/webapp';

import loadEnumsV2 from './loadEnums';

let testData = {};

/**
 *
 * @param {Object} context
 * @param {string} context.config
 * @param {string} context.scope
 * @param {Object} [options]
 * @param {boolean} [options.isMock]
 * @param {boolean} [options.isV2]
 * @returns
 */
function loadBaseConfig (context, options) {
	if (options && options.isMock) {
		const config = options.isV2 ? testData.config : testData.configV1;
		return new Promise(resolve => {
			setTimeout(() => {
				resolve(_.cloneDeep(config));
			}, 300);
		});
	}
	else {
		return $.ajax(app.apiPath + `daybreak/config/${context.config}/scope/${context.scope}`)
			.then(response => {
				const config = _.get(response, 'result');
				return config;
			});
	}
}

/**
 *
 * @param {Object} context
 * @param {string} context.config
 * @param {string} context.scope
 * @param {Object} [options]
 * @param {boolean} [options.isMock]
 * @param {boolean} [options.isV2]
 * @param {*} [datamart]
 * @returns
 */
function loadEnums (context, options, datamart) {
	if (options && options.isMock) {
		const enums = options.isV2 ? testData.enums : testData.enumsV1;
		return new Promise(resolve => {
			setTimeout(() => {
				resolve(_.cloneDeep(enums));
			}, 300);
		});
	}
	else {
		if (options.isV2) {
			return loadEnumsV2(datamart, context);
		}
		else {
			return $.ajax(app.apiPath + `daybreak/config/${context.config}/enums`)
				.then(
					response => _.get(response, 'result.enums'),
					err => {
					// if configs haven't been set up, that's fine, return an empty result set
						if (err.status === 404) {
							return {};
						}
						// if it failed for other reasons, pass that failure up the chain.
						else return $.Deferred().reject(err);
					});
		}
	}
}

function applyEnumsToDatamart (datamart, enums) {
	_.forEach(datamart.tables, table => {
		// this can/should be streamlined once enums are removed from existing configs
		_.forEach(table.properties, (column, name) => {
			const colEnum = _.get(enums, [table.record, name]);
			if (colEnum) {
				column.enum = colEnum;
			}
			// remove old enums baked into config
			else if (column.enum) {
				delete column.enum;
			}
		});
	});
}

function applyEnumsToDatamartV2 (tables, enums) {
	_.forEach(tables, table => {
		// this can/should be streamlined once enums are removed from existing configs
		_.forEach(table.properties, (column, name) => {
			const colEnum = _.get(enums, [table.id, name]);
			if (colEnum) {
				column.enum = colEnum;
			}
		});
	});
}

async function doLoadConfig (context, options) {
	const config = await loadBaseConfig(context, options);
	const isV2 = config.v === 2;

	const datamartSpec = config.datamart;
	if (isV2) {
		options.isV2 = isV2;
	}

	const enums = await loadEnums(context, options, datamartSpec);

	if (isV2) {
		// take enumerations and apply them to columns in config
		const tables = [].concat(datamartSpec.tables, datamartSpec.views || []);
		applyEnumsToDatamartV2(tables, enums);
	}
	else {
		applyEnumsToDatamart(config.datamart, enums);
	}

	// Set up datamart
	const engine = isV2 ? 'exasol' : 'drill';

	try {
		const datamart = new Datamart(_.cloneDeep(datamartSpec), { queryEngine: engine });
		return { datamart, config };
	}
	catch (err) {
		return err;
	}
}

/**
 * @typedef daybreakconfig
 * @type {Object}
 * @property {string} organization - the organization this belongs to
 * @property {string} project - the project this belongs to
 * @property {string} config - the id of the config
 * @property {string} id - the id of the scope within the config
 * @property {string} name - the name of the config
 * @property {Object} datamart - the calculated datamart
 * @property {number} v - whether v1 or v2 resource
 * @property {?Object} nlp_config
 * @property {string[]} nlp_config.examples - examples of valid queries in this context
 * @property {string} nlp_config.url - the url to get nlp query result
 * @property {Object[]} standard_queries
 */

/**
 *
 * @typedef daybreakinstancestuff
 * @property {Datamart} datamart
 * @property {daybreakconfig} config

 */
/**
 * load config and enums and combine
 * @param  {Object}  context org and project to load stuff from
 * @param  {string}  context.config
 * @param  {string}  context.scope
 * @param  {Object}  [options]
 * @param  {boolean} [options.isMock]  whether to use mock data and just pretend to load
 * @param  {boolean} [options.isV2] whether its requesting v2 mock data or not
 * @return {Promise<daybreakinstancestuff>} promise resolving to object containing config and datamart
 */
function loadConfig (context, options) {
	if (!options) options = {};
	if (options.isMock) {
		return import(/* webpackChunkName: "daybreak-test-configs" */ '../testUtils/testData')
			.then(module => {
				testData = module.default;

				return doLoadConfig(context, options);
			});
	}
	else return doLoadConfig(context, options);
}

export default loadConfig;
