<template>
<ul class="nav nav-tabs">
	<li
		v-for="item in items"
		:key="item.id"
		v-tooltip.hideOnClick
		class="nav-item"
		:title="item.description || false">
		<a
			:href="item.id === selection? false : '#'"
			:data-item="item.id"
			:class="['nav-link', {active: item.id === selection, disabled: !!item.disabled}]"
			@click.prevent="update(item.id)">
			{{ item.label }}
			<badge
				v-for="(badge, i) in item.badges"
				:key="i"
				:text="badge.text"
				:icon-name="badge.iconName"
				:description="badge.description"
				:class-name="badge.className" />
		</a>
	</li>
</ul>
</template>

<script>
import { Badge, Tooltip } from 'aunsight-lib-ui';

export default {
	name: 'TabList',
	components: {
		Badge
	},
	directives: {
		Tooltip
	},
	props: {
		items: {
			type: Array,
			required: true
		},
		selection: {
			type: String,
			default: ''
		}
	},

	methods: {
		update (id) {
			this.$emit('itemselected', id);
		}
	}
};

</script>
