<template>
<notification-list
	class="flex-grow-1"
	tool="daybreak"
	:resolve-entity="resolveEntity" />
</template>

<script>

import NotificationList from 'aunsight-webapp/src/js/modules/DirectNotifications/NotificationList.vue';

import resolveEntity from '@/utils/resolveEntity';

export default {
	components: {
		NotificationList
	},

	data () {
		return {
			resolveEntity
		};
	}
};

</script>
