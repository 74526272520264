/**
 * Notes on types:
 * * `['x', 'y']` an array of multiple types
 * * `*` (asterisk) means any column type (excluding relations)
 * * `number` includes [number, integer, float]
 * * `null` on a value type means the operator needs no value
 * * `relation` means that this column links to another table.
 *
 * **Since the column types here don't directly map onto the types for the columns themselves, the calling code will need to do some interpeting.**
 * @typedef operator
 * @type {Object}
 * @property {string} id - machine name for operation
 * @property {string} title - human readable name
 * @property {string} valueType - what type the operator expects
 * @property {(string|string[])} columnType - the column types that can accept this operator
 * @property {boolean} [requiresEnum] - if true, the column must have an enumeration to use this operator
 * @property {boolean} [excludeAggregations] - if true, can't be used in an aggregation operation
 * @property {Array<any>} [enum] - if present, a finite list of values posible for the value
 * @property {number} [min]
 * @property {number} [max]
 */

/**
 * List of operators for use in UI
 *
 * Each operation has the following:
 * * title - human readable text
 * * valueType - what the operator compares to
 * * columnType - what `type` does a column need to have to use this operator
 *
 * @type {operator[]}
 */

const operators = [
	{
		id: 'eq',
		title: 'equals',
		valueType: '*',
		columnType: ['number', 'integer', 'float', 'string', 'boolean']
	},

	{
		id: 'neq',
		title: 'not equals',
		valueType: '*',
		columnType: ['number', 'integer', 'float', 'string', 'boolean']
	},

	{
		id: 'date_in_range',
		title: 'in the range',
		valueType: 'relativeRange',
		columnType: 'date'
	},

	{
		id: 'gt',
		title: 'greater than',
		valueType: 'number',
		columnType: 'number'
	},

	{
		id: 'gte',
		title: 'greater than or equal to',
		valueType: 'number',
		columnType: 'number'
	},

	{
		id: 'lt',
		title: 'less than',
		valueType: 'number',
		columnType: 'number'
	},
	{
		id: 'lte',
		title: 'less than or equal to',
		valueType: 'number',
		columnType: 'number'
	},

	{
		id: 'in',
		title: 'is one of',
		valueType: '[string]',
		columnType: 'string'
	},
	{
		id: 'not_in',
		title: 'is not one of',
		valueType: '[string]',
		columnType: 'string'
	},

	{
		id: 'null',
		title: 'is null',
		valueType: null,
		columnType: '*',
		excludeAggregations: true
	},

	{
		id: 'notnull',
		title: 'is not null',
		valueType: null,
		columnType: '*',
		excludeAggregations: true
	},

	{
		id: 'date_between',
		title: 'between dates',
		valueType: 'dateRange',
		columnType: 'date'

	},

	{
		id: 'date_eq',
		title: 'on date',
		valueType: 'date',
		columnType: 'date'
	},

	{
		id: 'date_neq',
		title: 'not on date',
		valueType: 'date',
		columnType: 'date'
	},

	{
		id: 'date_gt',
		title: 'after date',
		valueType: 'date',
		columnType: 'date'
	},

	{
		id: 'date_gte',
		title: 'on or after date',
		valueType: 'date',
		columnType: 'date'
	},

	{
		id: 'date_lt',
		title: 'before date',
		valueType: 'date',
		columnType: 'date'
	},

	{
		id: 'date_lte',
		title: 'on or before date',
		valueType: 'date',
		columnType: 'date'
	},

	// {
	//   title: 'is like',
	//   valueType: 'string',
	//   columnType: 'string'
	// },

	{
		id: 'startswith',
		title: 'starts with',
		valueType: 'string',
		columnType: 'string'
	},

	{
		id: 'endswith',
		title: 'ends with',
		valueType: 'string',
		columnType: 'string'
	},

	{
		id: 'contains',
		title: 'contains',
		valueType: 'string',
		columnType: 'string'
	},

	{
		id: 'all',
		title: 'includes all of',
		valueType: 'filterList',
		columnType: 'relation'
	},

	{
		id: 'any',
		title: 'includes any of',
		valueType: 'filterList',
		columnType: 'relation'
	},

	{
		id: 'none',
		title: 'does not include',
		valueType: 'filterList',
		columnType: 'relation'
	},

	{
		id: 'nany',
		title: 'does not include any of',
		valueType: 'filterList',
		columnType: 'relation'
	},

	// {
	//   id: 'in_week',
	//   title: 'in week',
	//   valueType: 'number',
	//   columnType: 'date',
	//   min: 1,
	//   max: 53
	// },
	{
		id: 'in_month',
		title: 'in month',
		valueType: 'string',
		columnType: 'date',
		enum: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
	},
	{
		id: 'on_day',
		title: 'on day',
		valueType: 'number',
		columnType: 'date',
		min: 1,
		max: 31
	},
	{
		id: 'on_weekday',
		title: 'on weekday',
		valueType: 'string',
		columnType: 'date',
		enum: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
	},
	{
		id: 'in_quarter',
		title: 'in quarter',
		valueType: 'number',
		columnType: 'date',
		enum: [1, 2, 3, 4]
	}
];

export default operators;
