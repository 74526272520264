<template>
<div class="pz-column-selector">
	<!-- search columns -->
	<div class="input-group mb-3">
		<input
			id="pz-column-selector-search-input"
			v-model="searchString"
			class="form-control"
			aria-label="search"
			type="search"
			placeholder="Search fields...">
		<div class="input-group-append">
			<button
				id="pz-column-selector-search-clear"
				class="btn btn-outline-secondary"
				type="button"
				title="Clear Search"
				@click="clearSearch">
				<icon name="times" />
			</button>
		</div>
	</div>

	<!-- select controls -->
	<div class="pz-column-selector-scroll-container">
		<div>
			Select
			<button
				type="button"
				class="btn btn-link"
				name="selectAll"
				@click="selectAll">
				All
			</button>
			<button
				type="button"
				class="btn btn-link"
				name="selectNone"
				@click="selectNone">
				None
			</button>
		</div>

		<ul class="pz-column-selector-list list-unstyled">
			<li v-for="table in workingList" :key="table.id">
				<h6 class="w-100 bg-light p-2 position-relative font-weight-normal">
					<a
						role="button"
						class="stretched-link"
						data-toggle="collapse"
						:href="'#'+table.id + '-table'"
						:aria-expanded="(structuredQuery.table === table.id).toString()"
						:aria-controls="table.id + '-table'">
						<i class="toggle-icon fa fa-fw pr-2" />
						{{ table.name }}
						<span class="text-muted float-right">
							{{ getSelectedCount(table) }} fields selected
						</span>
					</a>
				</h6>
				<div
					:id="table.id + '-table'"
					:class="['collapse', structuredQuery.table === table.id? 'show' : null]">
					<div>
						Select
						<button
							type="button"
							class="btn btn-link"
							name="tableSelectAll"
							@click="selectAllIn(table)">
							All
						</button>
						<button
							type="button"
							class="btn btn-link"
							name="tableSelectNone"
							@click="selectNoneIn(table)">
							None
						</button>
					</div>

					<!-- list -->
					<draggable
						:class="['table-drag-group', searchString? 'nosort': false]"
						:list="table.columns"
						:disabled="!!searchString"
						handle=".handle">
						<div
							v-for="(column, i) in table.columns"
							:key="column.col"
							:hidden="searchTester && !searchTester.test(column.col)"
							class="pz-column-selector-list-item">
							<!-- checkbox/label for selection -->
							<div class="custom-control custom-switch">
								<input
									:id="`pz-column-selector-item-${table.id}-${i}`"
									v-model="column.selected"
									type="checkbox"
									class="custom-control-input">
								<label
									class="custom-control-label"
									:for="`pz-column-selector-item-${table.id}-${i}`">
									{{ column.col }}
								</label>
							</div>

							<!-- handle for reordering -->
							<div class="float-right handle">
								<i class="fas fa-grip-vertical" />
							</div>
						</div>
					</draggable>
				</div> <!-- end table's list -->
			</li>
		</ul> <!-- end selector list -->
	</div>
</div>
</template>

<script>
import { Icon } from 'aunsight-lib-ui';
import _ from 'lodash';
import draggable from 'vuedraggable';

import getRelationColumns from './util/getRelationColumns';

export default {
	components: {
		draggable,
		Icon
	},

	props: {
		datamart: { type: Object, required: true },
		structuredQuery: { type: Object, required: true },
		// an optional starting selection not coming from structured query
		startingSelection: {
			type: Array,
			default: null
		}
	},

	data () {
		return {
			workingList: [],
			searchString: ''
		};
	},

	computed: {
		mainTable () {
			const tableName = this.structuredQuery.table;
			if (!tableName) return {};

			const table = this.datamart.getTable(tableName);
			return {
				id: table.id,
				name: _.get(table, 'spec.name') || table.id,
				columns: this.getColumnsOfTable(table.id)
			};
		},

		foreignTables () {
			const foreignTables = getRelationColumns(this.datamart, this.structuredQuery.table);
			foreignTables.forEach(table => {
				table.columns = table.columns.map(column => column.id);
			});
			return foreignTables;
		},

		// used to disable apply
		currentlySelectedCount () {
			return _.sum(this.workingList.map(this.getSelectedCount));
		},

		searchTester () {
			if (!this.searchString) return false;
			else return new RegExp(this.searchString, 'i');
		},

		defaultState () {
			return this.mainTable.columns.map(c => ({ column: c }));
		}

	},

	watch: {
		workingList: {
			deep: true,
			handler: function () {
				this.apply();
			}
		}
	},

	created () {
		this.createWorkingList();
	},

	methods: {
		// get how many columns are selected for a given table
		getSelectedCount (table) {
			return _.reduce(table.columns, (p, c) => {
				if (c.selected) p++;
				return p;
			}, 0);
		},

		// given a table id, get list of columns in it
		getColumnsOfTable (id) {
			const table = this.datamart.getTable(id);

			return _.clone(table.spec.propertiesOrder);
		},

		getStartingSelectedColumns () {
			const tableName = this.structuredQuery.table;

			// temporary holder for selected columns.
			// Each key is table name and value is array of selected
			const separated = {};

			// if it has never been set, use default
			const startingList = this.startingSelection || this.structuredQuery.columns || this.defaultState;

			for (const column of startingList) {
				const tabid = column.table || tableName;
				if (!separated[tabid]) separated[tabid] = [];
				separated[tabid].push(column.column);
			}

			return [this.mainTable, ...this.foreignTables].map(rc => {
				return {
					..._.pick(rc, ['id', 'key', 'root_key']),
					columns: separated[rc.id] || []
				};
			});
		},

		createWorkingList () {
			const selected = this.getStartingSelectedColumns();

			const workingList = [];

			_.forEach([this.mainTable, ...this.foreignTables], (table, i) => {
				const subList = [];
				const seltabl = selected[i];

				// add selected items (will be shown at top)
				if (seltabl) {
					_.forEach(selected[i].columns, col => {
						const st = {
							col: col,
							selected: true
						};
						subList.push(st);
					});
				}

				// unselected items are either non selected ones or all columns if table not in selected.
				const unselected = seltabl
					? _.without(table.columns, ...seltabl.columns)
					: this.getColumnsOfTable(table.id);

				// add unselected items (will be shown at bottom)
				_.forEach(unselected, col => {
					subList.push({
						col: col,
						selected: false
					});
				});

				const subItem = {
					..._.omit(table, 'columns'),
					columns: subList
				};
				workingList.push(subItem);
			});

			this.workingList = workingList;
		},

		// get results in structured query format
		getSelectionResult () {
			const selection = [];
			for (const table of this.workingList) {
				// if not the main table, we'll need to add table and key to column
				const isMainTable = table.id === this.structuredQuery.table;

				// for each column in table, if it is selected, add it to selection array
				for (const column of (table.columns)) {
					if (column.selected) {
						const c = { column: column.col };

						if (!isMainTable) {
							c.table = table.id;
							_.assign(c, _.pick(table, ['key', 'root_key']));
						}

						selection.push(c);
					}
				}
			}

			return selection;
		},

		apply () {
			let selection = this.getSelectionResult();

			const initial = this.startingSelection || this.structuredQuery.columns || this.defaultState;

			if (selection && _.isEqual(selection, this.defaultState)) {
				// if it's equal to the default state, set to null
				selection = null;
			}
			const hasChanged = !_.isEqual(selection, initial);

			this.$emit('update', selection, hasChanged);
		},

		selectAll () {
			_.forEach(this.workingList, (table) => {
				_.forEach(table.columns, (column) => {
					column.selected = true;
				});
			});
		},

		selectNone () {
			_.forEach(this.workingList, (table) => {
				_.forEach(table.columns, (column) => {
					column.selected = false;
				});
			});
		},

		selectAllIn (table) {
			const wrktable = _.find(this.workingList, { id: table.id });
			_.forEach(wrktable.columns, (column) => {
				column.selected = true;
			});
		},

		selectNoneIn (table) {
			const wrktable = _.find(this.workingList, { id: table.id });
			_.forEach(wrktable.columns, (column) => {
				column.selected = false;
			});
		},

		clearSearch () {
			this.searchString = '';
		}
	}
};

</script>

<style lang="scss">
@import 'app_variables';

.pz-column-selector {
	.pz-column-selector-scroll-container {
		// scrolling list of columns can take up to the full window height minus 300px
		max-height: calc(100vh - 300px);
		overflow: auto;

		// move the handle over to make room for scroll bar
		padding-right: 1.2rem;
	}

	// add disabled styling to sort UI when search is in progress
	.nosort {
		.handle {
			color: $disabled-color;
			cursor: not-allowed;
		}
	}

	[data-toggle='collapse'][aria-expanded=true] .toggle-icon::before {
		content: '\F0D7';
	}

	[data-toggle='collapse'][aria-expanded=false] .toggle-icon::before {
		content: '\F0DA';
	}
	// style text of table headers, remove link formatting
	h6 {
		a,
		a:focus,
		a:hover,
		a:visited {
			color: $body-color;
			text-decoration: none;
		}
	}

	.handle {
		cursor: grab;
	}

	// when dragging, add a background color to list item for better visual identification
	.sortable-chosen {
		background-color: theme-color-level('info', $alert-bg-level);
	}

	// makes sure grab handle is on same line as label and toggle
	.custom-control {
		display: inline;
	}

	.pz-column-selector-list-item {
		// space between list items
		margin-bottom: 0.5rem;

		// add hover background color
		&:hover {
			background-color: $list-group-hover-bg;
		}
	}
}

</style>
