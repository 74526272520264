
<script>
// This is a drop-in replacement for router-link.
// It adds organizationId and projectId to the params.
export default {
	name: 'RouterLinkContext',
	computed: {
		toWithContext () {
			const { configId, scopeId } = this.$route.params;
			const params = this.$attrs.to.params || {};
			const to = { ...this.$attrs.to };
			to.params = { ...params, configId, scopeId };
			return to;
		},
		propsWithContext () {
			const props = { ...this.$attrs };
			props.to = this.toWithContext;
			return props;
		}

	},
	render: function (createElement) {
		return createElement('router-link', { props: this.propsWithContext }, this.$slots.default);
	}
};
</script>
