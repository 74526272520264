<template>
<!-- using login as background wrapper -->
<login-wrapper>
	<h2 class="text-center p-5">
		Redirecting <icon
			name="spinner"
			spin
			custom-class="ml-3" />
	</h2>
</login-wrapper>
</template>

<script>

/***************************************************************************************
 * *********** Redirect from Org/Project URL to Workspace/View URL ************
 *
 * This view is here to redirect people from old org/project urls to their corresponding
 * workspace/view urls, assuming it's a 1:1 transition.
 * If there are many views assiciated with a project, for now we just dump them onto
 * the the workspace selector.
 *
 * This can be done in the router, but it's async, so it's probably better as a view
 *
 */

import { Icon } from 'aunsight-lib-ui';
import $ from 'jquery';
import _ from 'lodash';

import LoginWrapper from '@/views/Login';
import app from '@/webapp';

export default {
	name: 'Redirecting',

	components: {
		LoginWrapper,
		Icon
	},

	mounted () {
		// make sure contexts are loaded
		// (Since user has used url as a starting point, it's likely
		// these won't be loaded yet)
		if (this.$store.state.contextsPromise) {
			this.$store.state.contextsPromise.then(() => this.goToConfigScope());
		}

		else this.goToConfigScope();
	},

	methods: {
		goToConfigScope () {
			const params = this.$route.params;

			// find the configs associated with this context
			const contexts = _.filter(this.$store.state.contexts, {
				organization: { id: params.organizationId },
				project: { id: params.projectId }
			});

			// if there's one applicable context, redirect to it
			if (contexts.length === 1) {
				this.goToConvertedScope(contexts[0]);
			}

			// if there were many scopes for this context just go to context selector
			else if (contexts.length > 1) {
				this.$router.push({
					name: 'Context Selector'
				});
			}

			// if there were no scopes, check to see if it was because the user was not permitted
			// or if it didn't exist, then send that info onto the context selector
			else {
				this.checkAccess().then(isNotPermitted => {
					const redirectInfo = isNotPermitted
						? 'You do not have permissions to view the link you were trying to access.'
						: 'Error:  We were unable to find a Daybreak site at this URL. Please check your link again or contact your system administrator.';
					this.$router.push({
						name: 'Context Selector',
						params: {
							notFoundContext: _.pick(params, ['organizationId', 'projectId']),
							redirectInfo: redirectInfo
						}
					});
				});
			}
		},

		/**
		 * @param {contextitem} context
		 */
		goToConvertedScope (context) {
			const params = this.$route.params;

			// replacing the /organization/:org/project/:proj/ with new params.
			// We can't really use named routes here without reparsing the route
			let urlstr = `/workspace/${context.config.id}/view/${context.id}`;

			// for databuilder routes, add the rest of the path to the end
			if (params.pathMatch.startsWith('databuilder')) {
				urlstr += '/' + params.pathMatch;
			}

			this.$router.push({ path: urlstr });
		},

		/**
		 * @returns {Promise<boolean>} whether user did not have access to view the context entered
		 */
		checkAccess () {
			const params = this.$route.params;
			return new Promise(resolve => {
				const url = app.apiPath + `daybreak/context/${params.organizationId}/${params.projectId}`;
				$.ajax(url).then(
					// probably happens when user has permission to view entity but does not have group access
					() => resolve(true),

					err => {
						resolve(err.status === 401);
					}
				);
			});
		}
	}
};
</script>
