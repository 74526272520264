<template>
<select
	ref="select"
	name="column"
	class="form-control pz-filter-col-selector"
	:value="namespacedColumn"
	:disabled="disabled"
	@change="update">
	<optgroup label="relations">
		<option
			v-for="relation in parsedRelations"
			:key="relation.name"
			:value="relation.name"
			:label="relation.label">
			{{ relation.label }}
		</option>
		<option v-if="noRelations" disabled>
			N/A
		</option>
	</optgroup>

	<optgroup label="fields">
		<option
			v-for="field in parsedColumns"
			:key="field.id"
			:value="field.id"
			:label="field.label">
			{{ field.label }}
		</option>
	</optgroup>
</select>
</template>

<script>
import 'bootstrap-select';

import $ from 'jquery';
import _ from 'lodash';

export default {
	name: 'FilterColumnField',

	props: {
		column: { type: String, default: undefined },
		columns: { type: Array, required: true },
		relations: { type: Array, default: undefined },
		disabled: {
			type: Boolean,
			default: false
		}
	},

	data () {
		return {
			// stub out debounced functions
			refreshPicker: undefined
		};
	},

	computed: {
		noRelations () {
			return _.isEmpty(this.relations);
		},

		namespacedColumn () {
			// if currently empty, don't try to find it
			if (!this.column) return;

			// current val is a relation
			const rel = _.find(this.relations, r => r.name === this.column || r.id === this.column);
			if (rel) {
				return this.getNamespacedRel(rel);
			}
			else return this.column;
		},

		parsedColumns () {
			return this.columns.map(col => {
				return {
					id: col.id,
					label: this.getColumnLabel(col)
				};
			});
		},

		parsedRelations () {
			if (!this.relations) return;
			return this.relations.map(rel => {
				return {
					id: rel.id,
					name: this.getNamespacedRel(rel),
					label: this.getRelationLabel(rel)
				};
			});
		}
	},

	watch: {
		disabled () {
			this.refreshPicker();
		},
		relations () {
			this.onUpdateOptions();
		},
		columns () {
			this.onUpdateOptions();
		}
	},

	mounted () {
		this.createPicker();
	},

	beforeDestroy () {
		if (this.hasPicker) {
			this.destroyPicker();
		}
	},

	created () {
		// create debounced functions
		this.refreshPicker = _.debounce(this.__refreshPicker, 100);
	},

	methods: {
		update (e) {
			this.$emit('change', 'column', e.target.value);
		},

		getColumnLabel (column) {
			return column.spec.name || column.spec.id;
		},

		getRelationLabel (relation) {
			const tableName = relation.table.id;
			const relName = relation.spec.name || relation.spec.id; // name may not be set
			return `${relName} [link to ${tableName}]`;
		},

		getNamespacedRel (relation) {
			// relation columns must be prepended with owning table to prevent naming
			// conflicts in case of same column name in multiple tables
			const tableName = relation.foreign
				? relation.table.id
				: relation.columnTable.id;

			const name = relation.id || relation.name;
			return `${tableName}.${name}`;
		},

		// debounce it because multiple watches can call it
		__refreshPicker () {
			this.$nextTick(() => {
				// something odd happening where updating the columns/relations in DOM causes one to be selected
				this.$refs.select.value = this.namespacedColumn || '';
				// delay to make sure dom updates have been applied
				$(this.$refs.select).selectpicker('refresh');
			});
		},

		destroyPicker () {
			$(this.$refs.select).selectpicker('destroy').off('changed.bs.select');
			this.hasPicker = false;
		},

		createPicker () {
			this.hasPicker = true;
			$(this.$refs.select).selectpicker({
				liveSearch: true,
				liveSearchPlaceholder: 'search...',

				noneSelectedText: 'select...',

				// makes it look like a select
				styleBase: 'form-control',
				style: '',

				// default is glyphicons, change to font awesome
				iconBase: 'fas',
				tickIcon: 'fa-check'

				// no need to show the 'select...' option in dropdown
				// hideDisabled: true
			})
				.on('changed.bs.select', (e) => {
					this.update(e);
				});
		},

		onUpdateOptions () {
			// if it was previously using picker, just refresh it
			if (this.hasPicker) {
				this.refreshPicker();
			}
			// if it was previously NOT using picker, create it
			else {
				this.createPicker();
			}
		}
	}
};

</script>
<style lang="scss">
@import 'app_variables';

// mimic the style of a <select>'s disabled state, darkening background
// bootstrap-select doesn't do this by default
.pz-filter-col-selector.bootstrap-select.disabled .form-control {
	background-color: $input-disabled-bg;
	color: inherit;
}
</style>
