<template>
<div class="pz-filter-aggregation">
	<div class="form-row">
		<!-- aggregation operator -->
		<div class="col">
			<filter-agg-operator-field
				:agg-operator="filter.aggOperator"
				:agg-operators="aggregationOperators"
				:disabled="disabled"
				@change="update" />
		</div>

		<!-- column selector (when needed) -->
		<div v-if="!filter.aggOperator || needsColumn" class="col-5">
			<filter-column-field
				:column="columnId"
				:columns="columns || []"
				:disabled="!needsColumn || disabled"
				@change="update " />
		</div>

		<!-- operator field -->
		<div class="col">
			<filter-operator-field
				:disabled="!needsOperator || disabled"
				:operator="filter.operator"
				:operators="availableOperators"
				@change="update" />
		</div>

		<!-- value field -->
		<div class="col">
			<filter-value-string-field
				:id="filter.id"
				:disabled="!needsValue || disabled"
				:value="filter.value"
				@change="update" />
		</div>

		<div class="col-auto p-0">
			<button
				name="remove"
				class="btn btn-link"
				title="Remove this condition"
				:disabled="disabled"
				@click="remove">
				<span class="fa-stack icon-stack-danger">
					<i class="bottom-icon fas fa-circle fa-stack-2x" />
					<i class="top-icon fas fa-minus fa-stack-1x" />
				</span>
			</button>
		</div>
	</div> <!-- end form row -->
</div>
</template>

<script>

import _ from 'lodash';

import Filter from '../lib/Filter';
import FilterAggOperatorField from './FilterAggOperatorField.vue';
import FilterColumnField from './FilterColumnField.vue';
import FilterOperatorField from './FilterOperatorField.vue';
import FilterValueStringField from './FilterValueStringField.vue';

export default {
	name: 'FilterAggregation',

	components: {
		FilterAggOperatorField,
		FilterOperatorField,
		FilterColumnField,
		FilterValueStringField
	},

	props: {
		filter: {
			type: Filter.AggCondition,
			required: true
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		columns () {
			if (!this.filter.aggOperator) return;
			return this.filter.getAvailableColumns();
		},

		availableOperators () {
			return this.filter.getAvailableOperators();
		},

		aggregationOperators () {
			return this.filter.getAggOperators();
		},

		needsColumn () {
			return !!this.filter.aggOperator && this.filter.needsColumn();
		},

		needsOperator () {
			// if column is set OR agg operator is set and doesn't require a column
			return !!this.filter.column || (!!this.filter.aggOperator && !this.needsColumn);
		},

		needsValue () {
			return this.needsOperator && !!this.filter.operator;
		},

		columnId () {
			return _.get(this, ['filter', 'column', 'id']);
		}
	},

	methods: {
		update (field, value) {
			if (field === 'aggOperator') this.filter.aggOperator = value;
			if (field === 'operator') this.filter.operator = value;
			if (field === 'value') this.filter.value = value;
			if (field === 'column') {
				this.filter.column = _.find(this.columns, { id: value });
			}
		},

		remove (id) {
			this.$emit('remove', this.filter.id);
		}
	}
};

</script>
