<template>
<div class="pz-table-selector form-row form-inline">
	<select
		ref="select"
		name="selected_entity"
		class="form-control"
		@change="update">
		<option
			value=""
			label="Select"
			disabled
			:selected="!selectedTable" />
		<option
			v-for="table in tables"
			:key="table.id"
			:value="table.id || table.name"
			:label="getTableLabel(table)"
			:selected="table.id === selectedTable" />
	</select>

	<span class="form-text px-1"> that match </span>

	<select
		ref="opSelect"
		name="table_operator"
		class="form-control"
		@change="updateOp">
		<option value="all" :selected="selectedOperator==='all'">
			all
		</option>
		<option value="any" :selected="selectedOperator==='any'">
			any
		</option>
	</select>

	<span class="px-1"> of the following conditions: </span>
</div>
</template>

<script>
import _ from 'lodash';

export default {
	name: 'TableField',

	props: {
		tables: { type: Array, required: true },
		selectedTable: { type: String, required: true },
		selectedOperator: { type: String, required: true }
	},

	methods: {
		update (e) {
			// in case the parent needs to prevent the change, provide a cancel function
			const cancel = () => {
				this.$refs.select.value = this.selectedTable;
			};

			this.$emit('change', e.target.value, cancel);
		},
		updateOp (e) {
			this.$emit('changeOp', e.target.value);
		},

		getTableLabel (table) {
			let name = _.get(table, ['text', 'plural']);

			if (!name) name = table.name;

			return _.capitalize(name);
		}
	}

};
</script>
