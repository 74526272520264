<template>
<div id="pz-context-selector">
	<div class="innercontent container bg-white m-5 card">
		<div class="card-body">
			<!-- header -->
			<h1 class="h5 card-title">
				<span class="fa fa-stack text-primary header-icon">
					<icon name="circleFilled" custom-class="fa-stack-2x" />
					<icon name="institution" custom-class="fa-stack-1x fa-inverse" />
				</span>
				Select a workspace

				<!-- log out button -->
				<button
					type="button"
					class="float-right btn btn-link p-0"
					name="logout"
					@click="logout">
					Log Out
				</button>
			</h1>

			<!-- show warning if redirected from an old context that couldn't be opened -->
			<div
				v-if="redirectErrorExists"
				class="alert alert-warning alert-dismissible"
				role="alert">
				{{ redirectMessage }}
				<button
					type="button"
					class="close"
					data-dismiss="alert"
					aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>

			<!-- show error message if loading failed (unlikely) -->
			<div v-if="contextError" id="pz-context-selector-error-message">
				<error-message
					title="Loading Error"
					message="An error occurred loading your workspaces. If this problem persists, please contact your administrator."
					:errors="[contextError]" />
			</div>

			<!-- if still loading, show spinner -->
			<div v-else-if="!contexts" id="pz-context-selector-loading">
				<icon
					name="spinner"
					spin
					title="loading" />
			</div>

			<!-- list of contexts -->
			<div v-else class="context-container">
				<router-link
					v-for="(c, i) in contexts"
					:key="c.id + i"
					class="context-item"
					:to="{name: 'Home', params: {configId: c.config.id, scopeId: c.id}}">
					<span>
						<template v-for="(namepart, j) in getContextNameArr(c)">
							<!-- if not the first, add a dash and newline before it -->
							<template v-if="j > 0"> -<br :key="j"></template>
							{{ namepart }}
						</template>
					</span>
				</router-link>
			</div>
		</div>  <!-- end card body -->
	</div>
</div>
</template>

<script>
import { ErrorMessage, Icon } from 'aunsight-lib-ui';
import _ from 'lodash';

import getContextNameArr from '@/utils/getContextNameArr';
import app from '@/webapp';

export default {
	components: {
		Icon,
		ErrorMessage
	},

	computed: {
		contexts () {
			return this.$store.state.contexts;
		},
		contextError () {
			return this.$store.state.contextError;
		},

		redirectErrorExists () {
			return !!this.$route.params.redirectInfo;
		},

		redirectMessage () {
			if (!this.redirectErrorExists) return null;
			return this.$route.params.redirectInfo;
		}
	},

	watch: {
		contexts (newContexts, oldContexts) {
			if (_.isNull(oldContexts)) {
				this.redirectMaybe();
			}
		}
	},

	created () {
		// make sure contexts are loaded before checking if redirecting is needed
		if (this.$store.state.contextsPromise) {
			this.$store.state.contextsPromise.then(() => this.redirectMaybe());
		}
		else this.redirectMaybe();
	},

	methods: {
		logout () {
			app.auth.logout();
		},

		getContextNameArr,

		redirectMaybe () {
			// if there is only one context, go there
			if (_.get(this.$store, 'state.contexts.length') === 1) {
				const context = this.$store.state.contexts[0];
				this.$router.push({
					name: 'Home',
					params: {
						configId: context.config.id,
						scopeId: context.id,
						...this.$route.params
					}
				});
			}

			// if user has no available contexts, send them to welcome screen
			if (_.isArray(this.contexts) && this.contexts.length === 0) {
				this.$router.push({ name: 'Welcome' });
			}
		}
	}

};

</script>

<style lang="scss">
@import 'app_variables';

#pz-context-selector {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	overflow: hidden;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	background-color: #004cb2;
	background-image: url('../assets/login-illustration-tileable.png');
	background-repeat: repeat;

	// the card with content
	.innercontent {
		overflow: hidden;

		@media (min-width: map-get($grid-breakpoints, 'md')) {
			margin-left: auto;
			margin-right: auto;
			width: 45rem;
		}

		#pz-context-selector-loading {
			text-align: center;
			font-size: 3rem;
		}

		.header-icon {
			margin-right: 0.3rem;
			font-size: small;
		}

		.card-body {
			height: 100%;
			overflow: hidden;
			display: flex;
			flex-flow: column nowrap;
		}

		.card-title {
			margin-bottom: 2rem;
			flex: 0 0 auto;
		}

		.context-container {
			flex: 1 1;
			display: flex;
			flex-flow: row wrap;
			justify-content: center;
			overflow: auto;
		}

		.context-item {
			width: 9rem;
			height: 8rem;
			margin: 0.5rem;
			padding: 1rem;
			border: 3px solid $border-color;
			border-radius: $border-radius;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			overflow: hidden;
			font-weight: bold;
			color: $body-color;

			&:hover {
				text-decoration: none;
			}

			&:hover,
			&:focus {
				border-color: $secondary;
			}

			> span {
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
}

</style>
