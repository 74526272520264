<template>
<div class="pz-filter-item">
	<div class="form-row">
		<div class="col-6">
			<filter-column-field
				:column="columnId"
				:columns="columns"
				:relations="relations"
				:disabled="disabled"
				@change="update " />
		</div>
		<div class="col">
			<filter-operator-field
				:disabled="!needsOperator"
				:operator="filter.operator"
				:operators="availableOperators"
				@change="update" />
		</div>
		<div class="col">
			<filter-value-field
				v-if="!isRelation"
				:id="filter.id"
				:op-type="opType"
				:disabled="!needsValue || isValueDisabled"
				:value="filter.value"
				:enumeration="getEnum()"
				@change="update" />
		</div>
		<div class="col-auto p-0">
			<button
				name="remove"
				class="btn btn-link"
				title="Remove this condition"
				:disabled="disabled"
				@click="remove">
				<span class="fa-stack icon-stack-danger">
					<i class="bottom-icon fas fa-circle fa-stack-2x" />
					<i class="top-icon fas fa-minus fa-stack-1x" />
				</span>
			</button>
		</div>
	</div>

	<!-- any validation errors -->
	<!-- there will never be both col and value, because value validation requires a column spec -->
	<div v-if="filter.validationError" class="text-danger text-right pr-4">
		{{ filter.validationError.message }}
		<span
			v-tooltip
			:title="getFullEnumValidationMessage()">
			<icon name="info" />
		</span>
	</div>

	<div v-if="filter.columnError" class="text-danger">
		{{ filter.columnError.message }}
	</div>

	<!-- child list for relations -->
	<filter-list
		v-if="isRelation && needsValue"
		:class="`pz-cond-group-${['any','nany'].includes(filter.operator)? 'or': 'and'}`"
		:columns="getChildColumns()"
		:value="filter.value"
		:parent-id="filter.id"
		:enable-agg="true"
		:aggregation="aggregation"
		:disabled="isValueDisabled"
		@change="childupdate"
		@addRow="addRow"
		@remove="childremove"
		@addAggregation="addAggregation"
		@removeAggregation="removeAggregation" />

	<!-- helper row for relative date operator -->
	<filter-value-relative-range-sub-field
		v-if="needsRelativeRangeField"
		class="px-4"
		:value="filter.value"
		@change="update" />
</div>
</template>

<script>

import { Icon, Tooltip } from 'aunsight-lib-ui';
import _ from 'lodash';

import FilterColumnField from './FilterColumnField.vue';
import FilterOperatorField from './FilterOperatorField.vue';
import FilterValueField from './FilterValueField.vue';
import FilterValueRelativeRangeSubField from './FilterValueRelativeRangeSubField.vue';

export default {
	name: 'FilterItem',

	directives: {
		Tooltip
	},

	components: {
		Icon,
		FilterOperatorField,
		FilterColumnField,
		FilterValueField,
		// async because of circular dependency
		FilterList: () => import('./FilterList.vue'),
		FilterValueRelativeRangeSubField
	},

	props: {
		filter: { type: Object, required: true },
		columns: { type: Array, required: true },
		relations: { type: Array, default: undefined },
		disabled: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		availableOperators () {
			return this.filter.getAvailableOperators();
		},

		needsOperator () {
			return !!this.filter.column && !this.filter.columnError && !this.disabled;
		},

		opType () {
			let type = this.filter.getOperatorType();

			// not the ideal way, should be put into a filter class function!
			if (type === '*') {
				type = this.filter.getColumnType();
			}

			return type;
		},

		// whether the value field should be present
		needsValue () {
			const operator = this.filter.operator;
			if (!operator) return false;

			return this.filter.needsValue();
		},

		// whether the value field should be interactive
		isValueDisabled () {
			return !!this.filter.columnError || this.disabled;
		},

		isRelation () {
			return this.filter.isRelation();
		},

		columnId () {
			return _.get(this, ['filter', 'column', 'id']);
		},

		needsRelativeRangeField () {
			return this.opType === 'relativeRange';
		},

		aggregation () {
			if (this.filter.hasAggregation) return this.filter.aggregation;
			else return null;
		}
	},

	methods: {
		update (field, value) {
			this.$emit('change', this.filter.id, field, value);
		},

		childupdate (id, field, value) {
			this.$emit('change', id, field, value);
		},

		remove (id) {
			this.$emit('remove', this.filter.id);
		},

		childremove (id) {
			this.$emit('remove', id);
		},

		addRow (e) {
			this.$emit('addRow', e);
		},

		addAggregation () {
			this.filter.aggregation = {};
		},

		removeAggregation () {
			this.filter.aggregation = null;
		},

		getEnum () {
			return this.filter.getEnumeration();
		},

		getChildColumns () {
			// true if an association has been chosen
			const table = this.filter.table;

			if (table) {
				return _.filter(table.getSimpleColumns(), col => col.spec.filterable);
			}

			else return false;
		},

		getFullEnumValidationMessage () {
			let vals;
			if (_.isArray(this.filter.value)) {
				vals = this.filter.validationError.invalidValues.map(v => `"${v}"`).join(', ');
			}
			else vals = `"${this.filter.value}"`;
			return `The value ${vals} that you requested is not available, please follow up with your client success advisor for next steps`;
		}
	}
};

</script>
