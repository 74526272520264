import 'bootstrap';
import 'backbone';
import './controllers/ErrorReporter';
import './controllers/GaTracker';

import ActivityController from 'aunsight-webapp/src/js/controllers/ActivityManager.js';
import SocketIOConnection from 'aunsight-webapp/src/js/controllers/SocketIOConnection';
import Vue from 'vue';
import Vuex, { Store } from 'vuex';

import RouterLinkContext from '@/components/RouterLinkContext';
import AuthManager from '@/controllers/AuthManager';
import ContextsManager from '@/controllers/ContextsManager';
import NotificationTracker from '@/controllers/NotificationTracker';
import storeObj from '@/store';

import App from './App.vue';
import eventsToTrack from './controllers/EventsToTrack';
import router from './router';
import app from './webapp';

Vue.config.productionTip = false;

Vue.component('RouterLinkContext', RouterLinkContext);

Vue.use(Vuex);
const store = new Store(storeObj);

fetch('/config').then(res => res.json()).then(config => {
	app.apiPath = config.apiPath;
	app.socketIOPath = config.socketIOPath;
	app.nlpAutoUrl = config.nlpAutoUrl;
	app.environment = config.environment;
	app.socket = new SocketIOConnection({ socketIOPath: app.socketIOPath });
	app.activity = new ActivityController();
	app.auth = new AuthManager({ app });
	app.contextsManager = new ContextsManager({ store });
	app.notifications = new NotificationTracker({ tool: 'daybreak' });
}).then(() => {
	app.vue = new Vue({
		router,
		store,
		render: h => h(App)
	}).$mount('#app');
	eventsToTrack(app.vue);
});
