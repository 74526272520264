<template>
<div id="pz-home-page" class="bg-light">
	<!-- header -->
	<h1 class="h3">
		Welcome back, {{ name }}!
	</h1>

	<!-- search -->
	<nlp-widget v-if="hasNLP" :context="context" />

	<!-- recent queries -->
	<recent-queries-widget :context="context" />

	<!-- references -->
	<references-widget />

	<modal
		v-if="redirectErrorExists"
		title="Redirected"
		:text="redirectMessage"
		:open="!isRedirectModalDismissed"
		:show-save-button="false"
		@close="isRedirectModalDismissed=true" />
</div>
</template>

<script>
import { Modal } from 'aunsight-lib-ui';
import _ from 'lodash';

import NLPWidget from '@/components/LandingNLPWidget.vue';
import RecentQueriesWidget from '@/components/LandingRecentQueriesWidget.vue';
import ReferencesWidget from '@/components/LandingReferencesWidget.vue';
import app from '@/webapp';

export default {
	name: 'Landing',
	components: {
		Modal,
		RecentQueriesWidget,
		ReferencesWidget,
		nlpWidget: NLPWidget
	},

	props: {
		config: {
			type: Object,
			required: true
		},
		context: {
			type: Object,
			required: true
		}
	},

	data () {
		return {
			isRedirectModalDismissed: false
		};
	},

	computed: {
		name () {
			return app.auth.user.get('firstname');
		},
		hasNLP () {
			return !!_.get(this.config, 'nlp_config.url');
		},

		redirectErrorExists () {
			return !!this.$route.params.notFoundContext;
		},

		redirectMessage () {
			if (!this.redirectErrorExists) return null;
			return this.$route.params.redirectInfo;
		}
	}
};
</script>

<style lang="scss">
@import 'app_variables';

#pz-home-page {
	padding: 1rem;
	overflow: auto;

	> .card {
		// lighten border as per design
		border-color: $gray-200;
		// putting some space between widgets
		margin-bottom: 1rem;
	}
}
</style>
