<template>
<span class="pz-filter-val-field">

	<input
		type="text"
		class="form-control"
		autocomplete="off"
		:value="value"
		:disabled="disabled"
		:list="enumeration? id + '-datalist' : false"
		@input="update">

	<!-- TODO: use a super cool library that makes this look nicer -->
	<datalist v-if="enumeration" :id="id + '-datalist'">
		<option
			v-for="opt in enumeration"
			:key="opt"
			:value="opt"
			:label="opt" />
	</datalist>

</span>
</template>

<script>

export default {
	name: 'FilterValueStringField',
	props: {
		enumeration: { type: Array, default: undefined },
		id: { type: String, required: true },
		// eslint-disable-next-line
		value: {},
		disabled: Boolean
	},
	methods: {
		update (event) {
			this.$emit('change', 'value', event.target.value);
		}
	}
};

</script>
