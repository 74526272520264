import { store as authStore } from 'aunsight-lib-login';
import getXHRError from 'aunsight-webapp/src/js/util/getXHRError';
import $ from 'jquery';
import _ from 'lodash';

import app from '@/webapp';

/**
 * a context description as listed by the server
 * @typedef contextitem
 * @type {Object}
 * @property {string} id
 * @property {string} name
 * @property {?string} description
 * @property {string} datamart
 * @property {Object} organization
 * @property {string} organization.id
 * @property {string} organization.name
 * @property {Object} project
 * @property {string} project.id
 * @property {Object} config
 * @property {string} config.id
 * @property {string} config.name
 */

export default {
	strict: true,
	modules: {
		[authStore.NS]: authStore.storeObj
	},
	state: () => {
		return {
			newNotifications: 0,

			/**
			 * @type {?contextitem[]}
			 */
			contexts: null,

			/**
			 * @type {?(Object|Error)}
			 */
			contextError: null,

			/**
			 * for when contexts are being loaded for code to wait for it
			 * @type {?JQueryXHR}
			 */
			contextsPromise: null
		};
	},

	getters: {
		getContext: (state) => (context) => {
			return _.find(state.contexts, {
				config: { id: context.config },
				id: context.scope
			});
		}
	},

	mutations: {
		newNotifications (state, count) {
			state.newNotifications = count;
		},
		setContexts (state, contexts) {
			state.contexts = contexts;
		},
		setContextError (state, contextError) {
			state.contextError = contextError;
		},
		setContextsPromise (state, promise) {
			state.contextsPromise = promise;
		}
	},

	actions: {
		loadContexts ({ commit }) {
			const query = `{
				user(id: "${app.auth.user.id}") { daybreak_scope_memberships {
					id name description organization { id name } project { id } config { id name } datamart
				} }
			}`;

			const promise = $.ajax(app.getGraphQlUrl(), { method: 'post', data: { query } })
				.then(response => {
					const contexts = _.get(response, 'result.data.user.daybreak_scope_memberships');
					commit('setContexts', contexts);

					if (!contexts && _.get(response, 'result.errors')) {
						commit('setContextError', response.result.errors[0]);
						commit('setContextsPromise', null);
					}
				})
				.fail(error => {
					if (_.isFunction(error.state)) error = getXHRError(error);
					commit('setContextError', error);
					commit('setContextsPromise', null);
				});
			commit('setContextsPromise', promise);
		}
	}
};
