/**
 * definitions for aggregation operations
 * @typedef {Object} aggOperatorDef
 * @property {string} id - machine name for agg type
 * @property {string} title - human readable name for agg type
 * @property {?string} columnType - what sort of column can use it. if null, it doesn't need a column
 */
/**
 * Specs for the different type of aggregators
 * @type {aggOperatorDef[]}
 */
const aggOperators = [
	{
		id: 'sum',
		title: 'sum',
		columnType: 'number'
	},
	{
		id: 'count',
		title: 'count',
		columnType: null
	},
	{
		id: 'count_distinct',
		title: 'count distinct',
		columnType: '*'
	},
	{
		id: 'avg',
		title: 'average',
		columnType: 'number'
	},
	{
		id: 'max',
		title: 'maximum',
		columnType: 'number'
	},
	{
		id: 'min',
		title: 'minimum',
		columnType: 'number'
	}
];

export default aggOperators;
