<template>
<div>
	<au-modal
		id="change-password-modal"
		:open="open"
		title="Change Password"
		@close="hideModal">
		<form v-if="step !== 'success'" autocomplete="off">
			<input
				:style="hiddenUsername"
				type="text"
				autocomplete="username">
			<div class="form-group row">
				<label for="password" class="col-sm-4 col-form-label">New Password:</label>
				<div class="col-sm-8">
					<input
						id="password"
						ref="password"
						v-model="password"
						type="password"
						class="form-control"
						placeholder="Password"
						name="password"
						autocomplete="new-password"
						@keyup.enter="changeOrFocus('passwordConfirm')">
					<password-validator :password="password" @passwordValid="passwordValid" />
				</div>
			</div>
			<div class="form-group row">
				<label for="passwordConfirm" class="col-sm-4 col-form-label">Confirm Password:</label>
				<div class="col-sm-8">
					<input
						id="passwordConfirm"
						ref="passwordConfirm"
						v-model="passwordConfirm"
						type="password"
						class="form-control"
						placeholder="Password Confirm"
						name="passwordConfirm"
						autocomplete="new-password"
						@keyup.enter="changeOrFocus('password')">
				</div>
			</div>
		</form>
		<div
			v-if="step==='success'"
			class="alert alert-success"
			role="alert">
			Password successfully updated.
		</div>
		<div
			v-if="step==='fail'"
			class="alert alert-warning"
			role="alert">
			Password change failed- please try again.
		</div>
		<template v-if="step==='success'" #footer>
			<button
				type="submit"
				class="btn btn-primary float-right"
				@click.prevent="hideModal">
				Close
			</button>
		</template>
		<template v-else #footer>
			<button
				type="submit"
				class="btn btn-default float-right"
				@click.prevent="hideModal">
				Cancel
			</button>
			<button
				type="submit"
				class="btn btn-primary float-right"
				:disabled="changeDisabled"
				@click.prevent="changePassword">
				Change Password
			</button>
		</template>
	</au-modal>
</div>
</template>

<script>
import { Modal as AuModal, PasswordValidator } from 'aunsight-lib-ui';

import app from '@/webapp';

export default {
	name: 'ChangePasswordModal',
	components: { AuModal, PasswordValidator },
	props: {
		open: Boolean
	},
	data () {
		return {
			password: '',
			passwordConfirm: '',
			passwordIsValid: false,
			step: 'change',
			// Styled here for doc purposes.
			// Hidden input attempts to prevent Chrome from auto-filling incorrect fields.
			hiddenUsername: 'display: none;'
		};
	},
	computed: {
		changeDisabled: function () {
			return (
				this.passwordConfirm.length === 0 ||
				this.passwordConfirm !== this.password ||
				!this.passwordIsValid
			);
		}
	},
	watch: {
		open (val) {
			if (val) {
				this.step = 'change';
				this.password = '';
				this.passwordConfirm = '';
			}
		}
	},
	methods: {
		passwordValid: function (val) {
			this.passwordIsValid = val;
		},
		hideModal () {
			this.$emit('close');
		},
		changeOrFocus (next) {
			if (this.changeDisabled) {
				this.$refs[next].focus();
			}
			else {
				this.changePassword();
			}
		},
		changePassword () {
			const vm = this;
			return app.auth.user.changePW({ password: this.password })
				.then(() => {
					vm.step = 'success';
				}).catch(response => {
					vm.step = 'fail';
					this.errorMessage = response;
				});
		}
	}
};
</script>
