import EventBus from '@/utils/EventBus';

/**
 * Class responsible for populating contexts after login and clearing them on log out
 */
class ContextsManager {
	constructor ({ store }) {
		this.store = store;
		EventBus.$on('user:loaded', () => this.store.dispatch('loadContexts'));
		EventBus.$on('logged:out', () => {
			this.store.commit('setContexts', null);
		});
	}
}

export default ContextsManager;
