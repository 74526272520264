<template>
<form class="pz-download-file-form form-horizontal">
	<!-- format field -->
	<div class="form-group row">
		<label class="col-sm-3 col-form-label" for="pz-download-file-format-select">
			File Format
		</label>
		<div class="col-sm-9">
			<select
				id="pz-download-file-format-select"
				v-model="format"
				class="form-control"
				name="format">
				<option
					v-for="f in supportedFormats"
					:key="f"
					:label="f"
					:value="f">
					{{ f }}
				</option>
			</select>
		</div>
	</div>

	<!-- file name field -->
	<div class="form-group row ">
		<label class="col-sm-3 col-form-label" for="pz-download-file-name-input">
			Filename
		</label>
		<div class="col-sm-9">
			<input
				id="pz-download-file-name-input"
				v-model="query_filename"
				type="text"
				class="form-control"
				name="query_filename"
				placeholder="Use default">
		</div>
	</div>
	<!-- include header field -->
	<div class="form-group row">
		<div class="col-sm-3">
			Table Header
		</div>
		<div class="col-sm-9">
			<div class="form-check">
				<input
					id="pz-download-file-includeheader"
					v-model="includeHeader"
					type="checkbox"
					class="form-check-input"
					name="include_header">
				<label class="form-check-label" for="pz-download-file-includeheader">
					Include table headers in the export
				</label>
			</div>
		</div>
	</div>
</form>
</template>

<script>
import _ from 'lodash';

export default {

	props: {
		initialValues: {
			type: Object,
			default: () => ({})
		},
		supportedFormats: {
			type: Array,
			default: () => ['ldjson', 'csv', 'psv', 'tsv']
		}
	},
	data () {
		return {
			format: '',
			includeHeader: false,
			query_filename: ''
		};
	},

	watch: {
		format: function () {
			this.update();
		},
		includeHeader: function () {
			this.update();
		},
		query_filename: function () {
			this.update();
		}
	},

	created () {
		_.assign(this, _.pick(this.initialValues, ['format', 'includeHeader', 'query_filename']));

		this.update = _.debounce(this.update, 100);
	},

	methods: {
		update () {
			this.$emit('update', {
				format: this.format,
				includeHeader: this.includeHeader,
				query_filename: this.query_filename
			});
		}
	}
};

</script>
