<template>
<select
	class="form-control pz-filter-op-selector"
	name="operator"
	:disabled="disabled"
	:value="operator"
	@change="update">
	<option
		value=""
		label="select..."
		disabled
		:selected="!operator" />

	<option
		v-for="op in operators"
		:key="op.id"
		:value="op.id"
		:label="op.title"
		:selected="op.id === operator" />
</select>
</template>

<script>

export default {
	name: 'FilterOperatorField',

	props: {
		operator: {
			type: String,
			default: ''
		},
		operators: {
			type: Array,
			required: true
		},

		disabled: {
			type: Boolean,
			default: false
		}
	},

	methods: {
		update (event) {
			this.$emit('change', 'operator', event.target.value);
		}
	}
};

</script>
