<template>
<span class="pz-select-search-field">

	<select
		ref="select"
		class="form-control"
		autocomplete="off"
		:name="name"
		:disabled="disabled"
		:enumeration="list"
		:multiple="isMultiple">

		<slot>
			<option
				value=""
				label="select..."
				disabled
				:selected="!value">
				select...
			</option>

			<option
				v-for="(item, index) in listItems"
				:key="index"
				:value="item.value"
				:label="item.label">
				{{ item.label }}
			</option>
		</slot>
	</select>

</span>
</template>

<script>
import 'bootstrap-select';

import $ from 'jquery';
import _ from 'lodash';

/*
 * A generic-ish select input with bootstrap select.
 */

export default {
	name: 'SelectSearchField',
	props: {
		// can either be array of strings or array of objects with value/label keys
		list: {
			type: Array,
			required: true
		},
		// eslint-disable-next-line
		value: {},
		name: {
			type: String,
			default: ''
		},
		disabled: Boolean,
		isMultiple: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		listItems () {
			// if it's an array of strings, convert to array of value/label objs
			return this.list.map(item => {
				if (!_.isObject(item)) {
					// should be a string already but just to be sure
					return { value: String(item), label: String(item) };
				}
				else return item;
			});
		}
	},

	watch: {
		disabled () {
			this.refreshPicker();
		},

		list () {
			// if it was previously using picker, just refresh it
			if (this.hasPicker) {
				this.refreshPicker();
			}
		},

		// happens when going between normal val and in/not in which is a list
		// Need to reinstantiate the picker so it picks up this change
		isMultiple () {
			// refresh alone doesn't update 'multiple'
			this.destroyPicker();
			this.$nextTick(() => this.createPicker());
		},

		value (val) {
			// when the value changes upstream, update the picker's value if needed
			if (this.hasPicker && !_.isEqual($(this.$refs.select).selectpicker('val'), val)) {
				$(this.$refs.select).selectpicker('val', val);
			}
		}
	},

	mounted () {
		this.createPicker();
	},

	beforeDestroy () {
		if (this.hasPicker) {
			this.destroyPicker();
		}
	},

	methods: {
		update () {
			const value = $(this.$refs.select).selectpicker('val');
			this.$emit('change', this.name, value);
		},

		// debounce it because multiple watches can call it
		refreshPicker: _.debounce(function () {
			// delay to make sure dom updates have been applied
			this.$nextTick(() => {
				$(this.$refs.select).selectpicker('refresh');
			});
		}, 100),

		destroyPicker () {
			$(this.$refs.select).selectpicker('destroy').off('changed.bs.select');
			this.hasPicker = false;
			// make sure value is cleared because destroying picker won't
			this.$refs.select.value = '';
		},

		createPicker () {
			this.hasPicker = true;

			$(this.$refs.select).selectpicker({
				liveSearch: true,
				liveSearchPlaceholder: 'search...',

				noneSelectedText: 'select...',

				// makes it look like a select
				styleBase: 'form-control',
				style: '',

				// default is glyphicons, change to font awesome
				iconBase: 'fas',
				tickIcon: 'fa-check',

				// no need to show the 'select...' option in dropdown
				hideDisabled: true,

				selectedTextFormat: 'count > 2'
			})
				.on('changed.bs.select', (e) => {
					if (this.initChange) {
						this.initChange = false;
					}
					else this.update(e);
				});

			// set the value of picker if needed
			if (!_.isEqual($(this.$refs.select).selectpicker('val'), this.value)) {
				// don't capture the initial value set as a user action.
				this.initChange = true;
				$(this.$refs.select).selectpicker('val', this.value);
			}
		}
	}
};

</script>

<style lang="scss">
@import 'app_variables';

// mimic the style of a <select>'s disabled state, darkening background
// bootstrap-select doesn't do this by default
.pz-select-search-field {
	.bootstrap-select.disabled .form-control {
		background-color: $input-disabled-bg;
	}
}
</style>
