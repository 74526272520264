<template>
<div ref="preview" class="pz-sql-preview overflow-auto">
	{{ query }}
</div>
</template>

<script>
export default {
	name: 'SQLPreview',

	props: {
		query: {
			type: String,
			default: ''
		}
	},

	mounted () {
		this.hightlightSyntax();
	},

	methods: {
		hightlightSyntax () {
			const highlighter = window.ace.require('ace/ext/static_highlight');
			highlighter.highlight(this.$refs.preview, {
				mode: 'ace/mode/sql', theme: 'ace/theme/xcode'
			});
		}
	}
};

</script>

<style lang="scss">
.pz-sql-preview .ace-xcode {
	height: 100%;
	padding: 0.5rem;
}
</style>
