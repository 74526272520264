<template>
<li class="nav-item">
	<router-link-context
		class="nav-link"
		:to="path"
		:data-id="dataId"
		:title="title"
		data-toggle="tooltip"
		data-placement="right"
		data-trigger="hover">
		<icon v-if="iconName" :name="iconName" />
		<span v-if="!condensed" class="nav-label">{{ title }}</span>
	</router-link-context>
</li>
</template>

<script>
/* eslint-disable vue/require-prop-types */
/* eslint-disable vue/require-default-prop */
import { Icon } from 'aunsight-lib-ui';

import RouterLinkContext from '@/components/RouterLinkContext';

export default {
	name: 'SideBarItem',
	components: { Icon, RouterLinkContext },
	props: {
		item: { type: Object, required: true },
		condensed: {}
	},
	computed: {
		path () {
			return this.item.path;
		},
		dataId () {
			return this.item.id || this.item.urlPath;
		},
		iconHTML () {
			return this.item.iconHTML;
		},
		iconClass () {
			return this.item.iconClass;
		},
		iconName () {
			return this.item.icon;
		},
		title () {
			return this.item.title || 'Landing';
		}
	}
};
</script>

<style lang="scss" scoped>
@import 'app_variables';

.nav-label {
	margin-left: 1em;
}

.nav-pills .nav-link.active {
	font-weight: bold;
	background-color: rgba($white, 0.2);
}

</style>
