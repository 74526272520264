<template>
<div class="pz-query-text-view">
	<!-- put the v-for in a template wrapper so spacing can be added after each one -->
	<!-- normal spaces aren't preserved so using  &nbsp; -->
	<!-- span + span doesn't allow line breaks between them and normal spaces can't reliably be added between them, so using wbr -->
	<template v-for="(f, index) in fragments">
		<text-fragment
			:key="index"
			:text="f.text"
			:type="f.type" />&nbsp;<wbr :key="index+ 'wbr'">
	</template>

	<!-- in the case of text generation failure, make a note to the user. -->
	<error-message
		v-if="textError"
		:errors="[textError]"
		:title="textErrorTitle"
		:message="textErrorMessage" />
</div>
</template>

<script>
import { ErrorMessage } from 'aunsight-lib-ui';

import TextFragment from './TextFragment.vue';

export default {
	components: {
		TextFragment,
		ErrorMessage
	},

	props: {
		structuredQuery: {
			type: Object, required: true
		},
		datamart: {
			required: true, type: Object
		}
	},

	data () {
		return {
			/** @type {Error} */
			textError: null,

			textErrorTitle: 'Query Text Error',
			textErrorMessage: 'The query cannot be transcribed into text, please contact your Client Success Manager to troubleshoot'
		};
	},

	computed: {
		fragments () {
			// eslint-disable-next-line vue/no-side-effects-in-computed-properties
			this.textError = null;
			// don't try to make fragment if no table has been selected yet
			if (!this.structuredQuery.table) return;
			const engine = this.datamart.buildTextEngine();

			// can possibly throw error and the view will just stop updating
			try {
				const frags = engine.renderStructuredQuery(this.structuredQuery);
				return frags.parts;
			}
			catch (err) {
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				this.textError = err;
				return [];
			}
		}
	}
};

</script>

<style lang="scss">
.pz-query-text-view {
	// add line height to allow for pill styling
	line-height: 2em;

	// add some spaceing around
	margin: 1em;
}
</style>
