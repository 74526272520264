import _ from 'lodash';

/**
 * @typedef foreignTable
 * @type {Object}
 * @property {Column[]} columns
 * @property {string} id - id of table
 * @property {string} name - name of table
 * @property {string} root_key - if a local relation, the column id which links the two
 * @property {string} key - if a foreign relation, the column id that links the two
 */

/**
 * Get an easily consumable list of columns on related tables
 * @param  {Datamart} datamart
 * @param  {string} tableId - the table being queried
 * @return {foreignTable[]}
 */
function getRelationColumns (datamart, tableId) {
	const table = datamart.getTable(tableId);

	if (!table) return;

	const foreign = table.getForeignRelationColumns().map(col => {
		return {
			id: col.table.id,
			name: col.table.spec.name || col.table.id,
			key: col.id,
			columns: _.values(col.table.spec.properties)
		};
	});

	const local = table.getRelationColumns().map(col => {
		const tabId = col.spec.link.table || col.spec.link.view;
		const ctable = datamart.getTable(tabId);
		return {
			id: tabId,
			name: ctable.spec.name || tabId,
			root_key: col.id,
			columns: _.values(ctable.spec.properties)
		};
	});

	return [...foreign, ...local];
}

export default getRelationColumns;
