<template>
<div class="pz-taglist">
	<span
		v-for="tag in tags"
		:key="tag"
		class="badge badge-primary pz-tag">
		{{ tag }}
		<button
			v-if="isOwnQuery"
			type="button"
			class="pz-taglist-removebutton"
			aria-label="Remove tag"
			title="Remove tag"
			@click="removeTag(tag)">
			<span aria-hidden="true">&times;</span>
		</button>
	</span>

	<span
		v-if="isOwnQuery"
		v-tooltip
		:tabindex="disabled? 0 : null"
		class="d-inline-block"
		data-toggle="tooltip"
		:title="addButtonTooltip">
		<button
			v-if="!frequentTags.length"
			type="button"
			class="btn btn-sm btn-secondary pz-taglist-addbutton"
			:disabled="disabled"
			@click="addNewTag">

			<icon name="plus" /> &nbsp;
			<span>Add Tag</span>
		</button>
		<div v-else class="btn-group">
			<!-- only show add button if no suggested tags -->
			<button
				type="button"
				class="btn btn-sm btn-secondary pz-taglist-addbutton"
				:disabled="disabled"
				data-toggle="dropdown"
				aria-expanded="false">
				<icon name="plus" /> &nbsp;
				<span>Add Tag</span>
			</button>

			<!-- show dropdown if there are suggestions -->
			<div class="dropdown-menu">
				<button
					v-for="tag in shownFrequentTags"
					:key="tag"
					type="button"
					class="dropdown-item"
					@click="addTag(tag)">{{ tag }}</button>
				<button
					type="button"
					class="dropdown-item"
					@click="addNewTag">
					<icon name="plus" /> &nbsp;
					<span>Create New Tag</span>
				</button>
			</div>
		</div>
	</span>

	<!--for deleting tags -->
	<div v-if="isLoading" class="pz-taglist-load-progress-container">
		<mn-component-view
			:view="PromiseProgressDialog"
			:options="progressDialogOptions"
			@destroy="onClose" />
	</div>

	<add-tag-dialog
		:open="addTagDialogOpen"
		:tags="tags"
		:add-tag="addTag"
		@done="addTagDialogOpen = false" />
</div>
</template>

<script>
import { Icon, Tooltip } from 'aunsight-lib-ui';
import MnComponentView from 'aunsight-webapp/src/js/AWIComponents/MnComponentView.vue';
import AWIPrompt from 'aunsight-webapp/src/js/AWIComponents/Prompt/Prompt';
import PromiseProgressDialog from 'aunsight-webapp/src/js/modules/ProgressDialog/PromiseProgressDialog';
import $ from 'jquery';
import _ from 'lodash';

import AddTagDialog from '@/components/AddTagDialog.vue';
import app from '@/webapp';

export default {
	name: 'TagList',

	components: {
		Icon,
		MnComponentView,
		AddTagDialog
	},

	directives: {
		Tooltip
	},

	props: {
		query: {
			type: Object,
			required: true
		},
		context: {
			type: Object,
			required: true
		}
	},

	data () {
		return {
			isLoading: false,
			PromiseProgressDialog,
			progressDialogOptions: {},
			AWIPrompt,
			recentlyAdded: [],
			frequentTags: [],
			addTagDialogOpen: false
		};
	},

	computed: {
		isOwnQuery () {
			return _.get(this.query.created_by, 'id') === app.auth.user.id;
		},

		tags () {
			// remove recently added, sort, then add recent back onto the end.
			return _.without(this.query.tags, ...this.recentlyAdded)
				.sort()
				.concat(this.recentlyAdded);
		},

		disabled () {
			return _.size(this.tags) >= 5;
		},

		addButtonTooltip () {
			return this.disabled
				? 'You\'ve reached the 5 tags limit. Please remove a tag before adding a new one.'
				: 'Select additional tags or create a new tag';
		},

		shownFrequentTags () {
			const prevTags = this.query.tags || [];
			const tags = _.without(this.frequentTags, ...prevTags);
			return _.take(tags, 5);
		}
	},

	created () {
		this.fetchFrequentTags();
	},

	methods: {
		addTag (tag) {
			let tags = this.query.tags || [];
			tags = tags.concat(tag);

			const promise = $.ajax(app.apiPath + 'query/query/' + this.query.id, {
				method: 'PUT',
				data: {
					query: {
						id: this.query.id,
						tags
					}
				}
			}).then(() => {
				this.recentlyAdded.push(tag);
				this.$emit('tagsupdated', tags);
			});

			return promise;
		},

		addFrequentTag (tag) {
			const promise = this.addTag(tag);
			promise.then(() => this.onClose());

			this.isLoading = true;
			this.progressDialogOptions = {
				promises: [promise],
				showServerError: true,
				title: 'Adding tag',
				errorDisplay: {
					message: 'Unable to add tag'
				}
			};
		},

		removeTag (tag) {
			return AWIPrompt({
				type: 'confirm',
				text: 'Are you sure you want to remove this tag?'
			}).then(
				() => {
					const tags = _.without(this.query.tags, tag);

					const promise = $.ajax(app.apiPath + 'query/query/' + this.query.id, {
						method: 'PUT',
						data: {
							query: {
								id: this.query.id,
								tags
							}
						}
					}).then(() => {
						this.recentlyAdded = _.without(this.recentlyAdded, tag);
						this.$emit('tagsupdated', tags);
						this.onClose();
					});

					this.isLoading = true;
					this.progressDialogOptions = {
						promises: [promise],
						showServerError: true,
						title: 'Removing tag',
						errorDisplay: {
							message: 'Unable to remove tag'
						}
					};
					return promise;
				}
			);
		},

		onClose () {
			this.isLoading = false;
			this.progressDialogOptions = {};
		},

		fetchFrequentTags () {
			const url = `${app.apiPath}daybreak/config/${this.context.config}/scope/${this.context.scope}/tag?limit=10`;
			$.ajax(url).then((response) => {
				this.frequentTags = response.result;
			});
		},

		addNewTag () {
			this.addTagDialogOpen = true;
		}
	}
};
</script>
<style lang="scss">
@import 'app_variables';

.pz-taglist {
	.dropdown-menu {
		z-index: 1901;
	}

	.pz-tag {
		// changing size of text to be btn-sm
		font-size: $btn-font-size-sm;

		// space between items
		margin-right: 0.5rem;

		// make same size as button
		vertical-align: middle;
		font-weight: $btn-font-weight;
		line-height: $btn-line-height-sm;
		padding: $btn-padding-y-sm $btn-padding-x-sm $btn-padding-y-sm $btn-padding-x-sm;
	}

	// wrapper tooltip won't display without this
	.pz-taglist-addbutton:disabled {
		pointer-events: none;
	}

	.pz-taglist-removebutton {
		// make size consistent with text
		font-size: inherit;

		// no normal button style
		padding: 0;
		background-color: transparent;
		border: 0;

		// center it between text and edge
		padding-right: 0.3rem;
		margin-right: -0.5rem;

		// expand click area without expanding visual space
		padding-top: 0.3rem;
		padding-bottom: 0.3rem;
		margin-top: -0.3rem;
		margin-bottom: -0.3rem;

		// hide when not hovered
		color: transparent;

		// make shown when hovered or focused
		&:hover,
		&:focus {
			color: $white;
		}
	}
}
</style>
